import { skipToken } from "@reduxjs/toolkit/query";
import { rowsPerPage } from "@src/helpers/constants";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  callApi,
  useGetCallCountsQuery,
  useGetCallsQuery,
  useGetConversationQuery,
} from "@src/store/call/api";
import {
  getFilterBySiteId,
  getFilterByTeamId,
  getIsFilterByAssignedToCurrentUser,
  getIsFilterByUnassigned,
  getStatusCursor,
} from "@src/store/call/callSlice";
import type {
  CallCounts,
  CallSearchParams,
  InboxListItem,
} from "@src/store/call/interfaces";
import { setHideMobileNavbar } from "@src/store/ui/actions";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useConversationSearchParams from "./useConversationSearchParams";
import { useFilterParams } from "./useFilterParams";
import { usePrefetchConversations } from "./usePrefetchConversations";

const DEFAULT_CALL_COUNTS: CallCounts = {
  total_completed: 0,
  total_reviews: 0,
  incoming_calls: 0,
  me: 0,
  teams: [],
  unassigned: 0,
} as const;

export const useCallManagement = () => {
  const { selectedConversationId, setSelectedConversationId } =
    useConversationSearchParams();
  const { filters, updateFilters } = useFilterParams();

  // Add memoization for these selectors if they aren't already memoized in the slice
  const isFilterByAssignedToCurrentUser = useAppSelector(
    getIsFilterByAssignedToCurrentUser,
  );
  const isFilterByUnassigned = useAppSelector(getIsFilterByUnassigned);
  const filterByTeamId = useAppSelector(getFilterByTeamId);
  const filterBySiteId = useAppSelector(getFilterBySiteId);
  const currentCursor = useAppSelector((state) =>
    getStatusCursor(state, filters.status),
  );

  const dispatch = useAppDispatch();

  // Track previous filters to prevent unnecessary API calls
  const prevFiltersRef = useRef({
    status: filters.status,
    search: filters.search,
  });

  // Memoize the search params object to prevent unnecessary re-renders
  const callSearchParams: CallSearchParams = useMemo(() => {
    return {
      limit: rowsPerPage,
      q: filters.search,
      categories: filters.reasons,
      is_new_user: filters.isNewUser,
      is_urgent: filters.isUrgent,
      is_external: filters.isExternal,
      is_care_team: filters.isCareTeam,
      assigned_to_me: isFilterByAssignedToCurrentUser ? 1 : 0,
      unassigned: isFilterByUnassigned ? 1 : 0,
      team_id: filterByTeamId,
      site_id: filterBySiteId,
    };
  }, [
    filters.search,
    filters.reasons,
    filters.isNewUser,
    filters.isUrgent,
    filters.isExternal,
    filters.isCareTeam,
    isFilterByAssignedToCurrentUser,
    isFilterByUnassigned,
    filters,
    filterByTeamId,
    filterBySiteId,
  ]);

  const queryParams = useMemo(
    () => ({
      status: filters.status,
      params: callSearchParams,
      cursor: currentCursor,
    }),
    [filters.status, callSearchParams, currentCursor],
  );

  // Use the memoized query params
  const { isLoading, isFetching, data } = useGetCallsQuery(queryParams, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  useEffect(() => {
    const pollForNewData = async () => {
      try {
        const pollQueryParams = {
          status: filters.status,
          params: callSearchParams,
          cursor: -1, // Always poll from the beginning
        };

        const _response = await dispatch(
          callApi.endpoints.getCalls.initiate(pollQueryParams, {
            forceRefetch: true,
          }),
        ).unwrap();
      } catch (error) {
        console.error("Error polling for new data:", error);
      }
    };

    // Set up polling interval
    const pollInterval = setInterval(pollForNewData, 30000);

    // Clean up on unmount
    return () => clearInterval(pollInterval);
  }, [dispatch, filters.status, callSearchParams]);

  const { data: callCounts = DEFAULT_CALL_COUNTS } = useGetCallCountsQuery(
    filterBySiteId ? Number(filterBySiteId) : skipToken,
    {
      pollingInterval: 30000,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  // Memoize conversation query params
  const conversationQueryParam = useMemo(
    () => (selectedConversationId ? Number(selectedConversationId) : skipToken),
    [selectedConversationId],
  );

  const { data: call } = useGetConversationQuery(conversationQueryParam, {
    pollingInterval: 30000,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  // Use useCallback for event handlers
  const handleCloseCallDetails = useCallback(() => {
    setSelectedConversationId(null);
    dispatch(setHideMobileNavbar(false));
  }, [dispatch, setSelectedConversationId]);

  // Only trigger API calls when specific filter conditions change
  useEffect(() => {
    const prevFilters = prevFiltersRef.current;

    // Only refetch when certain filters change
    if (
      prevFilters.status !== filters.status ||
      prevFilters.search !== filters.search
    ) {
      dispatch(
        callApi.endpoints.getCalls.initiate(queryParams, {
          forceRefetch: true,
        }),
      );

      // Update the ref with current values
      prevFiltersRef.current = {
        status: filters.status,
        search: filters.search,
      };
    }
  }, [dispatch, filters.status, filters.search, queryParams]);

  // Prefetch conversations only when inbox data changes
  usePrefetchConversations(data?.data, Number(selectedConversationId));

  // Memoize nextConversation calculation
  const nextConversation = useMemo(() => {
    if (!selectedConversationId || !data?.data) {
      return null;
    }

    const currentIndex = data.data.findIndex(
      (call) =>
        call.items[0].data.conversation_id === Number(selectedConversationId),
    );

    if (currentIndex === -1) {
      return null;
    }

    if (currentIndex + 1 < data.data.length) {
      return {
        patient: data.data[currentIndex + 1].patient,
        items: data.data[currentIndex + 1].items,
      };
    }

    if (currentIndex > 0) {
      return {
        patient: data.data[currentIndex - 1].patient,
        items: data.data[currentIndex - 1].items,
      } as InboxListItem;
    }

    return null;
  }, [selectedConversationId, data?.data]);

  return {
    call,
    data,
    filters,
    isLoading,
    isFetching,
    nextConversation,
    callCounts,
    selectedConversationId,
    callSearchParams,
    setSelectedConversationId,
    handleCloseCallDetails,
    updateFilters,
  };
};
