import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Alert, AlertTitle } from "@mui/material";
import DeactivateModal from "@src/components/Settings/Admin/DeactivateModal";
import FeatureFlagToggle from "@src/components/Settings/Admin/FeatureFlagToggle";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  deactivateAccount,
  selectDeactivateStatus,
} from "@src/store/admin/adminSlice";
import { FeatureFlags } from "@src/store/types";
import { selectUser } from "@src/store/user/userReducer";
import { useCallback, useEffect, useState } from "react";
import { useGetOrganizationPromptQuery } from "src/store/organization/api";
import { useUpdateOrganizationPromptMutation } from "src/store/organization/api";
import { ReactComponent as DangerIcon } from "../../assets/icons/exclamation-triangle.svg";
import Button from "../../components/Basic/Button";
import Loader from "../../components/Basic/Loader";
import SettingsContainer from "../../components/Settings/SettingsContainer";

const AdminSettings = () => {
  const user = useAppSelector(selectUser);
  const deactivateStatus = useAppSelector(selectDeactivateStatus);
  const dispatch = useAppDispatch();
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);

  const { data: organizationPrompt, isLoading: isLoadingOrganizationPrompt } =
    useGetOrganizationPromptQuery();

  const [organizationPromptState, setOrganizationPromptState] = useState(
    organizationPrompt?.prompt?.replace(/\\n/g, "\n") || "",
  );

  const [
    updateOrganizationPrompt,
    { isLoading: isUpdatingOrganizationPrompt },
  ] = useUpdateOrganizationPromptMutation();

  const handlePromptRetrieval = useCallback((value: string) => {
    let processed = value;

    // Remove wrapping quotes if present
    if (processed.startsWith('"') && processed.endsWith('"')) {
      try {
        // Try to parse it as JSON (handles all escaping correctly)
        processed = JSON.parse(processed) as string;
      } catch (_) {
        // If JSON parsing fails, manually remove quotes
        processed = processed.substring(1, processed.length - 1);
      }
    }

    // Handle any remaining escaped characters
    // This handles double-escaped newlines and quotes that might remain
    processed = processed
      .replace(/\\\\n/g, "\n")
      .replace(/\\n/g, "\n")
      .replace(/\\\\"/g, '"')
      .replace(/\\"/g, '"');

    return processed;
  }, []);

  const handlePromptSaving = useCallback((value: string) => {
    // First escape quotes
    let processed = value.replace(/"/g, '\\"');

    // Then escape newlines
    processed = processed.replace(/\n/g, "\\n");

    return processed;
  }, []);

  useEffect(() => {
    if (organizationPrompt) {
      setOrganizationPromptState(
        handlePromptRetrieval(organizationPrompt.prompt),
      );
    }
  }, [organizationPrompt, handlePromptRetrieval]);

  const handleDeactivateAccount = () => {
    dispatch(deactivateAccount());
  };

  return (
    <SettingsContainer>
      <div className="w-full md:max-w-2xl">
        {deactivateStatus.success && (
          <Alert severity="error" className="mb-6">
            <AlertTitle>Account Deactivated</AlertTitle>
            <p>
              Your account has been deactivated. You will be logged out shortly.
            </p>
          </Alert>
        )}

        {deactivateStatus.error && (
          <Alert severity="error" className="mb-6">
            <AlertTitle>Error</AlertTitle>
            <p>{deactivateStatus.error}</p>
          </Alert>
        )}

        <h1 className="text-2xl font-bold">{user.organization.name}</h1>
        <h2 className="text-base font-medium mt-2 text-tertiary">
          Feature Flags
        </h2>
        <div className="mt-6 space-y-6">
          <FeatureFlagToggle
            flag={FeatureFlags.SCRIBE_ONLY}
            title="Scribe"
            description="Enable scribe functionality for this clinic"
          />

          <FeatureFlagToggle
            flag={FeatureFlags.FRONT_DESK_INBOX}
            title="Front Desk Inbox"
            description="Enable front desk inbox functionality"
          />

          <FeatureFlagToggle
            flag={FeatureFlags.CCM}
            title="CCM"
            description="Enable CCM functionality"
          />

          <FeatureFlagToggle
            flag={FeatureFlags.CUSTOMIZE_CALLS}
            title="Customize Calls"
            description="Enable customize calls functionality"
          />

          <hr className="border-gray-250 w-full my-6" />

          <h2 className="text-base font-semibold mb-2">Organization Prompt</h2>
          <div>
            {isLoadingOrganizationPrompt ? (
              <Loader size={5} borderWidth={2} />
            ) : (
              <TextareaAutosize
                className="border border-gray-300 p-2 w-full resize-none px-3 py-2.5 outline-none focus:outline-none
                  rounded-lg align-top scrollbar"
                maxRows={20}
                minRows={2}
                aria-label="Organization Prompt"
                placeholder="Organization Prompt"
                value={organizationPromptState}
                onChange={(e) => {
                  setOrganizationPromptState(e.target.value);
                }}
              />
            )}
            <Button
              variant="primary"
              className="mt-4 ml-auto"
              onClick={() => {
                updateOrganizationPrompt(
                  handlePromptSaving(organizationPromptState),
                );
              }}
              disabled={isUpdatingOrganizationPrompt}
            >
              {isUpdatingOrganizationPrompt ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>

        <hr className="border-gray-250 w-full my-6" />

        <div className="flex items-center space-x-2 mb-2">
          <DangerIcon width="20" height="20" />
          <h2>Danger Zone</h2>
        </div>

        <div className="space-y-4">
          <Button
            variant="red-filled-light"
            disabled={deactivateStatus.loading}
            onClick={() => setOpenDeactivateModal(true)}
          >
            {deactivateStatus.loading
              ? "Deactivating..."
              : "Deactivate Account"}
          </Button>
        </div>
      </div>

      {openDeactivateModal && (
        <DeactivateModal
          onCancel={() => setOpenDeactivateModal(false)}
          onConfirm={handleDeactivateAccount}
        />
      )}
    </SettingsContainer>
  );
};

export default AdminSettings;
