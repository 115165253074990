import { getFormattedPhoneNumber } from "@src/helpers/helpers";
import { updateClinic } from "@src/store/user/thunks";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../Basic/Button";
import Input from "../../Basic/Input";

const EditClinicForm = ({ user, className = "", onClose }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user.customer.name,
    },
  });

  const onSubmit = (data) => {
    dispatch(
      updateClinic(
        data.name,
        user.customer.display_name,
        user.customer.legacy_phone_number,
        user.customer.clinic_address,
        onClose,
      ),
    );
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="min-h-0 flex flex-col overflow-y-auto scrollbar">
        <form
          id="edit-clinic"
          onSubmit={handleSubmit(onSubmit)}
          className="px-5 space-y-4"
        >
          <Input
            label="Clinic Name"
            register={register}
            name="name"
            placeholder="Type clinic name"
            required="Clinic name can't be empty"
            withoutAsterisk
            validate={(value) => {
              if (value.trim()) {
                return true;
              }
              return "Clinic name can't be empty";
            }}
            error={errors.name}
          />

          <div className="text-sm space-y-2">
            <p className="font-semibold">Voice Agent</p>
            <p className="text-tertiary font-medium">
              {getFormattedPhoneNumber(user.customer.phone_number)}
            </p>
          </div>
        </form>
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button className="w-full md:w-fit" form="edit-clinic" type="submit">
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditClinicForm;
