import { ReactComponent as PlusIcon } from "@assets/icons/plus-small.svg";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "@src/helpers/constants";
import { makeSlug } from "@src/helpers/helpers";
import { useAppDispatch, useAppSelector } from "@src/store";
import { setOpenEditInfoModalAction } from "@src/store/patient/actions";
import { getPatientInfo } from "@src/store/patient/thunks";
import { selectUser } from "@src/store/user/userReducer";
import { NavLink } from "react-router";

interface AddPhoneNumberProps {
  patientId: number;
  call?: boolean;
}

const AddPhoneNumber = ({ patientId, call }: AddPhoneNumberProps) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const patientPanelTab = call
    ? makeSlug(patientPanelTabs.CALL)
    : makeSlug(patientPanelTabs.MESSAGE);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-gray-background">
      <div className="w-12 h-12 rounded-full flex items-center justify-center bg-primary-blue-light mb-5">
        <PlusIcon width="20" height="20" />
      </div>
      <p className="text-xl lg:text-2xl font-bold mb-1">No Phone Number</p>
      <p className="text-sm lg:text-base font-medium text-tertiary mb-6">
        Add phone number to {call ? "call" : "message"} patient
      </p>
      {!user.customer.has_ehr_data && (
        <NavLink
          replace
          to={`${Pages.PATIENTS}/${patientId}/?tab=${makeSlug(patientProfileTabs.INFORMATION)}&panel=${patientPanelTab}`}
          className="text-sm font-semibold h-10 px-3.5 bg-primary-blue text-white flex items-center justify-center rounded-lg"
          onClick={() => {
            dispatch(getPatientInfo(patientId));
            dispatch(setOpenEditInfoModalAction(true));
          }}
        >
          Add Phone Number
        </NavLink>
      )}
    </div>
  );
};

export default AddPhoneNumber;
