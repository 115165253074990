import { ReactComponent as DeleteIcon } from "@assets/icons/trash-redesign.svg";
import { ReactComponent as UserRoundedIcon } from "@assets/icons/user-rounded.svg";
import type { User } from "@src/store/user/userReducer";
import { useMemo, useState } from "react";
import { Link } from "react-router";
import { useAppDispatch } from "../../../store";
import { deleteScribe } from "../../../store/voiceRecorder/thunks";
import ActionsButton from "../../Basic/ActionsButton";
import Loader from "../../Basic/Loader";
import DeleteModal from "../../Campaigns/DeleteModal";
import RecordingIndicator from "../RecordingIndicator";
import { MOCK_PATIENT_FIRST_NAME } from "../consts";
import useRecordingDuration from "../hooks/useRecordingDuration";
import { SCRIBE_SEARCH_PARAM } from "../hooks/useScribeSearchParams";
import type { ScribeGroupItem } from "../store/selectors";
import { getProviderNotes } from "../store/thunks";

const ScribeListItem = ({
  user,
  scribe,
  isSelected,
  isFrontDesk,
  isFrontDeskInbox,
}: {
  user: User;
  scribe: ScribeGroupItem;
  isSelected: boolean;
  isFrontDesk: boolean;
  isFrontDeskInbox: boolean;
}) => {
  const duration = useRecordingDuration(scribe);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isSystemAdmin = user?.email?.endsWith("@quadrant.health");
  const isSuperAdmin = user?.role_id === 0;

  const showProviderDetails = isSystemAdmin || isSuperAdmin;

  const dispatch = useAppDispatch();

  const className = useMemo(
    () =>
      `flex justify-between p-3 py-2 cursor-pointer rounded-lg border border-transparent hover:bg-white group ${
        isSelected
          ? "md:bg-[#d5dce3] md:hover:bg-[#d5dce3]"
          : "hover:border-gray-foreground"
      }`,
    [isSelected],
  );

  const showPatient =
    scribe.patient &&
    // Hack for unassigned patient
    scribe.patient.first_name !== null &&
    scribe.patient.first_name !== MOCK_PATIENT_FIRST_NAME;
  // End hack

  const onDelete = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteScribe(scribe.audioId, () => {
        dispatch(getProviderNotes({}));
        setIsDeleteModalOpen(false);
      }),
    );
  };

  const ProviderDetails = () => {
    return (
      <div className="flex space-x-3">
        <p>
          {[scribe.providerName?.trim(), duration]
            .filter((field) => !!field)
            .join(" ・ ")}
        </p>
      </div>
    );
  };

  const DetailsWithPatient = () => {
    return (
      <div className="flex space-x-3">
        <p>
          {[
            `${scribe.patient.first_name} ${scribe.patient.last_name}`.trim(),
            duration,
          ]
            .filter((field) => !!field)
            .join(" ・ ")}
        </p>
      </div>
    );
  };

  const DetailsWithoutPatient = () => {
    return (
      <p className="flex">
        {(isFrontDesk || isFrontDeskInbox) && (
          <UserRoundedIcon width="16" height="16" stroke="#667085" />
        )}
        {duration
          ? `${!(isFrontDesk || isFrontDeskInbox) ? "" : " ・ "}${duration}`
          : ""}
      </p>
    );
  };

  const ScribeDetails = () => {
    return (
      <>
        {showProviderDetails && <ProviderDetails />}
        {!showProviderDetails && showPatient && <DetailsWithPatient />}
        {!showProviderDetails && !showPatient && <DetailsWithoutPatient />}
        <p className="text-xs text-[#A0A6B2]">{scribe.time}</p>
      </>
    );
  };

  return (
    <>
      <Link
        to={{
          search: `?${SCRIBE_SEARCH_PARAM}=${scribe.audioId}`,
        }}
        className={className}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between">
            <p
              className={`w-50 h-5 text-sm font-semibold truncate text-primary
              ${isSelected ? "" : "group-hover:text-primary-blue"}`}
            >
              {scribe.title}
            </p>
            <div className="min-w-10 flex justify-end">
              {!scribe.isGenerating && !scribe.isRecording && (
                <>
                  {!scribe.isPaused && (
                    <ActionsButton
                      buttonClassName="w-5 h-5 rounded-md"
                      className="md:hidden"
                      actions={[
                        {
                          icon: (
                            <DeleteIcon
                              width="20"
                              height="20"
                              className="flex-none"
                            />
                          ),
                          label: "Delete",
                          onClick: onDelete,
                        },
                      ]}
                    />
                  )}

                  <button
                    type="button"
                    className="hidden md:flex flex-none opacity-0 md:group-hover:opacity-100 md:justify-end"
                    onClick={onDelete}
                  >
                    <DeleteIcon width="16" height="16" />
                  </button>
                </>
              )}
              {scribe.isGenerating && (
                <Loader
                  size={4}
                  borderWidth={2}
                  borderColor="border-zinc-450"
                />
              )}
              {((!scribe.isGenerating && scribe.isRecording) ||
                scribe.isPaused) && (
                <RecordingIndicator
                  isRecording={!scribe.isPaused}
                  size={20}
                  className={scribe.isPaused ? "md:group-hover:hidden" : ""}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between text-xs text-[#404654]">
            <ScribeDetails />
          </div>
        </div>
      </Link>

      {isDeleteModalOpen && (
        <DeleteModal
          title="Delete Encounter"
          description="Are you sure you want to delete the note?"
          onSubmit={handleDelete}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ScribeListItem;
