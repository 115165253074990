import { ReactComponent as TemplatesIcon } from "@assets/icons/notes-templates.svg";
import { ReactComponent as PenIcon } from "@assets/icons/pen.svg";
import { ReactComponent as PlusCircleIcon } from "@assets/icons/plus-circle.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/plus.svg";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  sectionDetailLevels,
  sectionStyles,
} from "../../../../helpers/constants";
import { onDragEnd } from "../../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectUser } from "../../../../store/user/userReducer";
import Button from "../../../Basic/Button";
import DeleteModalWithoutWarning from "../../../Basic/DeleteModalWithoutWarning";
import Switch from "../../../Basic/Switch";
import {
  type NoteTemplate,
  selectDefaultProviderNoteTemplate,
  selectNoteTemplates,
  selectSelectedNoteTemplate,
  setDefaultProviderNoteTemplate,
  setSelectedNoteTemplate,
} from "../../../IndependentScribe/store/noteTemplateSlice";
import {
  createTemplate,
  createTemplateSection,
  deleteTemplate,
  deleteTemplateSection,
  describeNoteTemplate,
  getNoteTemplates,
  updateNoteTemplate,
  updateTemplateSectionsOrder,
} from "../../../IndependentScribe/store/thunks";
import Dropdown from "../Dropdown/Dropdown";
import CreateTemplateModal from "./CreateTemplateModal";
import SectionPreview from "./SectionPreview";
import TemplateEditModal from "./TemplateEditModal";
import TemplateSectionCard from "./TemplateSectionCard";

const NotesTemplates = () => {
  const user = useAppSelector(selectUser);
  const defaultProviderNoteTemplate = useAppSelector(
    selectDefaultProviderNoteTemplate,
  );
  const noteTemplates = useAppSelector(selectNoteTemplates);
  const selectedNoteTemplate = useAppSelector(selectSelectedNoteTemplate);
  // const [showICD10, setShowICD10] = useState(false);
  const [showAdditionalInstructions, setShowAdditionalInstructions] =
    useState(false);
  const [openCreateTemplateModal, setOpenCreateTemplateModal] = useState(false);
  const [openAdditionalInstructionsModal, setOpenAdditionalInstructionsModal] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [sections, setSections] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNoteTemplates());
  }, [dispatch]);

  useEffect(() => {
    // preselect default note template if no selected
    if (selectedNoteTemplate) {
      return;
    }

    if (defaultProviderNoteTemplate) {
      dispatch(setSelectedNoteTemplate(defaultProviderNoteTemplate));
    } else if (user?.note_template_id && noteTemplates.length > 0) {
      dispatch(
        describeNoteTemplate(user.note_template_id, (data) =>
          dispatch(setSelectedNoteTemplate(data)),
        ),
      );
    } else if (user?.customer?.note_template_id && noteTemplates.length > 0) {
      dispatch(
        describeNoteTemplate(user.customer.note_template_id, (data) =>
          dispatch(setSelectedNoteTemplate(data)),
        ),
      );
    }
  }, [
    defaultProviderNoteTemplate,
    selectedNoteTemplate,
    user,
    noteTemplates,
    dispatch,
  ]);

  useEffect(() => {
    setShowAdditionalInstructions(!!selectedNoteTemplate?.custom_instructions);
    // setShowICD10(!!selectedNoteTemplate?.show_icd10_codes_suggestions);
    setSections(selectedNoteTemplate?.sections);
  }, [selectedNoteTemplate]);

  useEffect(() => {
    if (
      sections?.length > 0 &&
      selectedNoteTemplate?.sections?.length > 0 &&
      JSON.stringify(sections) !==
        JSON.stringify(selectedNoteTemplate?.sections)
    ) {
      const newSectionsOrder = sections.map((section, index) => ({
        note_template_section_id: section.note_template_section_id,
        order: index + 1,
      }));
      dispatch(
        updateTemplateSectionsOrder(
          selectedNoteTemplate.note_template_id,
          newSectionsOrder,
        ),
      );
    }
  }, [sections, selectedNoteTemplate, dispatch]);

  const handleSelectTemplate = (template: NoteTemplate) => {
    if (template.note_template_id !== selectedNoteTemplate?.note_template_id) {
      dispatch(
        describeNoteTemplate(template.note_template_id, (data: NoteTemplate) =>
          dispatch(setSelectedNoteTemplate(data)),
        ),
      );
    }
  };

  // const handleUpdateShowICD10 = () => {
  //   dispatch(
  //     updateNoteTemplate(
  //       selectedNoteTemplate.note_template_id,
  //       selectedNoteTemplate.name,
  //       selectedNoteTemplate.note_language,
  //       selectedNoteTemplate.custom_instructions,
  //       !showICD10,
  //     ),
  //   );
  //   setShowICD10(!showICD10);
  // };

  const handleUpdateInstructions = (instructions) => {
    dispatch(
      updateNoteTemplate(
        selectedNoteTemplate.note_template_id,
        selectedNoteTemplate.name,
        selectedNoteTemplate.note_language,
        instructions,
        selectedNoteTemplate.show_icd10_codes_suggestions,
        selectedNoteTemplate.is_hidden,
        () => {
          setOpenAdditionalInstructionsModal(false);
          dispatch(
            describeNoteTemplate(
              selectedNoteTemplate.note_template_id,
              (data) => dispatch(setSelectedNoteTemplate(data)),
            ),
          );
        },
      ),
    );
  };

  const handleUpdateTemplateName = (newTitle) => {
    if (
      selectedNoteTemplate &&
      selectedNoteTemplate.name.trim() !== newTitle.trim()
    ) {
      dispatch(
        updateNoteTemplate(
          selectedNoteTemplate.note_template_id,
          newTitle.trim(),
          selectedNoteTemplate.note_language,
          selectedNoteTemplate.custom_instructions,
          selectedNoteTemplate.show_icd10_codes_suggestions,
          selectedNoteTemplate.is_hidden,
          () => {
            dispatch(
              describeNoteTemplate(
                selectedNoteTemplate.note_template_id,
                (data) => {
                  dispatch(setSelectedNoteTemplate(data));
                  if (
                    defaultProviderNoteTemplate?.note_template_id ===
                    selectedNoteTemplate.note_template_id
                  ) {
                    dispatch(setDefaultProviderNoteTemplate(data));
                  }
                },
              ),
            );
          },
        ),
      );
    }
  };

  const handleCreateTemplate = () => {
    setOpenCreateTemplateModal(true);
  };

  const handleCreateTemplateFromScratch = (data: any) => {
    const sections = data.sections.map((section, index) => ({
      name: section.title,
      section_style: sectionStyles.AUTO,
      hide_by_default: 0,
      subsections: [],
      detail_level: sectionDetailLevels.NORMAL,
      split_by_problem: 0,
      include_differential: 0,
      order: index + 1,
      has_subsections: 0,
    }));
    dispatch(
      createTemplate(
        data.title,
        user.settings.scribe_note_language,
        data.custom_instructions,
        sections,
        () => setOpenCreateTemplateModal(false),
      ),
    );
  };

  const handleRemoveCustomTemplate = (item) => {
    setTemplateToDelete(item);
    setOpenDeleteModal(true);
  };

  const onConfirmDelete = () => {
    if (
      selectedNoteTemplate.note_template_id ===
      templateToDelete.note_template_id
    ) {
      dispatch(setSelectedNoteTemplate(null));
    }

    dispatch(deleteTemplate(templateToDelete.note_template_id));
    setTemplateToDelete(null);
    setOpenDeleteModal(false);
  };

  const handleRemoveSection = (section) => {
    dispatch(
      deleteTemplateSection(
        selectedNoteTemplate.note_template_id,
        section.note_template_section_id,
      ),
    );
  };

  const handleCreateSection = () => {
    const sectionOrder = selectedNoteTemplate.sections.length + 1;
    dispatch(
      createTemplateSection(
        selectedNoteTemplate.note_template_id,
        sectionOrder,
      ),
    );
  };

  const handleHidePreMadeTemplate = (template) => {
    dispatch(
      updateNoteTemplate(
        template.note_template_id,
        template.name,
        template.note_language,
        template.custom_instructions,
        template.show_icd10_codes_suggestions,
        !template.is_hidden,
      ),
    );
  };

  return (
    <div className="w-full h-full md:overflow-hidden md:flex relative">
      <div className="w-full md:w-2/3 2xl:w-full overflow-hidden md:pr-6 2xl:pr-[392px]">
        <div className="h-full overflow-y-auto scrollbar md:pr-2">
          <p className="font-semibold text-base mb-3">Note Template</p>
          <Dropdown
            customItems={noteTemplates.filter((template) => template.is_custom)}
            preMadeItems={noteTemplates.filter(
              (template) => !template.is_custom,
            )}
            selectedItem={selectedNoteTemplate}
            onSelectItem={handleSelectTemplate}
            onCreateItem={handleCreateTemplate}
            onUpdateTitle={handleUpdateTemplateName}
            onRemoveCustomItem={handleRemoveCustomTemplate}
            onHidePreMadeItem={handleHidePreMadeTemplate}
            icon={
              <TemplatesIcon
                width="20"
                height="20"
                stroke="#667085"
                className="flex-none"
              />
            }
            itemName="template"
            closeDropdownOnSelect
          />

          {selectedNoteTemplate?.sections && (
            <>
              <hr className="my-6 border-gray-foreground" />
              <div className="space-y-4">
                <p className="font-semibold text-base">Sections settings</p>
                {selectedNoteTemplate.sections.map((section) => (
                  <TemplateSectionCard
                    key={section.note_template_section_id}
                    section={section}
                    removable={!!selectedNoteTemplate.is_custom}
                    onRemoveSection={() => handleRemoveSection(section)}
                  />
                ))}
              </div>
            </>
          )}
          {!!selectedNoteTemplate?.is_custom && (
            <button
              type="button"
              onClick={handleCreateSection}
              className="flex items-center space-x-2 mt-4"
            >
              <PlusCircleIcon
                width="20"
                height="20"
                stroke="#2970FF"
                className="flex-none"
              />
              <p className="text-sm font-semibold text-primary-blue">
                Add section
              </p>
            </button>
          )}

          {selectedNoteTemplate && (
            <>
              <hr className="my-6 border-gray-foreground" />
              {/*<div*/}
              {/*  className="w-full flex items-center justify-between p-5 space-x-2 rounded-lg bg-white*/}
              {/*    border border-gray-foreground mb-4"*/}
              {/*>*/}
              {/*  <div className="flex items-center space-x-2">*/}
              {/*    <p className="text-base font-semibold text-tertiary">*/}
              {/*      Show ICD-10 code suggestions*/}
              {/*    </p>*/}
              {/*    <div className="rounded-full text-primary-blue text-xs font-semibold py-1 px-2 bg-primary-blue-light">*/}
              {/*      BETA*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <Switch*/}
              {/*    size="medium-large"*/}
              {/*    enabled={showICD10}*/}
              {/*    onChange={handleUpdateShowICD10}*/}
              {/*  />*/}
              {/*</div>*/}

              <div className="p-5 rounded-lg bg-white border border-gray-foreground">
                <div className="w-full flex items-center justify-between space-x-2">
                  <div className="flex items-center space-x-2">
                    <p className="text-base font-semibold text-tertiary">
                      Additional instructions
                    </p>
                    <div className="rounded-full text-primary-blue text-xs font-semibold py-1 px-2 bg-primary-blue-light">
                      BETA
                    </div>
                  </div>
                  {selectedNoteTemplate.custom_instructions ? (
                    <Switch
                      size="medium-large"
                      enabled={true}
                      onChange={() => handleUpdateInstructions(null)}
                    />
                  ) : (
                    <Button
                      variant="cancel"
                      onClick={() => setOpenAdditionalInstructionsModal(true)}
                      className="space-x-2"
                    >
                      <PlusIcon
                        width="20"
                        height="20"
                        stroke="#2970FF"
                        className="flex-none"
                      />
                      <p>Add</p>
                    </Button>
                  )}
                </div>
                {showAdditionalInstructions && (
                  <div className="mt-5 rounded-lg bg-gray-110 p-5 border border-gray-foreground relative group">
                    <p className="whitespace-pre-wrap text-sm text-zinc-700 font-medium">
                      {selectedNoteTemplate.custom_instructions ||
                        "No additional instructions"}
                    </p>
                    <button
                      type="button"
                      className="absolute top-2 right-2 w-8 h-8 rounded-lg flex items-center justify-center bg-white
                        border border-gray-foreground opacity-0 group-hover:opacity-100 transition-opacity"
                      onClick={() => setOpenAdditionalInstructionsModal(true)}
                    >
                      <PenIcon width="16" height="16" stroke="#667085" />
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {sections?.length > 0 && (
        <div className="hidden md:flex absolute top-0 right-0 overflow-hidden h-full w-1/3 2xl:w-[368px]">
          <div
            className="w-full min-h-0 h-fit max-h-full flex pr-1 py-1 rounded-lg bg-white border border-gray-foreground
              overflow-hidden"
          >
            <div className="w-full min-h-0 max-h-full flex flex-col pl-4 py-5">
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, sections, setSections)}
              >
                <Droppable droppableId="droppable-section-previews">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="space-y-6 overflow-y-auto pr-3 scrollbar"
                    >
                      {sections.map((section, index) => (
                        <SectionPreview
                          key={section.note_template_section_id}
                          index={index}
                          section={section}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      )}

      {openCreateTemplateModal && (
        <CreateTemplateModal
          onClose={() => setOpenCreateTemplateModal(false)}
          onCreateFromScratch={handleCreateTemplateFromScratch}
        />
      )}

      {openAdditionalInstructionsModal && selectedNoteTemplate && (
        <TemplateEditModal
          template={selectedNoteTemplate}
          onClose={() => setOpenAdditionalInstructionsModal(false)}
          onSubmit={(data) => {
            handleUpdateInstructions(data.custom_instructions);
          }}
        />
      )}

      {openDeleteModal && (
        <DeleteModalWithoutWarning
          onClose={() => setOpenDeleteModal(false)}
          onDelete={onConfirmDelete}
          title="Delete Template"
          description="Are you sure you want to delete this template?"
        />
      )}
    </div>
  );
};

export default NotesTemplates;
