import Modal from "@src/components/Basic/Modal";
import ModalMobile from "@src/components/Basic/ModalMobile";
import SiteForm from "@src/components/Settings/Clinic/SiteForm";
import type { BasicSite } from "@src/store/voiceAgent/types";
import type React from "react";
import { useMediaQuery } from "react-responsive";

interface SiteModalProps {
  open: boolean;
  onClose: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onSave: (data) => void;
  selectedSite: BasicSite;
}

const SiteModal = ({ open, selectedSite, onClose, onSave }: SiteModalProps) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && selectedSite && (
      <Modal className="pt-5 rounded-2xl">
        <div className="grow md:grow-0 sm:w-168 overflow-hidden flex flex-col">
          <p className="text-base md:text-xl font-semibold mb-5 px-5">
            {selectedSite.site_id ? "Edit Site" : "Add Site"}
          </p>
          <SiteForm site={selectedSite} onSave={onSave} onCancel={onClose} />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      header={selectedSite?.site_id ? "Edit Site" : "Add Site"}
      onClickAway={onClose}
      className=""
    >
      {selectedSite && (
        <SiteForm
          site={selectedSite}
          onSave={onSave}
          onCancel={onClose}
          className="py-2"
        />
      )}
    </ModalMobile>
  );
};

export default SiteModal;
