import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-down-thin.svg";
import { type RootState, useAppDispatch, useAppSelector } from "@src/store";
import { selectSite } from "@src/store/site/thunks";
import { useEffect, useState } from "react";
import ProfilePicture from "../Basic/ProfilePicture";

interface SiteSelectProps {
  expandMenu?: boolean;
  isMobile?: boolean;
  onSelectCallback?: () => void;
}

const SiteSelect = ({
  expandMenu = true,
  isMobile = false,
  onSelectCallback,
}: SiteSelectProps) => {
  const { user } = useAppSelector((state: RootState) => state.user);
  const { userSites } = useAppSelector((state: RootState) => state.site);
  const [sites, setSites] = useState(userSites[user.doctor_id] || []);
  const [openSiteList, setOpenSiteList] = useState(false);
  const [siteOptions, setSiteOptions] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSites(userSites[user.doctor_id] || []);
  }, [userSites, user]);

  useEffect(() => {
    if (sites?.length > 1) {
      setSiteOptions(
        sites.filter((site) => site.customer_id !== user.customer_id),
      );
    }
  }, [sites, user]);

  const onClickSite = () => {
    setOpenSiteList(!openSiteList);
  };

  const onSelectSite = (customerId: number) => {
    dispatch(selectSite(customerId));
    onSelectCallback?.();
  };

  if (siteOptions.length === 0) {
    return null;
  }

  return (
    <>
      <div
        className={`cursor-pointer select-none grid grid-cols-conv-layout items-center font-medium truncate
          py-4 ${isMobile ? "px-6" : "px-5"} border-t bg-gray-background`}
        onClick={onClickSite}
      >
        <div className="grid grid-cols-2-right items-center gap-3 truncate flex-none">
          <ProfilePicture
            firstName={user.customer.name}
            flagPadding={false}
            bgColor="primary-blue-light"
          />
          <p
            className={`truncate transition-all ${!isMobile && expandMenu ? "w-full" : isMobile ? "w-full" : "w-0"}`}
          >
            {user.customer.name}
          </p>
        </div>
        {(expandMenu || isMobile) && (
          <ArrowIcon
            width="12"
            height="8"
            className={`transition-all ml-3 ${openSiteList ? "-rotate-180" : ""}`}
          />
        )}
      </div>

      <div
        className={`${isMobile ? "px-5" : "px-3"} transition-all overflow-hidden
          ${
            isMobile
              ? `grid grid-rows-tab-layout border-b ${openSiteList ? "h-fit py-3" : "h-0 py-0"}`
              : `max-h-60 grid grid-rows-tab-layout ${expandMenu && openSiteList ? "h-fit py-3" : "h-0 py-0"}`
          }`}
      >
        <p className="uppercase text-tertiary font-medium text-xs tracking-wide truncate my-2">
          Select clinic
        </p>
        <div
          className={`${isMobile ? "h-full space-y-1 overflow-x-hidden" : "overflow-y-auto scrollbar min-h-0 h-full space-y-1"}`}
        >
          {siteOptions.map((site) => (
            <div
              key={site.customer_id}
              className="grid grid-cols-2-right gap-3 py-2 cursor-pointer"
              onClick={() => onSelectSite(site.customer_id)}
            >
              <ProfilePicture
                firstName={site.name}
                flagPadding={false}
                bgColor="gray-110"
                textColor="tertiary"
              />
              <p className={`text-wrap ${isMobile ? "pt-1" : ""}`}>
                {site.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SiteSelect;
