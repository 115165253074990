import { useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ReactComponent as BuildingIcon } from "../../../../assets/icons/building-office.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/chevron-up.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone-arrows.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-circle.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-redesign.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user-circle.svg";
import { ReactComponent as UsersIcon } from "../../../../assets/icons/users-group.svg";
import AvatarStack from "../../../Basic/AvatarStack";
import LoaderDots from "../../../Basic/LoaderDots";
import NumberBadge from "./NumberBadge";
import TableFilters from "./TableFilters";

const mockSupportLines = [
  {
    id: 1,
    name: "West Valley Clinic (Kershaw)",
    totalSites: 2,
    totalAuthorizedProviders: 3,
    totalPatientsServed: 20,
    phoneNumber: "740-135-2352",
    connectedSites: [
      {
        name: "Main Transfer Line",
        phone: "740-135-2352",
      },
      {
        name: "West Valley Clinic (Lancaster)",
        phone: "Forwarded to Main Line",
        authorizedProviders: null,
        patientsServed: 20,
      },
    ],
  },
  {
    id: 2,
    name: "Medication Line",
    totalSites: 1,
    totalAuthorizedProviders: 3,
    totalPatientsServed: 20,
    phoneNumber: "740-135-2352",
    connectedSites: [
      {
        name: "West Valley Clinic (Lancaster)",
        phone: "Forwarded to Main Line",
        authorizedProviders: null,
        patientsServed: 20,
      },
    ],
  },
];

const tableRowClasses = "w-full items-center grid grid-cols-12 gap-3 px-4 h-14";
const nameColumnClasses = "col-span-3 truncate";
const connectedSitesColumnClasses = "col-span-2 truncate";
const authorizedProvidersColumnClasses = "col-span-2 truncate";
const patientServedColumnClasses = "col-span-2 truncate";
const phoneNumberColumnClasses = "col-span-2 truncate";
const actionsColumnClasses = "col-span-1 truncate";

const SupportLines = () => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  // todo
  const hasMore = false;

  const onNext = (): void => {
    // todo
  };

  const toggleRow = (id: number): void => {
    if (expandedRow === id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(id);
    }
  };

  const calculateExpandedHeight = (items): string => {
    const ROW_HEIGHT = 56;
    return `${items ? (items.length + 1) * ROW_HEIGHT : ROW_HEIGHT}px`;
  };

  return (
    <div className="grid grid-rows-tab-layout overflow-hidden h-full">
      <TableFilters />

      <div
        className="overflow-auto h-full w-full scrollbar pr-1"
        ref={tableRef}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={onNext}
          hasMore={hasMore}
          useWindow={false}
          loader={
            <div
              key={0}
              className="w-full h-10 flex items-center justify-center"
            >
              <LoaderDots />
            </div>
          }
        >
          <div className="md:min-w-fit w-full h-fit">
            <div className="relative min-w-134">
              <div
                className={`sticky z-10 top-0 left-0 w-full bg-white text-xs font-semibold text-tertiary uppercase
                  ${tableRowClasses}`}
              >
                <div className={nameColumnClasses}>Support line</div>
                <div className={connectedSitesColumnClasses}>
                  Connected Sites
                </div>
                <div className={authorizedProvidersColumnClasses}>
                  Authorized Providers
                </div>
                <div className={patientServedColumnClasses}>
                  Patients Served
                </div>
                <div className={phoneNumberColumnClasses}>Phone Number</div>
                <div className={actionsColumnClasses} />
              </div>

              {mockSupportLines ? (
                mockSupportLines.length > 0 ? (
                  mockSupportLines.map((supportLine, index) => {
                    return (
                      <div
                        className={`w-full border-b border-x border-gray-foreground
                          ${index === 0 ? "rounded-t-lg border-t" : index === mockSupportLines.length - 1 ? "rounded-b-lg" : ""}`}
                      >
                        <div key={supportLine.id} className={tableRowClasses}>
                          <div
                            className={`${nameColumnClasses} text-sm font-semibold`}
                          >
                            {supportLine.name}
                          </div>

                          <div className={connectedSitesColumnClasses}>
                            <NumberBadge
                              number={supportLine.totalSites}
                              icon={<BuildingIcon width="17" height="16" />}
                            />
                          </div>

                          <div className={authorizedProvidersColumnClasses}>
                            <NumberBadge
                              number={supportLine.totalAuthorizedProviders}
                              icon={<UsersIcon width="17" height="16" />}
                            />
                          </div>

                          <div className={patientServedColumnClasses}>
                            <NumberBadge
                              number={supportLine.totalPatientsServed}
                              icon={<UserIcon width="17" height="16" />}
                            />
                          </div>

                          <div
                            className={`${phoneNumberColumnClasses} text-sm`}
                          >
                            {supportLine.phoneNumber}
                          </div>

                          <div className={actionsColumnClasses}>
                            <button
                              type="button"
                              className={`w-6 h-6 flex items-center justify-center transition-all
                              ${expandedRow === supportLine.id ? "" : "rotate-180"}`}
                              onClick={() => toggleRow(supportLine.id)}
                            >
                              <ArrowIcon width="20" height="20" />
                            </button>
                          </div>
                        </div>

                        <div
                          className="transition-all overflow-hidden"
                          style={{
                            height:
                              expandedRow === supportLine.id
                                ? calculateExpandedHeight(
                                    supportLine.connectedSites,
                                  )
                                : "0",
                          }}
                        >
                          {supportLine.connectedSites?.map((site) => {
                            return (
                              <div
                                className={`${tableRowClasses} bg-gray-background group`}
                              >
                                <div className={nameColumnClasses} />

                                <div
                                  className={`${connectedSitesColumnClasses} text-sm`}
                                >
                                  {site.name}
                                </div>

                                <div
                                  className={authorizedProvidersColumnClasses}
                                >
                                  {site.authorizedProviders && (
                                    <AvatarStack
                                      users={site.authorizedProviders}
                                      length={4}
                                      totalNumber={
                                        site.authorizedProviders.length
                                      }
                                    />
                                  )}
                                </div>

                                <div className={patientServedColumnClasses}>
                                  {site.patientsServed && (
                                    <NumberBadge
                                      number={site.patientsServed}
                                      icon={<UserIcon width="17" height="16" />}
                                    />
                                  )}
                                </div>

                                <div
                                  className={`${phoneNumberColumnClasses} text-sm flex items-center space-x-2`}
                                >
                                  <PhoneIcon width="16" height="16" />
                                  <p>{site.phone}</p>
                                </div>

                                <div className={actionsColumnClasses}>
                                  <button
                                    type="button"
                                    className="transition-all opacity-0 group-hover:opacity-100"
                                  >
                                    <TrashIcon width="20" height="20" />
                                  </button>
                                </div>
                              </div>
                            );
                          })}

                          <div
                            className={`${tableRowClasses} bg-gray-background`}
                          >
                            <div className={nameColumnClasses} />
                            <div
                              className={`${connectedSitesColumnClasses} text-sm`}
                            >
                              <button
                                type="button"
                                className="text-sm text-primary-blue flex items-center space-x-2"
                              >
                                <PlusIcon width="20" height="20" />
                                <p>Add Site</p>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="px-3 xl:px-4 h-14 w-full flex items-center justify-center my-1.5 text-sm">
                    No Support Lines Found
                  </div>
                )
              ) : (
                <div className="px-3 xl:px-4 h-14 w-full flex items-center my-1.5 text-sm">
                  Loading ...
                </div>
              )}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SupportLines;
