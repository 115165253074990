import {
  Pages,
  careManagementTabs,
  settingsTabs,
} from "@src/helpers/constants";
import { useAppDispatch, useAppSelector } from "@src/store";
import { useFeature } from "@src/store/featureFlagSelectors";
import { FeatureFlags } from "@src/store/types";
import {
  setSelectedCareManagementTabAction,
  setSelectedSettingsTabAction,
} from "@src/store/user/actions";
import { selectUser } from "@src/store/user/userReducer";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation, useNavigate } from "react-router";
import { ReactComponent as CareManagementIcon } from "../../assets/icons/care-management.svg";
import { ReactComponent as InboxIcon } from "../../assets/icons/inbox-redesign2.svg";
import { ReactComponent as ScribeIcon } from "../../assets/icons/microphone-scribe.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/patients-redesign.svg";
import { ReactComponent as VoiceAgentIcon } from "../../assets/icons/phone-new.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/schedule-gray-redesign.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-redesign.svg";
import { ReactComponent as TrackingIcon } from "../../assets/icons/tracking.svg";
import { setInterruptedRecordingAction } from "../IndependentScribe/store/scribeSlice";
import { getRecordingScribe } from "../IndependentScribe/store/selectors";
import { SetInterruptedRecordingActionType } from "../IndependentScribe/store/types";
import MobileMenu from "./MobileMenu";
import Navbar from "./Navbar";
import VersionChecker from "./VersionChecker";

//styles
const navBtn =
  "h-10 md:h-fit flex items-center whitespace-nowrap cursor-pointer md:py-4 ";
const activeNavBtn = "text-primary ";
const iconStroke = "#667085";
const activeIconStroke = "#2970FF";
const iconSize = 24;
const iconClasses = "flex-none transition-all ";
const labelClasses = "ml-3";
const labelHiddenClasses = "md:transition-all md:w-0 md:opacity-0";

interface NavigationProps {
  isPatientProfile: boolean;
}

const Navigation = ({ isPatientProfile }: NavigationProps) => {
  const user = useAppSelector(selectUser);
  const recordingNote = useAppSelector(getRecordingScribe);
  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const isCCM = useFeature(FeatureFlags.CCM);
  const isCustomizeCalls = useFeature(FeatureFlags.CUSTOMIZE_CALLS);
  const isCommandCenter = useFeature(FeatureFlags.COMMAND_CENTER);
  const isSystemAdmin = user?.email?.endsWith("@quadrant.health");

  const { selectedPatientInfo } = useAppSelector((state) => state.patient);
  const { selectedSettingsTab } = useAppSelector((state) => state.user);
  const { hideMobileNavbar } = useAppSelector((state) => state.ui);
  const [isSettingsActive, setIsSettingsActive] = useState(false);
  const [expandMenu, setExpandMenu] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const isIndependentScribe = location.pathname.match(/\/scribe$/);

  const settingsTabPermissions = {
    [Pages.SETTINGS_NOTES]: !isScribeOnly,
    [Pages.SETTINGS_CARE_MANAGEMENT]: !isCCM,
    [Pages.SETTINGS_ADMIN]: !isSystemAdmin,
    // [Pages.SETTINGS_PRICING]: !isSystemAdmin,
    [Pages.SETTINGS_CALL_METRICS]: !isCommandCenter,
  };

  const handleNavLinkClick = (e, url) => {
    if (recordingNote) {
      e.preventDefault();
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.NAVIGATION,
          value: url,
        }),
      );
    }
  };

  const handleClickSettings = () => {
    let navigateLink = Pages.SETTINGS_PROFILE;

    if (selectedSettingsTab) {
      const selectedTabLink = settingsTabs.find(
        (tab) => tab.text === selectedSettingsTab,
      )?.link;

      if (!selectedTabLink) {
        navigateLink = Pages.SETTINGS_PROFILE;
      } else {
        const isTabDisabled = settingsTabPermissions[selectedTabLink];
        navigateLink = isTabDisabled ? Pages.SETTINGS_PROFILE : selectedTabLink;
      }
    }

    if (recordingNote) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.SETTINGS_NAVIGATION,
          value: navigateLink,
        }),
      );
      return;
    }

    if (!selectedSettingsTab) {
      dispatch(setSelectedSettingsTabAction("Profile"));
    }

    navigate(navigateLink);
  };

  useEffect(() => {
    if (careManagementTabs.some((tab) => location.pathname === tab.link)) {
      dispatch(
        setSelectedCareManagementTabAction(
          careManagementTabs.find((tab) => tab.link === location.pathname).text,
        ),
      );
    }

    if (settingsTabs.some((tab) => location.pathname === tab.link)) {
      dispatch(
        setSelectedSettingsTabAction(
          settingsTabs.find((tab) => tab.link === location.pathname).text,
        ),
      );
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (settingsTabs.some((tab) => location.pathname === tab.link)) {
      setIsSettingsActive(true);
    } else {
      setIsSettingsActive(false);
    }
  }, [location.pathname]);

  const navButtons = (
    <>
      {(isFrontDesk || isFrontDeskInbox) && (
        <NavLink
          aria-label="Front Desk"
          to={Pages.FRONT_DESK}
          className={({ isActive }) =>
            isActive ? navBtn + activeNavBtn : navBtn
          }
          onClick={(e) => handleNavLinkClick(e, Pages.FRONT_DESK)}
        >
          {({ isActive }) => (
            <>
              <InboxIcon
                stroke={isActive ? activeIconStroke : iconStroke}
                width={iconSize}
                height={iconSize}
                className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
              />
              <p
                className={
                  expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                }
              >
                Front Desk
              </p>
            </>
          )}
        </NavLink>
      )}

      {(isFrontDesk || isFrontDeskInbox) && (
        <NavLink
          aria-label="Patients"
          to={Pages.PATIENTS}
          className={({ isActive }) =>
            isActive ? navBtn + activeNavBtn : navBtn
          }
          onClick={(e) => handleNavLinkClick(e, Pages.PATIENTS)}
        >
          {({ isActive }) => (
            <>
              <PatientIcon
                stroke={isActive ? activeIconStroke : iconStroke}
                width={iconSize}
                height={iconSize}
                className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
              />
              <p
                className={
                  expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                }
              >
                Patients
              </p>
            </>
          )}
        </NavLink>
      )}

      {isScribeOnly && (
        <NavLink
          aria-label="Scribe"
          to={Pages.SCRIBE}
          className={({ isActive }) =>
            isActive ? navBtn + activeNavBtn : navBtn
          }
          onClick={(e) => handleNavLinkClick(e, Pages.SCRIBE)}
          data-testid="nav-button-scribe"
        >
          {({ isActive }) => (
            <>
              <ScribeIcon
                stroke={isActive ? activeIconStroke : iconStroke}
                width={iconSize}
                height={iconSize}
                className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
              />
              <p
                className={
                  expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                }
              >
                Scribe
              </p>
            </>
          )}
        </NavLink>
      )}

      {isCCM && (
        <>
          <NavLink
            aria-label="Campaigns"
            to={Pages.CALENDAR}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.CALENDAR)}
          >
            {({ isActive }) => (
              <>
                <CalendarIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  fill={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Calendar
                </p>
              </>
            )}
          </NavLink>

          <NavLink
            aria-label="Enroll"
            to={Pages.ENROLL}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.ENROLL)}
          >
            {({ isActive }) => (
              <>
                <CareManagementIcon
                  fill={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Enroll
                </p>
              </>
            )}
          </NavLink>

          <NavLink
            aria-label="Time Tracking"
            to={Pages.BILLING_METRICS}
            className={({ isActive }) =>
              isActive ? navBtn + activeNavBtn : navBtn
            }
            onClick={(e) => handleNavLinkClick(e, Pages.BILLING_METRICS)}
          >
            {({ isActive }) => (
              <>
                <TrackingIcon
                  stroke={isActive ? activeIconStroke : iconStroke}
                  width={iconSize}
                  height={iconSize}
                  className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
                />
                <p
                  className={
                    expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                  }
                >
                  Time tracking
                </p>
              </>
            )}
          </NavLink>
        </>
      )}

      {isCustomizeCalls && (
        <NavLink
          aria-label="Call Center"
          to={Pages.VOICE_AGENT}
          className={({ isActive }) =>
            isActive ? navBtn + activeNavBtn : navBtn
          }
          onClick={(e) => handleNavLinkClick(e, Pages.VOICE_AGENT)}
        >
          {({ isActive }) => (
            <>
              <VoiceAgentIcon
                stroke={isActive ? activeIconStroke : iconStroke}
                fill={isActive ? activeIconStroke : iconStroke}
                width={iconSize}
                height={iconSize}
                className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
              />
              <p
                className={
                  expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
                }
              >
                Voice Agent
              </p>
            </>
          )}
        </NavLink>
      )}

      <div className="w-full">
        <button
          type="button"
          className={`w-full ${navBtn}
            ${isSettingsActive ? activeNavBtn : ""}`}
          onClick={handleClickSettings}
        >
          <SettingsIcon
            stroke={isSettingsActive ? activeIconStroke : iconStroke}
            width={iconSize}
            height={iconSize}
            className={`${iconClasses} ${expandMenu ? "" : "ml-2"}`}
          />
          <p
            className={
              expandMenu || !isDesktop ? labelClasses : labelHiddenClasses
            }
          >
            Settings
          </p>
        </button>
      </div>
    </>
  );

  if (
    !isDesktop &&
    !isIndependentScribe &&
    (hideMobileNavbar || (selectedPatientInfo && isPatientProfile))
  ) {
    return <></>;
  }

  return (
    <>
      {isDesktop ? (
        <Navbar
          navButtons={navButtons}
          expandMenu={expandMenu}
          setExpandMenu={setExpandMenu}
          handleNavLinkClick={handleNavLinkClick}
        />
      ) : (
        <MobileMenu
          navButtons={navButtons}
          handleNavLinkClick={handleNavLinkClick}
        />
      )}
      <VersionChecker />
    </>
  );
};

export default Navigation;
