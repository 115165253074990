import { WS_URL } from "../../helpers/config";

/**
 * Connection status enum
 */
export enum ConnectionStatus {
  CONNECTED = "CONNECTED: WebSocket connection established successfully",
  CONNECTING = "CONNECTING: Attempting to establish WebSocket connection",
  DISCONNECTED = "DISCONNECTED: WebSocket connection closed or not established",
  FAILED = "FAILED: WebSocket connection failed after maximum reconnection attempts",
}

export interface StatusChangeEvent {
  status: ConnectionStatus;
  timestamp: number;
  reason?: string;
  code?: number;
  reconnectAttempt?: number;
  nextAction?: string;
}

/**
 * ConnectionManager handles WebSocket connection lifecycle
 * - Establishes and maintains WebSocket connections
 * - Handles connection lifecycle (open, close, error)
 * - Implements reconnection with exponential backoff
 * - Manages connection status and notifies listeners
 */
export class ConnectionManager {
  private websocket: WebSocket | null = null;
  private token: string;
  private reconnectTimer: NodeJS.Timeout | null = null;
  private isConnecting = false;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 10;
  private listeners: Array<(event: MessageEvent) => void> = [];
  private connectionStatusListeners: Array<(event: StatusChangeEvent) => void> =
    [];
  private _currentConnectionId = 0;

  /**
   * Connect to the WebSocket server
   */
  public connect(token: string): void {
    if (!token) {
      // throw new Error("Token is required to connect to the WebSocket server");
      return;
    }

    this.token = token;

    // Guard against connecting when already connected/connecting
    if (
      this.websocket &&
      (this.websocket.readyState === WebSocket.OPEN ||
        this.websocket.readyState === WebSocket.CONNECTING)
    ) {
      // console.log(
      //   "WebSocket already connected/connecting, skipping connection",
      // );
      return;
    }

    // If we're already in the process of connecting via our flag,
    // don't try to connect again
    if (this.isConnecting) {
      // console.log("Already in the process of connecting, skipping connection");
      return;
    }

    // Clean up any existing socket first
    this.cleanupExistingConnection();

    this.isConnecting = true;

    // Store a connection ID to detect cancellations
    const connectionId = Date.now();
    this._currentConnectionId = connectionId;

    this.notifyStatusChange(ConnectionStatus.CONNECTING, {
      reason: "Connection initiated",
      nextAction: "Establishing WebSocket connection",
    });

    // Add a small delay before the initial connection attempt
    // This helps avoid connection problems during app initialization
    const delay = this.reconnectAttempts === 0 ? 500 : 0;

    // console.log(`Setting connection delay: ${delay}ms`);

    setTimeout(() => {
      if (this._currentConnectionId !== connectionId) {
        console.log(
          "Connection attempt was canceled by a newer connection request",
        );
        return;
      }

      if (!this.isConnecting) {
        console.log("Connection attempt was canceled via disconnect()");
        return;
      }

      try {
        // console.log(
        //   "Attempting to connect to WebSocket URL:",
        //   `${WS_URL}?token=***`,
        // );

        const wsUrl = `${WS_URL}?token=${this.token}`;
        this.websocket = new WebSocket(wsUrl);

        // Set up event handlers
        this.websocket.onopen = this.handleOpen;
        this.websocket.onmessage = this.handleMessage;
        this.websocket.onerror = this.handleError;
        this.websocket.onclose = this.handleClose;
      } catch (error) {
        console.error("WebSocket connection error:", error);
        this.cleanupWebSocket();
      }
    }, delay);
  }

  private cleanupExistingConnection(): void {
    if (!this.websocket) {
      return;
    }

    console.log("Cleaning up existing connection");

    // Remove all handlers to prevent callbacks during closing
    this.websocket.onopen = null;
    this.websocket.onmessage = null;
    this.websocket.onerror = null;
    this.websocket.onclose = null;

    try {
      if (
        this.websocket.readyState !== WebSocket.CLOSED &&
        this.websocket.readyState !== WebSocket.CLOSING
      ) {
        console.log("Closing existing WebSocket");
        this.websocket.close();
      }
    } catch (e) {
      console.warn("Error closing existing WebSocket:", e);
    }

    this.websocket = null;
  }

  /**
   * Disconnect from the WebSocket server
   */
  public disconnect(): void {
    console.log("Disconnecting from WebSocket");

    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }

    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }

    this.isConnecting = false;
    this.notifyStatusChange(ConnectionStatus.DISCONNECTED, {
      reason: "Manual disconnection",
      nextAction: "Connection closed",
    });
  }

  /**
   * Update the authentication token
   */
  public updateToken(token: string): void {
    this.token = token;

    if (token) {
      // console.log("Connecting to WebSocket");
      this.connect(this.token);
    } else {
      // console.log("Disconnecting from WebSocket");
      this.disconnect();
    }
  }

  /**
   * Add a message listener
   */
  public addMessageListener(listener: (event: MessageEvent) => void): void {
    this.listeners.push(listener);
  }

  /**
   * Remove a message listener
   */
  public removeMessageListener(listener: (event: MessageEvent) => void): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  /**
   * Add a connection status listener
   */
  public addConnectionStatusListener(
    listener: (event: StatusChangeEvent) => void,
  ): void {
    this.connectionStatusListeners.push(listener);
    // Immediately notify of current status with a proper event object
    const currentStatus = this.getStatus();
    listener({
      status: currentStatus,
      timestamp: Date.now(),
      reason: "Initial status notification",
    });
  }

  /**
   * Remove a connection status listener
   */
  public removeConnectionStatusListener(
    listener: (event: StatusChangeEvent) => void,
  ): void {
    this.connectionStatusListeners = this.connectionStatusListeners.filter(
      (l) => l !== listener,
    );
  }

  /**
   * Get the current connection status
   */
  public getStatus(): ConnectionStatus {
    if (!this.websocket) {
      return ConnectionStatus.DISCONNECTED;
    }

    switch (this.websocket.readyState) {
      case WebSocket.CONNECTING:
        return ConnectionStatus.CONNECTING;
      case WebSocket.OPEN:
        return ConnectionStatus.CONNECTED;
      // case WebSocket.CLOSING:
      // case WebSocket.CLOSED:
      default:
        return ConnectionStatus.DISCONNECTED;
    }
  }

  /**
   * Handle WebSocket open event
   */
  private handleOpen = (): void => {
    // console.log("WebSocket connected");
    this.isConnecting = false;
    this.reconnectAttempts = 0;
    this.notifyStatusChange(ConnectionStatus.CONNECTED, {
      reason: "WebSocket connection established successfully",
    });
  };

  /**
   * Handle WebSocket message event
   */
  private handleMessage = (event: MessageEvent): void => {
    // Notify all listeners about the message
    this.listeners.forEach((listener) => listener(event));
  };

  /**
   * Handle WebSocket error event
   */
  private handleError = (event: Event): void => {
    console.error("WebSocket error:", event);

    // Only explicitly close the WebSocket if it's in the OPEN state
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
    } else {
      // For CONNECTING, CLOSING, or CLOSED states, just clean up
      this.cleanupWebSocket();
    }
  };

  private cleanupWebSocket(): void {
    this.websocket = null;
    this.isConnecting = false;
    this.notifyStatusChange(ConnectionStatus.DISCONNECTED, {
      reason: "Connection cleanup after error",
      nextAction:
        this.reconnectAttempts < this.maxReconnectAttempts
          ? `Will attempt reconnection in ${Math.min(1000 * 2 ** this.reconnectAttempts, 30000)}ms`
          : "No further reconnection attempts",
    });
    this.scheduleReconnect();
  }

  /**
   * Handle WebSocket close event
   */
  private handleClose = (event: CloseEvent): void => {
    console.log(`WebSocket closed with code ${event.code}: ${event.reason}`);

    // Check for authentication errors (common API Gateway codes)
    if (event.code === 1008 || event.code === 4001) {
      console.error("Authentication failed. Token may be invalid or expired.");
      // Maybe trigger a token refresh here or notify the app
    }

    this.websocket = null;
    this.isConnecting = false;
    this.notifyStatusChange(ConnectionStatus.DISCONNECTED, {
      reason: `Connection closed: ${event.reason || "No reason provided"}`,
      code: event.code,
      nextAction:
        this.reconnectAttempts < this.maxReconnectAttempts
          ? `Will attempt reconnection in ${Math.min(1000 * 2 ** this.reconnectAttempts, 30000)}ms`
          : "No further reconnection attempts",
    });
    this.scheduleReconnect();
  };

  /**
   * Schedule a reconnection attempt with exponential backoff
   */
  private scheduleReconnect(): void {
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }

    // Implement exponential backoff
    const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);
    this.reconnectAttempts++;

    if (this.reconnectAttempts <= this.maxReconnectAttempts) {
      this.reconnectTimer = setTimeout(() => {
        this.connect(this.token);
      }, delay);
      console.log(
        `Scheduling reconnect in ${delay}ms (attempt ${this.reconnectAttempts})`,
      );
    } else {
      console.error(
        `Maximum reconnection attempts (${this.maxReconnectAttempts}) reached`,
      );
      this.notifyStatusChange(ConnectionStatus.FAILED, {
        reason: `Maximum reconnection attempts (${this.maxReconnectAttempts}) reached`,
        reconnectAttempt: this.reconnectAttempts,
        nextAction: "No further reconnection attempts",
      });
    }
  }

  /**
   * Notify all status listeners of a status change
   */
  private notifyStatusChange(
    status: ConnectionStatus,
    details: Partial<Omit<StatusChangeEvent, "status" | "timestamp">> = {},
  ): void {
    const event: StatusChangeEvent = {
      status,
      timestamp: Date.now(),
      ...details,
    };

    // Log detailed status change information
    // console.log(
    //   `WebSocket status changed to ${status}`,
    //   details.reason ? `Reason: ${details.reason}` : "",
    //   details.code ? `Code: ${details.code}` : "",
    //   details.reconnectAttempt
    //     ? `Reconnect attempt: ${details.reconnectAttempt}`
    //     : "",
    //   details.nextAction ? `Next action: ${details.nextAction}` : "",
    // );

    this.connectionStatusListeners.forEach((listener) => listener(event));
  }
}

export default ConnectionManager;
