import { useAppSelector } from "@src/store";
import { type ReactNode, useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router";
import Notifications, { NotificationContent } from "../Basic/Notifications";

interface NotificationsWrapperProps {
  children: ReactNode;
}

const NotificationsWrapper = ({ children }: NotificationsWrapperProps) => {
  const { notification } = useAppSelector((state) => state.user);
  const location = useLocation();

  useEffect(() => {
    if (notification) {
      if (notification.status === "error") {
        toast.error(
          (t) => (
            <NotificationContent
              id={t.id}
              title={notification.title}
              text={notification.desc}
            />
          ),
          { id: notification.desc },
        );
      } else {
        toast.success(
          (t) => (
            <NotificationContent
              id={t.id}
              title={notification.title}
              text={notification.desc}
            />
          ),
          { id: notification.desc },
        );
      }
    }
  }, [notification]);

  useEffect(() => {
    if (location.pathname) {
      toast.remove();
    }
  }, [location.pathname]);

  return (
    <div id="wrapper" className="font-Mulish font-medium text-primary">
      {children}
      <Notifications />
    </div>
  );
};

export default NotificationsWrapper;
