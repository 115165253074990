import {
  doctorSchema,
  zeroOrOneSchema,
} from "@src/helpers/commonValidationSchemas";
import { directPhoneNumbersSchema } from "@src/store/voiceAgent/validationSchemas";
import { z } from "zod";
import { clinicSchema } from "../clinic/schema";
import { organizationSchema } from "../user/validationSchemas";

const assistantSettingsSchema = z.object({
  clinic_address: z.string().nullable(),
  clinic_hours: z.string().nullable(),
  created_at: z.string(),
  customer_assistant_id: z.number(),
  faq: z.string().nullable(),
  site_assistant_settings_id: z.number(),
  site_id: z.number(),
  updated_at: z.string(),
});

export const basicSiteSchema = z.object({
  assistant_settings: assistantSettingsSchema.nullable(),
  created_at: z.string(),
  customer_id: z.number(),
  direct_phone_numbers: directPhoneNumbersSchema.array().nullable(),
  name: z.string(),
  site_id: z.number(),
  updated_at: z.string(),
  total_patients: z.number().nullish(),
  is_default: z.union([z.boolean(), zeroOrOneSchema]),
});

export const basicSitesSchema = z.array(basicSiteSchema);

const userSiteSchema = z.object({
  assistant_id: z.string().nullish(),
  assistant_phone_number: z.string().nullish(),
  clinic_address: z.string().nullish(),
  created_at: z.string(),
  custom_instructions: z.string().nullish(),
  customer_id: z.number(),
  display_name: z.string().nullable(),
  incentive_program_enabled: zeroOrOneSchema.nullish(),
  incentive_program_points: zeroOrOneSchema.nullish(),
  legacy_phone_number: z.string().nullish(),
  name: z.string(),
  note_template_id: z.number().nullish(),
  organization_id: z.number().nullish(),
  phone_number: z.string(),
  time_to_move_complete: z.number().nullish(),
  time_to_move_review: z.number().nullish(),
  updated_at: z.string(),
});

export const userSitesSchema = z.array(userSiteSchema);

export const siteSchema = userSiteSchema.extend({
  customer: clinicSchema.nullish(),
  display_name: z.string(),
  organization: organizationSchema.nullable(),
  total_patients: z.number().min(0),
  providers: z.array(doctorSchema),
  total_providers: z.number().min(0),
  total_teams: z.number().min(0),
});

export const sitesSchema = z.array(siteSchema);
