import dayjs from "dayjs";
import { forwardRef } from "react";
import { useNavigate } from "react-router";

interface CustomPickerButtonProps {
  onClick: () => void;
  isActive: boolean;
  label: string;
  basePeriodUrl: string;
  defaultStartDate: Date;
  defaultEndDate: Date;
}

const CustomPickerButton = forwardRef<
  HTMLButtonElement,
  CustomPickerButtonProps
>(
  (
    {
      onClick,
      isActive,
      label,
      basePeriodUrl,
      defaultStartDate,
      defaultEndDate,
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const defaultStartDateSlug = dayjs(defaultStartDate).format("YYYY-MM-DD");
    const defaultEndDateSlug = dayjs(defaultEndDate).format("YYYY-MM-DD");

    return (
      <button
        type="button"
        className={`py-2.5 px-3 text-sm font-semibold w-full md:w-fit whitespace-nowrap flex-none flex
          items-center justify-center ${isActive ? "bg-gray-110 text-primary" : "bg-white text-tertiary"}`}
        onClick={() => {
          if (!isActive) {
            navigate(
              `${basePeriodUrl}&start-date=${defaultStartDateSlug}&end-date=${defaultEndDateSlug}`,
              { replace: true },
            );
          }
          setTimeout(() => {
            onClick();
          }, 100);
        }}
        ref={ref}
      >
        {label}
      </button>
    );
  },
);

export default CustomPickerButton;
