import Skeleton from "@mui/material/Skeleton";
import AnalyticsTable from "@src/components/VoiceAgent/SupportLineView/Analytics/AnalyticsTable";
import { useMemo } from "react";

interface AnalyticsLeaderboardSkeletonProps {
  rows?: number;
  bgColor?: string;
  animation?: "pulse" | "wave" | false;
}

const AnalyticsLeaderboardSkeleton = ({
  rows = 15,
  bgColor = "#F8F8F8",
  animation = "wave",
}: AnalyticsLeaderboardSkeletonProps) => {
  const columns = [
    { label: "User", accessor: "updated_by" },
    { label: "Completed", accessor: "total" },
  ];

  const data = useMemo(() => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        updated_by: (
          <div className="flex items-center space-x-2">
            <Skeleton
              animation={animation}
              variant="circular"
              width={32}
              height={32}
              sx={{ bgcolor: bgColor }}
            />
            <div className="space-y-1">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={150}
                height={14}
                sx={{ bgcolor: bgColor, borderRadius: "16px" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={80}
                height={10}
                sx={{ bgcolor: bgColor, borderRadius: "16px" }}
              />
            </div>
          </div>
        ),
        total: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
      });
    }
    return tableData;
  }, [rows, bgColor, animation]);

  return (
    <div className="grid w-full">
      <AnalyticsTable columns={columns} data={data} />
    </div>
  );
};

export default AnalyticsLeaderboardSkeleton;
