import { roundNumberToTwoDecimals } from "@src/helpers/helpers";

interface SmallNumberCardProps {
  title: string;
  number: number | string;
}

const SmallNumberCard = ({ title, number }: SmallNumberCardProps) => {
  return (
    <div className="flex flex-col items-start font-medium overflow-hidden h-9">
      <p className="text-xs text-tertiary truncate">{title}</p>
      <p className="text-sm">{roundNumberToTwoDecimals(number)}</p>
    </div>
  );
};

export default SmallNumberCard;
