import Loader from "@src/components/Basic/Loader";
import Switch from "@src/components/Basic/Switch";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  selectFeatureFlagUpdate,
  updateFeatureFlag,
} from "@src/store/admin/adminSlice";
import { useFeature } from "@src/store/featureFlagSelectors";
import { setFeatureFlag } from "@src/store/featureFlagSlice";
import { FeatureFlags } from "@src/store/types";
import { useEffect } from "react";

interface FeatureFlagToggleProps {
  flag: FeatureFlags;
  title: string;
  description: string;
}

const FeatureFlagToggle = ({
  flag,
  title,
  description,
}: FeatureFlagToggleProps) => {
  const dispatch = useAppDispatch();
  const isEnabled = useFeature(flag);
  const isCCMEnabled = useFeature(FeatureFlags.CCM);
  const updateStatus = useAppSelector(selectFeatureFlagUpdate(flag));

  // Handle reverting on error
  useEffect(() => {
    if (updateStatus?.error && updateStatus.previousValue !== null) {
      dispatch(setFeatureFlag({ flag, enabled: updateStatus.previousValue }));
    }
  }, [updateStatus?.error, updateStatus?.previousValue, flag, dispatch]);

  const handleToggle = () => {
    //  if disabling front desk inbox, disable CCM as well
    if (flag === FeatureFlags.FRONT_DESK_INBOX && isEnabled && isCCMEnabled) {
      dispatch(updateFeatureFlag({ flag: FeatureFlags.CCM, enabled: false }));
    }
    dispatch(updateFeatureFlag({ flag, enabled: !isEnabled }));
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex-1">
        <h3 className="text-base font-semibold">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
        {updateStatus?.error && (
          <p className="text-sm text-red-500 mt-1">{updateStatus.error}</p>
        )}
      </div>
      <div className="flex items-center gap-2">
        <div className="w-5">
          {updateStatus?.loading && <Loader size={5} borderWidth={2} />}
        </div>
        <div className="flex items-center space-x-2">
          <p className="w-6 text-center text-tertiary text-sm font-medium">
            {isEnabled ? "Yes" : "No"}
          </p>
          <Switch
            size="medium-large"
            enabled={isEnabled}
            onChange={handleToggle}
            disabled={updateStatus?.loading}
          />
        </div>
      </div>
    </div>
  );
};

export default FeatureFlagToggle;
