import { useGetTeamsQuery, useGetUsersQuery } from "@src/store/user/api";
import type { Team, User } from "@src/store/user/userReducer";
import {
  useCreateCallReasonMutation,
  useDeleteCallReasonMutation,
  useGetCallReasonsQuery,
  useUpdateCallReasonMutation,
} from "@src/store/voiceAgent/api";
import type { CallReasonToUpdate } from "@src/store/voiceAgent/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-circle.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash-redesign.svg";
import AssignButton from "../../Inbox/RightPanel/AssignButton";
import CallReasonFieldsSkeleton from "../../Skeletons/CallReasonFieldsSkeleton";
import CallReasonModal from "./CallReasonModal";
import SectionControls from "./SectionControls";

export type ReasonFormValues = {
  call_reason_id?: number;
  reason: string;
  reason_prompt: string;
  assign_type?: "provider" | "team" | null;
  assign_to_id?: number | null;
};

type FormValues = {
  reasons: ReasonFormValues[];
};

const reasonFieldsToCompare: (keyof CallReasonToUpdate)[] = [
  "reason",
  "reason_prompt",
  "assign_type",
  "assign_to_id",
];

const CallReasons = () => {
  const [editMode, setEditMode] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [focusedNameInput, setFocusedNameInput] = useState<number | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const { data: callReasons, isLoading: isLoadingCallReasons } =
    useGetCallReasonsQuery();
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery({
    search: "",
  });
  const { data: teams = [], isLoading: isLoadingTeams } = useGetTeamsQuery({
    search: "",
  });
  const [updateCallReason] = useUpdateCallReasonMutation();
  const [createCallReason] = useCreateCallReasonMutation();
  const [deleteCallReason] = useDeleteCallReasonMutation();

  const { register, handleSubmit, control, watch, reset, setValue } =
    useForm<FormValues>({
      defaultValues: { reasons: callReasons },
    });
  const { fields, append, remove } = useFieldArray({
    name: "reasons",
    control,
  });

  const isLoading =
    isLoadingCallReasons || isLoadingUsers || isLoadingTeams || !fields;

  const resetCallReasons = useCallback(() => {
    if (callReasons) {
      reset({
        reasons: callReasons,
      });
    } else {
      reset({
        reasons: [],
      });
    }
  }, [callReasons, reset]);

  useEffect(() => {
    resetCallReasons();
  }, [resetCallReasons]);

  const watchReasons = watch("reasons");

  const usedReasonNames = useMemo(
    () =>
      watchReasons?.map((reason) => {
        return reason.reason?.trim().toLowerCase();
      }),
    [watchReasons],
  );

  const isSaveDisabled = watchReasons?.some(
    (reason) => !reason.reason.trim() || !reason.reason_prompt.trim(),
  );

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    resetCallReasons();
  };

  const onAddReason = () => {
    setOpenAddModal(true);
  };

  const onSubmit = async (data: FormValues) => {
    setEditMode(false);

    const updatePromises = data.reasons
      .filter((reason) => reason.call_reason_id)
      .map((reason) => {
        const existingReason = callReasons.find(
          (callReason) => callReason.call_reason_id === reason.call_reason_id,
        );

        const updatedReason: CallReasonToUpdate = {
          reason: reason.reason,
          reason_prompt: reason.reason_prompt,
          assign_type: reason.assign_to_id ? reason.assign_type : null,
          assign_to_id: reason.assign_to_id,
        };

        const areEqual = reasonFieldsToCompare.every(
          (key) => existingReason[key] === updatedReason[key],
        );

        if (!areEqual) {
          return updateCallReason({
            call_reason_id: reason.call_reason_id,
            reason: updatedReason,
          }).unwrap();
        }

        return null; // skip unchanged reasons
      })
      .filter(Boolean); // remove null values

    try {
      await Promise.all(updatePromises); // batch api calls
    } catch (error) {
      console.error("Error updating call reasons:", error);
    }
  };

  const onAssign = (
    index: number,
    assignTo: { provider: User | null; team: Team | null },
  ) => {
    const assignType = assignTo.provider
      ? "provider"
      : assignTo.team
        ? "team"
        : null;
    const assignToId = assignTo.provider
      ? assignTo.provider.doctor_id
      : assignTo.team
        ? assignTo.team.doctor_team_id
        : null;

    const scrollY = formRef.current?.scrollTop || 0;

    setValue(`reasons.${index}.assign_type`, assignType);
    setValue(`reasons.${index}.assign_to_id`, assignToId);

    reset({
      reasons: [...watch("reasons")],
    });

    // restore scroll position after render
    requestAnimationFrame(() => {
      if (formRef.current) {
        formRef.current.scrollTop = scrollY;
      }
    });
  };

  const handleAddReason = (data: ReasonFormValues) => {
    append(data);
    createCallReason({
      reason: data.reason,
      reason_prompt: data.reason_prompt,
      assign_type: data.assign_type,
      assign_to_id: data.assign_to_id,
    });
    setOpenAddModal(false);
  };

  const handleDeleteReason = (index: number, call_reason_id: number) => {
    setFocusedNameInput(null);
    remove(index);
    if (call_reason_id) {
      deleteCallReason(call_reason_id);
    }
  };

  return (
    <div
      className={`grid grid-rows-tab-layout gap-5 overflow-hidden h-full ${editMode ? "pb-13 md:pb-0" : ""}`}
    >
      <SectionControls
        title="Build call types and question sets to gather information for each request. Send followup texts after the completion of each call."
        editMode={editMode}
        onEdit={handleEdit}
        onCancel={handleCancel}
        disabled={isSaveDisabled || isLoading}
        formId="call-reasons-form"
      />

      <form
        ref={formRef}
        id="call-reasons-form"
        onSubmit={handleSubmit(onSubmit)}
        className="overflow-y-auto pr-1 scrollbar space-y-6"
      >
        {isLoading ? (
          <CallReasonFieldsSkeleton heightPx={166} />
        ) : (
          fields?.map((field, index) => {
            const assignedTo = {
              provider:
                field.assign_type === "provider"
                  ? users.find((user) => user.doctor_id === field.assign_to_id)
                  : null,
              team:
                field.assign_type === "team"
                  ? teams.find(
                      (team) => team.doctor_team_id === field.assign_to_id,
                    )
                  : null,
            };

            return (
              <div key={field.id} className="w-full">
                <div
                  className="h-12 text-xl font-semibold flex items-center justify-between space-x-3 mb-3"
                  onBlur={() => setFocusedNameInput(null)}
                >
                  <input
                    {...register(`reasons.${index}.reason`)}
                    className="py-2.5 focus:px-3 rounded-lg focus:border border-gray-250 outline-none w-full h-12 text-xl
                    font-semibold disabled:px-0 disabled:border-none"
                    placeholder="Reason name"
                    disabled={!editMode}
                    onFocus={() => setFocusedNameInput(index)}
                  />

                  {focusedNameInput === index ? (
                    <button
                      type="button"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        handleDeleteReason(index, field.call_reason_id);
                      }}
                    >
                      <TrashIcon width="20" height="20" />
                    </button>
                  ) : (
                    <AssignButton
                      label="Auto Assign"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className="h-full"
                      assignedTo={assignedTo}
                      onAssign={(assignTo) => onAssign(index, assignTo)}
                      disabled={!editMode}
                    />
                  )}
                </div>

                <TextareaAutosize
                  {...register(`reasons.${index}.reason_prompt`)}
                  className="w-full resize-none px-3 py-2.5 outline-none focus:outline-none rounded-lg align-top
                  scrollbar border border-gray-250 focus:ring-0 text-base disabled:bg-stone-60"
                  placeholder="Reason content"
                  disabled={!editMode}
                  minRows={6}
                  maxRows={14}
                />
              </div>
            );
          })
        )}

        <button
          type="button"
          className="text-sm text-primary-blue flex items-center space-x-2"
          onClick={onAddReason}
          disabled={isLoading}
        >
          <PlusIcon width="20" height="20" />
          <p>Add reason</p>
        </button>
      </form>

      <CallReasonModal
        usedReasonNames={usedReasonNames}
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        onSave={handleAddReason}
      />
    </div>
  );
};

export default CallReasons;
