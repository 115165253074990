import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-down.svg";
import { ReactComponent as LoginIcon } from "@assets/icons/login.svg";
import SmallNumberCard from "@src/components/Settings/CallMetrics/SmallNumberCard";
import { roundBigNumber } from "@src/helpers/helpers";
import type { CustomerMetrics } from "@src/store/adminMetrics/types";
import { type MouseEvent, useState } from "react";

interface FrontDeskMetricsItemProps {
  clinic: CustomerMetrics;
  onLogin: (clinic: CustomerMetrics) => void;
  onClickItem: (id: number) => void;
  selectedClinicId: number | null;
}

const FrontDeskMetricsItem = ({
  clinic,
  onClickItem,
  onLogin,
  selectedClinicId,
}: FrontDeskMetricsItemProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={`border border-gray-foreground rounded-lg p-3 flex flex-col cursor-pointer hover:bg-gray-background
        ${selectedClinicId === clinic.customer_id ? "bg-gray-background" : ""}`}
      onClick={() => onClickItem(clinic.customer_id)}
    >
      <div className="grid items-center grid-cols-conv-layout gap-2 overflow-hidden">
        <div className="w-full overflow-hidden">
          <p className="w-full text-sm font-medium truncate">
            {clinic.customer_name}
          </p>
          <div className="w-full grid items-center grid-cols-conv-layout gap-1 overflow-hidden">
            <p className="text-xs font-medium text-tertiary truncate">
              {clinic.support_email}
            </p>
            <button
              type="button"
              className="flex-none"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
                onLogin(clinic);
              }}
            >
              <LoginIcon width="20" height="20" />
            </button>
          </div>
        </div>

        <button
          type="button"
          className={`w-8 h-8 flex items-center justify-center rounded-lg
            ${expanded ? "bg-primary-blue-light" : "bg-gray-110"}`}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          <ArrowIcon
            width="10"
            height="7"
            className={`transition-all ${expanded ? "-rotate-180" : ""}`}
          />
        </button>
      </div>

      <div
        className={`grid grid-cols-4 gap-2 mt-2 overflow-hidden transition-all ${expanded ? "h-20" : "h-9"}`}
      >
        <SmallNumberCard
          title="Calls"
          number={roundBigNumber(clinic.total_calls)}
        />
        <SmallNumberCard
          title="Minutes"
          number={roundBigNumber(clinic.total_minutes)}
        />
        <SmallNumberCard title="Avg min" number={clinic.avg_minutes} />
        <SmallNumberCard title="Texts" number={roundBigNumber(clinic.text)} />
        <SmallNumberCard
          title="Internal"
          number={roundBigNumber(clinic.internal)}
        />
        <SmallNumberCard
          title="Review"
          number={roundBigNumber(clinic.review)}
        />
        <SmallNumberCard
          title="Unassigned"
          number={roundBigNumber(clinic.unassigned)}
        />
        <SmallNumberCard
          title="Completed"
          number={roundBigNumber(clinic.completed)}
        />
      </div>
    </div>
  );
};

export default FrontDeskMetricsItem;
