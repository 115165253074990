import "./wdyr";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isoWeek from "dayjs/plugin/isoWeek";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router";
import App from "./App";
import { store } from "./store";
import "./index.css";
// import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import DevDebugManager from "./components/DevDebugPanel/DevDebugManager";
import reportWebVitals from "./reportWebVitals";
import WebSocketManager from "./services/websocket/WebSocketManager";

dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.updateLocale("en", {
  weekStart: 1,
});

Bugsnag.start({
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.REACT_APP_VERSION,
  apiKey: "4606bb2fa569487141ae78336a6efde2",
  plugins: [new BugsnagPluginReact()],
});

// BugsnagPerformance.start({
//   apiKey: "4606bb2fa569487141ae78336a6efde2",
// });

console.log("Version: ", process.env.REACT_APP_VERSION);

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store} stabilityCheck="always">
        <WebSocketManager />
        {process.env.NODE_ENV !== "production" && <DevDebugManager />}
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <App />
          </LocalizationProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
