import Skeleton from "@mui/material/Skeleton";
import { memo } from "react";

interface OutgoingMessageSkeletonProps {
  bgColor?: string;
  animation?: "wave" | "pulse";
  className?: string;
}

const OutgoingMessageSkeleton = ({
  bgColor = "#EAEDF3",
  animation = "wave",
  className = "",
}: OutgoingMessageSkeletonProps) => {
  return (
    <div
      className={`flex max-w-4/5 xl:max-w-2/3 flex-row-reverse ml-auto ${className}`}
    >
      <Skeleton
        animation={animation}
        variant="circular"
        width={32}
        height={32}
        sx={{ bgcolor: bgColor, flex: "none" }}
      />
      <div className="pt-2.5 mr-2">
        <Skeleton
          animation={animation}
          variant="rounded"
          width={90}
          height={10}
          sx={{ bgcolor: bgColor, borderRadius: "10px", marginLeft: "auto" }}
        />
        <div className="py-3 px-3 md:px-4 w-fit flex flex-col bg-gray-foreground rounded-outgoing-message ml-auto space-y-2 mt-3">
          <Skeleton
            animation={animation}
            variant="rounded"
            width={252}
            height={10}
            sx={{ bgcolor: bgColor, borderRadius: "10px" }}
          />
          <Skeleton
            animation={animation}
            variant="rounded"
            width={100}
            height={10}
            sx={{ bgcolor: bgColor, borderRadius: "10px" }}
          />
          <Skeleton
            animation={animation}
            variant="rounded"
            width={60}
            height={10}
            sx={{
              bgcolor: bgColor,
              borderRadius: "10px",
              marginLeft: "auto",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(OutgoingMessageSkeleton);
