import { zeroOrOneSchema } from "@src/helpers/commonValidationSchemas";
import { z } from "zod";

export const directPhoneNumbersSchema = z.object({
  name: z.string(),
  phone_number: z.string(),
  description: z.string(),
  created_at: z.string(),
  site_direct_phone_number_id: z.number(),
  site_id: z.number(),
  updated_at: z.string(),
  is_main_line: z.union([z.boolean(), zeroOrOneSchema]),
  message_to_caller: z.string().nullable(),
  transfer_to_number: z.union([
    z.literal("no_transfer"),
    z.literal("auto_transfer"),
  ]),
  when_to_transfer: z.string().nullable(),
});

export const voiceSchema = z.object({
  assistant_voice_id: z.number(),
  audio_sample: z.string().nullable(),
  created_at: z.string(),
  description: z.string(),
  external_id: z.string(),
  gender: z.string(),
  name: z.string(),
  picture: z.string().nullable(),
  text_sample: z.string(),
  updated_at: z.string(),
});

export const voicesSchema = z.array(voiceSchema);

export const assistantSchema = z.object({
  customer_assistant_id: z.number(),
  customer_id: z.number(),
  assistant_external_id: z.string(),
  assistant_name: z.string(),
  assistant_description: z.string(),
  practicing_providers: z.string().nullable(),
  insurance_accepted: z.string().nullable(),
  faq: z.string().nullable(),
  assistant_voice_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  assistant_voice: voiceSchema,
});

export const systemMessagesSchema = z.object({
  first_message: z.string().nullable(),
  voicemail_message: z.string().nullable(),
  end_message: z.string().nullable(),
  idle_message: z.string().nullable(),
});

export const practiceInformationSchema = z.object({
  practicing_providers: z.string().nullable(),
  insurance_accepted: z.string().nullable(),
  faq: z.string().nullable(),
});

export const protocolMessagesSchema = z.object({
  urgent_message: z.string().nullable(),
  urgent_flags: z.string().nullable(),
});

export const callReasonSchema = z.object({
  call_reason_id: z.number(),
  customer_id: z.number(),
  customer_assistant_id: z.number(),
  reason: z.string(),
  reason_prompt: z.string(),
  assign_type: z.union([z.literal("provider"), z.literal("team")]).nullable(),
  assign_to_id: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const callReasonsSchema = z.array(callReasonSchema);

export const metricsCallReasonSchema = z.object({
  category: z.string(),
  total: z.number(),
});

const updatedBySchema = z.object({
  doctor_id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  occupation: z.string().nullable(),
  profile_picture: z.string().nullable(),
  phone_number: z.string().nullable(),
  email: z.string(),
  customer_id: z.number(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  role_id: z.number(),
  status: z.string(),
  display_name: z.string().nullable(),
  organization_id: z.number(),
  npi: z.string().nullable(),
  signup_ehr_name: z.string().nullable(),
  terms_signed_at: z.string().nullable(),
  note_template_id: z.number().nullable(),
});

export const completedByUserSchema = z.object({
  total: z.number(),
  updated_by: updatedBySchema.nullable(),
});

export const metricsSchema = z.object({
  total_completed: z.number(),
  incoming_calls: z.number(),
  calls_minutes: z.number().nullable(),
  call_reasons: z.array(metricsCallReasonSchema),
  completed_by_user: z.array(completedByUserSchema).nullable(),
});

export const promptSchema = z.string();

export const isValidSchema = z.boolean();
