import Popover from "@mui/material/Popover";
import { getTeamIcon } from "@src/components/Settings/Users/Teams/IconSelection";
import { selectInputText } from "@src/helpers/helpers";
import type { AssignedTeam, AssignedUser } from "@src/store/call/interfaces";
import { useGetTeamsQuery, useGetUsersQuery } from "@src/store/user/api";
import type { Team, User } from "@src/store/user/userReducer";
import { useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import ProfilePicture from "../../Basic/ProfilePicture";

const getListItemStyles = (isSelected: boolean) =>
  `flex items-center space-x-2 text-sm font-medium py-1.5 px-2 rounded-lg cursor-pointer ${
    isSelected ? "bg-gray-200 hover:bg-gray-300" : "hover:bg-gray-110"
  }`;

interface AssignButtonProps {
  onAssign: (assignTo: { provider: User | null; team: Team | null }) => void;
  assignedTo: { provider: AssignedUser | null; team: AssignedTeam | null };
  label?: string;
  anchorOrigin?: {
    vertical: "top" | "center" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  transformOrigin?: {
    vertical: "top" | "center" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  className?: string;
  buttonClassName?: string;
  labelClassName?: string;
  displayArrow?: boolean;
  popoverWidth?: string;
  hideSearchInput?: boolean;
  disabled?: boolean;
}

const AssignButton = ({
  assignedTo,
  onAssign,
  label = "Assign",
  anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  className = "",
  buttonClassName = "",
  labelClassName = "text-sm",
  displayArrow = false,
  popoverWidth = "230px",
  hideSearchInput = false,
  disabled,
}: AssignButtonProps) => {
  const [openList, setOpenList] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchFilter] = useDebounce(searchValue, 200);

  const { data: users = [] } = useGetUsersQuery({
    search: searchFilter,
  });
  const { data: teams = [] } = useGetTeamsQuery({
    search: searchFilter,
  });

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
  };

  const handleOpenList = (e: React.MouseEvent<HTMLElement>) => {
    setOpenList(true);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseList = () => {
    setOpenList(false);
    setAnchorEl(null);
  };

  const handleAssign = (resource: User | Team) => {
    handleCloseList();

    const assignTo = {
      provider: null,
      team: null,
    };

    if (
      resource &&
      "doctor_team_id" in resource &&
      assignedTo?.team?.doctor_team_id !== resource.doctor_team_id
    ) {
      assignTo.team = resource;
    } else if (
      resource &&
      "doctor_id" in resource &&
      assignedTo?.provider?.doctor_id !== resource.doctor_id
    ) {
      assignTo.provider = resource;
    }

    onAssign(assignTo);
  };

  const isResourceSelected = (resource: User | Team) => {
    if ("doctor_team_id" in resource) {
      return resource.doctor_team_id === assignedTo?.team?.doctor_team_id;
    }
    return resource.doctor_id === assignedTo?.provider?.doctor_id;
  };

  const sortedUsers = users
    .map((user) => ({
      ...user,
      display:
        user.display_name?.trim() ||
        `${user.first_name?.trim() || ""} ${user.last_name?.trim() || "-"}`,
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  const sortedTeams = teams
    .map((team) => ({
      ...team,
      display: team.name?.trim() || "-",
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  return (
    <div className={`flex items-center relative ${className}`}>
      <button
        type="button"
        className={`flex items-center h-full px-3 py-1.5 overflow-hidden ${buttonClassName}`}
        onClick={handleOpenList}
        disabled={disabled}
      >
        {assignedTo?.provider || assignedTo?.team ? (
          <div
            className={`h-8 rounded-full flex items-center space-x-1
              ${displayArrow ? "w-full" : "border border-gray-foreground pl-1 pr-2"}`}
          >
            {assignedTo.provider ? (
              <ProfilePicture
                size={6}
                fontSize="xxs"
                firstName={assignedTo.provider.first_name}
                lastName={assignedTo.provider.last_name}
                flagPadding={false}
                src={assignedTo.provider.profile_picture}
              />
            ) : (
              getTeamIcon(assignedTo.team.team_icon, "20", "#121212")
            )}
            <div
              className={`leading-normal font-medium text-left truncate
                ${displayArrow ? "" : "text-xxs max-w-20 lg:max-w-34 "}`}
            >
              {!displayArrow && <p className="text-tertiary">Assigned to</p>}
              <p className="truncate">
                {assignedTo.provider
                  ? assignedTo.provider.display_name ||
                    `${assignedTo.provider.first_name} ${assignedTo.provider.last_name}`
                  : assignedTo.team.name}
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`font-semibold truncate flex items-center
              ${disabled ? "text-tertiary" : "text-primary-blue"}
              ${labelClassName}`}
          >
            {label}
          </div>
        )}

        {displayArrow && (
          <ArrowIcon
            width="10"
            height="7"
            className={`flex-none ml-2 transition-all ${openList ? "-rotate-180" : ""}`}
          />
        )}
      </button>

      {openList && anchorEl && (
        <Popover
          open={openList}
          onClose={handleCloseList}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          TransitionProps={{
            onEntered: () => {
              searchInputRef.current?.focus();
            },
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: popoverWidth,
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: "0 0 16px 0",
              },
            },
          }}
        >
          <div className="w-full max-h-[70vh] height-sm:max-h-108 overflow-hidden grid grid-rows-note-layout py-2">
            <div className="overflow-y-auto scrollbar pl-2 pr-1 pb-2 space-y-1">
              {sortedTeams?.map((team) => (
                <div
                  key={team.doctor_team_id}
                  className={getListItemStyles(isResourceSelected(team))}
                  onClick={() => handleAssign(team)}
                >
                  {getTeamIcon(team.team_icon, "20", "#121212")}
                  <p>{team.display}</p>
                </div>
              ))}

              {sortedUsers.length > 0 && sortedTeams.length > 0 && <hr />}

              {sortedUsers?.map((user) => (
                <div
                  key={user.doctor_id}
                  className={getListItemStyles(isResourceSelected(user))}
                  onClick={() => handleAssign(user)}
                >
                  <ProfilePicture
                    size={6}
                    fontSize="xxs"
                    firstName={user.first_name}
                    lastName={user.last_name}
                    flagPadding={false}
                    src={user.profile_picture}
                  />
                  <p>{user.display}</p>
                </div>
              ))}
            </div>
            {!hideSearchInput && (
              <div className="relative w-full px-2">
                <input
                  type="text"
                  ref={searchInputRef}
                  value={searchValue}
                  onClick={selectInputText}
                  onChange={handleSearchInput}
                  className="rounded-lg h-9 text-sm outline-none w-full pl-2 pr-7 placeholder:text-gray-400 border"
                  placeholder="Search ..."
                />
                <SearchIcon
                  className="absolute top-2 right-3.5"
                  width="20"
                  height="20"
                />
              </div>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default AssignButton;
