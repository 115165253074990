import {
  handleValidation,
  patientSchema,
} from "@src/helpers/commonValidationSchemas";
import { handleRequestError } from "@src/helpers/helpers";
import { baseApi } from "@src/store/baseApi";
import type { Patient } from "@src/store/patient/interfaces";
import {
  appointmentTypeSchema,
  insuranceSchema,
  reasonSchema,
  scheduleProviderSchema,
  scheduleSiteSchema,
  siteSlotsSchema,
} from "@src/store/schedule/schemas";
import type {
  AppointmentType,
  Insurance,
  Reason,
  ScheduleProvider,
  ScheduleSite,
  SiteSlots,
} from "@src/store/schedule/types";
import { z } from "zod";

export const scheduleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatientByToken: builder.query<Patient, string>({
      query: (token) => ({
        url: `/call/link/${token}`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load patient info");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: { patient: Patient }) => {
        const patientInfo = response.patient;
        handleValidation(patientSchema, patientInfo, "getPatientByToken");
        return patientInfo;
      },
      providesTags: (result) =>
        result ? [{ type: "Patient" as const, id: result.patient_id }] : [],
    }),

    getScheduleSites: builder.query<
      ScheduleSite[],
      { provider_id?: number; token: string }
    >({
      query: ({ provider_id, token }) => ({
        url: `/schedule/sites${provider_id ? `?provider_id=${provider_id}` : ""}`,
        method: "GET",
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load sites");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: ScheduleSite[]) => {
        const scheduleSites = response;
        handleValidation(
          z.array(scheduleSiteSchema),
          scheduleSites,
          "getScheduleSites",
        );
        return scheduleSites;
      },
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ScheduleSite" as const,
                id,
              })),
              { type: "ScheduleSite" as const, id: "LIST" },
              {
                type: "ScheduleSite" as const,
                id: `LIST_PROVIDER_${arg.provider_id ?? "ALL"}`,
              },
            ]
          : [{ type: "ScheduleSite" as const, id: "LIST" }],
    }),

    getScheduleProviders: builder.query<
      ScheduleProvider[],
      { site_id?: number; token: string }
    >({
      query: ({ site_id, token }) => ({
        url: `/schedule/providers${site_id ? `?site_id=${site_id}` : ""}`,
        method: "GET",
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load providers");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: ScheduleProvider[]) => {
        const scheduleProviders = response;
        handleValidation(
          z.array(scheduleProviderSchema),
          scheduleProviders,
          "getScheduleProviders",
        );
        return scheduleProviders;
      },
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ScheduleProvider" as const,
                id,
              })),
              { type: "ScheduleProvider" as const, id: "LIST" },
              {
                type: "ScheduleProvider" as const,
                id: `LIST_SITE_${arg.site_id ?? "ALL"}`,
              },
            ]
          : [{ type: "ScheduleProvider" as const, id: "LIST" }],
    }),

    getScheduleSlots: builder.query<
      SiteSlots,
      {
        site_id?: number;
        provider_id?: number;
        reason_id?: number;
        appointment_type_id?: number;
        insurance_id?: number;
        token: string;
      }
    >({
      query: ({
        site_id,
        provider_id,
        reason_id,
        appointment_type_id,
        insurance_id,
        token,
      }) => {
        const queryParams = [
          site_id ? `site_id=${site_id}` : "",
          provider_id ? `provider_id=${provider_id}` : "",
          reason_id ? `reason_id=${reason_id}` : "",
          appointment_type_id
            ? `appointment_type_id=${appointment_type_id}`
            : "",
          insurance_id ? `insurance_id=${insurance_id}` : "",
        ]
          .filter(Boolean)
          .join("&");

        return {
          url: `/schedule/slots${queryParams ? `?${queryParams}` : ""}`,
          method: "GET",
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load slots");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: SiteSlots) => {
        const scheduleSlots = response;
        handleValidation(siteSlotsSchema, scheduleSlots, "getScheduleSlots");
        return scheduleSlots;
      },
      providesTags: (_result, _error, arg) => {
        const site = arg.site_id ?? "ALL";
        const provider = arg.provider_id ?? "ALL";
        const reason = arg.reason_id ?? "ALL";
        const type = arg.appointment_type_id ?? "ALL";
        const insurance = arg.insurance_id ?? "ALL";
        const tagId = `${site}_${provider}_${reason}_${type}_${insurance}`;
        return [{ type: "ScheduleSlot" as const, id: tagId }];
      },
    }),

    getAppointmentTypes: builder.query<AppointmentType[], { token: string }>({
      query: ({ token }) => ({
        url: "/schedule/appointment-types",
        method: "GET",
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to load appointment types",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: AppointmentType[]) => {
        const appointmentTypes = response;
        handleValidation(
          z.array(appointmentTypeSchema),
          appointmentTypes,
          "getAppointmentTypes",
        );
        return appointmentTypes;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ScheduleType" as const,
                id,
              })),
              { type: "ScheduleType" as const, id: "LIST" },
            ]
          : [{ type: "ScheduleType" as const, id: "LIST" }],
    }),

    getInsurances: builder.query<Insurance[], { token: string }>({
      query: ({ token }) => ({
        url: "/schedule/insurances",
        method: "GET",
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load insurances");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: Insurance[]) => {
        const insurances = response;
        handleValidation(z.array(insuranceSchema), insurances, "getInsurances");
        return insurances;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ScheduleInsurance" as const,
                id,
              })),
              { type: "ScheduleInsurance" as const, id: "LIST" },
            ]
          : [{ type: "ScheduleInsurance" as const, id: "LIST" }],
    }),

    getReasons: builder.query<Reason[], { token: string }>({
      query: ({ token }) => ({
        url: "/schedule/reasons",
        method: "GET",
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load reasons");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: Reason[]) => {
        const reasons = response;
        handleValidation(z.array(reasonSchema), reasons, "getReasons");
        return reasons;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ScheduleReason" as const,
                id,
              })),
              { type: "ScheduleReason" as const, id: "LIST" },
            ]
          : [{ type: "ScheduleReason" as const, id: "LIST" }],
    }),

    scheduleAppointment: builder.mutation<
      number,
      {
        token: string;
        slot_id: number;
        patient: Partial<Patient>;
        update_info: boolean;
        agreements: {
          phone_call: boolean;
          sms_message: boolean;
          email_message: boolean;
        };
        hear_about_us: string;
        reason_for_visit: string;
      }
    >({
      query: ({
        token,
        slot_id,
        patient,
        update_info,
        agreements,
        hear_about_us,
        reason_for_visit,
      }) => ({
        url: "/schedule/appointment",
        method: "POST",
        data: {
          slot_id,
          patient,
          update_info,
          agreements,
          hear_about_us,
          reason_for_visit,
        },
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to schedule appointment");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: { appointmentId: number }) => {
        const appointmentId = response.appointmentId;
        handleValidation(z.number(), appointmentId, "scheduleAppointment");
        return appointmentId;
      },
    }),
  }),
});

export const {
  useGetPatientByTokenQuery,
  useGetScheduleSitesQuery,
  useGetScheduleProvidersQuery,
  useGetScheduleSlotsQuery,
  useGetAppointmentTypesQuery,
  useGetInsurancesQuery,
  useGetReasonsQuery,
  useScheduleAppointmentMutation,
} = scheduleApi;
