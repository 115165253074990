import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import type { RootState } from "..";
import api from "../../helpers/api";
import { initializeFeatureFlags, setFeatureFlag } from "../featureFlagSlice";
import type { FeatureFlags } from "../types";
import { getUser } from "../user/thunks";

interface FeatureFlagError {
  flag: FeatureFlags;
  enabled: boolean;
  message: string;
}

interface AdminState {
  featureFlagUpdates: {
    [key in FeatureFlags]?: {
      loading: boolean;
      error: string | null;
      previousValue: boolean | null;
    };
  };
  deactivateAccount: {
    loading: boolean;
    error: string | null;
    success: boolean;
  };
}

const initialState: AdminState = {
  featureFlagUpdates: {},
  deactivateAccount: {
    loading: false,
    error: null,
    success: false,
  },
};

export const updateFeatureFlag = createAsyncThunk<
  { flag: FeatureFlags; enabled: boolean },
  { flag: FeatureFlags; enabled: boolean },
  { rejectValue: FeatureFlagError }
>(
  "admin/updateFeatureFlag",
  async ({ flag, enabled }, { dispatch, rejectWithValue }) => {
    try {
      // Optimistically update the feature flag
      dispatch(setFeatureFlag({ flag, enabled }));

      // Make the API call
      await api.patch("/admin/feature-flags", {
        flag,
        enabled,
      });

      // Refresh user data to get latest feature flags
      const user = await getUser();
      dispatch(initializeFeatureFlags(user.organization.featureFlags));

      return { flag, enabled };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue({
          flag,
          enabled,
          message:
            error.response?.data?.message || "Failed to update feature flag",
        });
      }
      throw error;
    }
  },
);

export const deactivateAccount = createAsyncThunk(
  "admin/deactivateAccount",
  async () => {
    try {
      await api.delete("/admin/organization");
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          error.response?.data?.message || "Failed to deactivate account",
        );
      }
      throw error;
    }
  },
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetDeactivateStatus: (state) => {
      state.deactivateAccount = initialState.deactivateAccount;
    },
  },
  extraReducers: (builder) => {
    builder
      // Update Feature Flag
      .addCase(updateFeatureFlag.pending, (state, action) => {
        const flag = action.meta.arg.flag;
        const currentValue =
          state.featureFlagUpdates[flag]?.previousValue ??
          action.meta.arg.enabled;
        state.featureFlagUpdates[flag] = {
          loading: true,
          error: null,
          previousValue: currentValue,
        };
      })
      .addCase(updateFeatureFlag.fulfilled, (state, action) => {
        const flag = action.payload.flag;
        state.featureFlagUpdates[flag] = {
          loading: false,
          error: null,
          previousValue: null,
        };
      })
      .addCase(updateFeatureFlag.rejected, (state, action) => {
        const payload = action.payload;
        if (payload) {
          const { flag, message } = payload;
          state.featureFlagUpdates[flag] = {
            loading: false,
            error: message,
            previousValue: null,
          };
        }
      })
      // Deactivate Account
      .addCase(deactivateAccount.pending, (state) => {
        state.deactivateAccount = {
          loading: true,
          error: null,
          success: false,
        };
      })
      .addCase(deactivateAccount.fulfilled, (state) => {
        state.deactivateAccount = {
          loading: false,
          error: null,
          success: true,
        };
      })
      .addCase(deactivateAccount.rejected, (state, action) => {
        state.deactivateAccount = {
          loading: false,
          error: action.error.message || "Failed to deactivate account",
          success: false,
        };
      });
  },
});

export const { resetDeactivateStatus } = adminSlice.actions;

export const selectFeatureFlagUpdate =
  (flag: FeatureFlags) => (state: RootState) =>
    state.admin.featureFlagUpdates[flag];

export const selectDeactivateStatus = (state: RootState) =>
  state.admin.deactivateAccount;
