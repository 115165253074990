import { useCallback, useEffect, useState } from "react";

interface VersionInfo {
  version: string;
  timestamp: number;
  message: string;
}

interface VersionCheckerProps {
  /** Interval in ms to check for updates (default: 15 minutes) */
  checkInterval?: number;
  /** URL to fetch version info from */
  versionUrl?: string;
  /** Callback when visibility changes */
  onVisibilityChange?: (isVisible: boolean) => void;
}

/**
 * Component that checks for new application versions and prompts the user to update.
 * Shows a banner at the top of the layout without blocking other elements.
 * Requests update if:
 * 1. More than 2 days since the last version, or
 * 2. There is an update on the minor or major version
 */
const VersionChecker = ({
  checkInterval = 15 * 60 * 1000,
  versionUrl = "/version.json",
  onVisibilityChange,
}: VersionCheckerProps) => {
  const [newVersionAvailable, setNewVersionAvailable] =
    useState<boolean>(false);
  const [currentVersion] = useState<string | undefined>(
    process.env.REACT_APP_VERSION,
  );
  const [releaseTimestamp, setReleaseTimestamp] = useState<number | null>(null);

  const isFrontdeskPage = location.pathname.match(/\/front-desk$/);

  const parseVersion = useCallback((version: string | undefined) => {
    if (!version) {
      return { major: 0, minor: 0, patch: 0 };
    }

    // Handle versions with commit hash (e.g., "1.2.3+abc123")
    const versionPart = version.split("+")[0];
    const parts = versionPart.split(".");

    return {
      major: Number.parseInt(parts[0]) || 0,
      minor: parts.length > 1 ? Number.parseInt(parts[1]) || 0 : 0,
      patch: parts.length > 2 ? Number.parseInt(parts[2]) || 0 : 0,
    };
  }, []);

  const isSignificantUpdate = useCallback(
    (currentVer: string | undefined, latestVer: string | null) => {
      if (!currentVer || !latestVer) {
        return false;
      }

      // Clean the versions before parsing
      const cleanCurrentVer = currentVer.replace(/"/g, "");
      const cleanLatestVer = latestVer.replace(/"/g, "");

      const current = parseVersion(cleanCurrentVer);
      const latest = parseVersion(cleanLatestVer);

      // Only consider major or minor version changes significant
      // Patch version changes are not considered significant
      return current.major < latest.major || current.minor < latest.minor;
    },
    [parseVersion],
  );

  const checkForNewVersion = useCallback(async () => {
    try {
      // Fetch version.json with cache disabled to ensure we get fresh data
      const response = await fetch(versionUrl, {
        cache: "no-store",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });

      if (response.ok) {
        const data = (await response.json()) as VersionInfo;
        setReleaseTimestamp(data.timestamp);

        // First, parse both versions to their base form (without commit hash)
        const currentBase = currentVersion
          ? currentVersion.split("+")[0].replaceAll('"', "")
          : "";
        const latestBase = data.version ? data.version.split("+")[0] : "";

        // Compare the base versions to determine if there's actually a version change
        const isActualVersionChange = currentBase !== latestBase;

        if (isActualVersionChange) {
          // Check if it's a significant update (major/minor version change)
          const significantUpdate = isSignificantUpdate(
            currentVersion,
            data.version,
          );

          // Check if it's been more than 2 days since the release
          const twoDaysInMs = 2 * 24 * 60 * 60 * 1000;
          const isOlderThanTwoDays =
            data.timestamp && Date.now() - data.timestamp > twoDaysInMs;

          // Show update notification if either condition is met
          setNewVersionAvailable(significantUpdate || isOlderThanTwoDays);
        } else {
          // Not an actual version change, just different commit hashes
          setNewVersionAvailable(false);
        }
      }
    } catch (error) {
      console.error("Failed to check for new version:", error);
    }
  }, [currentVersion, versionUrl, isSignificantUpdate]);

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange(newVersionAvailable);
    }
  }, [newVersionAvailable, onVisibilityChange]);

  // Check for new version on initial load and at the specified interval
  useEffect(() => {
    checkForNewVersion();

    const interval = setInterval(checkForNewVersion, checkInterval);
    return () => clearInterval(interval);
  }, [checkInterval, checkForNewVersion]);

  const handleRefresh = () => {
    // Clear cache and reload page
    const clearCachesAndReload = async () => {
      if ("caches" in window) {
        try {
          const keyList = await caches.keys();
          await Promise.all(keyList.map((key) => caches.delete(key)));
        } catch (err) {
          console.error("Error clearing cache:", err);
        }
      }
      window.location.reload();
    };

    clearCachesAndReload();
  };

  if (!newVersionAvailable || !isFrontdeskPage) {
    return null;
  }

  // Format date nicely if available
  const formattedDate = releaseTimestamp
    ? new Date(releaseTimestamp).toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : "";

  return (
    <div
      className="w-full flex flex-row items-center min-h-[40px] z-[9999] bg-[#f0f7ff] p-2 md:px-4 shadow-sm md:pl-20"
      data-testid="version-notification"
    >
      <span
        className="font-medium text-sm text-black flex-grow text-center px-2 sm:px-4 flex justify-center items-center
          leading-tight"
      >
        {formattedDate
          ? `New update available ${formattedDate}`
          : "New update available"}
      </span>

      <button
        type="button"
        onClick={handleRefresh}
        className="bg-primary-blue flex justify-center p-1 md:p-2 px-4 md:px-10 gap-3 text-white rounded-lg
          font-semibold items-center"
        data-testid="update-button"
      >
        Update
      </button>
    </div>
  );
};

export default VersionChecker;
