import CallMetrics from "@src/pages/Settings/CallMetrics";
// import PricingSettings from "@src/pages/Settings/PricingSettings";
import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import { Pages, getDefaultRoute } from "./helpers/constants";
import { lazyRetry } from "./helpers/lazyRetry";
import BillingMetrics from "./pages/BillingMetrics";
import Calendar from "./pages/Calendar";
import Enroll from "./pages/Enroll";
import FrontDesk from "./pages/FrontDesk";
import Login from "./pages/Login";
import Onboarding from "./pages/Onboarding";
import PatientProfile from "./pages/PatientProfile";
import Patients from "./pages/Patients";
import Scribe from "./pages/Scribe";
import AdminSettings from "./pages/Settings/AdminSettings";
import CareManagementSettings from "./pages/Settings/CareManagementSettings";
import ClinicSettings from "./pages/Settings/ClinicSettings";
import NotesSettings from "./pages/Settings/NotesSettings";
import ProfileSettings from "./pages/Settings/ProfileSettings";
import SettingsTabs from "./pages/Settings/SettingsTabs";
import UsersSettings from "./pages/Settings/UsersSettings";
import VoiceAgent from "./pages/VoiceAgent";
import { useAppDispatch, useAppSelector } from "./store";
import { useFeature } from "./store/featureFlagSelectors";
import { getPatientInfo } from "./store/patient/thunks";
import { FeatureFlags } from "./store/types";
import { selectUser } from "./store/user/userReducer";

const Metrics = lazy(() => lazyRetry(() => import("./pages/Metrics.js")));
const SignUp = lazy(() => lazyRetry(() => import("./pages/SignUp.js")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("./pages/ForgotPassword.js")),
);
const CheckEmail = lazy(() => lazyRetry(() => import("./pages/CheckEmail.js")));
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./pages/ResetPassword.js")),
);
const ResetPasswordConfirmation = lazy(() =>
  lazyRetry(() => import("./pages/ResetPasswordConfirmation.js")),
);
const PasswordCreation = lazy(() =>
  lazyRetry(() => import("./pages/PasswordCreation.js")),
);
const PatientForm = lazy(() =>
  lazyRetry(() => import("./pages/PatientForm.js")),
);

const AppRoutes = () => {
  const user = useAppSelector(selectUser);
  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isCCM = useFeature(FeatureFlags.CCM);
  const isCustomizeCalls = useFeature(FeatureFlags.CUSTOMIZE_CALLS);
  const isCommandCenter = useFeature(FeatureFlags.COMMAND_CENTER);

  const dispatch = useAppDispatch();

  const isSystemAdmin = user?.email?.endsWith("@quadrant.health");

  return (
    <Routes>
      <Route path={Pages.METRICS} element={<Metrics />} />
      <Route path={Pages.LOGIN} element={<Login signup={false} />} />
      <Route path={Pages.SIGNUP} element={<Login signup={true} />} />
      <Route path={Pages.SIGNUP_V2} element={<SignUp />} />
      <Route path={Pages.FORGOT_PASS} element={<ForgotPassword />} />
      <Route path={Pages.CHECK_EMAIL} element={<CheckEmail />} />
      <Route path={Pages.RESET_PASS} element={<ResetPassword />} />
      <Route
        path={Pages.RESET_PASS_CONFIRM}
        element={<ResetPasswordConfirmation />}
      />
      <Route path={Pages.CREATE_PASS} element={<PasswordCreation />} />
      <Route path={Pages.ONBOARDING} element={<Onboarding />} />
      <Route path={Pages.FORM_PATIENTS} element={<PatientForm />} />

      <Route element={<ProtectedRoute />}>
        {isScribeOnly && <Route path={Pages.SCRIBE} element={<Scribe />} />}
        {(isFrontDesk || isFrontDeskInbox) && (
          <>
            <Route path={Pages.FRONT_DESK} element={<FrontDesk />} />
            <Route path={Pages.PATIENTS} element={<Patients />} />
            <Route
              path={Pages.PATIENT_PROFILE}
              element={<PatientProfile />}
              loader={async ({ params }) => {
                console.log("preloading the route");
                dispatch(getPatientInfo(Number(params.id)));

                return {};
              }}
            />
          </>
        )}
        {isCustomizeCalls && (
          <Route path={Pages.VOICE_AGENT} element={<VoiceAgent />} />
        )}
        {isCCM && (
          <>
            <Route path={Pages.ENROLL} element={<Enroll />} />
            <Route path={Pages.BILLING_METRICS} element={<BillingMetrics />} />
            <Route path={Pages.CALENDAR} element={<Calendar />} />

            <Route
              path={Pages.SETTINGS_CARE_MANAGEMENT}
              element={
                <SettingsTabs>
                  <CareManagementSettings />
                </SettingsTabs>
              }
            />
          </>
        )}

        <Route
          path={Pages.SETTINGS_PROFILE}
          element={
            <SettingsTabs>
              <ProfileSettings />
            </SettingsTabs>
          }
        />
        <Route
          path={Pages.SETTINGS_USERS}
          element={
            <SettingsTabs>
              <UsersSettings />
            </SettingsTabs>
          }
        />
        <Route
          path={Pages.SETTINGS_CLINIC}
          element={
            <SettingsTabs>
              <ClinicSettings />
            </SettingsTabs>
          }
        />
        <Route
          path={Pages.SETTINGS_NOTES}
          element={
            <SettingsTabs>
              <NotesSettings />
            </SettingsTabs>
          }
        />

        {isSystemAdmin && (
          <>
            <Route
              path={Pages.SETTINGS_ADMIN}
              element={
                <SettingsTabs>
                  <AdminSettings />
                </SettingsTabs>
              }
            />

            {/*<Route*/}
            {/*  path={Pages.SETTINGS_PRICING}*/}
            {/*  element={*/}
            {/*    <SettingsTabs>*/}
            {/*      <PricingSettings />*/}
            {/*    </SettingsTabs>*/}
            {/*  }*/}
            {/*/>*/}
          </>
        )}

        {isCommandCenter && (
          <Route
            path={Pages.SETTINGS_CALL_METRICS}
            element={
              <SettingsTabs>
                <CallMetrics />
              </SettingsTabs>
            }
          />
        )}

        <Route
          path="*"
          element={
            <Navigate
              to={getDefaultRoute({
                isScribeOnlyFeatureEnabled: isScribeOnly,
                isFrontDeskInboxEnabled: isFrontDeskInbox,
                isFrontDeskEnabled: isFrontDesk,
              })}
              replace
            />
          }
        />
      </Route>

      <Route path="*" element={<Navigate to={Pages.LOGIN} replace />} />
    </Routes>
  );
};

export default AppRoutes;
