import { useEffect, useRef } from "react";
import { useAppSelector } from "../../store";
import { selectToken } from "../../store/user/userReducer";
import { getWebSocketService } from "../websocketServiceInstance";

const WebSocketManager = () => {
  const disconnectTimeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  const token = useAppSelector(selectToken);
  const wsService = getWebSocketService();

  useEffect(() => {
    wsService.updateToken(token);
  }, [token, wsService]);

  // This effect will clear the timeout if we remount quickly (StrictMode)
  useEffect(() => {
    return () => {
      if (disconnectTimeoutRef.current) {
        console.log("Component remounted, canceling disconnect");
        clearTimeout(disconnectTimeoutRef.current);
      }
    };
  }, []);

  return null;
};

export default WebSocketManager;
