import Skeleton from "@mui/material/Skeleton";
import { roundNumberToTwoDecimals } from "@src/helpers/helpers";

interface AnalyticsNumberCardProps {
  title: string;
  number: number | string;
  isLoading?: boolean;
  className?: string;
}

const AnalyticsNumberCard = ({
  title,
  number,
  isLoading,
  className = "",
}: AnalyticsNumberCardProps) => {
  return (
    <div
      className={`p-5 border border-gray-250 bg-white rounded-lg h-fit w-full space-y-2 ${className}`}
    >
      <p>{title}</p>
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={30}
          width={100}
          sx={{
            bgcolor: "#F8F8F8",
            borderRadius: "30px",
          }}
        />
      ) : (
        <p className="text-3xl font-semibold">
          {roundNumberToTwoDecimals(number)}
        </p>
      )}
    </div>
  );
};

export default AnalyticsNumberCard;
