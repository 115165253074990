import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AnalyticsPeriod } from "@src/store/voiceAgent/types";
import { getDefaultDates } from "@src/utils/dateUtil";
import { forwardRef } from "react";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-down.svg";
import CustomPickerButton from "./CustomPickerButton";
import { useDatePicker } from "./useDatePicker";

interface YearPickerProps {
  baseUrl: string;
}

const YearPicker = ({ baseUrl }: YearPickerProps) => {
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    isActive,
    handleSelectDate,
    normalizeDate,
  } = useDatePicker({
    periodType: AnalyticsPeriod.YEAR,
    baseUrl: baseUrl,
  });

  const defaultDates = getDefaultDates(AnalyticsPeriod.YEAR);

  const CustomYearButton = forwardRef<HTMLButtonElement, any>(
    ({ onClick }, ref) => (
      <CustomPickerButton
        ref={ref}
        isActive={isActive}
        onClick={onClick}
        label="Year"
        basePeriodUrl={baseUrl}
        defaultStartDate={defaultDates.start}
        defaultEndDate={defaultDates.end}
      />
    ),
  );

  const renderCustomContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={`customYearPicker shadow-md ${className}`}>
        {children}
      </CalendarContainer>
    );
  };

  const getYearsPickerRangeStart = (date: Date) => {
    const dateYear = date.getFullYear();
    const rangeStart = dateYear - (dateYear % 12) + 1;
    return [rangeStart, rangeStart + 11];
  };

  const renderCustomHeader = ({
    date,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => {
    const [rangeStart, rangeEnd] = getYearsPickerRangeStart(date);

    return (
      <div className="flex items-center justify-between px-3 pt-2 text-sm font-medium">
        <button
          type="button"
          onClick={decreaseYear}
          disabled={prevYearButtonDisabled}
          className={
            prevYearButtonDisabled || rangeStart <= minDate.getFullYear()
              ? "invisible"
              : ""
          }
        >
          <ArrowIcon
            width="10"
            height="10"
            stroke="#0F172A"
            className="rotate-90"
          />
        </button>

        <p className="text-base font-semibold">
          {rangeStart} - {rangeEnd}
        </p>

        <button
          type="button"
          onClick={increaseYear}
          disabled={nextYearButtonDisabled}
          className={
            nextYearButtonDisabled || rangeEnd >= maxDate.getFullYear()
              ? "invisible"
              : ""
          }
        >
          <ArrowIcon
            width="10"
            height="10"
            stroke="#0F172A"
            className="-rotate-90"
          />
        </button>
      </div>
    );
  };

  return (
    <DatePicker
      selected={normalizeDate(startDate)}
      onChange={handleSelectDate}
      customInput={<CustomYearButton />}
      calendarContainer={renderCustomContainer}
      renderCustomHeader={renderCustomHeader}
      popperClassName="customYearPickerPopper"
      popperPlacement="bottom"
      startDate={normalizeDate(startDate)}
      endDate={normalizeDate(endDate)}
      minDate={minDate}
      maxDate={maxDate}
      showYearPicker
      disabledKeyboardNavigation
      shouldCloseOnSelect={false}
    />
  );
};

export default YearPicker;
