// Step 3: Create a new featureFlagSelectors.ts file
// featureFlagSelectors.ts
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";
import type { FeatureFlags } from "./types";

const selectFeatureFlagState = (state: RootState) => state.featureFlags;

export const selectFeatureFlag = (flag: FeatureFlags) =>
  createSelector(
    selectFeatureFlagState,
    (state) => state.flags[flag]?.enabled ?? false,
  );

export const selectFeatureFlagConfig = (flag: FeatureFlags) =>
  createSelector(selectFeatureFlagState, (state) => state.flags[flag]?.config);

// Define the hook here without importing from store/index.ts
export const useFeature = (flag: FeatureFlags) => {
  return useSelector(
    (state: RootState) => state.featureFlags.flags[flag]?.enabled ?? false,
  );
};
