import { ReactComponent as CopyIcon } from "@assets/icons/copy-check.svg";
import { ReactComponent as FileDownloadIcon } from "@assets/icons/file-download.svg";
import { useUrlState } from "@src/hooks/useUrlState";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useCallback, useEffect, useRef, useState } from "react";
import { copyText, getAge } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { saveEncounterNoteSimple } from "../../../store/conversation/thunks";
import type { Patient } from "../../../store/patient/interfaces";
import { setNotificationAction } from "../../../store/user/actions";
import { selectUser } from "../../../store/user/userReducer";
import TextEditor from "../../Basic/TextEditor";
import { setScribe } from "../store/scribeSlice";
import { selectScribeById } from "../store/selectors";

interface ScribeNoteProps {
  originalNote: string;
  patient?: Patient;
}

const ScribeNote = ({ originalNote, patient }: ScribeNoteProps) => {
  const user = useAppSelector(selectUser);
  const { value: selectedScribeId } = useUrlState("scribeId", null);
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );
  const contentRef = useRef(null);
  const pdfContentRef = useRef(null);
  const noteRef = useRef(originalNote);
  const [focused, setFocused] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    noteRef.current = originalNote;
  }, [originalNote]);

  const handleChange = useCallback((value) => {
    noteRef.current = value;
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
    if (!selectedScribe?.noteId) {
      return;
    }

    dispatch(
      setScribe({
        audioId: selectedScribe.audioId,
        scribe: {
          note: noteRef.current,
          isEditing: false,
        },
      }),
    );

    dispatch(saveEncounterNoteSimple(selectedScribe?.audioId, noteRef.current));
  }, [dispatch, selectedScribe?.audioId, selectedScribe?.noteId]);

  const handleFocus = () => {
    setFocused(true);
    dispatch(
      setScribe({
        audioId: selectedScribe.audioId,
        scribe: {
          isEditing: true,
        },
      }),
    );
  };

  const handleCopyNote = () => {
    copyText(noteRef.current, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied note content",
          desc: "Note content has been successfully copied to the clipboard",
        }),
      ),
    );
  };

  interface CSSPropertiesWithVendorPrefix extends React.CSSProperties {
    WebkitFontSmoothing?: "antialiased" | "subpixel-antialiased" | "none";
    MozOsxFontSmoothing?: "grayscale" | "unset";
  }

  const handleDownloadNote = async () => {
    try {
      const pdfContent = pdfContentRef.current;
      if (!pdfContent) {
        return;
      }

      // Set PDF content styles
      pdfContent.style.display = "block";
      pdfContent.style.position = "fixed";
      pdfContent.style.top = "0";
      pdfContent.style.left = "-9999px";
      pdfContent.style.width = "800px";
      pdfContent.style.backgroundColor = "#fff";
      pdfContent.style.padding = "40px";

      const canvas = await html2canvas(pdfContent, {
        scale: 2,
        logging: false,
        useCORS: true,
        allowTaint: true,
        backgroundColor: "#fff",
        windowWidth: 800,
        onclone: (clonedDoc) => {
          const clonedElement = clonedDoc.querySelector("[data-hidden-pdf]");
          if (clonedElement instanceof HTMLElement) {
            clonedElement.style.display = "block";
            clonedElement.style.height = "auto";
            clonedElement.style.visibility = "visible";
            clonedElement.style.fontFamily =
              "'Inter', -apple-system, sans-serif";
          }
        },
      });

      // Reset original element
      pdfContent.style.display = "none";
      pdfContent.style.position = "static";
      pdfContent.style.width = "auto";
      pdfContent.style.padding = "0";

      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height],
        hotfixes: ["px_scaling"],
      });

      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight(),
      );
      pdf.save(`scribe-note-${selectedScribe.audioId}.pdf`);

      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully downloaded note",
          desc: "Note has been successfully downloaded as PDF",
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Failed to download note",
          desc: "There was an error downloading the note as PDF",
        }),
      );
    }
  };

  return (
    <>
      {/* Main content visible on page */}
      <div ref={contentRef} className="space-y-4">
        <div className="flex flex-col space-y-2">
          {selectedScribe.providerId !== user.doctor_id && (
            <p className="text-sm font-semibold tracking-wider text-tertiary">
              RECORDED BY: {selectedScribe.providerName}
            </p>
          )}
          <div className="flex space-x-2">
            <p
              className="text-sm font-semibold tracking-wider text-tertiary"
              data-testid="clinical-note"
            >
              CLINICAL NOTE
            </p>
            <button
              type="button"
              aria-label="Copy note"
              onClick={handleCopyNote}
            >
              <CopyIcon width="24" height="24" className="flex-none" />
            </button>
            <button
              type="button"
              aria-label="Download note"
              onClick={handleDownloadNote}
            >
              <FileDownloadIcon
                width="17"
                height="17"
                className="flex-none -mt-0.5"
              />
            </button>
          </div>
        </div>

        <TextEditor
          htmlMarkup={originalNote}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={false}
          hideToolbar
          className={`mt-2 mb-6 border rounded-lg text-sm text-primary ${focused ? "border-primary-blue" : "border-gray-300"}`}
        />
      </div>

      {/* Hidden content for PDF */}
      <div
        ref={pdfContentRef}
        className="hidden bg-white"
        data-hidden-pdf
        style={
          {
            fontFamily:
              "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
          } as CSSPropertiesWithVendorPrefix
        }
      >
        <div className="space-y-6">
          {selectedScribe?.title && (
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              {selectedScribe.title}
            </h1>
          )}

          {patient && (
            <div className="text-base text-gray-600">
              <span>
                {patient.preferred_name
                  ? patient.preferred_name
                  : patient.first_name}{" "}
                {patient.last_name}
              </span>
              <span>{` ${getAge(patient.birthdate)} yo`}</span>
            </div>
          )}

          <div className="prose prose-gray max-w-none">
            <TextEditor
              onChange={() => {}}
              htmlMarkup={originalNote}
              disabled={true}
              hideToolbar
              className="text-base leading-relaxed"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScribeNote;
