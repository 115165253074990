import { SET_SITES, SET_SITE_SEARCH_VALUE, SET_USER_SITES } from "../types";

export function setSitesAction(sites) {
  return {
    type: SET_SITES,
    payload: {
      sites,
    },
  };
}

export function setSiteSearchValueAction(siteSearchValue) {
  return {
    type: SET_SITE_SEARCH_VALUE,
    payload: {
      siteSearchValue,
    },
  };
}

export function setUserSitesAction(id, sites) {
  return {
    type: SET_USER_SITES,
    payload: {
      id,
      sites,
    },
  };
}
