import { useUrlState } from "@src/hooks/useUrlState";
import { useAppSelector } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import ScribeNote from "../ScribeNote";
import ScribeViewDetailsFooter from "../ScribeViewDetailsFooter";
import { selectScribeById } from "../store/selectors";

const ScribeViewDetails = ({
  patient,
  onResumeRecording,
}: {
  patient: Patient | null;
  onResumeRecording: () => void;
}) => {
  const { value: selectedScribeId } = useUrlState("scribeId", null);
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  return (
    <div className="w-full h-full min-h-screen flex flex-col md:justify-between px-4 md:px-0 pt-5">
      <main className="w-full h-full overflow-auto pr-1 scrollbar">
        <ScribeNote originalNote={selectedScribe?.note} patient={patient} />
        {/* <FreeText
          originalFreeText={selectedScribe?.freeText}
          variant="oldRecord"
          copyButton
        /> */}
      </main>
      <ScribeViewDetailsFooter onResumeRecording={onResumeRecording} />
    </div>
  );
};

export default ScribeViewDetails;
