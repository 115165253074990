import Skeleton from "@mui/material/Skeleton";
import { memo } from "react";
import IncomingMessageSkeleton from "./IncomingMessageSkeleton";
import OutgoingMessageSkeleton from "./OutgoingMessageSkeleton";

interface ChatSkeletonProps {
  bgColor?: string;
  animation?: "wave" | "pulse";
  className?: string;
}

const ChatSkeleton = ({
  bgColor = "#D6DBE4",
  animation = "wave",
  className = "",
}: ChatSkeletonProps) => {
  return (
    <div className={className}>
      <IncomingMessageSkeleton
        bgColor={bgColor}
        animation={animation}
        className="mt-2"
      />

      <Skeleton
        animation={animation}
        variant="rounded"
        width={100}
        height={16}
        sx={{
          bgcolor: bgColor,
          borderRadius: "16px",
          margin: "24px auto 16px",
        }}
      />

      <OutgoingMessageSkeleton bgColor={bgColor} animation={animation} />
      <IncomingMessageSkeleton
        bgColor={bgColor}
        animation={animation}
        className="mt-3"
      />

      <Skeleton
        animation={animation}
        variant="rounded"
        width={100}
        height={16}
        sx={{
          bgcolor: bgColor,
          borderRadius: "16px",
          margin: "24px auto 16px",
        }}
      />

      <OutgoingMessageSkeleton bgColor={bgColor} animation={animation} />
      <IncomingMessageSkeleton
        bgColor={bgColor}
        animation={animation}
        className="mt-3"
      />
    </div>
  );
};

export default memo(ChatSkeleton);
