import CacheManager from "./websocket/CacheManager";
import ConnectionManager, {
  type StatusChangeEvent,
  type ConnectionStatus,
} from "./websocket/ConnectionManager";
import MessageProcessor from "./websocket/MessageProcessor";

/**
 * WebSocketService coordinates the components of the WebSocket system
 * - Acts as a facade to simplify the API for consumers
 * - Coordinates ConnectionManager, MessageProcessor, and CacheManager
 */
export class WebSocketService {
  private connectionManager: ConnectionManager;
  private messageProcessor: MessageProcessor;
  private cacheManager: CacheManager;
  private statusListeners: Array<(event: StatusChangeEvent) => void> = [];

  constructor() {
    // console.log(
    //   "WebSocketService initialized with token length:",
    //   token?.length,
    // );

    // Create the components
    this.cacheManager = new CacheManager();
    this.messageProcessor = new MessageProcessor(this.cacheManager);
    this.connectionManager = new ConnectionManager();

    // console.log("Components created, setting up listeners");

    // Set up message processing
    this.connectionManager.addMessageListener(
      this.messageProcessor.processMessage.bind(this.messageProcessor),
    );

    // Forward connection status events
    this.connectionManager.addConnectionStatusListener(this.handleStatusChange);
  }

  /**
   * Disconnect from the WebSocket server
   */
  public disconnect(): void {
    console.log(
      "🛑 WebSocketService disconnect called from:",
      new Error().stack,
    );
    this.connectionManager.disconnect();
  }

  /**
   * Update the authentication token
   */
  public updateToken(token: string): void {
    this.connectionManager.updateToken(token);
  }

  /**
   * Get the current connection status
   */
  public getStatus(): ConnectionStatus {
    return this.connectionManager.getStatus();
  }

  /**
   * Add a connection status listener
   */
  public addStatusListener(listener: (event: StatusChangeEvent) => void): void {
    this.statusListeners.push(listener);
    // Immediately notify of current status with a proper event object
    listener({
      status: this.connectionManager.getStatus(),
      timestamp: Date.now(),
      reason: "Initial status notification from WebSocketService",
    });
  }

  /**
   * Remove a connection status listener
   */
  public removeStatusListener(
    listener: (event: StatusChangeEvent) => void,
  ): void {
    this.statusListeners = this.statusListeners.filter((l) => l !== listener);
  }

  /**
   * Handle status changes from the connection manager
   */
  private handleStatusChange = (event: StatusChangeEvent): void => {
    // console.log(
    //   "WebSocketService received status change:",
    //   event.status,
    //   event.reason || "(no reason provided)",
    // );
    this.statusListeners.forEach((listener) => listener(event));
  };

  /**
   * Configure performance options
   */
  public configurePerformance(options: {
    batchSize?: number;
    debounceThreshold?: number;
    countInvalidationDelay?: number;
  }): void {
    if (options.batchSize !== undefined) {
      this.cacheManager.setBatchSize(options.batchSize);
    }

    if (options.debounceThreshold !== undefined) {
      this.messageProcessor.setDebounceThreshold(options.debounceThreshold);
    }

    if (options.countInvalidationDelay !== undefined) {
      this.cacheManager.setCountInvalidationDelay(
        options.countInvalidationDelay,
      );
    }
  }

  public addMessageListener(listener: (event: MessageEvent) => void): void {
    this.connectionManager.addMessageListener(listener);
  }
}

export default WebSocketService;
