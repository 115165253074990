import WebSocketService from "./WebSocketService";

// Private instance variable
let websocketServiceInstance: WebSocketService | null = null;

/**
 * Returns the singleton instance of WebSocketService
 * Creates a new instance if none exists, otherwise returns existing instance
 */
export function getWebSocketService(): WebSocketService {
  if (!websocketServiceInstance) {
    console.log("Creating new WebSocketService instance");
    websocketServiceInstance = new WebSocketService();
  }

  return websocketServiceInstance;
}

/**
 * Resets the WebSocketService instance (useful for testing or logout)
 */
export function resetWebSocketService(): void {
  if (websocketServiceInstance) {
    websocketServiceInstance.disconnect();
    websocketServiceInstance = null;
  }
}
