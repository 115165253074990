import Popover from "@mui/material/Popover";
import { getFormattedPhoneNumber } from "@src/helpers/helpers";
import type { DirectPhoneNumber } from "@src/store/voiceAgent/types";
import type React from "react";
import { useState } from "react";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone-call.svg";

interface PhoneNumbersDropdownButtonProps {
  phoneNumbers: DirectPhoneNumber[];
}

const PhoneNumbersDropdownButton = ({
  phoneNumbers,
}: PhoneNumbersDropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenList = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseList = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        type="button"
        className="text-xs font-semibold text-white bg-slate-650 rounded-full py-1 px-2"
        onClick={handleOpenList}
      >
        +{phoneNumbers.length - 1}
      </button>

      {anchorEl && (
        <Popover
          open={Boolean(anchorEl)}
          onClose={handleCloseList}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: "150px",
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: "8px 0 0 0",
              },
            },
          }}
        >
          <div className="w-full max-h-[70vh] height-sm:max-h-108 overflow-hidden py-2 px-3 divide-y">
            {phoneNumbers.map((phoneNumber) => (
              <div
                key={phoneNumber.site_direct_phone_number_id}
                className="text-xs font-medium truncate space-y-1 py-2"
              >
                <p className="truncate text-tertiary">{phoneNumber.name}</p>
                <div className="flex items-center space-x-2">
                  <PhoneIcon
                    className="flex-none"
                    fill="#121212"
                    width="16"
                    height="16"
                  />
                  <p className="truncate text-primary">
                    {getFormattedPhoneNumber(phoneNumber.phone_number)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default PhoneNumbersDropdownButton;
