import Skeleton from "@mui/material/Skeleton";
import Switch from "@src/components/Basic/Switch";
import TextareaAutosizeForm from "@src/components/Basic/TextareaAutosizeForm";
import {
  useGetCustomPromptQuery,
  useGetDefaultPromptQuery,
  useGetIsCustomPromptQuery,
  useUpdateCustomPromptMutation,
  useUpdateDefaultPromptMutation,
} from "@src/store/voiceAgent/api";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PasswordModal from "./PasswordModal";
import SectionControls from "./SectionControls";

interface FormValues {
  prompt: string;
}

const customizeDefaultValues = {
  prompt: "",
};

const Customize = () => {
  const [editMode, setEditMode] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [updatedPrompt, setUpdatedPrompt] = useState<string>("");

  const { data: isCustom, isLoading: isLoadingIsCustom } =
    useGetIsCustomPromptQuery();
  const { data: defaultPrompt, isLoading: isLoadingDefaultPrompt } =
    useGetDefaultPromptQuery();
  const { data: customPrompt, isLoading: isLoadingCustomPrompt } =
    useGetCustomPromptQuery();
  const [updateDefaultPrompt] = useUpdateDefaultPromptMutation();
  const [updateCustomPrompt] = useUpdateCustomPromptMutation();

  const [isCustomSwitch, setIsCustomSwitch] = useState(!!isCustom);
  const isLoading =
    isLoadingIsCustom || isLoadingDefaultPrompt || isLoadingCustomPrompt;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: customizeDefaultValues });

  const resetCustomizeForm = useCallback(() => {
    if (defaultPrompt && !isCustomSwitch) {
      reset({ prompt: defaultPrompt });
    } else if (customPrompt && isCustomSwitch) {
      reset({ prompt: customPrompt });
    } else {
      reset(customizeDefaultValues);
    }
  }, [defaultPrompt, customPrompt, isCustomSwitch, reset]);

  useEffect(() => {
    resetCustomizeForm();
  }, [resetCustomizeForm]);

  useEffect(() => {
    setIsCustomSwitch(!!isCustom);
  }, [isCustom]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    resetCustomizeForm();
    setIsCustomSwitch(!!isCustom);
  };

  const toggleDefaultPrompt = () => {
    setIsCustomSwitch(!isCustomSwitch);
  };

  const onSubmitForm = (data: FormValues) => {
    const updatedPrompt = data?.prompt?.trim();
    if (updatedPrompt) {
      setUpdatedPrompt(updatedPrompt);
      setOpenSubmitModal(true);
    }
  };

  const onSubmitPrompt = () => {
    setEditMode(false);
    setOpenSubmitModal(false);

    if (!isCustomSwitch) {
      updateDefaultPrompt(updatedPrompt);
      if (isCustom) {
        updateCustomPrompt({
          prompt: customPrompt || "",
          is_custom: false,
        });
      }
    } else {
      updateCustomPrompt({ prompt: updatedPrompt, is_custom: true });
    }
  };

  return (
    <div
      className={`grid grid-rows-tab-layout gap-5 overflow-hidden h-full ${editMode ? "pb-13 md:pb-0" : ""}`}
    >
      <SectionControls
        title={
          <div className="flex items-center space-x-3 h-10">
            <p>Default prompt</p>
            {isLoading ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                height={20}
                width={36}
                sx={{
                  bgcolor: "#F8F8F8",
                  borderRadius: "20px",
                }}
              />
            ) : (
              <Switch
                enabled={!isCustomSwitch}
                onChange={toggleDefaultPrompt}
                size="medium"
                disabled={!editMode}
              />
            )}
          </div>
        }
        editMode={editMode}
        onEdit={handleEdit}
        onCancel={handleCancel}
        formId="customize-form"
      />

      <form
        id="customize-form"
        onSubmit={handleSubmit(onSubmitForm)}
        className="w-full flex flex-col gap-5 md:gap-6 overflow-y-auto pr-1 scrollbar"
      >
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rounded"
            height={358}
            sx={{
              bgcolor: "#F8F8F8",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
            }}
          />
        ) : (
          <TextareaAutosizeForm
            register={register}
            name="prompt"
            placeholder={isCustomSwitch ? "Custom prompt" : "Default prompt"}
            disabled={!editMode}
            labelClassName="text-base mb-3"
            textareaClassName="text-base"
            minRows={14}
            borderColor="gray-250"
            disabledBgColor="stone-60"
            registerOptions={{
              validate: (value) => {
                if (value.trim()) {
                  return true;
                }
                return "Prompt can’t be blank";
              },
            }}
            error={errors.prompt}
          />
        )}
      </form>

      {openSubmitModal && (
        <PasswordModal
          onSubmit={onSubmitPrompt}
          onClose={() => setOpenSubmitModal(false)}
          title="Edit prompt"
          description="Enter your password"
        />
      )}
    </div>
  );
};

export default Customize;
