import { AnalyticsPeriod } from "@src/store/voiceAgent/types";
import dayjs from "dayjs";

export const createLocalDate = (year: number, month = 1, day = 1): Date => {
  return new Date(year, month - 1, day, 0, 0, 0, 0);
};

export const getDefaultDates = (periodType: AnalyticsPeriod) => {
  const today = new Date();

  switch (periodType) {
    case AnalyticsPeriod.WEEK: {
      const startOfIsoWeek = dayjs(today).startOf("isoWeek");
      return {
        start: createLocalDate(
          startOfIsoWeek.year(),
          startOfIsoWeek.month() + 1,
          startOfIsoWeek.date(),
        ),
        end: today,
      };
    }

    case AnalyticsPeriod.MONTH: {
      const startOfMonth = dayjs(today).startOf("month");
      return {
        start: createLocalDate(
          startOfMonth.year(),
          startOfMonth.month() + 1,
          1,
        ),
        end: today,
      };
    }

    case AnalyticsPeriod.YEAR: {
      const startOfYear = dayjs(today).startOf("year");
      return {
        start: createLocalDate(startOfYear.year()),
        end: today,
      };
    }

    case AnalyticsPeriod.CUSTOM: {
      const startOfIsoWeek = dayjs(today).startOf("isoWeek");
      return {
        start: createLocalDate(
          startOfIsoWeek.year(),
          startOfIsoWeek.month() + 1,
          startOfIsoWeek.date(),
        ),
        end: today,
      };
    }

    default:
      return { start: today, end: today };
  }
};

export const formatDateToYMD = (date: Date): string => {
  if (!date) {
    return "";
  }

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
};

export const normalizeDate = (date: Date): Date => {
  return createLocalDate(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
  );
};

export const parseUrlDate = (
  searchParams: URLSearchParams,
  paramName: string,
  defaultDate: Date,
): Date => {
  const dateStr = searchParams.get(paramName);
  if (dateStr && /^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
    const [year, month, day] = dateStr.split("-").map(Number);
    return createLocalDate(year, month, day);
  }
  return defaultDate;
};

export const getPeriodUnit = (periodType: AnalyticsPeriod) => {
  switch (periodType) {
    case AnalyticsPeriod.WEEK:
      return "isoWeek";
    case AnalyticsPeriod.MONTH:
      return "month";
    case AnalyticsPeriod.YEAR:
      return "year";
    default:
      return "day";
  }
};
