import { createSelector } from "reselect";
import type { RootState } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import type { Scribe } from "./interfaces";
import { scribesAdapter } from "./scribeSlice";

// Selector base
const selectScribeState = (state: RootState) => state.scribe;

export const {
  selectAll: selectAllScribes,
  selectById: selectScribeById,
  // selectIds: selectScribeIds,
} = scribesAdapter.getSelectors(selectScribeState);

// Basic selectors
export const getAllScribes = selectAllScribes;
export const getVisibleScribes = createSelector([selectAllScribes], (scribes) =>
  scribes.filter((scribe) => scribe.isVisible),
);

// Types
export type GroupingType = "daily" | "monthly";
export type ScribeGroup = {
  date: string;
  sortableDate: string;
  entries: ScribeGroupItem[];
};

export type ScribeGroupItem = {
  id: number;
  audioId: string;
  title: string;
  time: string;
  createdAt: number;
  startedAt: string | null;
  accumulatedDuration: number;
  patient: Patient | null;
  isRecording: boolean;
  isGenerating: boolean;
  isPaused: boolean;
  note?: string;
  providerName?: string;
  type: "scribe" | "call" | "chat" | "campaign";
};

const convertToLocalTime = (dateTimeStr: string): Date => {
  // Handle empty or invalid input
  if (!dateTimeStr) {
    console.warn("Invalid date string provided:", dateTimeStr);
    return new Date();
  }

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    // First attempt: Try parsing as ISO string
    if (dateTimeStr.includes("T")) {
      const date = new Date(dateTimeStr);
      if (!Number.isNaN(date.getTime())) {
        return date;
      }
    }

    // Second attempt: Parse custom format "YYYY-MM-DD HH:mm:ss"
    const [datePart, timePart] = dateTimeStr.split(" ");
    if (!datePart || !timePart) {
      throw new Error("Invalid date-time format");
    }

    const [year, month, day] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);

    // Validate components
    if ([year, month, day, hours, minutes, seconds].some(Number.isNaN)) {
      throw new Error("Invalid date-time components");
    }

    // Create a UTC date string that's timezone-aware
    const isoString = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, seconds),
    ).toISOString();

    // Format options for parsing with the user's timezone
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: userTimezone,
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    });

    // Convert to local timezone
    const parts = formatter.formatToParts(new Date(isoString)).reduce(
      (acc, part) => {
        acc[part.type] = part.value;
        return acc;
      },
      {} as Record<string, string>,
    );

    // Create the final date object
    const localDate = new Date(
      Number(parts.year),
      Number(parts.month) - 1,
      Number(parts.day),
      Number(parts.hour),
      Number(parts.minute),
      Number(parts.second),
    );

    if (Number.isNaN(localDate.getTime())) {
      throw new Error("Invalid date result");
    }

    return localDate;
  } catch (error) {
    console.warn("Error converting date:", dateTimeStr, error);
    return new Date(); // Return current date as fallback
  }
};

const parseDateTime = (dateTimeStr: string): Date => {
  return convertToLocalTime(dateTimeStr);
};

// Date caching system remains the same
const dateCache = new Map<
  string,
  Map<GroupingType, { formattedDate: string; sortableDate: string }>
>();
const today = new Date();
today.setHours(0, 0, 0, 0);
const todayTime = today.getTime();

const CACHE_CLEANUP_INTERVAL = 1000 * 60 * 60;
setInterval(() => {
  if (dateCache.size > 1000) {
    dateCache.clear();
  }
}, CACHE_CLEANUP_INTERVAL);

const getDateInfo = (dateString: string, groupingType: GroupingType) => {
  let dateEntries = dateCache.get(dateString);
  if (!dateEntries) {
    dateEntries = new Map();
    dateCache.set(dateString, dateEntries);
  }

  let cached = dateEntries.get(groupingType);
  if (cached) {
    return cached;
  }

  const createDate = parseDateTime(dateString);
  const dayDifference =
    (todayTime - createDate.getTime()) / (1000 * 60 * 60 * 24);

  let formattedDate: string;
  const currentFormatters = getFormatters();

  if (groupingType === "daily") {
    if (createDate.toDateString() === today.toDateString()) {
      formattedDate = "Today";
    } else if (dayDifference < 1) {
      formattedDate = "Yesterday";
    } else if (dayDifference < 7) {
      formattedDate = currentFormatters.daily.weekday.format(createDate);
    } else {
      formattedDate = currentFormatters.daily.date.format(createDate);
    }
  } else {
    // Monthly grouping
    formattedDate = currentFormatters.monthly.date.format(createDate);
  }

  cached = {
    formattedDate,
    sortableDate: createDate.toISOString(),
  };
  dateEntries.set(groupingType, cached);
  return cached;
};

export const createFormatters = (timezone: string, locale: string) => ({
  daily: {
    date: new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }),
    time: new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    weekday: new Intl.DateTimeFormat(locale, { weekday: "long" }),
  },
  monthly: {
    date: new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      month: "long",
      year: "numeric",
    }),
    time: new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      month: "short",
      day: "2-digit",
    }),
  },
});

let formatters: ReturnType<typeof createFormatters>;

const getFormatters = () => {
  if (!formatters) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
    formatters = createFormatters(userTimezone, userLocale);
  }
  return formatters;
};

const createGroupedScribes = (
  scribes: Scribe[],
  groupingType: GroupingType = "daily",
): ScribeGroup[] => {
  const groupMap = new Map<string, ScribeGroup>();
  const currentFormatters = getFormatters();
  const timeFormatter =
    groupingType === "daily"
      ? currentFormatters.daily.time
      : currentFormatters.monthly.time;

  scribes.forEach((note) => {
    if (!note.createdAt) {
      return;
    }

    let dateStr = "";
    const localDate = convertToLocalTime(note.createdAt);
    try {
      if (localDate.getTime() === 0) {
        console.error("Local date is invalid", note.createdAt);
      }

      dateStr = localDate.toISOString();
    } catch (_error) {
      console.error("Error converting to local time", note.createdAt);
      return;
    }

    const { formattedDate, sortableDate } = getDateInfo(dateStr, groupingType);

    let group = groupMap.get(formattedDate);
    if (!group) {
      group = {
        date: formattedDate,
        sortableDate,
        entries: [],
      };
      groupMap.set(formattedDate, group);
    }

    group.entries.push({
      id: note.noteId,
      audioId: note.audioId,
      title: note.title,
      time: timeFormatter.format(localDate),
      createdAt: localDate.getTime(),
      startedAt: note.startedAt,
      accumulatedDuration: note.accumulatedDuration,
      patient: note.patient,
      isRecording: note.isRecording,
      isGenerating: note.isGenerating,
      isPaused: (note.isRecording && note.startedAt === null) || note.isPaused,
      providerName: note.providerName,
      type: note.type,
      note: note.note,
    });
  });

  return Array.from(groupMap.values())
    .sort((a, b) => b.sortableDate.localeCompare(a.sortableDate))
    .map((group) => ({
      ...group,
      entries: group.entries.sort((a, b) => b.createdAt - a.createdAt),
    }));
};

// Optimized equality checking
const groupsAreEqual = (a: ScribeGroup[], b: ScribeGroup[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    const groupA = a[i];
    const groupB = b[i];

    if (
      groupA.date !== groupB.date ||
      groupA.entries.length !== groupB.entries.length
    ) {
      return false;
    }

    for (let j = 0; j < groupA.entries.length; j++) {
      const entryA = groupA.entries[j];
      const entryB = groupB.entries[j];

      if (
        entryA.audioId !== entryB.audioId ||
        entryA.isRecording !== entryB.isRecording ||
        entryA.isPaused !== entryB.isPaused ||
        entryA.isGenerating !== entryB.isGenerating ||
        entryA.title !== entryB.title ||
        entryA.patient?.patient_id !== entryB.patient?.patient_id
      ) {
        return false;
      }
    }
  }

  return true;
};

export const getGroupedScribes = createSelector(
  [
    getVisibleScribes,
    (_: RootState, groupingType: GroupingType = "daily") => groupingType,
  ],
  (scribes, groupingType) => createGroupedScribes(scribes, groupingType),
  {
    memoizeOptions: {
      resultEqualityCheck: groupsAreEqual,
    },
  },
);

export const getGroupedScribesByPatientId = createSelector(
  [
    getVisibleScribes,
    (_: RootState, patientId: number) => patientId,
    (_: RootState, __: number, groupingType: GroupingType = "monthly") =>
      groupingType,
  ],
  (scribes, patientId, groupingType) =>
    createGroupedScribes(
      scribes.filter((scribe) => scribe.patient?.patient_id === patientId),
      groupingType,
    ),
  {
    memoizeOptions: {
      resultEqualityCheck: groupsAreEqual,
    },
  },
);

export const getRecordingScribe = createSelector(
  [getVisibleScribes],
  (scribes) => scribes.find((scribe) => scribe.isRecording) ?? null,
);

export const selectIsLoading = createSelector(
  [selectScribeState],
  (state) => state.isLoading,
);

export const selectPagination = createSelector(
  [selectScribeState],
  (state) => state.pagination,
);

export const selectIsFetching = createSelector(
  [selectScribeState],
  (state) => state.pagination.isFetching,
);

export const selectInterruptedRecordingAction = createSelector(
  [selectScribeState],
  (state) => state.interruptedRecordingAction,
);
