import type {
  InboxListItem,
  SearchCallResponseWithDetails,
} from "./interfaces";

/**
 * Gets the timestamp for an item based on its status
 */
export function getItemTimestamp(
  item: InboxListItem,
  isCompletedInbox: boolean,
  response?: SearchCallResponseWithDetails,
): number {
  let timestamp: number;

  if (isCompletedInbox) {
    const lastCompletedAt =
      response?.last_completed_at || item.items[0]?.data?.last_completed_at;
    timestamp = lastCompletedAt
      ? new Date(lastCompletedAt).getTime()
      : Number.NaN;
  }

  if (!isCompletedInbox || Number.isNaN(timestamp)) {
    const date = item.items[0]?.data?.date;
    timestamp = date ? new Date(date).getTime() : Number.NaN;
  }

  return Number.isNaN(timestamp) ? 0 : timestamp;
}

/**
 * Merges two lists of inbox items with proper sorting and duplicate handling
 */
export function mergeInboxItems(
  currentList: InboxListItem[],
  newItems: InboxListItem[],
  isCompletedInbox: boolean,
  response?: SearchCallResponseWithDetails,
): InboxListItem[] {
  const itemMap = new Map<number, InboxListItem>();

  for (const item of currentList) {
    itemMap.set(item.patient.patient_id, item);
  }

  for (const item of newItems) {
    itemMap.set(item.patient.patient_id, item);
  }

  return Array.from(itemMap.values()).sort((a, b) => {
    const timestampA = getItemTimestamp(a, isCompletedInbox, response);
    const timestampB = getItemTimestamp(b, isCompletedInbox, response);
    return timestampB - timestampA;
  });
}
