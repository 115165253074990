import Bugsnag from "@bugsnag/js";
import axios, { type AxiosError } from "axios";
import { setAPIAuthToken } from "../../helpers/api";
import {
  handleValidation,
  rolesSchema,
} from "../../helpers/commonValidationSchemas";
import { BASE_URL, setAuthToken } from "../../helpers/config";
import { Pages } from "../../helpers/constants";
import { handleRequestError } from "../../helpers/helpers";
import LocalStorageService from "../../services/LocalStorageService";
import { setRegisteredEmailAddress } from "../auth/actions";
import { baseApi } from "../baseApi";
import type { AppDispatch } from "../index";
import {
  setDoctorOptionsAction,
  setErrorAction,
  setNotificationAction,
  setRoleOptionsAction,
  setRolesAction,
  setSignupInfoAction,
  setTokenAction,
  setUserAction,
  setUsersWithoutTeamAction,
} from "./actions";
import {
  doctorTeamIdSchema,
  tokenSchema,
  userInfoSchema,
  userListSchema,
  userSchema,
} from "./validationSchemas";

const storageService = new LocalStorageService();

export const getUser = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/user/me`);

    const user = response.data.data;

    handleValidation(userSchema, user, "getUser");

    Bugsnag.setUser(user.doctor_id, user.email, user.display_name);
    Bugsnag.setContext(`org: ${user.customer.name}`);

    return user;
  } catch (error) {
    handleRequestError(error);
  }
};

export const updateStoredUser = (user) => (dispatch: AppDispatch) => {
  if (storageService.getItem("user")) {
    storageService.setItem("user", user);
  }
  dispatch(setUserAction(user));
};

export const setToken = (email, password) => (dispatch: AppDispatch) => {
  axios
    .post(`${BASE_URL}/auth/login`, {
      email: email,
      password: password,
    })
    .then((response) => {
      const token = response.data.data.auth_token;

      handleValidation(tokenSchema, token, "setToken");

      if (token) {
        dispatch(setTokenAction(token));

        setAuthToken(token);
        setAPIAuthToken(token);

        getUser().then((user) => {
          dispatch(setUserAction(user));
        });
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response?.status !== 401) {
        console.error(error);
        Bugsnag.notify(new Error(error.message));
        dispatch(setErrorAction(error.response?.data.error.message));
        return;
      }

      dispatch(setErrorAction("Failed to login"));

      handleRequestError(error);
    });
};

export const setSettings =
  (settings, notification = true) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/user/general`, settings)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save settings",
          );
        } else {
          getUser().then((user) => dispatch(updateStoredUser(user)));
          if (notification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Settings successfully saved",
                desc: "Your general settings have been successfully saved",
              }),
            );
          }
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to save settings");
      });
  };

export const updateUser =
  (doctorId, updatedFields, onUserUpdated) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/user/update/${doctorId}`, updatedFields)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update user information",
          );
        } else {
          getUser().then((user) => {
            dispatch(updateStoredUser(user));
            dispatch(setDoctorOptionsAction(user?.doctors));
            dispatch(baseApi.util.invalidateTags(["User"]));

            onUserUpdated();
          });
          dispatch(
            setNotificationAction({
              status: "success",
              title: "User information successfully updated",
              desc: "Information has been successfully updated",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to update user information",
        );
      });
  };

export const updatePassword = (oldPassword, newPassword) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/update-password`, {
      old_password: oldPassword,
      new_password: newPassword,
    })
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to update password",
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password successfully updated",
            desc: "Your password has been successfully updated",
          }),
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to update password");
    });
};

export const createPassword = (resetToken, newPassword) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/create-password/${resetToken}`, {
      password: newPassword,
    })
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to create password",
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password successfully updated",
            desc: "Your password has been successfully updated",
          }),
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to create password");
    });
};

export const forgotPassword = (email) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/forgot-password`, {
      email: email,
    })
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to reset password",
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Password reset",
            desc: "Please check the email to reset the password",
          }),
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to reset password");
    });
};

export const resetPassword = async (payload, newPassword) => {
  await axios
    .post(`${BASE_URL}/user/reset-password`, {
      payload: payload,
      password: newPassword,
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error);
    });
};

export const createUser =
  (providers, onEnd = () => {}) =>
  (dispatch) => {
    // providers -> [{email: "email@mail.com", role_id: 1, ...}, {...}]
    axios
      .post(`${BASE_URL}/user/create`, { providers: providers })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to create user",
          );
        } else {
          dispatch(baseApi.util.invalidateTags(["User"]));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "User successfully created",
              desc: "Your invitation has been sent to entered email",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to create user");
      })
      .finally(() => {
        dispatch(baseApi.util.invalidateTags(["User"]));
        onEnd();
      });
  };

export const getUsersWithoutTeam = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/user/list-without-team`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load users",
        );
      } else {
        const usersWithoutTeam = response.data.data;

        handleValidation(
          userListSchema,
          usersWithoutTeam,
          "getUsersWithoutTeam",
        );

        dispatch(
          setUsersWithoutTeamAction(
            usersWithoutTeam?.length > 0 ? usersWithoutTeam : [],
          ),
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load users");
    });
};

export const deleteTeam =
  (doctorTeamId: number, notification = true) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/team/remove/${doctorTeamId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to delete team",
          );
        } else {
          dispatch(baseApi.util.invalidateTags(["Team"]));
          if (notification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Team successfully deleted",
                desc: "Team has been successfully deleted",
              }),
            );
          }
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to delete team");
      });
  };

export const getRoles = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/user/roles`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load roles",
        );
      } else {
        const roles = response.data.data;

        handleValidation(rolesSchema, roles, "getRoles");

        dispatch(setRolesAction(roles));

        const roleOptions = Object.keys(roles).map((key) => ({
          value: key,
          text: roles[key],
        }));
        dispatch(setRoleOptionsAction(roleOptions));
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load roles");
    });
};

export const createUserTeam =
  (teamName, showNotification = true, onSuccess = (_: number) => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/create-team`, {
        name: teamName,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to create team",
          );
        } else {
          const doctorTeamId = response.data.data.doctor_team_id;

          handleValidation(doctorTeamIdSchema, doctorTeamId, "createUserTeam");

          dispatch(baseApi.util.invalidateTags(["Team"]));
          getUser().then((user) => dispatch(updateStoredUser(user)));
          onSuccess(doctorTeamId);
          if (showNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Team successfully created",
                desc: "Team has been successfully created",
              }),
            );
          }
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to create team");
      });
  };

export const createTeam =
  (
    teamName: string,
    members: { doctor_id: number }[] = [],
    onSuccess: () => void = () => {},
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/team/create`, {
        name: teamName,
        members: members,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to create team",
          );
        } else {
          onSuccess();

          dispatch(baseApi.util.invalidateTags(["Team"]));
          getUser().then((user) => dispatch(updateStoredUser(user)));

          dispatch(
            setNotificationAction({
              status: "success",
              title: "Team successfully created",
              desc: "Team has been successfully created",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to create team");
      });
  };

export const addTeamMember =
  (doctorTeamId: number, doctorId: number, onSuccess: () => void = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/team/add-member/${doctorTeamId}/${doctorId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to add team member",
          );
        } else {
          onSuccess();

          dispatch(baseApi.util.invalidateTags(["Team"]));
          getUser().then((user) => dispatch(updateStoredUser(user)));
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to add team member");
      });
  };

export const removeTeamMember =
  (doctorTeamId: number, doctorId: number, onSuccess: () => void = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/team/remove-member/${doctorTeamId}/${doctorId}`)
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to remove team member",
          );
        } else {
          onSuccess();

          dispatch(baseApi.util.invalidateTags(["Team"]));
          getUser().then((user) => dispatch(updateStoredUser(user)));
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to remove team member");
      });
  };

export const updateTeam =
  (
    doctorTeamId: number,
    newTeamName: string,
    showInbox: 1 | 0,
    teamIcon: string | null,
    showNotification = true,
    onSuccess = () => {},
  ) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/team/update/${doctorTeamId}`, {
        name: newTeamName,
        show_inbox: showInbox,
        team_icon: teamIcon,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update team",
          );
        } else {
          dispatch(baseApi.util.invalidateTags(["Team"]));
          getUser().then((user) => dispatch(updateStoredUser(user)));
          onSuccess();
          if (showNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Team successfully updated",
                desc: "Team has been successfully updated",
              }),
            );
          }
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to update team");
      });
  };

export const getUserInfo = (token) => (dispatch) => {
  axios
    .get(`${BASE_URL}/user/info/${token}`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to load user info",
        );
      } else {
        const userInfo = response.data.data;

        handleValidation(userInfoSchema, userInfo, "getUserInfo");

        dispatch(setSignupInfoAction(response.data.data));
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(error, dispatch, "Failed to load user info");
    });
};

export const registerUser =
  (
    token,
    password,
    firstName,
    lastName,
    displayName,
    phoneNumber,
    occupation,
    profilePicture,
    navigate,
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/complete-sign-up/${token}`, {
        password: password,
        first_name: firstName,
        last_name: lastName,
        display_name: displayName,
        phone_number: phoneNumber,
        occupation: occupation,
        profile_picture: profilePicture,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to register user",
          );
        } else {
          navigate(Pages.LOGIN);
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to register user");
      });
  };

export const registerUserV2 =
  (providerEmail, providerPassword) => (dispatch) => {
    axios
      .post(`${BASE_URL}/customer/sign-up/v2`, {
        providerEmail,
        providerPassword,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to register user",
          );
        } else {
          dispatch(setRegisteredEmailAddress(providerEmail));
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to register user");
      });
  };

export const deleteUser =
  (doctorId, onError = null) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/user/delete/${doctorId}`)
      .then((response) => {
        if (response.data.error) {
          if (onError) {
            onError(response.data.error.message);
            handleRequestError(response.data.error);
          } else {
            handleRequestError(
              response.data.error,
              dispatch,
              "Failed to delete user",
            );
          }
        } else {
          dispatch(baseApi.util.invalidateTags(["User"]));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "User successfully deleted",
              desc: "User has been successfully deleted",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to delete user");
      });
  };

export const resendVerificationEmail = (userId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/user/resend-verification-email/${userId}`)
    .then((response) => {
      if (response.data.error) {
        handleRequestError(
          response.data.error,
          dispatch,
          "Failed to resend verification email",
        );
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Verification email sent",
            desc: "Verification email has been successfully sent",
          }),
        );
      }
    })
    .catch((error: Error | AxiosError) => {
      handleRequestError(
        error,
        dispatch,
        "Failed to resend verification email",
      );
    });
};

export const updateClinic =
  (name, displayName, legacyPhoneNumber, address, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/customer/update`, {
        name: name,
        display_name: displayName,
        legacy_phone_number: legacyPhoneNumber,
        clinic_address: address,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to update clinic",
          );
        } else {
          getUser().then((user) => dispatch(updateStoredUser(user)));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Clinic successfully updated",
              desc: "Your clinic information has been successfully updated",
            }),
          );
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to update clinic");
      });
  };
