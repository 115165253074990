import { ReactComponent as BuildingsIcon } from "@assets/icons/buildings.svg";
import type { BasicSite } from "@src/store/voiceAgent/types";

interface SiteDropdownItemProps {
  site: BasicSite;
  isSelected: boolean;
  onSelect: (siteId: number | null) => void;
}

const SiteDropdownItem = ({
  site,
  isSelected,
  onSelect,
}: SiteDropdownItemProps) => {
  return (
    <button
      type="button"
      className={`text-sm font-medium p-2 rounded-lg hover:bg-gray-background flex items-center justify-between
        space-x-2 overflow-hidden ${isSelected ? "bg-gray-background text-primary" : " text-tertiary"}`}
      onClick={() => onSelect(site.site_id)}
    >
      <p className="truncate">{site.name}</p>
      {isSelected && (
        <BuildingsIcon width="20" height="20" className="flex-none" />
      )}
    </button>
  );
};

export default SiteDropdownItem;
