import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MetricsTable from "../components/BillingMetrics/MetricsTable";
import { rowsPerPage } from "../helpers/constants";
import LocalStorageService from "../services/LocalStorageService";
import {
  setBillingLoadingAction,
  setCurrentDateStampAction,
} from "../store/billingMetrics/actions";
import {
  getBillingNumbers,
  getBillingPatients,
} from "../store/billingMetrics/thunks";

const storageService = new LocalStorageService();

const BillingMetrics = () => {
  const { billingPatients, currPage, currentDateStamp, selectedBillingType } =
    useSelector((state) => state.billingMetrics);
  const currPageRef = useRef(currPage);
  const currDateStampRef = useRef(currentDateStamp);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  useEffect(() => {
    currPageRef.current = currPage;
  }, [currPage]);

  useEffect(() => {
    if (!billingPatients) {
      dispatch(setBillingLoadingAction(true));
    }
    if (!currDateStampRef.current) {
      dispatch(setBillingLoadingAction(true));
      const dateStamp = dayjs().startOf("month").format("YYYY-MM-DD");
      dispatch(setCurrentDateStampAction(dateStamp));
    }
  }, [dispatch, billingPatients]);

  useEffect(() => {
    if (currentDateStamp) {
      dispatch(getBillingNumbers(selectedBillingType, currentDateStamp));
    }
  }, [dispatch, selectedBillingType, currentDateStamp]);

  useEffect(() => {
    if (currentDateStamp) {
      currDateStampRef.current = currentDateStamp;
      dispatch(
        getBillingPatients(
          currentDateStamp,
          rowsPerPage * currPageRef.current,
          storageService.getItem("billingSearchValue") ?? "",
          storageService.getItem("trackingBillingType") ?? "all",
          storageService.getItem("trackingCptCode") ?? "all",
          storageService.getItem("trackingSortedBy") ?? null,
          storageService.getItem("sortingDirection") ?? null,
        ),
      );
    }
  }, [dispatch, currentDateStamp]);

  return (
    <div className="w-full overflow-hidden grid md:h-full">
      <div
        className={`flex overflow-hidden bg-gray-background
          ${isDesktop && isHeightSm ? "p-4 height-md:p-6" : "p-4 md:p-2"}`}
      >
        <MetricsTable />
      </div>
    </div>
  );
};

export default BillingMetrics;
