const TableFilters = () => {
  return (
    <div className="flex items-center justify-between py-5">
      <p className="h-10 flex items-center text-lg font-medium">
        Support Line Metrics
      </p>
    </div>
  );
};

export default TableFilters;
