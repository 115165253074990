import { useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router";

/**
 * Options for configuring the useUrlState hook
 */
type UrlStateOptions<T> = {
  /**
   * Custom function to convert the value to a string for the URL
   */
  serialize?: (value: T) => string;

  /**
   * Custom function to convert the string from the URL back to the value
   */
  deserialize?: (value: string) => T;

  /**
   * Whether to replace the current history entry instead of adding a new one
   * @default true
   */
  replace?: boolean;
};

// Store pending updates in a global map that persists across hook instances
const pendingUpdates = new Map<string, any>();
let updateScheduled = false;

/**
 * A hook for syncing state with URL search parameters, with batched updates
 * to prevent race conditions between multiple parameter updates
 *
 * @param paramName The name of the URL search parameter
 * @param initialValue The initial value to use if the parameter is not in the URL
 * @param options Configuration options
 * @returns An object with the current value and a setter function
 */
export function useUrlState<T>(
  paramName: string,
  initialValue: T,
  options: UrlStateOptions<T> = {},
) {
  const {
    serialize = ((val: T) => String(val)) as (value: T) => string,

    deserialize = (val: string) => {
      if (typeof initialValue === "number") {
        return Number(val) as unknown as T;
      }
      if (typeof initialValue === "boolean") {
        return (val === "true") as unknown as T;
      }
      return val as unknown as T;
    },

    replace = true,
  } = options;

  const [searchParams, setSearchParams] = useSearchParams();
  const replaceRef = useRef(replace);

  // Store the latest setSearchParams function in a ref to ensure batched updates
  // use the most up-to-date version
  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  // Process and apply all batched updates
  const processBatchedUpdates = useCallback(() => {
    if (pendingUpdates.size === 0) {
      return;
    }

    // Get current search params
    const newParams = new URLSearchParams(searchParams);

    // Apply all pending updates
    for (const [param, value] of pendingUpdates.entries()) {
      if (value === null || value === undefined) {
        newParams.delete(param);
      } else {
        newParams.set(param, value);
      }
    }

    // Apply updates
    setSearchParamsRef.current(newParams, { replace: replaceRef.current });

    // Clear pending updates
    pendingUpdates.clear();
    updateScheduled = false;
  }, [searchParams]);

  const value = useMemo(() => {
    if (!searchParams.has(paramName)) {
      return initialValue;
    }

    return deserialize(searchParams.get(paramName) ?? "");
  }, [searchParams, paramName, deserialize, initialValue]);

  const setValue = useCallback(
    (newValueOrUpdater: T | ((prevValue: T) => T)) => {
      const newValue =
        typeof newValueOrUpdater === "function"
          ? (newValueOrUpdater as (prevValue: T) => T)(value)
          : newValueOrUpdater;

      if (newValue === value) {
        return;
      }

      // Store this update in the pending updates map
      if (
        newValue === null ||
        newValue === undefined ||
        newValue === initialValue
      ) {
        pendingUpdates.set(paramName, null);
      } else {
        pendingUpdates.set(paramName, serialize(newValue));
      }

      // Schedule batch update if not already scheduled
      if (!updateScheduled) {
        updateScheduled = true;
        queueMicrotask(processBatchedUpdates);
      }
    },
    [paramName, serialize, value, initialValue, processBatchedUpdates],
  );

  return useMemo(() => ({ value, setValue }), [value, setValue]);
}
