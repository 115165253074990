import { makeSlug } from "@src/helpers/helpers";
import { NavLink } from "react-router";

const TabButton = ({
  icon,
  rightIcon = null,
  label,
  onClick = () => {},
  active,
  navigateTo = "#",
}) => {
  return (
    <NavLink
      replace
      to={navigateTo}
      onClick={onClick}
      className={`flex items-center justify-center space-x-2 w-full h-full font-medium rounded-lg text-sm lg:text-base
        ${makeSlug(active) === makeSlug(label) ? "text-primary bg-primary-blue-light" : "text-tertiary"}`}
    >
      {icon}
      <span>{label}</span>
      {rightIcon}
    </NavLink>
  );
};

export default TabButton;
