import { makeSlug } from "@src/helpers/helpers";
import { AnalyticsPeriod } from "@src/store/voiceAgent/types";
import {
  formatDateToYMD,
  getDefaultDates,
  normalizeDate,
  parseUrlDate,
} from "@src/utils/dateUtil";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";

interface UseDatePickerProps {
  periodType: AnalyticsPeriod;
  baseUrl: string;
  isRange?: boolean;
}

export const useDatePicker = ({
  periodType,
  baseUrl,
  isRange = false,
}: UseDatePickerProps) => {
  const minDate = new Date(2017, 0, 1);
  const maxDate = new Date();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const periodSlug = makeSlug(periodType);
  const selectedPeriod =
    searchParams.get("period") || makeSlug(AnalyticsPeriod.WEEK);
  const isActive = selectedPeriod === periodSlug;

  const defaultDates = useMemo(() => getDefaultDates(periodType), [periodType]);
  const parsedStartDate = useMemo(
    () => parseUrlDate(searchParams, "start-date", defaultDates.start),
    [searchParams, defaultDates.start],
  );
  const parsedEndDate = useMemo(
    () => parseUrlDate(searchParams, "end-date", defaultDates.end),
    [searchParams, defaultDates.end],
  );

  const [internalStartDate, setInternalStartDate] = useState(parsedStartDate);
  const [internalEndDate, setInternalEndDate] = useState(parsedEndDate);

  useEffect(() => {
    if (searchParams.get("start-date") && searchParams.get("end-date")) {
      setInternalStartDate(
        parseUrlDate(searchParams, "start-date", defaultDates.start),
      );
      setInternalEndDate(
        parseUrlDate(searchParams, "end-date", defaultDates.end),
      );
    }
  }, [searchParams, defaultDates]);

  const handleSelectDate = useCallback(
    (date: Date | [Date, Date]) => {
      if (isRange && Array.isArray(date)) {
        const [start, end] = date;
        setInternalStartDate(start);
        setInternalEndDate(end);

        navigate(
          `${baseUrl}&start-date=${formatDateToYMD(start)}&end-date=${formatDateToYMD(end)}`,
          { replace: true },
        );
        return;
      }

      const selectedDate = date as Date;
      let start: Date;
      let end: Date;

      switch (periodType) {
        case AnalyticsPeriod.YEAR:
          start = dayjs(selectedDate).startOf("year").toDate();
          end = dayjs()
            .year(selectedDate.getFullYear())
            .endOf("year")
            .isBefore(dayjs())
            ? dayjs(selectedDate).endOf("year").toDate()
            : dayjs().toDate();
          break;

        case AnalyticsPeriod.MONTH:
          start = dayjs(selectedDate).startOf("month").toDate();
          end = dayjs(selectedDate).endOf("month").isBefore(dayjs())
            ? dayjs(selectedDate).endOf("month").toDate()
            : dayjs().toDate();
          break;

        default:
          start = dayjs(selectedDate).startOf("isoWeek").toDate();
          end = dayjs(selectedDate).endOf("isoWeek").isBefore(dayjs())
            ? dayjs(selectedDate).endOf("isoWeek").toDate()
            : dayjs().toDate();
          break;
      }

      setInternalStartDate(start);
      setInternalEndDate(end);
      navigate(
        `${baseUrl}&start-date=${formatDateToYMD(start)}&end-date=${formatDateToYMD(end)}`,
        { replace: true },
      );
    },
    [baseUrl, isRange, navigate, periodType],
  );

  return {
    startDate: internalStartDate,
    endDate: internalEndDate,
    minDate,
    maxDate,
    parsedStartDate,
    parsedEndDate,
    selectedPeriod,
    isActive,
    handleSelectDate,
    normalizeDate,
  };
};
