import { ReactComponent as EditIcon } from "@assets/icons/pen.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useDeleteCallNoteMutation,
  useUpdateCallNoteMutation,
} from "@src/store/call/api";
import type { CallNote } from "@src/store/call/interfaces";
import { useGetProviderQuery } from "@src/store/provider/api";
import { useState } from "react";
import CallNoteEditing from "./CallNoteEditing";

interface CallNoteProps {
  callNote: CallNote;
  isFirst?: boolean;
  isLast?: boolean;
}

const CallNoteCard = ({ callNote, isFirst, isLast }: CallNoteProps) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState("");

  const { data: provider } = useGetProviderQuery(
    callNote.created_by ?? skipToken,
  );
  const [updateCallNote] = useUpdateCallNoteMutation();
  const [deleteCallNote] = useDeleteCallNoteMutation();

  const handleEditMode = () => {
    if (callNote.assistant_call_note_id >= 0) {
      setValue(callNote.note);
      setEditMode(!editMode);
    }
  };

  const handleDeleteNote = () => {
    deleteCallNote({
      assistantCallId: callNote.assistant_call_id,
      assistantCallNoteId: callNote.assistant_call_note_id,
    });
  };

  const handleUpdateNote = () => {
    if (value.trim() && value.trim() !== callNote.note) {
      updateCallNote({
        assistantCallId: callNote.assistant_call_id,
        assistantCallNoteId: callNote.assistant_call_note_id,
        note: value.trim(),
      });
    }
    setEditMode(false);
  };

  return (
    <div className="relative pl-9 py-2.5">
      <div className="absolute left-3 top-0 bottom-0 h-full w-0 border-l border-dashed border-gray-300" />
      {isFirst && <div className="absolute w-6 h-1/2 bg-white left-0 top-0" />}
      {isLast && (
        <div className="absolute w-6 h-1/2 bg-white left-0 bottom-0" />
      )}
      <div className="absolute left-0 top-1/2 -translate-y-1/2 w-6 h-6 bg-white flex items-center justify-center">
        <div className="w-2 h-2 border-2 border-gray-foreground rounded-full box-content" />
      </div>

      {editMode ? (
        <CallNoteEditing
          value={value}
          setValue={setValue}
          onDelete={handleDeleteNote}
          onSave={handleUpdateNote}
          saveLabel="Save"
        />
      ) : (
        <div
          className="bg-[#FFF7E9] rounded-lg p-3 whitespace-pre-wrap text-sm font-medium cursor-pointer relative group"
          onClick={handleEditMode}
        >
          <p className="text-[#32413C]">{callNote.note}</p>
          <p className="text-tertiary">
            {new Date(
              `${callNote.created_at.replace(/-/g, "/")} GMT+0`,
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
            {provider &&
              ` • ${provider.display_name ? provider.display_name : `${provider.first_name} ${provider.last_name}`}`}
          </p>

          <div
            className={`w-6 h-6 rounded-lg bg-[#FFF7E9] flex items-center justify-center absolute bottom-2.5 right-2.5
              transition-all opacity-0 ${callNote.assistant_call_note_id < 0 ? "" : "group-hover:opacity-100"}`}
          >
            <EditIcon width="20" height="20" stroke="#667085" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CallNoteCard;
