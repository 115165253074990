import React from "react";
import type { PerformanceMetrics } from "../types";

interface AppInfoTabProps {
  performanceMetrics: PerformanceMetrics;
}

const AppInfoTab: React.FC<AppInfoTabProps> = ({ performanceMetrics }) => {
  return (
    <div className="bg-white rounded border border-gray-200 shadow-sm">
      <div className="px-4 py-2 bg-gray-50 border-b border-gray-200">
        <h4 className="text-sm font-medium text-gray-700">Application Info</h4>
      </div>
      <div className="p-3">
        <table className="min-w-full">
          <tbody>
            <tr className="border-b border-gray-100">
              <td className="px-2 py-1 text-xs font-medium text-gray-500">
                Version
              </td>
              <td className="px-2 py-1 text-xs">
                {process.env.REACT_APP_VERSION || "Unknown"}
              </td>
            </tr>
            <tr className="border-b border-gray-100">
              <td className="px-2 py-1 text-xs font-medium text-gray-500">
                Environment
              </td>
              <td className="px-2 py-1 text-xs">{process.env.NODE_ENV}</td>
            </tr>
            <tr className="border-b border-gray-100">
              <td className="px-2 py-1 text-xs font-medium text-gray-500">
                React Version
              </td>
              <td className="px-2 py-1 text-xs">{React.version}</td>
            </tr>
            <tr className="border-b border-gray-100">
              <td className="px-2 py-1 text-xs font-medium text-gray-500">
                Load Time
              </td>
              <td className="px-2 py-1 text-xs">
                {performanceMetrics.pageLoadTime
                  ? `${performanceMetrics.pageLoadTime}ms`
                  : "N/A"}
              </td>
            </tr>
            {performanceMetrics.memory && (
              <>
                <tr className="border-b border-gray-100">
                  <td className="px-2 py-1 text-xs font-medium text-gray-500">
                    JS Heap Used
                  </td>
                  <td className="px-2 py-1 text-xs">
                    {performanceMetrics.memory.usedJSHeapSize} MB
                  </td>
                </tr>
                <tr className="border-b border-gray-100">
                  <td className="px-2 py-1 text-xs font-medium text-gray-500">
                    JS Heap Total
                  </td>
                  <td className="px-2 py-1 text-xs">
                    {performanceMetrics.memory.totalJSHeapSize} MB
                  </td>
                </tr>
              </>
            )}
            <tr className="border-b border-gray-100">
              <td className="px-2 py-1 text-xs font-medium text-gray-500">
                User Agent
              </td>
              <td className="px-2 py-1 text-xs">{navigator.userAgent}</td>
            </tr>
            <tr className="border-b border-gray-100">
              <td className="px-2 py-1 text-xs font-medium text-gray-500">
                Window Size
              </td>
              <td className="px-2 py-1 text-xs">
                {window.innerWidth} x {window.innerHeight}
              </td>
            </tr>
            <tr className="border-b border-gray-100">
              <td className="px-2 py-1 text-xs font-medium text-gray-500">
                Device Pixel Ratio
              </td>
              <td className="px-2 py-1 text-xs">{window.devicePixelRatio}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AppInfoTab;
