import { memo } from "react";

const badgeVariants = {
  urgent: "bg-urgent text-white",
  new: "bg-system-green-dark text-white",
  violet: "bg-violet-550 text-white",
  default: "bg-gray-140 text-tertiary",
};

interface BadgeProps {
  variant: "urgent" | "new" | "violet" | "default";
  label: string;
  withBorder?: boolean;
}

const Badge = ({ variant, label, withBorder = false }: BadgeProps) => {
  return (
    <div
      aria-label={label}
      className={`text-xs px-2 py-0.5 rounded-full w-fit ${badgeVariants[variant]}
        ${withBorder ? "border-2 border-white border-opacity-90" : ""}`}
    >
      {label}
    </div>
  );
};

export default memo(Badge);
