import { useAppDispatch } from "@src/store";
import { userApi } from "@src/store/user/api";
import { voiceAgentApi } from "@src/store/voiceAgent/api";
import { useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { getSites } from "../../store/site/thunks";
import CallsTable from "./InboxTable/CallsTable";
import { InboxLayout } from "./Layout/InboxLayout";
import CallDetails from "./RightPanel/CallDetails";
import { useCallManagement } from "./hooks/useCallManagement";

const FrontDeskPage = () => {
  const dispatch = useAppDispatch();

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const { selectedConversationId, nextConversation, handleCloseCallDetails } =
    useCallManagement();

  const prefetchContent = useCallback(() => {
    dispatch(
      voiceAgentApi.util.prefetch("getSites", undefined, { force: false }),
    );
    // Use getSites thunk instead of direct prefetch
    dispatch(getSites());

    // These seem to be valid prefetch endpoints
    dispatch(
      userApi.util.prefetch("getUsers", { search: "" }, { force: false }),
    );
    dispatch(
      userApi.util.prefetch("getTeams", { search: "" }, { force: false }),
    );
  }, [dispatch]);

  useEffect(() => {
    prefetchContent();
  }, [prefetchContent]);

  return (
    <InboxLayout isDesktop={isDesktop} isHeightSm={isHeightSm}>
      <CallsTable className="w-full h-full overflow-hidden" />
      <CallDetails
        className={`absolute top-0 right-0 z-[60] md:static transition-all duration-300 h-full
          overflow-hidden min-w-0 ${selectedConversationId ? "w-full md:ml-8" : "w-0"}`}
        onClose={handleCloseCallDetails}
        nextConversation={nextConversation}
      />
    </InboxLayout>
  );
};

export default FrontDeskPage;
