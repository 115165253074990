import { useMediaQuery } from "react-responsive";
import Modal from "../../Basic/Modal";
import ModalMobile from "../../Basic/ModalMobile";
import CallReasonForm from "./CallReasonForm";
import type { ReasonFormValues } from "./CallReasons";

interface CallReasonModalProps {
  usedReasonNames: string[];
  open: boolean;
  onClose: () => void;
  onSave: (data: ReasonFormValues) => void;
}

const CallReasonModal = ({
  usedReasonNames,
  open,
  onClose,
  onSave,
}: CallReasonModalProps) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && (
      <Modal className="pt-5 rounded-2xl">
        <div className="w-134 max-h-85vh-dynamic grid grid-rows-tab-layout overflow-hidden">
          <div className="flex items-center justify-between px-5 pb-3">
            <p className="text-base font-semibold">Reason</p>
          </div>
          <CallReasonForm
            onClose={onClose}
            onSave={onSave}
            usedReasonNames={usedReasonNames}
          />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile open={open} header="Reason" onClickAway={onClose} className="">
      <CallReasonForm
        onClose={onClose}
        onSave={onSave}
        className="pt-2"
        usedReasonNames={usedReasonNames}
      />
    </ModalMobile>
  );
};

export default CallReasonModal;
