import { z } from "zod";

export const ScribeEventTypeEnum = z.enum([
  "note_generating",
  "transcribing",
  "uploading",
  "concatenating",
  "retrying",
  "retried",
]);

export const ScribeStatusTypeEnum = z.enum([
  "end",
  "progress",
  "failed",
  "retrying",
  "retried",
  "failed_too_many_retries",
  "",
]);

export const ScribeEventSchema = z.object({
  id: z
    .number()
    .int({ message: "ID must be an integer" })
    .positive({ message: "ID must be positive" }),
  voiceConversationId: z
    .number()
    .int({ message: "Voice conversation ID must be an integer" })
    .positive({ message: "Voice conversation ID must be positive" }),
  event: ScribeEventTypeEnum,
  status: ScribeStatusTypeEnum,
  description: z.string({ message: "Description must be a string" }),
  fullPrompt: z.string({ message: "Full prompt must be a string" }).nullable(),
  fullResponse: z
    .string({ message: "Full response must be a string" })
    .nullable(),
  timeUntilLastEvent: z
    .number({ message: "Time until last event must be a number" })
    .nonnegative({ message: "Time until last event must be non-negative" }),
  totalTime: z
    .number({ message: "Total time must be a number" })
    .nonnegative({ message: "Total time must be non-negative" }),
  eventTimestamp: z
    .string({ message: "Event timestamp must be a string" })
    .regex(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, {
      message: "Invalid timestamp format. Expected format: YYYY-MM-DD HH:MM:SS",
    }),
});

export const ScribeEventsSchema = z.array(ScribeEventSchema);

export type ScribeEventType = z.infer<typeof ScribeEventTypeEnum>;
export type ScribeStatusType = z.infer<typeof ScribeStatusTypeEnum>;
export type ScribeEvent = z.infer<typeof ScribeEventSchema>;
export type ScribeEvents = z.infer<typeof ScribeEventsSchema>;

export const ScribeTranscriptionSchema = z
  .string({
    message: "Transcription must be a string",
  })
  .nullable();

export type ScribeTranscription = z.infer<typeof ScribeTranscriptionSchema>;

export const ScribeNoteTemplateIdSchema = z.number().nullable().optional();

export type ScribeNoteTemplateId = z.infer<typeof ScribeNoteTemplateIdSchema>;

export const ScribeAudioDetailsSchema = z.object({
  audio: z.object({
    audioUrl: z.string({ message: "Audio URL must be a string" }),
    metadata: z.object({
      lastModified: z.string({ message: "Last modified must be a string" }),
      contentType: z.string({ message: "Content type must be a string" }),
      contentLength: z.number({ message: "Content length must be a number" }),
      eTag: z.string({ message: "ETag must be a string" }),
      metadata: z.array(z.string()),
    }),
  }),
  note_template_id: ScribeNoteTemplateIdSchema,
  transcription: ScribeTranscriptionSchema,
});

export type ScribeAudioDetails = z.infer<typeof ScribeAudioDetailsSchema>;
