import { useEffect, useState } from "react";
import type {
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
  UseFormRegisterReturn,
} from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";

interface TextareaAutosizeFormProps<
  TFieldValues extends FieldValues = FieldValues,
> extends React.ComponentPropsWithoutRef<typeof TextareaAutosize> {
  className?: string;
  labelClassName?: string;
  textareaClassName?: string;
  borderColor?: string;
  disabledBgColor?: string;
  label?: string;
  placeholder?: string;
  name: Path<TFieldValues>;
  required?: boolean;
  withoutAsterisk?: boolean;
  register?: UseFormRegister<TFieldValues>;
  registerOptions?: RegisterOptions<TFieldValues>;
  error?: FieldError | null;
  initLength?: number;
  maxLength?: number | null;
  hasBorder?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const defaultRegister = <
  TFieldValues extends FieldValues,
>(): UseFormRegister<TFieldValues> => {
  return <TFieldName extends Path<TFieldValues>>(
    name: TFieldName,
    _options?: RegisterOptions<TFieldValues, TFieldName>,
  ): UseFormRegisterReturn<TFieldName> => ({
    onChange: () => Promise.resolve(true),
    onBlur: () => Promise.resolve(true),
    ref: () => Promise.resolve(true),
    name,
  });
};

function TextareaAutosizeForm<TFieldValues extends FieldValues = FieldValues>({
  className = "",
  labelClassName = "mb-1.5",
  textareaClassName = "text-sm",
  borderColor = "gray-300",
  disabledBgColor = "gray-150",
  label = "",
  placeholder,
  name,
  required = false,
  withoutAsterisk = false,
  register = defaultRegister<TFieldValues>(),
  registerOptions = {},
  error = null as FieldError | null,
  initLength = 0,
  maxLength = null,
  hasBorder = true,
  disabled = false,
  onChange = (_event) => {},
  onFocus = () => {},
  onBlur = () => {},
  ...props
}: TextareaAutosizeFormProps<TFieldValues>) {
  const [length, setLength] = useState(initLength);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setLength(initLength);
  }, [initLength]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e);
    if (maxLength) {
      setLength(e.target.value.length);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(false);
    onBlur?.(e);
  };

  // Register the field with react-hook-form
  const registration = register
    ? register(name, registerOptions)
    : {
        onChange: () => {},
        onBlur: () => {},
        ref: () => {},
        name,
      };

  return (
    <div className={`flex flex-col text-sm ${className}`}>
      {label && (
        <p className={`font-semibold ${labelClassName}`}>
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600">*</span>
          )}
        </p>
      )}
      <div className="relative">
        <TextareaAutosize
          {...registration}
          className={`w-full resize-none px-3 py-2.5 outline-none focus:outline-none rounded-lg align-top scrollbar
            ${textareaClassName}
            ${hasBorder ? "border focus:ring-0" : ""}
            ${error ? "border-red-400" : `border-${borderColor}`}
            ${disabled ? `bg-${disabledBgColor}` : "bg-white"}
          `}
          placeholder={placeholder}
          maxLength={maxLength ?? undefined}
          onChange={(e) => {
            registration.onChange(e); // Call react-hook-form's onChange
            handleChange(e); // Call our custom onChange
          }}
          onBlur={(e) => {
            registration.onBlur(e); // Call react-hook-form's onBlur
            handleBlur(e); // Call our custom onBlur
          }}
          onFocus={handleFocus}
          disabled={disabled}
          {...props}
        />
        {maxLength && focused && (
          <p className="absolute bottom-0.5 right-1 text-xs text-gray-400">
            {maxLength - length}
          </p>
        )}
      </div>
      {error && (
        <p className="text-red-400 text-sm font-normal mt-1.5">
          {error.message}
        </p>
      )}
    </div>
  );
}

export default TextareaAutosizeForm;
