import type ContentFormattingStrategy from "./ContentFormattingStrategy";

class PlainTextFormattingStrategy implements ContentFormattingStrategy {
  canHandle(): boolean {
    // Default strategy for any content
    return true;
  }

  format(content: string | null): React.ReactNode {
    if (!content) {
      return null;
    }

    // Split by newline characters or escaped newlines
    const lines = content.split(/\\n|\n/g);

    return (
      <div>
        {lines.map((line, i) => (
          <div key={i} className="whitespace-pre-wrap">
            {line}
          </div>
        ))}
      </div>
    );
  }
}

export default PlainTextFormattingStrategy;
