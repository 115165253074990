import { type ReactNode, memo } from "react";

interface InboxLayoutProps {
  children: ReactNode;
  isDesktop: boolean;
  isHeightSm: boolean;
}

export const InboxLayout = memo(
  ({ children, isDesktop, isHeightSm }: InboxLayoutProps) => (
    <div className="w-full overflow-hidden grid md:h-full">
      <div
        className={`flex overflow-hidden bg-gray-background
          ${isDesktop && isHeightSm ? "p-4 height-md:p-6" : "p-4 md:p-2"}`}
      >
        {children}
      </div>
    </div>
  ),
);
