import ContentFormattingContext from "./ContentFormattingContext";

const FormattedContent = ({
  content,
  eventType,
}: {
  content: string | null;
  eventType?: string;
}) => {
  const strategy = ContentFormattingContext.getStrategy(content, eventType);

  return (
    <div className="bg-white p-3 pt-10 rounded-md text-sm overflow-auto font-mono text-gray-800">
      {strategy.format(content)}
    </div>
  );
};

export default FormattedContent;
