import ClinicUsers from "@src/components/Settings/Users/ClinicUsers";
import SettingsContainer from "../../components/Settings/SettingsContainer";

const UsersSettings = () => {
  return (
    <SettingsContainer>
      <ClinicUsers />
    </SettingsContainer>
  );
};

export default UsersSettings;
