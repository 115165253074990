import { handleValidation } from "@src/helpers/commonValidationSchemas";
import { handleRequestError } from "@src/helpers/helpers";
import { baseApi } from "../baseApi";
import { callApi } from "../call/api";
import type {
  CallNextCursor,
  CallSearchParams,
  CallStatusTab,
} from "../call/interfaces";
import { basicSitesSchema } from "../site/validationSchemas";
import type {
  Assistant,
  BasicSite,
  CallReason,
  CallReasonToUpdate,
  Metrics,
  PracticeInformation,
  Prompt,
  ProtocolMessages,
  SystemMessages,
  Voice,
} from "./types";
import {
  assistantSchema,
  callReasonsSchema,
  isValidSchema,
  metricsSchema,
  practiceInformationSchema,
  promptSchema,
  protocolMessagesSchema,
  systemMessagesSchema,
  voicesSchema,
} from "./validationSchemas";

export const voiceAgentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystemMessages: builder.query<SystemMessages, void>({
      query: () => {
        return {
          url: "/assistant/system-messages",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load system messages");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: SystemMessages) => {
        handleValidation(systemMessagesSchema, response, "getSystemMessages");
        return response;
      },
      providesTags: ["SystemMessages"],
    }),

    updateSystemMessages: builder.mutation<string, SystemMessages>({
      query: (data) => {
        return {
          url: "/assistant/system-messages",
          method: "POST",
          data,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to update system messages",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: ["SystemMessages"],
    }),

    getSites: builder.query<BasicSite[], void>({
      query: () => ({
        url: "/site/list",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load sites");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: BasicSite[]) => {
        handleValidation(basicSitesSchema, response, "getSites");
        return response;
      },
      providesTags: [{ type: "Site", id: "LIST" }],
    }),

    createSite: builder.mutation<
      string,
      {
        name: string;
        is_default: boolean;
        clinic_address: string | null;
        clinic_hours: string | null;
        faq: string | null;
        phone_numbers:
          | {
              name: string;
              phone_number: string;
              description: string;
            }[]
          | null;
      }
    >({
      query: (data) => {
        return {
          url: "/site/create",
          method: "POST",
          data: data,
        };
      },
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to create site");
        };

        const temporaryId = -Date.now();
        const optimisticSite: BasicSite = {
          site_id: temporaryId,
          name: data.name,
          is_default: data.is_default,
          assistant_settings: {
            clinic_address: data.clinic_address,
            clinic_hours: data.clinic_hours,
            faq: data.faq,
          },
          direct_phone_numbers: data.phone_numbers,
          total_patients: 0,
        };

        const patchResult = dispatch(
          voiceAgentApi.util.updateQueryData("getSites", undefined, (draft) => {
            draft.push(optimisticSite);
          }),
        );

        try {
          const { data } = await queryFulfilled;
          const createdSiteId = Number(data);
          dispatch(
            voiceAgentApi.util.updateQueryData(
              "getSites",
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (site) => site.site_id === temporaryId,
                );
                if (index !== -1) {
                  draft[index].site_id = createdSiteId;
                }
              },
            ),
          );
        } catch (error) {
          patchResult.undo();
          onError(error);
        }
      },
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),

    updateSite: builder.mutation<
      string,
      {
        site_id: number;
        site: {
          name: string;
          is_default: boolean;
          clinic_address: string | null;
          clinic_hours: string | null;
          faq: string | null;
          phone_numbers:
            | {
                name: string;
                phone_number: string;
                site_direct_phone_number_id?: number;
                transfer_to_number: "no_transfer" | "auto_transfer";
                message_to_caller: string;
                when_to_transfer: string;
              }[]
            | null;
        };
      }
    >({
      query: ({ site_id, site }) => {
        return {
          url: `/site/update/${site_id}`,
          method: "POST",
          data: site,
        };
      },
      async onQueryStarted({ site_id, site }, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to update site");
        };

        const optimisticSite: BasicSite = {
          site_id,
          name: site.name,
          is_default: site.is_default,
          assistant_settings: {
            clinic_address: site.clinic_address,
            clinic_hours: site.clinic_hours,
            faq: site.faq,
          },
          direct_phone_numbers: site.phone_numbers,
        };

        const patchResult = dispatch(
          voiceAgentApi.util.updateQueryData("getSites", undefined, (draft) => {
            const index = draft.findIndex(
              (draftSite) => draftSite.site_id === site_id,
            );
            if (index !== -1) {
              draft[index] = { ...draft[index], ...optimisticSite };
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          onError(error);
        }
      },
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),

    deleteSite: builder.mutation<string, number>({
      query: (site_id) => {
        return {
          url: `/site/delete/${site_id}`,
          method: "POST",
        };
      },
      async onQueryStarted(site_id, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to delete site");
        };

        const patchResult = dispatch(
          voiceAgentApi.util.updateQueryData("getSites", undefined, (draft) => {
            return draft.filter((site) => site.site_id !== site_id);
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          onError(error);
        }
      },
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),

    getPracticeInformation: builder.query<PracticeInformation, void>({
      query: () => {
        return {
          url: "/assistant/practice-information",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to load practice information",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: PracticeInformation) => {
        handleValidation(
          practiceInformationSchema,
          response,
          "getPracticeInformation",
        );
        return response;
      },
      providesTags: ["PracticeInformation"],
    }),

    updatePracticeInformation: builder.mutation<string, PracticeInformation>({
      query: (data) => {
        return {
          url: "/assistant/practice-information",
          method: "POST",
          data,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to update practice information",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: ["PracticeInformation"],
    }),

    getCallReasons: builder.query<CallReason[], void>({
      query: () => {
        return {
          url: "/assistant/call-reasons",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load call reasons");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: CallReason[]) => {
        handleValidation(callReasonsSchema, response, "getCallReasons");
        return response;
      },
      providesTags: ["CallReasons"],
    }),

    updateCallReason: builder.mutation<
      string,
      { call_reason_id: number; reason: CallReasonToUpdate }
    >({
      query: ({ call_reason_id, reason }) => {
        return {
          url: `/assistant/call-reason/${call_reason_id}`,
          method: "POST",
          data: reason,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to update call reason");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: ["CallReasons"],
    }),

    createCallReason: builder.mutation<string, CallReasonToUpdate>({
      query: (data) => {
        return {
          url: "/assistant/call-reason",
          method: "POST",
          data,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to create call reason");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: ["CallReasons"],
    }),

    deleteCallReason: builder.mutation<string, number>({
      query: (call_reason_id) => {
        return {
          url: `/assistant/call-reason/${call_reason_id}/delete`,
          method: "POST",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to delete call reason");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: ["CallReasons"],
    }),

    getProtocolMessages: builder.query<ProtocolMessages, void>({
      query: () => {
        return {
          url: "/assistant/protocols",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to load protocol messages",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: ProtocolMessages) => {
        handleValidation(
          protocolMessagesSchema,
          response,
          "getProtocolMessages",
        );
        return response;
      },
      providesTags: ["ProtocolMessages"],
    }),

    updateProtocolMessages: builder.mutation<string, ProtocolMessages>({
      query: (data) => {
        return {
          url: "/assistant/protocols",
          method: "POST",
          data,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to update protocol messages",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: ["ProtocolMessages"],
    }),

    getSelectedAssistant: builder.query<Assistant, void>({
      query: () => {
        return {
          url: "/assistant/voice/current",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to load selected voice assistant",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: Assistant) => {
        handleValidation(assistantSchema, response, "getSelectedAssistant");
        return response;
      },
      providesTags: ["SelectedAssistant"],
    }),

    getVoices: builder.query<Voice[], void>({
      query: () => {
        return {
          url: "/assistant/voices",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to load voice assistants",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: Voice[]) => {
        handleValidation(voicesSchema, response, "getVoices");
        return response;
      },
    }),

    selectVoice: builder.mutation<string, Voice>({
      query: (voice) => {
        return {
          url: `/assistant/voice/${voice.assistant_voice_id}`,
          method: "POST",
        };
      },
      async onQueryStarted(voice, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to select voice assistant",
          );
        };

        const patchResult = dispatch(
          voiceAgentApi.util.updateQueryData(
            "getSelectedAssistant",
            undefined,
            (draft) => {
              if (draft) {
                draft.assistant_voice_id = voice.assistant_voice_id;
                draft.assistant_voice = voice;
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          onError(error);
        }
      },
      invalidatesTags: ["SelectedAssistant"],
    }),

    getMetrics: builder.query<
      Metrics,
      { startDate: string; endDate: string; siteId: string }
    >({
      query: ({ startDate, endDate, siteId }) => {
        return {
          url: `/inbound-call/metrics?start_date=${startDate}&end_date=${endDate}&period=custom&site_id=${siteId}`,
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load metrics");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: Metrics) => {
        handleValidation(metricsSchema, response, "getMetrics");
        return response;
      },
    }),

    getIsCustomPrompt: builder.query<boolean, void>({
      query: () => {
        return {
          url: "/assistant/prompt/is-custom",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to check if prompt is custom",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: { is_custom: boolean }) => {
        return response.is_custom;
      },
      providesTags: ["IsCustomPrompt"],
    }),

    getDefaultPrompt: builder.query<Prompt, void>({
      query: () => {
        return {
          url: "/assistant/prompt/default",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load default prompt");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: { prompt: Prompt }) => {
        const prompt = response.prompt;
        handleValidation(promptSchema, prompt, "getDefaultPrompt");
        return prompt;
      },
      providesTags: ["DefaultPrompt"],
    }),

    updateDefaultPrompt: builder.mutation<string, Prompt>({
      query: (data) => {
        return {
          url: "/assistant/prompt/default",
          method: "POST",
          data: { prompt: data },
        };
      },
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          voiceAgentApi.util.updateQueryData(
            "getDefaultPrompt",
            undefined,
            () => {
              return data;
            },
          ),
        );

        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to update default prompt",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          onError(error);
        }
      },
      invalidatesTags: ["DefaultPrompt"],
    }),

    getCustomPrompt: builder.query<Prompt, void>({
      query: () => {
        return {
          url: "/assistant/prompt/custom",
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load custom prompt");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: { prompt: Prompt }) => {
        const prompt = response.prompt;
        handleValidation(promptSchema, prompt, "getCustomPrompt");
        return prompt;
      },
      providesTags: ["CustomPrompt"],
    }),

    updateCustomPrompt: builder.mutation<
      string,
      { prompt: Prompt; is_custom: boolean }
    >({
      query: (data) => {
        return {
          url: "/assistant/prompt/custom",
          method: "POST",
          data,
        };
      },
      async onQueryStarted(
        { prompt, is_custom },
        { dispatch, queryFulfilled },
      ) {
        let patchCustomPrompt = null;

        if (is_custom) {
          patchCustomPrompt = dispatch(
            voiceAgentApi.util.updateQueryData(
              "getCustomPrompt",
              undefined,
              () => {
                return prompt;
              },
            ),
          );
        }

        const patchIsCustom = dispatch(
          voiceAgentApi.util.updateQueryData(
            "getIsCustomPrompt",
            undefined,
            () => {
              return is_custom;
            },
          ),
        );

        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to update prompt");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          if (patchCustomPrompt) {
            patchCustomPrompt.undo();
          }
          patchIsCustom.undo();
          onError(error);
        }
      },
      invalidatesTags: ["CustomPrompt", "IsCustomPrompt"],
    }),

    validatePassword: builder.mutation<boolean, string>({
      query: (password) => {
        return {
          url: "/assistant/validate-password",
          method: "POST",
          data: { password },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to validate password");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: { is_valid: boolean }) => {
        const isValid = response.is_valid;
        handleValidation(isValidSchema, isValid, "validatePassword");
        return isValid;
      },
    }),

    transferPatient: builder.mutation<
      string,
      { patient_id: number; site_id: number; conversation_id: number }
    >({
      query: ({ patient_id, site_id }) => {
        return {
          url: `/patient/change-site/${patient_id}/${site_id}`,
          method: "POST",
        };
      },
      async onQueryStarted(
        { conversation_id },
        { dispatch, queryFulfilled, getState },
      ) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to transfer patient");
        };

        // Start with a performance measurement
        // Get the API from RTK Query
        const api = getState().api;

        // Find relevant query keys efficiently based on fromStatus
        const queryKeys = Object.keys(api.queries).filter((key) =>
          key.startsWith("getCalls-"),
        );

        // Store all patch results
        const patchResults = [];

        // Process each relevant query
        queryKeys.forEach((key) => {
          const queryState = api.queries[key];

          // Skip if no data or not initialized
          if (!queryState?.data || queryState.status !== "fulfilled") {
            return;
          }

          // Create the update action
          const updateAction = callApi.util.updateQueryData(
            "getCalls",
            queryState.originalArgs as {
              status: CallStatusTab;
              params: CallSearchParams;
              cursor: CallNextCursor;
            },
            (draft) => {
              // Find the conversation to remove
              const indexToRemove = draft.data.findIndex(
                (item) =>
                  item.items[0].data.conversation_id === conversation_id,
              );

              // Remove if found
              if (indexToRemove !== -1) {
                draft.data.splice(indexToRemove, 1);
              }
            },
          );

          // Dispatch and store the result
          // We need to use `as any` to bypass the TypeScript error
          const patchResult = dispatch(updateAction as any);
          patchResults.push(patchResult);
        });

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      invalidatesTags: (_, __, { conversation_id }) => [
        { type: "Conversation", id: conversation_id },
      ],
    }),
  }),
});

export const {
  useGetSystemMessagesQuery,
  useUpdateSystemMessagesMutation,
  useGetSitesQuery,
  useCreateSiteMutation,
  useUpdateSiteMutation,
  useDeleteSiteMutation,
  useGetPracticeInformationQuery,
  useUpdatePracticeInformationMutation,
  useGetCallReasonsQuery,
  useUpdateCallReasonMutation,
  useCreateCallReasonMutation,
  useDeleteCallReasonMutation,
  useGetProtocolMessagesQuery,
  useUpdateProtocolMessagesMutation,
  useGetSelectedAssistantQuery,
  useGetVoicesQuery,
  useSelectVoiceMutation,
  useGetMetricsQuery,
  useGetIsCustomPromptQuery,
  useGetDefaultPromptQuery,
  useUpdateDefaultPromptMutation,
  useGetCustomPromptQuery,
  useUpdateCustomPromptMutation,
  useValidatePasswordMutation,
  useTransferPatientMutation,
} = voiceAgentApi;
