import type ContentFormattingStrategy from "./ContentFormattingStrategy";

class MedicalNoteFormattingStrategy implements ContentFormattingStrategy {
  canHandle(content: string | null, eventType?: string): boolean {
    return (
      eventType === "note_generating" ||
      content?.includes("Chief Complaints") ||
      content?.includes("HPI") ||
      content?.includes("Treatment Notes")
    );
  }

  format(content: string | null): React.ReactNode {
    if (!content) {
      return null;
    }

    // Convert <br> tags to React elements while preserving structure
    const sections = content.split(/<br><br>|<br\s*\/?><br\s*\/?>/g);

    return (
      <div className="medical-note">
        {sections.map((section, i) => {
          // Check if this is a section header
          const isSectionHeader = /^[A-Z][A-Za-z\s]+:/.test(section);

          if (isSectionHeader) {
            const [header, ...content] = section.split(/:(.+)/);
            return (
              <div key={i} className="note-section">
                <h3 className="font-medium text-gray-800">{header}:</h3>
                <div className="pl-4">
                  {content
                    .join(":")
                    .split(/<br\s*\/?>/g)
                    .map((line, j) => {
                      // Check if line is a list item
                      if (line.trim().startsWith("- ")) {
                        return (
                          <div key={j} className="list-item">
                            • {line.substring(2)}
                          </div>
                        );
                      }
                      return <div key={j}>{line}</div>;
                    })}
                </div>
              </div>
            );
          }

          return (
            <div key={i} className="mb-2">
              {section.split(/<br\s*\/?>/g).map((line, j) => (
                <div key={j}>{line}</div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
}

export default MedicalNoteFormattingStrategy;
