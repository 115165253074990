import { validateResponse } from "@src/store/baseApi";
import type { SearchCallResponseWithDetails } from "@src/store/call/interfaces";
import { searchCallResponseWithDetailsSchema } from "@src/store/call/validationSchemas";
import { store } from "../../store";
import { setConversationsUpdatedAction } from "../../store/conversation/actions";
import { setCallEventAction } from "../../store/voiceRecorder/actions";
import type CacheManager from "./CacheManager";
import { WebsocketEvent, chatEventDataSchema } from "./schemas";
import type {
  ChatEventData,
  VoiceEventData,
  WebSocketEventData,
} from "./types";

/**
 * MessageProcessor handles WebSocket message processing
 * - Processes incoming WebSocket messages
 * - Parses message data
 * - Delegates to appropriate handlers based on message type
 * - Implements debouncing for frequent updates
 */
export class MessageProcessor {
  private cacheManager: CacheManager;
  private lastProcessedConversation: { id: number; timestamp: number } | null =
    null;
  private debounceThreshold = 2000; // 2 seconds

  constructor(cacheManager: CacheManager) {
    this.cacheManager = cacheManager;
  }

  /**
   * Process an incoming WebSocket message
   */
  public processMessage(event: MessageEvent): void {
    try {
      const eventData = JSON.parse(event.data) as WebSocketEventData;

      if (eventData.type === WebsocketEvent.Chat) {
        const validatedEventData =
          validateResponse(chatEventDataSchema)(eventData);

        this.processChatMessage(validatedEventData);
      } else if (eventData.type === WebsocketEvent.Voice) {
        this.processVoiceMessage(eventData as VoiceEventData);
      } else if (eventData.type === WebsocketEvent.PatientTransferred) {
        const validatedEventData = validateResponse(
          searchCallResponseWithDetailsSchema,
        )(eventData.data);

        this.processPatientTransferred(validatedEventData);
      } else {
        // console.log(`Received unknown message type: ${eventData.type}`);
      }
    } catch (error) {
      console.error("Error processing WebSocket message:", error);
    }
  }

  /**
   * Process chat-type messages
   */
  private processChatMessage = async (
    eventData: ChatEventData,
  ): Promise<void> => {
    const id = eventData.conversation_id;
    const updatedConversationId = Number(id);

    // console.log(`🔄 Processing websocket update for conversation ${id}`);

    // Immediately invalidate the conversation cache
    this.cacheManager.invalidateConversationCache(updatedConversationId);

    // Check if we should debounce this message
    if (this.shouldDebounceMessage(updatedConversationId)) {
      // console.log(`Debouncing update for conversation ${id} (too frequent)`);
      return;
    }

    try {
      let conversationInListData = eventData.conversation;

      if (!conversationInListData) {
        // Fetch the updated conversation data
        conversationInListData =
          await this.cacheManager.fetchUpdatedConversation(
            updatedConversationId,
          );
      }

      if (!conversationInListData) {
        console.warn(`Failed to fetch updated data for conversation ${id}`);
        return;
      }

      // Process unread inbox indicator
      this.cacheManager.processUnreadInboxIndicator(
        conversationInListData,
        eventData.mentions,
      );

      // Process updates to conversation lists and counters
      this.cacheManager.processConversationUpdates(
        updatedConversationId,
        conversationInListData,
      );

      // Mark conversations as updated for UI refresh
      store.dispatch(setConversationsUpdatedAction(false));
    } catch (error) {
      console.error("Error handling chat message:", error);
    }
  };

  private processPatientTransferred = (
    eventData: SearchCallResponseWithDetails,
  ): void => {
    // console.log("Processing patient transferred event", eventData);

    const updatedConversationId =
      eventData.data[0].items[0].data.conversation_id;

    if (this.shouldDebounceMessage(updatedConversationId)) {
      // console.log(`Debouncing update for conversation ${id} (too frequent)`);
      return;
    }

    try {
      // Process unread inbox indicator
      this.cacheManager.processUnreadInboxIndicator(eventData, []);

      // Process updates to conversation lists and counters
      this.cacheManager.processConversationUpdates(
        updatedConversationId,
        eventData,
      );
    } catch (error) {
      console.error("Error handling chat message:", error);
    }
  };

  /**
   * Process voice-type messages
   */
  private processVoiceMessage = (eventData: VoiceEventData): void => {
    // console.log("Processing voice event");
    store.dispatch(setCallEventAction(eventData.data));
  };

  /**
   * Determine if a message should be debounced based on time threshold
   */
  private shouldDebounceMessage(conversationId: number): boolean {
    const currentTime = Date.now();

    if (
      this.lastProcessedConversation?.id === conversationId &&
      currentTime - this.lastProcessedConversation.timestamp <
        this.debounceThreshold
    ) {
      return true;
    }

    this.lastProcessedConversation = {
      id: conversationId,
      timestamp: currentTime,
    };

    return false;
  }

  /**
   * Set debounce threshold (useful for testing or adjusting based on app needs)
   */
  public setDebounceThreshold(threshold: number): void {
    this.debounceThreshold = threshold;
  }
}

export default MessageProcessor;
