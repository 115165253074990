import { Pages } from "@src/helpers/constants";
import { useActivityTracker } from "@src/hooks/useActivityTracker";
import { useAppDispatch, useAppSelector } from "@src/store";
import { openConversationEvent } from "@src/store/billingMetrics/thunks";
import { useSendMessageMutation } from "@src/store/call/api";
import type { Conversation } from "@src/store/call/interfaces";
import { removeFailedMessageAction } from "@src/store/conversation/actions";
import {
  getConversationFailedMessages,
  getConversationSearchInput,
  getConversationSentMessages,
} from "@src/store/conversation/conversationReducer";
import { getActiveFilters } from "@src/store/conversation/conversationReducer";
import {
  getConvCurrPage,
  getConversationsUpdated,
} from "@src/store/conversation/conversationReducer";
import { selectUser } from "@src/store/user/userReducer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";
import DateIndicator from "./DateIndicator";
import FailedMessagesList from "./FailedMessagesList";
import MessageList from "./MessageList";
import ScrollToBottomButton from "./ScrollToBottomButton";
import SentMessagesList from "./SentMessagesList";

interface ChatProps {
  scrolledUp: boolean;
  setScrolledUp: (scrolledUp: boolean) => void;
  currentConversation: Conversation;
  selectedConversationId: string;
}

const InboxChat = ({
  scrolledUp,
  setScrolledUp,
  currentConversation,
  selectedConversationId,
}: ChatProps) => {
  const [conversationChanged, setConversationChanged] = useState(true);
  const [dateIndicator, setDateIndicator] = useState("");
  const [showDateIndicator, setShowDateIndicator] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [failedMessageCurrent, setFailedMessageCurrent] = useState<
    {
      idempotencyKey: string;
      message: string;
      isInternal: boolean;
    }[]
  >([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [conversationMessagesLength, setConversationMessagesLength] = useState(
    {},
  );
  const [
    currentConversationLengthChanged,
    setCurrentConversationLengthChanged,
  ] = useState(false);

  const conversationSearchInput = useAppSelector(getConversationSearchInput);
  const activeFilters = useAppSelector(getActiveFilters);
  const sentMessages = useAppSelector(getConversationSentMessages);
  const failedMessages = useAppSelector(getConversationFailedMessages);
  const convCurrPage = useAppSelector(getConvCurrPage);
  const conversationsUpdated = useAppSelector(getConversationsUpdated);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const messageContainer = useRef(null);
  const dateIndicatorRef = useRef(null);
  const dateRefs = useRef([]);
  const scrolledUpRef = useRef(scrolledUp);
  const conversationSearchInputRef = useRef(conversationSearchInput);
  const conversationMessagesLengthRef = useRef(conversationMessagesLength);
  const convCurrPageRef = useRef(convCurrPage);
  const activeFiltersRef = useRef(activeFilters);

  useEffect(() => {
    if (selectedConversationId) {
      setConversationChanged(true);
    }
  }, [selectedConversationId]);

  const selectedConversationIdMemoized = useMemo(
    () => selectedConversationId,
    [selectedConversationId],
  );

  const patientPicture = useMemo(
    () =>
      currentConversation ? currentConversation.patient.profile_picture : "",
    [currentConversation],
  );

  const doTracking = useMemo(
    () =>
      currentConversation && window.location.pathname === Pages.FRONT_DESK
        ? currentConversation.trackable
        : false,
    [currentConversation],
  );

  const { restartTimer } = useActivityTracker(doTracking);

  const [sendMessage] = useSendMessageMutation();

  useEffect(() => {
    scrolledUpRef.current = scrolledUp;
  }, [scrolledUp]);

  useEffect(() => {
    conversationSearchInputRef.current = conversationSearchInput;
  }, [conversationSearchInput]);

  useEffect(() => {
    conversationMessagesLengthRef.current = conversationMessagesLength;
  }, [conversationMessagesLength]);

  useEffect(() => {
    convCurrPageRef.current = convCurrPage;
  }, [convCurrPage]);

  useEffect(() => {
    activeFiltersRef.current = activeFilters;
  }, [activeFilters]);

  const scrollChat = useCallback(() => {
    if (
      messageContainer.current?.scrollTop /
        (messageContainer.current?.scrollHeight -
          messageContainer.current?.clientHeight) <
      0.99
    ) {
      setScrolledUp(true);
    } else {
      setScrolledUp(false);
      setUnreadMessages(0);
    }
  }, [setScrolledUp]);

  const scrollToBottom = useCallback((smooth) => {
    messageContainer.current?.scrollTo({
      top: messageContainer.current?.scrollHeight,
      left: 0,
      behavior: smooth ? "smooth" : "auto",
    });
    setUnreadMessages(0);
  }, []);

  useEffect(() => {
    if (scrolledUpRef.current && !conversationsUpdated) {
      setUnreadMessages((prev) => prev + 1);
    }
  }, [conversationsUpdated]);

  useEffect(() => {
    if (!scrolledUpRef.current) {
      scrollToBottom(true);
    }
  }, [scrollToBottom]);

  useEffect(() => {
    if (
      sentMessages[selectedConversationIdMemoized] &&
      sentMessages[selectedConversationIdMemoized].length > 0
    ) {
      scrollToBottom(true);
    }
  }, [sentMessages, scrollToBottom, selectedConversationIdMemoized]);

  useEffect(() => {
    if (failedMessageCurrent.length > 0) {
      scrollToBottom(false);
    }
  }, [failedMessageCurrent, scrollToBottom]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", scrollChat);

    return () =>
      messageContainer.current?.removeEventListener("scroll", scrollChat);
  }, [scrollChat]);

  useEffect(() => {
    if (!currentConversation) {
      return;
    }

    setCurrentConversationLengthChanged(
      currentConversation.messages.length >
        conversationMessagesLengthRef.current[selectedConversationIdMemoized],
    );

    setConversationMessagesLength((prev) => ({
      ...prev,
      [selectedConversationIdMemoized]: currentConversation.messages.length,
    }));

    if (currentConversation.messages.length > 0) {
      setLastMessage(
        currentConversation.messages[currentConversation.messages.length - 1],
      );
    }

    if (conversationChanged) {
      scrollToBottom(false);

      (async () => {
        if (doTracking) {
          restartTimer();
          await openConversationEvent(
            currentConversation.patient.patient_id,
            currentConversation.campaign_type,
          );
        }
      })();
      setConversationChanged(false);
    } else {
      if (!scrolledUpRef.current) {
        scrollToBottom(true);
      }
    }
  }, [
    currentConversation,
    scrollToBottom,
    restartTimer,
    selectedConversationIdMemoized,
    conversationChanged,
    doTracking,
  ]);

  useEffect(() => {
    setFailedMessageCurrent(
      failedMessages[selectedConversationIdMemoized] || [],
    );
  }, [failedMessages, selectedConversationIdMemoized]);

  useEffect(() => {
    messageContainer.current.addEventListener("scroll", updateDateIndicator);

    const resizeObserver = new ResizeObserver(() => {
      if (messageContainer.current?.offsetWidth < 300) {
        setShowDateIndicator(false);
      }
    });
    resizeObserver.observe(messageContainer.current);

    return () => {
      messageContainer.current?.removeEventListener(
        "scroll",
        updateDateIndicator,
      );
      resizeObserver.disconnect();
    };
  }, []);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const updateDateIndicator = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    if (messageContainer.current?.scrollTop > 30) {
      setShowDateIndicator(true);
      timeoutIdRef.current = setTimeout(() => {
        setShowDateIndicator(false);
      }, 4000);
    } else {
      setShowDateIndicator(false);
    }

    dateRefs.current.forEach((dateRef) => {
      if (dateRef && dateIndicatorRef.current) {
        if (
          dateIndicatorRef.current.getBoundingClientRect().top >=
          dateRef.getBoundingClientRect().top
        ) {
          setDateIndicator(dateRef.textContent);
        }
      }
    });
  };

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  const removeFailedMessage = useCallback(
    (idempotencyKey: string) => {
      dispatch(
        removeFailedMessageAction(
          idempotencyKey,
          Number(selectedConversationIdMemoized),
        ),
      );
    },
    [dispatch, selectedConversationIdMemoized],
  );

  const resendFailedMessage = useCallback(
    (idempotencyKey: string, message: string, isInternal: boolean) => {
      removeFailedMessage(idempotencyKey);
      sendMessage({
        message,
        conversationId: Number(selectedConversationIdMemoized),
        isInternal,
        idempotencyKey,
      });
    },
    [sendMessage, removeFailedMessage, selectedConversationIdMemoized],
  );

  return (
    <div className="overflow-hidden relative flex">
      <div
        ref={messageContainer}
        className="pl-4 pb-4 pr-2 mr-2 overflow-y-auto scrollbar relative h-full w-full"
      >
        {!currentConversation ? (
          <ChatSkeleton />
        ) : (
          <>
            <DateIndicator
              showDateIndicator={showDateIndicator}
              dateIndicator={dateIndicator}
              dateIndicatorRef={dateIndicatorRef}
            />

            <MessageList
              conversation={currentConversation}
              patientPicture={patientPicture}
              dateRefs={dateRefs}
              user={user}
            />

            <SentMessagesList
              sentMessages={sentMessages}
              lastMessage={lastMessage}
              user={user}
              conversationId={Number(selectedConversationIdMemoized)}
            />

            <FailedMessagesList
              failedMessages={failedMessageCurrent}
              lastMessage={lastMessage}
              user={user}
              sentMessages={sentMessages}
              conversationId={Number(selectedConversationIdMemoized)}
              onRemoveMessage={removeFailedMessage}
              onResendMessage={resendFailedMessage}
            />

            <div className="absolute bottom-8 left-1/2 h-0 w-fit overflow-visible">
              <ScrollToBottomButton
                unreadMessages={unreadMessages}
                scrolledUp={scrolledUp}
                conversationLengthChanged={currentConversationLengthChanged}
                onClick={() => scrollToBottom(true)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InboxChat;
