import {
  Children,
  type ReactNode,
  isValidElement,
  useEffect,
  useRef,
} from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useMediaQuery } from "react-responsive";
import "./InputWithMentions.css";

import { getTeamIcon } from "@src/components/Settings/Users/Teams/IconSelection";
import ProfilePicture from "../../Basic/ProfilePicture";
import defaultMentionStyle from "./defaultMentionStyle.js";
import { getDefaultStyle } from "./defaultStyle";
import {
  type MentionItem,
  type MentionTeam,
  MentionType,
  type MentionUser,
} from "./interfaces";

const InputWithMentions = ({
  mentionItems = [],
  value = "",
  placeholder = "",
  disabled = false,
  onChange = () => {},
  onKeyDown = () => {},
}: {
  mentionItems: MentionItem[];
  value: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}) => {
  const inputEl = useRef(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const suggestionList = document.querySelector(".mentions__suggestions");
    const isOpen = !!suggestionList;

    if (isOpen && e.key === "Enter") {
      e.preventDefault();
      return;
    }

    onKeyDown(e);
  };

  return (
    <MentionsInput
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      style={getDefaultStyle(disabled, isDesktop)}
      inputRef={inputEl}
      placeholder={placeholder}
      a11ySuggestionsListLabel="Suggested mentions"
      forceSuggestionsAboveCursor={true}
      allowSpaceInQuery={true}
      className="mentions"
      disabled={disabled}
      customSuggestionsContainer={(children: ReactNode) => {
        const suggestions = Children.toArray(children);
        if (suggestions.length === 0) {
          return null;
        }

        const suggestionsElement = suggestions[0];
        if (!isValidElement(suggestionsElement)) {
          return null;
        }

        const suggestionProps = suggestionsElement.props.children;
        const userSuggestions = [];
        const teamSuggestions = [];

        Children.forEach(
          suggestionProps,
          (child: { props: { suggestion: MentionItem } }) => {
            if (!isValidElement(child)) {
              return;
            }

            if (child.props?.suggestion?.type === MentionType.USER) {
              userSuggestions.push(child);
            } else if (child.props?.suggestion?.type === MentionType.TEAM) {
              teamSuggestions.push(child);
            }
          },
        );

        return (
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <ul
              aria-label="Suggested mentions"
              className="m-0 p-2 list-none max-h-[70vh] height-sm:max-h-93 overflow-y-auto scrollbar"
            >
              <div>{teamSuggestions}</div>
              {userSuggestions.length > 0 && teamSuggestions.length > 0 && (
                <hr className="border-t border-gray-200 mx-2" />
              )}
              <div>{userSuggestions}</div>
            </ul>
          </div>
        );
      }}
    >
      <Mention
        data={mentionItems.filter(
          (item) => item.type !== MentionType.SEPARATOR,
        )}
        style={defaultMentionStyle}
        displayTransform={(_, display) => `@${display}`}
        renderSuggestion={(
          _suggestion: MentionItem,
          _search: string,
          highlightedDisplay: string,
        ) => (
          <div className="flex items-center space-x-2 text-sm font-medium py-1.5 px-2 rounded-lg cursor-pointer z-50">
            {_suggestion.type === MentionType.USER ? (
              <ProfilePicture
                size={6}
                fontSize="xxs"
                firstName={(_suggestion as MentionUser).first_name}
                lastName={(_suggestion as MentionUser).last_name}
                flagPadding={false}
                src={(_suggestion as MentionUser).profile_picture}
              />
            ) : (
              getTeamIcon(
                (_suggestion as MentionTeam).team_icon,
                "20",
                "#121212",
              )
            )}
            <div className="font-medium text-left truncate">
              {highlightedDisplay}
            </div>
          </div>
        )}
      />
    </MentionsInput>
  );
};

export default InputWithMentions;
