import { NavLink, useSearchParams } from "react-router";
import { Pages } from "../../helpers/constants";
import { makeSlug } from "../../helpers/helpers";

interface TableTabLinksProps {
  tabs: string[];
  className?: string;
}

const TableTabLinks = ({ tabs, className = "" }: TableTabLinksProps) => {
  const [searchParams] = useSearchParams();

  const selectedTab = searchParams.get("tab") || makeSlug(tabs[0]);

  return (
    <div className={`flex w-full overflow-x-auto scrollbar ${className}`}>
      {tabs.map((tab) => {
        const slug = makeSlug(tab);
        const isActive = selectedTab === slug;
        return (
          <NavLink
            key={tab}
            aria-label={tab}
            to={`${Pages.VOICE_AGENT}?tab=${slug}`}
            className={`py-2.5 px-4 rounded-t-lg text-sm flex-none ${isActive ? "bg-white text-primary" : "text-tertiary"}`}
          >
            {tab}
          </NavLink>
        );
      })}
    </div>
  );
};

export default TableTabLinks;
