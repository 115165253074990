import PhoneInputBlock from "@src/components/VoiceAgent/SupportLineView/PhoneInputBlock";
import { getFormattedPhoneNumber } from "@src/helpers/helpers";
import {
  useGetPracticeInformationQuery,
  useGetSitesQuery,
  useUpdatePracticeInformationMutation,
  useUpdateSiteMutation,
} from "@src/store/voiceAgent/api";
import type {
  BasicSite,
  DirectPhoneNumber,
  PracticeInformation as PracticeInformationType,
} from "@src/store/voiceAgent/types";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-circle.svg";
import Select from "../../Basic/Select";
import TextareaAutosizeForm from "../../Basic/TextareaAutosizeForm";
import InputSkeleton from "../../Skeletons/InputSkeleton";
import SectionControls from "./SectionControls";

type FormValues = PracticeInformationType & {
  site: number;
  clinic_address: string;
  clinic_hours: string;
  site_faq: string;
  phone_numbers: {
    name: string;
    phone_number: string;
    site_direct_phone_number_id?: number;
    transfer_to_number: "no_transfer" | "auto_transfer";
    message_to_caller: string;
    when_to_transfer: string;
  }[];
};

const PracticeInformation = () => {
  const [editMode, setEditMode] = useState(false);
  const [selectedSite, setSelectedSite] = useState<BasicSite>(null);

  const { data: sites, isLoading: isLoadingSites } = useGetSitesQuery();
  const { data: practiceInformation, isLoading: isLoadingPracticeInformation } =
    useGetPracticeInformationQuery();
  const [updateSite] = useUpdateSiteMutation();
  const [updatePracticeInformation] = useUpdatePracticeInformationMutation();

  const siteOptions = useMemo(() => {
    return sites?.map((site) => ({
      value: site.site_id,
      text: site.name,
    }));
  }, [sites]);

  const isLoading =
    isLoadingSites || isLoadingPracticeInformation || !siteOptions;

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      site: null,
      clinic_address: "",
      clinic_hours: "",
      phone_numbers: [],
      site_faq: "",
      practicing_providers: "",
      insurance_accepted: "",
      faq: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "phone_numbers",
    control,
  });

  const watchSite = watch("site");

  useEffect(() => {
    if (sites?.length && !watchSite) {
      const firstSiteId = sites[0].site_id;
      setValue("site", firstSiteId);
    }
  }, [sites, watchSite, setValue]);

  useEffect(() => {
    if (!watchSite || !sites?.length) {
      return;
    }

    const siteIdNumber = Number(watchSite);

    const selected =
      sites.find((site) => site.site_id === siteIdNumber) || null;

    if (!isEqual(selected, selectedSite)) {
      setSelectedSite(selected);

      setValue("clinic_address", selected.assistant_settings?.clinic_address);
      setValue("clinic_hours", selected.assistant_settings?.clinic_hours);
      setValue("site_faq", selected.assistant_settings?.faq);
      setValue("phone_numbers", mapPhoneNumbers(selected.direct_phone_numbers));
    }
  }, [watchSite, sites, setValue, selectedSite]);

  useEffect(() => {
    if (practiceInformation) {
      setValue(
        "practicing_providers",
        practiceInformation.practicing_providers,
      );
      setValue("insurance_accepted", practiceInformation.insurance_accepted);
      setValue("faq", practiceInformation.faq);
    }
  }, [practiceInformation, setValue]);

  const mapPhoneNumbers = useCallback((phoneNumbers: DirectPhoneNumber[]) => {
    return phoneNumbers
      ? phoneNumbers.map((number) => ({
          name: number.name,
          phone_number: number.phone_number,
          site_direct_phone_number_id: number.site_direct_phone_number_id,
          transfer_to_number: number.transfer_to_number,
          message_to_caller: number.message_to_caller,
          when_to_transfer: number.when_to_transfer,
        }))
      : [];
  }, []);

  const resetPracticeInformation = useCallback(() => {
    reset({
      site: selectedSite?.site_id || null,
      clinic_address: selectedSite?.assistant_settings?.clinic_address || "",
      clinic_hours: selectedSite?.assistant_settings?.clinic_hours || "",
      site_faq: selectedSite?.assistant_settings?.faq || "",
      phone_numbers: mapPhoneNumbers(selectedSite?.direct_phone_numbers),
      practicing_providers: practiceInformation?.practicing_providers || "",
      insurance_accepted: practiceInformation?.insurance_accepted || "",
      faq: practiceInformation?.faq || "",
    });
  }, [reset, selectedSite, practiceInformation, mapPhoneNumbers]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    resetPracticeInformation();
  };

  const onSubmit = (data: FormValues) => {
    setEditMode(false);

    if (selectedSite) {
      updateSite({
        site_id: selectedSite.site_id,
        site: {
          name: selectedSite.name,
          is_default: !!selectedSite.is_default,
          clinic_address:
            data.clinic_address ||
            selectedSite.assistant_settings?.clinic_address,
          clinic_hours:
            data.clinic_hours || selectedSite.assistant_settings?.clinic_hours,
          faq: data.site_faq || selectedSite.assistant_settings?.faq,
          phone_numbers:
            data.phone_numbers ||
            mapPhoneNumbers(selectedSite.direct_phone_numbers),
        },
      });
    }

    updatePracticeInformation({
      practicing_providers:
        data.practicing_providers || practiceInformation.practicing_providers,
      insurance_accepted:
        data.insurance_accepted || practiceInformation.insurance_accepted,
      faq: data.faq || practiceInformation.faq,
    });
  };

  const onAddNumber = () => {
    append({
      name: "",
      phone_number: "",
      transfer_to_number: "no_transfer",
      when_to_transfer: "",
      message_to_caller: "",
    });
  };

  return (
    <div
      className={`grid grid-rows-tab-layout gap-5 overflow-hidden h-full ${editMode ? "pb-13 md:pb-0" : ""}`}
    >
      <SectionControls
        title="Define clinic details to accurately answer common caller questions"
        editMode={editMode}
        onEdit={handleEdit}
        onCancel={handleCancel}
        disabled={isLoading}
        formId="practice-information-form"
      />

      <form
        id="practice-information-form"
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-2 gap-5 overflow-y-auto pr-1 scrollbar"
      >
        <div className="w-full h-fit space-y-5 bg-gray-background rounded-2xl p-4">
          {isLoading ? (
            <InputSkeleton label="Site" />
          ) : siteOptions?.length > 1 ? (
            <Select
              options={siteOptions || []}
              register={register}
              name="site"
              label="Site"
              placeholder="Site"
              labelClassName="text-base mb-3"
              selectClassName="text-base"
              borderColor="gray-250"
              disabledBgColor="stone-60"
              required="Site is required"
              withoutAsterisk
              error={errors.site}
            />
          ) : (
            <div className="text-base space-y-3">
              <p className="font-semibold">Site</p>
              <p
                className={`p-2 px-3 rounded-lg border border-gray-250 h-10 truncate bg-stone-60 flex items-center
                  ${siteOptions?.length === 1 ? "" : "text-gray-placeholder"}`}
              >
                {siteOptions?.length === 1 ? siteOptions[0].text : "Site"}
              </p>
              <input
                type="hidden"
                {...register("site")}
                value={siteOptions?.length === 1 ? siteOptions[0]?.value : ""}
                disabled
              />
            </div>
          )}

          {isLoading ? (
            <InputSkeleton heightPx={94} label="Clinic Address" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="clinic_address"
              label="Clinic Address"
              placeholder="Clinic Address"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={3}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}

          {isLoading ? (
            <InputSkeleton heightPx={190} label="Clinic Hours" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="clinic_hours"
              label="Clinic Hours"
              placeholder="Clinic Hours"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={7}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}

          {isLoading ? (
            <InputSkeleton heightPx={50} label="Direct Phone Numbers" />
          ) : (
            <div className="space-y-3">
              <p>Direct Phone Numbers</p>

              {fields.map((field, index) => {
                const values = getValues(`phone_numbers.${index}`);

                return editMode ? (
                  <PhoneInputBlock
                    key={field.id}
                    register={register}
                    control={control}
                    index={index}
                    remove={remove}
                    mobileLayoutOnMd
                  />
                ) : (
                  <div
                    key={field.id}
                    className="bg-stone-60 p-3 rounded-lg border border-gray-250"
                  >
                    <div className="flex items-center space-x-2 justify-between overflow-hidden">
                      <p className="truncate">
                        {values.name} :{" "}
                        {getFormattedPhoneNumber(values.phone_number)}
                      </p>
                      <div className="text-xs font-medium bg-gray-foreground rounded py-1 px-2 capitalize whitespace-nowrap">
                        {values.transfer_to_number?.replace("_", " ")}
                      </div>
                    </div>

                    {(values.message_to_caller || values.when_to_transfer) && (
                      <hr className="border-gray-foreground my-3" />
                    )}

                    {values.message_to_caller && (
                      <div className={values.when_to_transfer ? "mb-3" : ""}>
                        <p className="text-tertiary text-xs font-medium mb-0.5">
                          Message to Caller
                        </p>
                        <p>{values.message_to_caller}</p>
                      </div>
                    )}

                    {values.when_to_transfer && (
                      <div>
                        <p className="text-tertiary text-xs font-medium mb-0.5">
                          When to Transfer
                        </p>
                        <p>{values.when_to_transfer}</p>
                      </div>
                    )}
                  </div>
                );
              })}

              {editMode && (
                <button
                  type="button"
                  className="text-sm text-primary-blue flex items-center space-x-2"
                  onClick={onAddNumber}
                >
                  <PlusIcon width="20" height="20" />
                  <p>Add number</p>
                </button>
              )}
            </div>
          )}

          {isLoading ? (
            <InputSkeleton heightPx={262} label="Site FAQ" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="site_faq"
              label="Site FAQ"
              placeholder="Site FAQ"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={10}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}
        </div>

        <div className="space-y-5 md:pt-4">
          {isLoading ? (
            <InputSkeleton heightPx={190} label="Practicing Providers" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="practicing_providers"
              label="Practicing Providers"
              placeholder="Practicing Providers"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={7}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}

          {isLoading ? (
            <InputSkeleton heightPx={298} label="Insurances Accepted" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="insurance_accepted"
              label="Insurances Accepted"
              placeholder="Insurances Accepted"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={11.5}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}

          {isLoading ? (
            <InputSkeleton heightPx={262} label="FAQ" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="faq"
              label="FAQ"
              placeholder="FAQ"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={10}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default PracticeInformation;
