import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { voiceAgentSlice } from "@src/store/voiceAgent/voiceAgentSlice";
import { useDispatch, useSelector } from "react-redux";
import { noteTemplateSlice } from "../components/IndependentScribe/store/noteTemplateSlice";
import { scribeSlice } from "../components/IndependentScribe/store/scribeSlice";
import { storageMiddleware } from "../components/IndependentScribe/store/storageMiddleware";
import timerSlice from "../components/IndependentScribe/store/timerSlice";
import { adminSlice } from "./admin/adminSlice";
import authReducer from "./auth/authReducer";
import billingMetricsReducer from "./billingMetrics/billingMetricsReducer";
import { callSlice } from "./call/callSlice";
import { callStorageMiddleware } from "./call/callStorageMiddleware";
import campaignReducer from "./campaign/campaignReducer";
import conversationReducer from "./conversation/conversationReducer";
import {
  featureFlagsSlice,
  persistFeatureFlagsMiddleware,
} from "./featureFlagSlice";
import metricsReducer from "./metrics/metricsReducer";
import patientReducer from "./patient/patientReducer";
import siteReducer from "./site/siteReducer";
import templateReducer from "./template/templateReducer";
import uiReducer from "./ui/uiReducer";
import { userApi } from "./user/api";
import userReducer from "./user/userReducer";
import voiceRecorderReducer from "./voiceRecorder/voiceRecorderReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  [userApi.reducerPath]: userApi.reducer,
  conversation: conversationReducer,
  patient: patientReducer,
  campaign: campaignReducer,
  template: templateReducer,
  metrics: metricsReducer,
  voiceRecorder: voiceRecorderReducer,
  billingMetrics: billingMetricsReducer,
  scribe: scribeSlice.reducer,
  noteTemplate: noteTemplateSlice.reducer,
  site: siteReducer,
  timer: timerSlice,
  ui: uiReducer,
  call: callSlice.reducer,
  featureFlags: featureFlagsSlice.reducer,
  admin: adminSlice.reducer,
  voiceAgent: voiceAgentSlice.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["timer/setCurrentTime"],
          ignoredPaths: ["timer.currentTime"],
        },
      }).prepend(
        storageMiddleware,
        callStorageMiddleware,
        persistFeatureFlagsMiddleware,
        userApi.middleware,
      ),
    preloadedState,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
