import { getAge, getPatientMrn, getPatientSex } from "../../helpers/helpers";
import { useAppSelector } from "../../store";
import type { ConversationPatient } from "../../store/patient/interfaces";
import { selectUser } from "../../store/user/userReducer";

type PatientShortInfoProps = {
  patient: ConversationPatient;
  className?: string;
  showBirthdate?: boolean;
};

const PatientShortInfo = ({
  patient,
  className,
  showBirthdate = true,
}: PatientShortInfoProps) => {
  const user = useAppSelector(selectUser);
  const age = patient.birthdate ? getAge(patient.birthdate) : "";

  const info = [
    age ? `${age} yo` : "",
    patient.gender
      ? patient.gender.charAt(0).toUpperCase()
      : getPatientSex(patient.sex)
        ? getPatientSex(patient.sex).charAt(0).toUpperCase()
        : "",
    patient.pronouns,
    patient.birthdate && showBirthdate
      ? new Date(
          (patient.birthdate as string).replace(/-/g, "/"),
        ).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
      : "",
    getPatientMrn(patient.chart_number, user.customer.has_ehr_data),
  ]
    .filter(Boolean)
    .join(" • ");

  if (!info) {
    return null;
  }

  return (
    <div className={`font-medium flex items-center leading-none ${className}`}>
      {info}
    </div>
  );
};

export default PatientShortInfo;
