import dayjs from "dayjs";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setCurrentBillingPatientAction } from "../../../store/billingMetrics/actions";
import { getBillingPatient } from "../../../store/billingMetrics/thunks";
import { setProfileLoadingAction } from "../../../store/patient/actions";
import {
  getPatientEnrollments,
  getPatientInfo,
} from "../../../store/patient/thunks";
import { setInterruptedRecordingAction } from "../../IndependentScribe/store/scribeSlice";
import { getRecordingScribe } from "../../IndependentScribe/store/selectors";
import { SetInterruptedRecordingActionType } from "../../IndependentScribe/store/types";
import CareCoordinationSkeleton from "../../Skeletons/CareCoordinationSkeleton";
import EmptyEnrollments from "./EmptyEnrollments";
import EnrollmentCard from "./EnrollmentCard";
import { useNewEnrollmentLogic } from "./useNewEnrollmentLogic";

const EnrollmentsTab = ({ onDeleteEnrollment, setSelectedEnrollment }) => {
  const recordingNote = useAppSelector(getRecordingScribe);
  const { id } = useParams();
  const { selectedPatientInfo, selectedPatientEnrollments, profileLoading } =
    useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();

  const { handleNewEnrollment } = useNewEnrollmentLogic();

  useEffect(() => {
    if (!selectedPatientEnrollments) {
      dispatch(setProfileLoadingAction(true));
      dispatch(getPatientEnrollments(Number(id)));
    }

    if (!selectedPatientInfo) {
      dispatch(getPatientInfo(Number(id)));
    }

    return () => {
      dispatch(setCurrentBillingPatientAction(null));
    };
  }, [id, dispatch, selectedPatientEnrollments, selectedPatientInfo]);

  useEffect(() => {
    if (selectedPatientEnrollments && selectedPatientEnrollments.length > 0) {
      const currentDateStamp = dayjs().startOf("month").format("YYYY-MM-DD");
      const CAMPAIGN_TYPE_TO_BILLING_TYPE = {
        Outreach: "CCM",
        Outreach_PCM: "PCM",
        Outreach_BHI: "BHI",
      };
      const campaignType = selectedPatientEnrollments[0].campaign.campaign_type;
      const billingType =
        CAMPAIGN_TYPE_TO_BILLING_TYPE[campaignType] ?? campaignType;

      dispatch(getBillingPatient(Number(id), currentDateStamp, billingType));
    }
  }, [selectedPatientEnrollments, dispatch, id]);

  const onNewEnroll = () => {
    if (recordingNote) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.NEW_ENROLLMENT,
        }),
      );

      return;
    }

    handleNewEnrollment();
  };

  return (
    <div className="pb-24 md:pb-0 md:h-full">
      {selectedPatientInfo &&
      selectedPatientEnrollments &&
      selectedPatientEnrollments.length > 0 ? (
        <div className="md:h-full flex flex-col md:grid md:grid-rows-note-layout gap-1 relative">
          <div className="space-y-1">
            {selectedPatientEnrollments.map((campaign, index) => (
              <EnrollmentCard
                key={campaign.campaign_patient_id || campaign.campaign_id}
                enrollment={campaign}
                setSelectedEnrollment={setSelectedEnrollment}
                onDelete={onDeleteEnrollment}
                withoutNumber={!selectedPatientInfo.phone_number}
                openByDefault={index === 0 && selectedPatientInfo.consent !== 0}
                noConsent={selectedPatientInfo.consent === 0}
              />
            ))}
          </div>
        </div>
      ) : !selectedPatientEnrollments || profileLoading ? (
        <CareCoordinationSkeleton />
      ) : (
        <EmptyEnrollments
          hasPhoneNumber={!!selectedPatientInfo?.phone_number}
          onClick={onNewEnroll}
        />
      )}
    </div>
  );
};

export default EnrollmentsTab;
