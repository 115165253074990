import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-left-simple.svg";
import { ReactComponent as CalendarIcon } from "@assets/icons/calendar2.svg";
import dayjs from "dayjs";
import { forwardRef } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { useMediaQuery } from "react-responsive";
import "react-datepicker/dist/react-datepicker.css";
import "./YearMonthPicker.css";

const YearMonthPicker = ({
  startDate,
  onSelectDate,
  className = "bg-white py-2 px-4 rounded-lg border border-gray-foreground text-base h-10",
  iconSize = 20,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const CustomDateButton = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className={`flex items-center space-x-2 whitespace-nowrap text-tertiary ${className}`}
      onClick={onClick}
      ref={ref}
    >
      <CalendarIcon
        width={iconSize}
        height={iconSize}
        stroke="#667085"
        className="flex-none"
      />
      <p>{value}</p>
    </button>
  ));

  const renderCustomContainer = ({ className, children }) => {
    return (
      <CalendarContainer
        className={`customYearMonthPicker shadow-md ${className}`}
      >
        <div className="bg-primary-blue-light p-4 font-semibold">
          <p className="text-sm text-tertiary">Select Month</p>
          <p className="text-base text-primary-blue">
            {startDate.format("MMMM")}, {startDate.year()}
          </p>
        </div>
        <div className="relative">{children}</div>
      </CalendarContainer>
    );
  };

  const renderCustomHeader = ({
    date,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => {
    return (
      <div className="flex items-center justify-between px-3 pt-2 text-sm font-medium">
        <button
          type="button"
          onClick={decreaseYear}
          disabled={prevYearButtonDisabled}
          className={prevYearButtonDisabled ? "invisible" : ""}
        >
          <ArrowIcon width="12" height="12" stroke="#667085" />
        </button>

        {date.getFullYear()}

        <button
          type="button"
          onClick={increaseYear}
          disabled={nextYearButtonDisabled}
          className={nextYearButtonDisabled ? "invisible" : ""}
        >
          <ArrowIcon
            width="12"
            height="12"
            stroke="#667085"
            className="rotate-180"
          />
        </button>
      </div>
    );
  };

  const renderMonthContent = (_, shortMonth) => {
    return (
      <div className="h-8 rounded-full flex items-center justify-center text-sm font-normal">
        {shortMonth}
      </div>
    );
  };

  return (
    <DatePicker
      dateFormat={isDesktop ? "MMMM, yyyy" : "MMM, yyyy"}
      selected={startDate.toDate()}
      onChange={(date) => onSelectDate(dayjs(date))}
      customInput={<CustomDateButton />}
      showMonthYearPicker
      calendarContainer={renderCustomContainer}
      renderCustomHeader={renderCustomHeader}
      renderMonthContent={renderMonthContent}
      showFourColumnMonthYearPicker
      popperPlacement="bottom-end"
      maxDate={new Date()}
      disabledKeyboardNavigation
    />
  );
};

export default YearMonthPicker;
