import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AnalyticsPeriod } from "@src/store/voiceAgent/types";
import { getDefaultDates } from "@src/utils/dateUtil";
import dayjs from "dayjs";
import { forwardRef } from "react";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-down.svg";
import CustomPickerButton from "./CustomPickerButton";
import { useDatePicker } from "./useDatePicker";

interface WeekPickerProps {
  baseUrl: string;
}

const WeekPicker = ({ baseUrl }: WeekPickerProps) => {
  const { startDate, endDate, minDate, maxDate, isActive, handleSelectDate } =
    useDatePicker({
      periodType: AnalyticsPeriod.WEEK,
      baseUrl: baseUrl,
    });

  const defaultDates = getDefaultDates(AnalyticsPeriod.WEEK);

  const CustomWeekButton = forwardRef<HTMLButtonElement, any>(
    ({ onClick }, ref) => (
      <CustomPickerButton
        ref={ref}
        isActive={isActive}
        onClick={onClick}
        label="Week"
        basePeriodUrl={baseUrl}
        defaultStartDate={defaultDates.start}
        defaultEndDate={defaultDates.end}
      />
    ),
  );

  const renderCustomContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={`customWeekPicker shadow-md ${className}`}>
        {children}
      </CalendarContainer>
    );
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    return (
      <div className="flex items-center justify-between px-3 pt-2 pb-0 text-sm font-medium">
        <button
          type="button"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className={prevMonthButtonDisabled ? "invisible" : ""}
        >
          <ArrowIcon
            width="10"
            height="10"
            stroke="#0F172A"
            className="rotate-90"
          />
        </button>

        <p className="text-base font-semibold">
          {date.toLocaleString("en-US", { month: "short" })}{" "}
          {date.getFullYear()}
        </p>

        <button
          type="button"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className={nextMonthButtonDisabled ? "invisible" : ""}
        >
          <ArrowIcon
            width="10"
            height="10"
            stroke="#0F172A"
            className="-rotate-90"
          />
        </button>
      </div>
    );
  };

  const renderDayContents = (day: number, date: Date) => {
    const isStartDay = dayjs(startDate).isSame(date, "day");
    const isLastDay = dayjs(endDate).isSame(date, "day");
    const isInBetween = dayjs(date).isBetween(startDate, endDate, "day", "[]");

    return (
      <div
        className={`flex items-center justify-center w-full h-full
          ${isInBetween ? "bg-primary-blue-light text-primary" : ""}
          ${isStartDay ? "rounded-l-full" : ""}
          ${isLastDay ? "rounded-r-full" : ""}
        `}
      >
        <span
          className={`w-full h-full flex items-center justify-center ${
            isStartDay || isLastDay
              ? "bg-primary-blue rounded-full text-white"
              : ""
          }`}
        >
          {day}
        </span>
      </div>
    );
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleSelectDate}
      customInput={<CustomWeekButton />}
      calendarContainer={renderCustomContainer}
      renderCustomHeader={renderCustomHeader}
      renderDayContents={renderDayContents}
      popperPlacement="bottom-start"
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      showWeekPicker
      calendarStartDay={1}
      disabledKeyboardNavigation
    />
  );
};

export default WeekPicker;
