import Overview from "@src/components/VoiceAgent/Overview/Overview";
import SupportLineView from "@src/components/VoiceAgent/SupportLineView/SupportLineView";
import VoiceAgentPageHeader from "@src/components/VoiceAgent/VoiceAgentPageHeader";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../store";
import { selectUser } from "../store/user/userReducer";

const VoiceAgent = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const user = useAppSelector(selectUser);

  const selectedSupportLine = user.customer;

  return (
    <div className="w-full h-full bg-gray-background overflow-hidden">
      <div className="grid grid-rows-tab-layout gap-6 h-full overflow-hidden">
        <VoiceAgentPageHeader
          className={
            isDesktop && isHeightSm
              ? "px-4 pt-4 height-md:px-6 height-md:pt-6"
              : "px-4 pt-4 md:px-2 md:pt-2"
          }
          selectedLine={selectedSupportLine}
        />
        {selectedSupportLine ? (
          <SupportLineView
            className={
              isDesktop && isHeightSm ? "px-4 height-md:px-6" : "px-4 md:px-2"
            }
          />
        ) : (
          <Overview
            className={
              isDesktop && isHeightSm ? "px-4 height-md:px-6" : "px-4 md:px-2"
            }
          />
        )}
      </div>
    </div>
  );
};

export default VoiceAgent;
