import type { ScribeAudioDetails } from "../store/scribeEventTypes";

interface ScribeAdminAudioDetailsProps {
  audioDetails: ScribeAudioDetails;
}

const ScribeAdminAudioDetails = ({
  audioDetails,
}: ScribeAdminAudioDetailsProps) => {
  if (!audioDetails.audio) {
    return <div>No audio details found</div>;
  }

  const formatFileSize = (size: number) => {
    if (size < 1024) {
      return `${size} bytes`;
    }

    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    }

    if (size < 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }

    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  };

  return (
    <div>
      <div className="bg-gray-50 p-6 rounded-md">
        <div className="mb-4">
          <audio controls className="w-full">
            <track kind="captions" />
            <source src={audioDetails.audio.audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>

        <div className="flex flex-col sm:flex-row sm:justify-between text-sm text-gray-500">
          <div className="mb-2 sm:mb-0">
            File size:{" "}
            {formatFileSize(audioDetails.audio.metadata.contentLength)}
          </div>
          <div>Format: {audioDetails.audio.metadata.contentType}</div>
        </div>
      </div>

      <div className="mt-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              This audio recording contains confidential information. Please
              ensure appropriate security measures when downloading or sharing.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScribeAdminAudioDetails;
