import emptyCompletedSrc from "@assets/empty-threads-completed.svg";
import { ReactComponent as LoginIcon } from "@assets/icons/login.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { debounce } from "@mui/material/utils";
import FrontDeskMetricsItem from "@src/components/Settings/CallMetrics/FrontDeskMetricsItem";
import FrontDeskMetricsTableSkeleton from "@src/components/Skeletons/FrontDeskMetricsTableSkeleton";
import AnalyticsTable from "@src/components/VoiceAgent/SupportLineView/Analytics/AnalyticsTable";
import {
  roundBigNumber,
  roundNumberToTwoDecimals,
  selectInputText,
} from "@src/helpers/helpers";
import { useImpersonateOrganizationMutation } from "@src/store/adminMetrics/api";
import type { CustomerMetrics } from "@src/store/adminMetrics/types";
import { type ChangeEvent, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

interface FrontDeskMetricsProps {
  metricsData: CustomerMetrics[];
  selectedClinicId?: number | null;
  onSelectClinic?: (clinicId: number | null) => void;
  isLoading?: boolean;
  displayCost?: boolean;
  className?: string;
}

const FrontDeskMetrics = ({
  metricsData,
  selectedClinicId,
  onSelectClinic,
  isLoading,
  className = "",
  // displayCost,
}: FrontDeskMetricsProps) => {
  const [filteredData, setFilteredData] =
    useState<CustomerMetrics[]>(metricsData);
  const [searchValue, setSearchValue] = useState("");
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const [impersonateOrganization] = useImpersonateOrganizationMutation();

  useEffect(() => {
    if (metricsData) {
      debouncedSearch(searchValue);
    } else {
      setFilteredData([]);
    }
  }, [metricsData, searchValue]);

  const columns = useMemo(
    () => [
      { label: "Clinic", accessor: "clinic" },
      { label: "Calls", accessor: "total_calls" },
      // {
      //   label: displayCost ? "Cost" : "Per min",
      //   accessor: "cost",
      //   isSortable: true,
      // },
      { label: "Minutes", accessor: "total_minutes" },
      { label: "Avg min", accessor: "avg_minutes" },
      { label: "Texts", accessor: "text" },
      { label: "Internal", accessor: "internal" },
      { label: "Review", accessor: "review" },
      { label: "Unassigned", accessor: "unassigned" },
      { label: "Completed", accessor: "completed" },
    ],
    [
      // displayCost
    ],
  );

  const data = useMemo(() => {
    const tableData = [];
    filteredData?.forEach((clinic) => {
      tableData.push({
        original: clinic,
        clinic: (
          <div className="min-w-50 max-w-70 overflow-hidden">
            <div className="flex items-center space-x-2 overflow-hidden">
              <p className="text-sm font-medium text-primary truncate">
                {clinic.customer_name}
              </p>
              {/*<span*/}
              {/*  className="w-2.5 h-2.5 rounded-full border-2 border-white/90"*/}
              {/*  style={{*/}
              {/*    backgroundColor:*/}
              {/*      clinic.customer_status === "Active"*/}
              {/*        ? "#027A48"*/}
              {/*        : clinic.customer_status === "Not Active"*/}
              {/*          ? "#8D2822"*/}
              {/*          : "#9D8B63",*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
            <div className="w-full grid items-center grid-cols-conv-layout gap-1 overflow-hidden">
              <p className="text-xs font-medium text-tertiary truncate">
                {clinic.support_email}
              </p>
              <button
                type="button"
                className="flex-none"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleLoginToClinic(clinic);
                }}
              >
                <LoginIcon width="20" height="20" />
              </button>
            </div>
          </div>
        ),
        total_calls: roundBigNumber(clinic.total_calls),
        // cost: displayCost ? clinic.cost : clinic.per_min,
        total_minutes: roundBigNumber(clinic.total_minutes),
        avg_minutes: roundNumberToTwoDecimals(clinic.avg_minutes),
        text: roundBigNumber(clinic.text),
        internal: roundBigNumber(clinic.internal),
        review: roundBigNumber(clinic.review),
        unassigned: roundBigNumber(clinic.unassigned),
        completed: roundBigNumber(clinic.completed),
      });
    });
    return tableData;
  }, [
    // displayCost,
    filteredData,
  ]);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        setFilteredData(
          metricsData.filter((clinic) =>
            clinic.customer_name
              .toLowerCase()
              .trim()
              .includes(inputValue.toLowerCase().trim()),
          ),
        );
      }, 300),
    [metricsData],
  );

  const handleClickRow = (row: { original: CustomerMetrics }) => {
    handleSelectClinic(row.original.customer_id);
  };

  const handleSelectClinic = (id: number) => {
    if (onSelectClinic) {
      if (!selectedClinicId || id !== selectedClinicId) {
        onSelectClinic(id);
      } else if (selectedClinicId && id === selectedClinicId) {
        onSelectClinic(null);
      }
    }
  };

  const handleLoginToClinic = (clinic: CustomerMetrics) => {
    impersonateOrganization(clinic.organization_id);
  };

  const isRowHighlighted = (row: { original: CustomerMetrics }) => {
    return selectedClinicId === row.original.customer_id;
  };

  return (
    <div
      className={`bg-white border border-gray-250 rounded-lg p-5 overflow-hidden grid grid-rows-tab-layout
        ${className}`}
    >
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 mb-5">
        <div className="space-y-1">
          <p className="text-base font-semibold">Front Desk Metrics</p>
          <p className="text-xs font-normal text-tertiary">
            Clinics and number of calls completed
          </p>
        </div>

        <div className="relative w-full md:max-w-90">
          <input
            name="clinic-search"
            value={searchValue}
            onClick={selectInputText}
            onChange={handleSearchInput}
            className="rounded-lg h-10 text-sm outline-none w-full pl-10 placeholder:text-gray-400 bg-gray-background"
            placeholder="Search..."
            autoComplete="clinic-search"
          />
          <SearchIcon
            className="absolute top-2.5 left-2"
            width="20"
            height="20"
          />
        </div>
      </div>

      {isLoading || !filteredData ? (
        <FrontDeskMetricsTableSkeleton rows={3} />
      ) : data?.length > 0 ? (
        isDesktop ? (
          <AnalyticsTable
            columns={columns}
            data={data}
            onClickRow={handleClickRow}
            isRowHighlighted={isRowHighlighted}
          />
        ) : (
          <div className="space-y-2">
            {filteredData.map((clinic: CustomerMetrics) => (
              <FrontDeskMetricsItem
                key={clinic.customer_id}
                clinic={clinic}
                selectedClinicId={selectedClinicId}
                onLogin={handleLoginToClinic}
                onClickItem={handleSelectClinic}
              />
            ))}
          </div>
        )
      ) : (
        <div className="flex flex-col items-center justify-center mb-6 min-h-[280px]">
          <img src={emptyCompletedSrc} alt="No data" width="440" height="200" />
          <span className="text-lg md:text-xl text-center font-semibold">
            No Front Desk Metrics
          </span>
        </div>
      )}
    </div>
  );
};

export default FrontDeskMetrics;
