import type React from "react";
import type { UseFormRegister } from "react-hook-form";
import ErrorRoundExclamation from "./ErrorRoundExclamation";

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "className" | "minLength" | "required"
  > {
  label?: string | null;
  type?: string | null;
  name?: string | null;
  disabled?: boolean;
  value?: string | number;
  required?: boolean | string;
  withoutAsterisk?: boolean;
  placeholder?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  borderColor?: string;
  disabledBgColor?: string;
  register?: UseFormRegister<any>;
  validate?: (value: string) => boolean | string;
  validationPattern?:
    | {
        value: RegExp;
        message: string;
      }
    | string;
  minLength?: {
    value: number;
    message: string;
  } | null;
  min?: number | null;
  max?: number | null;
  error?: {
    message?: string;
    type?: string;
  } | null;
  errorExclamation?: boolean;
}

const Input: React.FC<InputProps> = ({
  label = null,
  type = null,
  name = null,
  disabled = false,
  value,
  required = false,
  withoutAsterisk = false,
  placeholder = null,
  onChange,
  className = "",
  labelClassName = "mb-1.5",
  inputClassName = "h-10",
  borderColor = "gray-300",
  disabledBgColor = "gray-150",
  register = () => ({}),
  validate = () => true,
  validationPattern = "",
  minLength = null,
  min = null,
  max = null,
  error = null,
  errorExclamation = true,
  ...props
}) => {
  const registerOptions = {
    ...(required && {
      required:
        typeof required === "string" ? required : "This field is required",
    }),
    ...(validationPattern && {
      pattern:
        typeof validationPattern === "string"
          ? { value: new RegExp(validationPattern), message: "Invalid format" }
          : validationPattern,
    }),
    ...(minLength && { minLength }),
    ...(validate && { validate }),
    ...(min !== null && { min }),
    ...(max !== null && { max }),
  };

  return (
    <div
      className={`flex flex-col text-sm ${className}`}
      data-testid="input-container"
    >
      {label && (
        <p className={`font-semibold ${labelClassName}`}>
          {label}
          {required && !withoutAsterisk && (
            <span className="text-red-600" data-testid="required-asterisk">
              *
            </span>
          )}
        </p>
      )}
      <div className="relative">
        <input
          name={name || undefined}
          type={type || undefined}
          disabled={disabled}
          value={value}
          placeholder={placeholder || undefined}
          onChange={onChange}
          {...(name ? register(name, registerOptions) : {})}
          {...props}
          className={`py-2.5 px-3 rounded-lg border outline-none w-full
            ${error ? "border-red-400" : `border-${borderColor}`}
            ${disabled ? `bg-${disabledBgColor}` : "bg-white"} ${inputClassName}`}
          data-testid="input-element"
        />
        {error && errorExclamation && (
          <ErrorRoundExclamation
            className="absolute top-1/2 -translate-y-1/2 right-4"
            data-testid="error-icon"
          />
        )}
      </div>
      {error?.message && (
        <p
          className="text-red-400 font-normal mt-1.5"
          data-testid="error-message"
        >
          {error.message}
        </p>
      )}
    </div>
  );
};

export default Input;
