import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-down.svg";
import SmallNumberCard from "@src/components/Settings/CallMetrics/SmallNumberCard";
import { roundBigNumber } from "@src/helpers/helpers";
import type { CallReasonMetrics } from "@src/store/adminMetrics/types";
import { useState } from "react";

interface ReasonListItemProps {
  reason: CallReasonMetrics;
}

const ReasonListItem = ({ reason }: ReasonListItemProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="border border-gray-foreground rounded-lg p-3 flex flex-col">
      <div className="grid items-center grid-cols-conv-layout gap-2 overflow-hidden">
        <p className="text-sm font-medium truncate">{reason.category}</p>

        <button
          type="button"
          className={`w-8 h-8 flex items-center justify-center rounded-lg flex-none
            ${expanded ? "bg-primary-blue-light" : "bg-gray-110"}`}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <ArrowIcon
            width="10"
            height="7"
            className={`transition-all ${expanded ? "-rotate-180" : ""}`}
          />
        </button>
      </div>

      <div
        className={`grid grid-cols-4 gap-2 mt-2 overflow-hidden transition-all ${expanded ? "h-20" : "h-9"}`}
      >
        <SmallNumberCard
          title="Calls %"
          number={roundBigNumber(reason.call_percentage)}
        />
        <SmallNumberCard
          title="Calls"
          number={roundBigNumber(reason.total_calls)}
        />
        <SmallNumberCard
          title="Minutes"
          number={roundBigNumber(reason.total_minutes)}
        />
        <SmallNumberCard title="Avg min" number={reason.avg_minutes} />
        <SmallNumberCard title="Max min" number={reason.max_minutes} />
        <SmallNumberCard
          title="Review"
          number={roundBigNumber(reason.review_count)}
        />
        <SmallNumberCard
          title="Unassigned"
          number={roundBigNumber(reason.unassigned)}
        />
        <SmallNumberCard
          title="Completed"
          number={roundBigNumber(reason.completed_count)}
        />
      </div>
    </div>
  );
};

export default ReasonListItem;
