import type { ReactNode } from "react";

interface MessageContentProps {
  message: string;
  statusText?: ReactNode;
}

const MessageContent = ({ message, statusText }: MessageContentProps) => {
  const parseMentions = (text: string) => {
    if (!text) {
      return "";
    }

    const mentionRegex = /@\[(.*?)]\((?:user|team|doctor)-\d+\)/g;
    const parts = text.split(mentionRegex);

    return (
      <span>
        {parts.map((part, index) => {
          const isMention = index % 2 === 1;
          if (isMention) {
            return (
              <span
                key={index}
                className="text-primary-blue bg-primary-blue-light"
              >
                @{part}
              </span>
            );
          }
          return part;
        })}
      </span>
    );
  };

  return (
    <div className="whitespace-pre-wrap leading-5">
      {parseMentions(message)}
      <div className="text-xxs text-zinc-450 whitespace-nowrap flex float-right items-end -mb-1 -mr-1 mt-1 ml-1.5">
        {statusText}
      </div>
    </div>
  );
};

export default MessageContent;
