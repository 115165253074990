import { createSelector } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import type { RootState } from "..";
import { filtersClear, patientPanelTabs } from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import {
  ADD_FAILED_MESSAGE,
  ADD_SENT_MESSAGE,
  COLLAPSE_SIDEBAR,
  EXPAND_PANEL,
  LOGOUT,
  REMOVE_FAILED_MESSAGE,
  REMOVE_SENT_MESSAGE,
  SET_ACTIVE_FILTERS,
  SET_CAMPAIGN_OPTIONS,
  SET_CLOSED_RESPONSES,
  SET_COLLAPSE_LIST,
  SET_CONVERSATIONS_SCROLL_POSITION,
  SET_CONVERSATIONS_UPDATED,
  SET_CONV_CURR_PAGE,
  SET_FILTERS_UPDATED,
  SET_INIT_FILTERS,
  SET_MESSAGES,
  SET_NEXT_CONVERSATION_ID,
  SET_SEARCH_INPUT,
  SET_SELECTED_CONVERSATION_PATIENT,
  SET_SELECTED_CONVERSATION_TAB,
  SET_SENT_MESSAGES,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  panelExpanded: false,
  sidebarCollapsed: false,
  nextConversationId: "",
  currentConversation: storageService.getItem("currentConversation") ?? null,
  convCurrPage: 1,
  collapsePatientList: storageService.getItem("collapsePatientList") ?? false,
  activeFilters: storageService.getItem("newInboxFilters") ?? filtersClear,
  initFilters: null,
  campaignFilterOptions: [],
  conversationSearchInput:
    storageService.getItem("conversationSearchInput") ?? "",
  conversationsScrollPosition: 0,
  sentMessages: {},
  failedMessages: storageService.getItem("failedMessages") ?? {},
  closedResponses: storageService.getItem("closedResponses") ?? [],
  noteHtmlMarkups: {},
  conversationsUpdated: true,
  filtersUpdated: true,
  selectedConversationTab:
    storageService.getItem("selectedConversationTab") ??
    patientPanelTabs.MESSAGE,
  messages: {},
  selectedConversationPatient: null,
};

export default function conversationReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_CONVERSATIONS_SCROLL_POSITION:
      return {
        ...state,
        conversationsScrollPosition: payload.conversationsScrollPosition,
      };
    case SET_CONV_CURR_PAGE:
      return { ...state, convCurrPage: payload.convCurrPage };
    case SET_NEXT_CONVERSATION_ID:
      return { ...state, nextConversationId: payload.conversationId };
    case EXPAND_PANEL:
      return { ...state, panelExpanded: payload.state };
    case COLLAPSE_SIDEBAR:
      return { ...state, sidebarCollapsed: payload.state };
    case SET_COLLAPSE_LIST:
      storageService.setItem("collapsePatientList", !state.collapsePatientList);
      return { ...state, collapsePatientList: payload.state };
    case SET_ACTIVE_FILTERS:
      if (isEqual(state.activeFilters, payload.activeFilters)) {
        return state;
      }

      storageService.setItem("newInboxFilters", payload.activeFilters);
      return { ...state, activeFilters: payload.activeFilters };
    case SET_INIT_FILTERS:
      if (isEqual(state.initFilters, payload.initFilters)) {
        return state;
      }

      return { ...state, initFilters: payload.initFilters };
    case SET_CAMPAIGN_OPTIONS:
      if (isEqual(state.campaignFilterOptions, payload.campaignFilterOptions)) {
        return state;
      }

      return { ...state, campaignFilterOptions: payload.campaignFilterOptions };
    case SET_SEARCH_INPUT:
      storageService.setItem(
        "conversationSearchInput",
        payload.conversationSearchInput,
      );
      return {
        ...state,
        conversationSearchInput: payload.conversationSearchInput,
      };
    case SET_SENT_MESSAGES:
      return { ...state, sentMessages: payload.sentMessages };
    case ADD_SENT_MESSAGE:
      return addSentMessage(state, payload);
    case REMOVE_SENT_MESSAGE:
      return removeSentMessage(state, payload);
    case ADD_FAILED_MESSAGE:
      return addFailedMessage(state, payload);
    case REMOVE_FAILED_MESSAGE:
      return removeFailedMessage(state, payload);
    case SET_CLOSED_RESPONSES:
      return setClosedResponses(state, payload);
    case SET_CONVERSATIONS_UPDATED:
      return { ...state, conversationsUpdated: payload.conversationsUpdated };
    case SET_FILTERS_UPDATED:
      return { ...state, filtersUpdated: payload.filtersUpdated };
    case SET_SELECTED_CONVERSATION_TAB:
      storageService.setItem(
        "selectedConversationTab",
        payload.selectedConversationTab,
      );
      return {
        ...state,
        selectedConversationTab: payload.selectedConversationTab,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: payload.messages,
      };

    case SET_SELECTED_CONVERSATION_PATIENT:
      return {
        ...state,
        selectedConversationPatient: payload.selectedConversationPatient,
      };
    default:
      return state;
  }
}

const addSentMessage = (
  state,
  payload: {
    message: string;
    conversationId: number;
    isInternal: boolean;
    idempotencyKey: string;
  },
) => {
  return state.sentMessages[payload.conversationId]
    ? {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]: [
            ...state.sentMessages[payload.conversationId],
            {
              message: payload.message,
              isInternal: payload.isInternal ? 1 : 0,
              idempotencyKey: payload.idempotencyKey,
            },
          ],
        },
      }
    : {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [payload.conversationId]: [
            {
              message: payload.message,
              isInternal: payload.isInternal ? 1 : 0,
              idempotencyKey: payload.idempotencyKey,
            },
          ],
        },
      };
};

const removeSentMessage = (
  state,
  payload: {
    conversationId: number;
    idempotencyKey: string;
  },
) => {
  const { conversationId, idempotencyKey } = payload;
  return state.sentMessages[conversationId] &&
    state.sentMessages[conversationId].length > 0
    ? {
        ...state,
        sentMessages: {
          ...state.sentMessages,
          [conversationId]: state.sentMessages[conversationId].filter(
            (message) => message.idempotencyKey !== idempotencyKey,
          ),
        },
      }
    : state;
};

const addFailedMessage = (
  state,
  payload: {
    message: string;
    conversationId: number;
    isInternal: boolean;
    idempotencyKey: string;
  },
) => {
  const content = {
    message: payload.message,
    isInternal: payload.isInternal ? 1 : 0,
    idempotencyKey: payload.idempotencyKey,
  };

  const newState = state.failedMessages[payload.conversationId]
    ? {
        ...state,
        failedMessages: {
          ...state.failedMessages,
          [payload.conversationId]: [
            ...state.failedMessages[payload.conversationId],
            content,
          ],
        },
      }
    : {
        ...state,
        failedMessages: {
          ...state.failedMessages,
          [payload.conversationId]: [content],
        },
      };

  storageService.setItem("failedMessages", newState.failedMessages);

  return newState;
};

const removeFailedMessage = (
  state,
  payload: {
    conversationId: number;
    idempotencyKey: string;
  },
) => {
  const newState = {
    ...state,
    failedMessages: {
      ...state.failedMessages,
      [payload.conversationId]: state.failedMessages[
        payload.conversationId
      ].filter((message) => message.idempotencyKey !== payload.idempotencyKey),
    },
  };

  storageService.setItem(
    "failedMessages",
    JSON.stringify(newState.failedMessages),
  );

  return newState;
};

const setClosedResponses = (
  state,
  payload: {
    conversationId: number;
  },
) => {
  const newState = {
    ...state,
    closedResponses: [...state.closedResponses, payload.conversationId],
  };
  storageService.setItem("closedResponses", newState.closedResponses);
  return newState;
};

export const getClosedResponses = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.closedResponses,
);

export const getMessages = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.messages,
);

// const conversationSearchInput = useAppSelector(
//   (state) => state.conversation.conversationSearchInput,
// );
// const activeFilters = useAppSelector(
//   (state) => state.conversation.activeFilters,
// );
// const convCurrPage = useAppSelector(
//   (state) => state.conversation.convCurrPage,
// );
// const conversationsUpdated = useAppSelector(
//   (state) => state.conversation.conversationsUpdated,
// );

export const getConversationSearchInput = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.conversationSearchInput,
);

export const getActiveFilters = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.activeFilters,
);

export const getConvCurrPage = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.convCurrPage,
);

export const getConversationsUpdated = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.conversationsUpdated,
);

export const getSelectedConversationPatient = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.selectedConversationPatient,
);

export const getSelectedConversationId = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.selectedConversationId,
);

export const getSelectedConversationTab = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.selectedConversationTab,
);

export const getConversationSentMessages = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.sentMessages,
);

export const getConversationFailedMessages = createSelector(
  (state: RootState) => state.conversation,
  (conversation) => conversation.failedMessages,
);
