import { useUrlState } from "@src/hooks/useUrlState";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import ScribeNoteSkeleton from "../../Skeletons/ScribeNoteSkeleton";
import ScribeViewDetailsFooter from "../ScribeViewDetailsFooter";
import { getScribeNote } from "../store/thunks";

const ScribeViewDetailsSkeleton = () => {
  const { value: selectedScribeId } = useUrlState("scribeId", null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getScribeNote(selectedScribeId));
  }, [dispatch, selectedScribeId]);

  return (
    <div className="w-full h-full min-h-screen flex flex-col md:justify-between px-4 md:px-0 pt-5 pb-18">
      <ScribeNoteSkeleton className="h-full overflow-hidden" />
      <ScribeViewDetailsFooter />
    </div>
  );
};

export default ScribeViewDetailsSkeleton;
