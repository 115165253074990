import type { Dispatch } from "@reduxjs/toolkit";
import type { RootState } from "..";
import { callApi } from "./api";
import {
  type CallNextCursor,
  type CallSearchParams,
  CallStatusTab,
} from "./interfaces";

/**
 * Updates all relevant queries in the RTK Query cache when moving a conversation
 * between statuses. Returns a patch result that can be undone if needed.
 */
export const updateCacheForMovedConversation = (
  conversationId: number,
  fromStatus: CallStatusTab,
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  // Start with a performance measurement
  const startTime = performance.now();
  const isDev = process.env.NODE_ENV !== "production";

  // Get the API from RTK Query
  const api = getState().api;

  // Find relevant query keys efficiently based on fromStatus
  const queryKeys = Object.keys(api.queries).filter((key) =>
    key.startsWith(`getCalls-${fromStatus}-`),
  );

  if (isDev) {
    console.log(
      `Processing ${queryKeys.length} ${fromStatus} queries for conversation ${conversationId}`,
    );
  }

  // Store all patch results
  const patchResults = [];

  // Process each relevant query
  queryKeys.forEach((key) => {
    const queryState = api.queries[key];

    // Skip if no data or not initialized
    if (!queryState?.data || queryState.status !== "fulfilled") {
      return;
    }

    // Create the update action
    const updateAction = callApi.util.updateQueryData(
      "getCalls",
      queryState.originalArgs as {
        status: CallStatusTab;
        params: CallSearchParams;
        cursor: CallNextCursor;
      },
      (draft) => {
        // Find the conversation to remove
        const indexToRemove = draft.data.findIndex(
          (item) => item.items[0].data.conversation_id === conversationId,
        );

        // Remove if found
        if (indexToRemove !== -1) {
          draft.data.splice(indexToRemove, 1);
        }
      },
    );

    // Dispatch and store the result
    // We need to use `as any` to bypass the TypeScript error
    const patchResult = dispatch(updateAction as any);
    patchResults.push(patchResult);
  });

  // Find and update the destination status queries to ensure no duplicates occur
  const toStatus =
    fromStatus === CallStatusTab.Pending
      ? CallStatusTab.Completed
      : CallStatusTab.Pending;
  const destinationQueryKeys = Object.keys(api.queries).filter((key) =>
    key.startsWith(`getCalls-${toStatus}-`),
  );

  if (isDev) {
    console.log(
      `Ensuring no duplicates in ${destinationQueryKeys.length} ${toStatus} queries for conversation ${conversationId}`,
    );
  }

  destinationQueryKeys.forEach((key) => {
    const queryState = api.queries[key];

    // Skip if no data or not initialized
    if (!queryState?.data || queryState.status !== "fulfilled") {
      return;
    }

    // Create the update action to remove any existing instance
    const updateAction = callApi.util.updateQueryData(
      "getCalls",
      queryState.originalArgs as {
        status: CallStatusTab;
        params: CallSearchParams;
        cursor: CallNextCursor;
      },
      (draft) => {
        // Check if conversation already exists in this list
        const existingIndex = draft.data.findIndex(
          (item) => item.items[0].data.conversation_id === conversationId,
        );

        // Remove if found to prevent duplication when server response arrives
        if (existingIndex !== -1) {
          draft.data.splice(existingIndex, 1);
          // Don't decrement total as it will be added back by the server response
        }
      },
    );

    // Dispatch but don't add to patchResults since we don't want to undo this
    dispatch(updateAction as any);
  });

  // Log performance in development
  if (isDev) {
    const endTime = performance.now();
    console.log(`Cache update completed in ${endTime - startTime}ms`);
  }

  // Return a combined patch result
  return {
    undo: () => {
      // Undo all patches in reverse order
      for (let i = patchResults.length - 1; i >= 0; i--) {
        patchResults[i].undo();
      }
    },
  };
};

export function serializeCallQueryArgs(queryArgs: {
  status: string;
  params: CallSearchParams;
}): string {
  const { status, params } = queryArgs;
  const filteredParams = Object.entries(params)
    .filter(([_key, value]) => value !== null)
    .sort(([a], [b]) => a.localeCompare(b));

  return `getCalls-${status}-${JSON.stringify(Object.fromEntries(filteredParams))}`;
}
