import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-right.svg";
import { ReactComponent as LoginIcon } from "@assets/icons/login.svg";
import LocalStorageService from "@src/services/LocalStorageService";
import { useAppSelector } from "@src/store";
import { selectUser } from "@src/store/user/userReducer";

const storageService = new LocalStorageService();

const OrganizationTopBar = () => {
  const user = useAppSelector(selectUser);

  const handleBackToCallMetrics = () => {
    const prevToken = storageService.getItem("prevToken");
    storageService.clear();
    storageService.setItem("token", prevToken);
    storageService.setItem("redirectToCallMetrics", "true");
    window.location.reload();
  };

  const handleLoginAsOrganization = () => {
    storageService.removeItem("prevToken");
    window.location.reload();
  };

  return (
    <div
      className="h-10 bg-primary-blue w-full text-white text-sm font-semibold relative z-10
        flex items-center justify-between px-4 space-x-3 overflow-hidden"
    >
      <button
        type="button"
        onClick={handleBackToCallMetrics}
        className="flex items-center space-x-2 truncate"
      >
        <ArrowIcon width="20" height="20" fill="white" className="rotate-180" />
        <p className="truncate">Back to Call Metrics</p>
      </button>
      <button
        type="button"
        onClick={handleLoginAsOrganization}
        className="flex items-center space-x-2 truncate"
      >
        <LoginIcon width="20" height="20" stroke="white" />
        <p className="truncate">Login as {user.organization.name}</p>
      </button>
    </div>
  );
};

export default OrganizationTopBar;
