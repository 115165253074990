interface ScribeAdminTranscriptionProps {
  audioId: string;
  content: string;
  wordCount: number;
  generatedDate: string;
}

const ScribeAdminTranscription = ({
  audioId,
  content,
  wordCount,
  generatedDate,
}: ScribeAdminTranscriptionProps) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(content);
  };

  const downloadTranscription = () => {
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `transcription-${audioId}.txt`;
    a.click();
  };

  return (
    <div>
      <div className="flex justify-between mb-4">
        <div>
          <button
            type="button"
            className="bg-white mr-2 py-1 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4
              font-medium text-gray-700 hover:bg-gray-50"
            onClick={copyToClipboard}
          >
            Copy
          </button>
          <button
            type="button"
            className="bg-white py-1 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium
             text-gray-700 hover:bg-gray-50"
            onClick={downloadTranscription}
          >
            Download
          </button>
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded-md h-96 overflow-y-auto">
        <pre className="text-sm text-gray-800 whitespace-pre-wrap font-mono">
          {content}
        </pre>
      </div>

      <div className="mt-4 text-sm text-gray-500">
        Transcription generated on {generatedDate} | Word count: {wordCount}
      </div>
    </div>
  );
};

export default ScribeAdminTranscription;
