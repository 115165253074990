import Bugsnag from "@bugsnag/js";
import type { FixLater } from "@src/components/IndependentScribe/store/scribeSlice";
import { handleValidation } from "@src/helpers/commonValidationSchemas";
import { rowsPerPage } from "@src/helpers/constants";
import { formatISOToCustom, handleRequestError } from "@src/helpers/helpers";
import type { RootState } from "..";
import { baseApi, validateResponse } from "../baseApi";
import {
  addFailedMessageAction,
  removeSentMessageAction,
  setSentMessagesAction,
} from "../conversation/actions";
import { conversationSchema } from "../conversation/validationSchemas";
import { setNotificationAction } from "../user/actions";
import type { Team } from "../user/userReducer";
import {
  serializeCallQueryArgs,
  updateCacheForMovedConversation,
} from "./apiHelper";
import {
  type AssignedUser,
  type CallCounts,
  type CallNextCursor,
  type CallNote,
  type CallSearchParams,
  CallStatusTab,
  type Conversation,
  type SearchCallResponse,
  type SearchCallResponseWithDetails,
  SortDirection,
} from "./interfaces";
import { mergeInboxItems } from "./sortingUtils";
import {
  callCountsSchema,
  callNoteSchema,
  searchCallResponseSchema,
  searchCallResponseWithDetailsSchema,
} from "./validationSchemas";

const defaultSearchParams: CallSearchParams = {
  q: "",
  categories: [],
  is_new_user: false,
  is_urgent: false,
  is_external: false,
  is_care_team: false,
  limit: rowsPerPage,
  sort_direction: SortDirection.Desc,
  assigned_to_me: 0,
  team_id: null,
};

export const callApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    searchCall: builder.query<
      SearchCallResponseWithDetails,
      { params: { conversation_id: number } }
    >({
      query: ({ params }) => {
        return {
          url: "/inbox/search/conversation",
          method: "POST",
          data: { conversation_id: params.conversation_id },
        };
      },
      transformResponse: (response: SearchCallResponseWithDetails) => {
        const validatedResponse = validateResponse(
          searchCallResponseWithDetailsSchema,
        )(response) as SearchCallResponseWithDetails;

        return validatedResponse;
      },
    }),

    getCalls: builder.query<
      SearchCallResponse,
      {
        status: CallStatusTab;
        cursor: CallNextCursor;
        params: CallSearchParams;
      }
    >({
      query: ({ status, cursor, params }) => {
        const searchParams = {
          ...defaultSearchParams,
          ...params,
          cursor,
        };

        const requestStatus =
          status === CallStatusTab.Pending ? "review" : "completed";

        return {
          url: `/inbox/search/${requestStatus}`,
          method: "POST",
          data: searchParams,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { status, params } = queryArgs;

        return serializeCallQueryArgs({ status, params });
      },
      merge: (
        currentCache: SearchCallResponse,
        newItems: SearchCallResponse,
        { arg: queryArg },
      ) => {
        const isCompletedInbox = queryArg.status === CallStatusTab.Completed;
        const isPollRequest = queryArg.cursor === -1;

        const updatedInbox = mergeInboxItems(
          currentCache.data,
          newItems.data,
          isCompletedInbox,
          newItems as SearchCallResponseWithDetails,
        );

        const nextCursor =
          isPollRequest && currentCache.next_cursor > newItems.next_cursor
            ? currentCache.next_cursor
            : newItems.next_cursor;

        return {
          data: updatedInbox,
          next_cursor: nextCursor,
          status: newItems.status,
        };
      },
      transformResponse: (response: {
        data: unknown;
        next_cursor: unknown;
        status: string;
      }) => {
        const validatedResponse = validateResponse(searchCallResponseSchema)(
          response,
        ) as SearchCallResponse;

        return validatedResponse;
      },
      providesTags: (result, _, { status, params }) =>
        result
          ? [
              { type: "Call" as const, status, id: "LIST" },
              ...result.data.map(({ items }) => ({
                type: "Call" as const,
                id: items[0].data.conversation_id,
                status,
                params,
              })),
            ]
          : [{ type: "Call" as const, status, id: "LIST", params }],
    }),

    getCallCounts: builder.query<CallCounts, number>({
      query: (siteId) => ({
        url: `/inbox/${siteId}/count`,
        method: "GET",
      }),
      providesTags: (_, __, siteId) => [
        {
          type: "Call",
          subType: "Counts",
          id: siteId,
        },
      ],
      transformResponse: (response) => {
        return handleValidation(callCountsSchema, response, "getCallCounts");
      },
    }),

    getConversation: builder.query<Conversation, number>({
      query: (id) => ({
        url: `/conversation/${id}`,
      }),
      transformResponse: (response: {
        data: { conversation: Conversation };
      }) => {
        const conversation = response.data.conversation;

        handleValidation(
          conversationSchema,
          conversation,
          `getConversation (${conversation.conversation_id})`,
        );

        return conversation;
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        const { data } = await queryFulfilled;

        const state = getState() as RootState;
        const sentMessages = JSON.parse(
          JSON.stringify(state.conversation.sentMessages),
        ) as {
          [conversationId: number]: {
            message: string;
            isInternal: boolean;
            idempotencyKey: string;
          }[];
        };
        const messages = sentMessages[id] ?? [];

        for (const message of messages) {
          if (
            data.messages.find(
              (incomingMessage) =>
                incomingMessage.type === "text" &&
                incomingMessage.message?.idempotency_key ===
                  message.idempotencyKey,
            )
          ) {
            sentMessages[id] = sentMessages[id].filter(
              (pendingMessage) =>
                pendingMessage.idempotencyKey !== message.idempotencyKey,
            );
          }
        }

        dispatch(setSentMessagesAction(sentMessages));
      },
      providesTags: (_, __, id) => [{ type: "Conversation", id }],
    }),

    getCallNotes: builder.query<CallNote[], number>({
      query: (assistantCallId) => ({
        url: `/call/note/${assistantCallId}`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load call notes");
        };
        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: { data: { notes: CallNote[] } }) => {
        const notes = response.data.notes;

        handleValidation(callNoteSchema.array(), notes, "getCallNotes");

        return notes as CallNote[];
      },

      // providesTags: (result, _, id) =>
      //   result ? [{ type: "CallNote", id }] : [{ type: "CallNote", id }],

      providesTags: (result = [], _, assistantCallId) => [
        { type: "CallNote", id: assistantCallId },
        ...result.map((note) => ({
          type: "CallNote" as const,
          id: `${assistantCallId}-${note.assistant_call_note_id}`,
        })),
      ],
    }),

    addCallNote: builder.mutation<
      CallNote,
      { assistantCallId: number; note: string; doctorId: number }
    >({
      query: ({ assistantCallId, note }) => ({
        url: `/call/note/create/${assistantCallId}`,
        method: "POST",
        data: { note },
      }),
      async onQueryStarted(
        { assistantCallId, note, doctorId },
        { dispatch, queryFulfilled },
      ) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to add note to the call");
        };

        const tempId = -Date.now();
        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getCallNotes",
            assistantCallId,
            (draft) => {
              draft.push({
                assistant_call_note_id: tempId,
                assistant_call_id: assistantCallId,
                note,
                created_at: formatISOToCustom(new Date().toISOString()),
                created_by: doctorId,
                updated_at: formatISOToCustom(new Date().toISOString()),
                updated_by: doctorId,
              });
            },
          ),
        );

        try {
          const { data: createdNote } = await queryFulfilled;

          dispatch(
            callApi.util.updateQueryData(
              "getCallNotes",
              assistantCallId,
              (draft) => {
                const index = draft.findIndex(
                  (note) => note.assistant_call_note_id === tempId,
                );
                if (index !== -1) {
                  draft[index] = createdNote;
                }
              },
            ),
          );
        } catch (error) {
          onError(error);
          patchResult.undo();
        }
      },
      transformResponse: (response: {
        data: { assistant_call_note: CallNote };
      }) => {
        handleValidation(
          callNoteSchema,
          response.data.assistant_call_note,
          "addCallNote",
        );
        return response.data.assistant_call_note;
      },
      invalidatesTags: (_result, _error, { assistantCallId }) => [
        { type: "CallNote", id: assistantCallId },
      ],
    }),

    updateCallNote: builder.mutation<
      void,
      { assistantCallId: number; assistantCallNoteId: number; note: string }
    >({
      query: ({ assistantCallNoteId, note }) => ({
        url: `/call/note/update/${assistantCallNoteId}`,
        method: "POST",
        data: { note },
      }),
      async onQueryStarted(
        { assistantCallId, assistantCallNoteId, note },
        { dispatch, queryFulfilled },
      ) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to update note");
        };

        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getCallNotes",
            assistantCallId,
            (draft) => {
              const index = draft.findIndex(
                (note) => note.assistant_call_note_id === assistantCallNoteId,
              );
              if (index !== -1) {
                draft[index].note = note;
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
          patchResult.undo();
        }
      },
      invalidatesTags: (_, { assistantCallId, assistantCallNoteId }) => [
        { type: "CallNote", id: `${assistantCallId}-${assistantCallNoteId}` },
      ],
    }),

    deleteCallNote: builder.mutation<
      void,
      { assistantCallId: number; assistantCallNoteId: number }
    >({
      query: ({ assistantCallNoteId }) => ({
        url: `/call/note/delete/${assistantCallNoteId}`,
        method: "POST",
      }),
      async onQueryStarted(
        { assistantCallId, assistantCallNoteId },
        { dispatch, queryFulfilled },
      ) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to delete note");
        };

        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getCallNotes",
            assistantCallId,
            (draft) => {
              const index = draft.findIndex(
                (note) => note.assistant_call_note_id === assistantCallNoteId,
              );
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
          patchResult.undo();
        }
      },
      invalidatesTags: (_, { assistantCallId }) => [
        { type: "CallNote", id: assistantCallId },
      ],
    }),

    assignPatientToProviderOrTeam: builder.mutation<
      { message: string },
      {
        conversation: Conversation;
        assignTo: {
          provider: AssignedUser | null;
          team: Team | null;
        };
      }
    >({
      query: ({ conversation, assignTo }) => ({
        url: `/inbox/assign/${conversation.patient.patient_id}`,
        method: "POST",
        data: {
          assign_to_id: assignTo.provider?.doctor_id ?? null,
          assign_to_team_id: assignTo.team?.doctor_team_id ?? null,
        },
      }),
      async onQueryStarted(
        { conversation, assignTo },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getConversation",
            Number(conversation.conversation_id),
            (draft) => {
              draft.assigned_to = assignTo.provider;
              draft.assigned_to_team = assignTo.team;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { conversation }) => [
        "Call",
        {
          type: "Conversation",
          id: conversation.conversation_id,
        },
      ],
    }),

    moveToReview: builder.mutation<void, { conversation: Conversation }>({
      query: ({ conversation }) => ({
        url: `/inbox/move-to-review/${conversation.patient.patient_id}`,
        method: "POST",
      }),
      async onQueryStarted(
        { conversation },
        { dispatch, queryFulfilled, getState },
      ) {
        const cachePatch = updateCacheForMovedConversation(
          conversation.conversation_id,
          CallStatusTab.Completed,
          dispatch,
          getState as () => RootState,
        );

        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getConversation",
            Number(conversation.conversation_id),
            (draft) => {
              draft.completed = 0;
            },
          ),
        );

        // const countPatchResult = dispatch(
        //   callApi.util.updateQueryData("getCallCounts", undefined, (draft) => {
        //     draft.total_completed -= 1;
        //     // draft.total_reviews += 1;
        //   }),
        // );

        try {
          await queryFulfilled;
        } catch {
          cachePatch.undo();
          patchResult.undo();
          // countPatchResult.undo();
        }
      },
      invalidatesTags: (_, __, { conversation }) => [
        {
          type: "Call",
          id: "LIST",
        },
        {
          type: "Conversation",
          id: conversation.conversation_id,
        },
      ],
    }),

    moveToCompleted: builder.mutation<
      void,
      { patientId: number; conversationId: number }
    >({
      query: ({ patientId }) => ({
        url: `/inbox/complete/${patientId}`,
        method: "POST",
      }),
      async onQueryStarted(
        { conversationId },
        { dispatch, queryFulfilled, getState },
      ) {
        const cachePatch = updateCacheForMovedConversation(
          conversationId,
          CallStatusTab.Pending,
          dispatch,
          getState as () => RootState,
        );

        const patchResult = dispatch(
          callApi.util.updateQueryData(
            "getConversation",
            Number(conversationId),
            (draft) => {
              draft.completed = 1;
            },
          ),
        );

        // const countPatchResult = dispatch(
        //   callApi.util.updateQueryData("getCallCounts", undefined, (draft) => {
        //     draft.total_completed += 1;
        //     draft.total_reviews -= 1;
        //   }),
        // );

        try {
          await queryFulfilled;
        } catch {
          cachePatch.undo();
          patchResult.undo();
          // countPatchResult.undo();
        }
      },
      invalidatesTags: (_, __, { conversationId }) => [
        {
          type: "Call",
          id: "LIST",
        },
        {
          type: "Conversation",
          id: conversationId,
        },
      ],
    }),

    sendMessage: builder.mutation<
      void,
      {
        message: string;
        conversationId: number;
        isInternal: boolean;
        idempotencyKey: string;
      }
    >({
      query: ({ message, conversationId, isInternal, idempotencyKey }) => ({
        url: "/message/send",
        method: "POST",
        data: {
          conversation_id: conversationId,
          message,
          is_internal: isInternal,
          idempotencyKey,
          sendAt: new Date().toISOString(),
        },
      }),
      async onQueryStarted(
        { message, conversationId, isInternal, idempotencyKey },
        { dispatch, queryFulfilled, getState },
      ) {
        const onError = (error: any) => {
          // Log complete error for debugging
          console.error("Send message error:", error);

          // Extract error details for user notification
          let errorDetails = "Failed to send message";

          // Try to access error response data
          if (error && typeof error === "object") {
            // For axios errors with response data
            if (error.response?.data) {
              const responseData =
                typeof error.response.data === "object"
                  ? JSON.stringify(error.response.data)
                  : error.response.data;
              errorDetails = `Failed to send message: ${responseData}`;
              console.error("Backend error details:", error.response.data);
            } else if (error.message) {
              // Use error message if no response data
              errorDetails = `Failed to send message: ${error.message}`;
            }
          }

          dispatch(removeSentMessageAction(conversationId, idempotencyKey));
          dispatch(
            addFailedMessageAction(
              message,
              conversationId,
              isInternal,
              idempotencyKey,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: errorDetails,
            }),
          );
          Bugsnag.notify(error);
        };

        const state = getState() as RootState;
        const sentMessages = JSON.parse(
          JSON.stringify(state.conversation.sentMessages),
        ) as {
          [conversationId: number]: {
            message: string;
            isInternal: boolean;
            idempotencyKey: string;
          }[];
        };

        if (!sentMessages[conversationId]) {
          sentMessages[conversationId] = [];
        }

        sentMessages[conversationId].push({
          message,
          isInternal,
          idempotencyKey,
        });

        dispatch(setSentMessagesAction(sentMessages));

        try {
          const { data }: { data: FixLater } = await queryFulfilled;

          if (data.error) {
            console.log("data.error", data.error);
            onError(data.error);
          }
        } catch (error) {
          // Log the complete error for diagnosis
          console.error("Send message failed:", error);

          // Log error response specifically
          if (error?.response) {
            console.error("Error response:", error.response);
            if (error.response.data) {
              console.error("Response data:", error.response.data);
            }
          }

          onError(error);
        }
      },
      invalidatesTags: (_, __, { conversationId, isInternal }) => {
        const list: FixLater = [
          {
            type: "Conversation",
            id: conversationId,
          },
        ];

        if (isInternal) {
          list.push({
            type: "Call",
            id: "LIST",
          });

          list.push({
            type: "Call",
            subType: "Counts",
          });
        }

        return list;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCallsQuery,
  useGetConversationQuery,
  useGetCallCountsQuery,
  useGetCallNotesQuery,
  useAddCallNoteMutation,
  useUpdateCallNoteMutation,
  useDeleteCallNoteMutation,
  useAssignPatientToProviderOrTeamMutation,
  useMoveToReviewMutation,
  useMoveToCompletedMutation,
  useSendMessageMutation,
} = callApi;
