import emptyTeamsIllustrationSrc from "@assets/empty-teams.svg";
import { ReactComponent as EditIcon } from "@assets/icons/pen.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/trash-redesign.svg";
import { ReactComponent as PatientHeartIcon } from "@assets/icons/user-heart-rounded.svg";
import { debounce } from "@mui/material/utils";
import ActionsButton from "@src/components/Basic/ActionsButton";
import Button from "@src/components/Basic/Button";
import DeleteModal from "@src/components/Basic/DeleteModal";
import UserTable from "@src/components/Basic/UserTable";
import PhoneNumbersDropdownButton from "@src/components/Settings/Clinic/PhoneNumbersDropdownButton";
import SiteModal from "@src/components/Settings/Clinic/SiteModal";
import SettingsContainer from "@src/components/Settings/SettingsContainer";
import SiteListItem from "@src/components/Settings/SiteListItem";
import SitesTableSkeleton from "@src/components/Skeletons/SitesTableSkeleton";
import { getFormattedPhoneNumber, selectInputText } from "@src/helpers/helpers";
import { useAppSelector } from "@src/store";
import { selectUser } from "@src/store/user/userReducer";
import {
  useCreateSiteMutation,
  useDeleteSiteMutation,
  useGetSitesQuery,
  useUpdateSiteMutation,
} from "@src/store/voiceAgent/api";
import type { BasicSite, DirectPhoneNumber } from "@src/store/voiceAgent/types";
import { useCallback, useEffect, useMemo, useState } from "react";

const newSite: BasicSite = {
  direct_phone_numbers: [],
  name: "",
  is_default: false,
  assistant_settings: {
    clinic_address: "",
    clinic_hours: "",
    faq: "",
  },
};

const ClinicSettings = () => {
  const user = useAppSelector(selectUser);
  const { data: sites, isLoading: isLoadingSites } = useGetSitesQuery();
  const [createSite] = useCreateSiteMutation();
  const [updateSite] = useUpdateSiteMutation();
  const [deleteSite] = useDeleteSiteMutation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredSites, setFilteredSites] = useState(sites);
  const [selectedSite, setSelectedSite] = useState(null);
  const [openSiteModal, setOpenSiteModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        setFilteredSites(
          sites.filter((site) =>
            site.name.toLowerCase().includes(inputValue.toLowerCase()),
          ),
        );
      }, 300),
    [sites],
  );

  useEffect(() => {
    if ((!sites && !isLoadingSites) || sites?.length === 0) {
      setFilteredSites([]);
    } else if (sites) {
      debouncedSearch(searchValue);
    }
  }, [sites, isLoadingSites, searchValue, debouncedSearch]);

  const columns = [
    { label: "Site name", accessor: "name" },
    { label: "Number of patients", accessor: "total_patients" },
    { label: "Forwarding numbers", accessor: "direct_phone_numbers" },
    { label: "Address", accessor: "clinic_address" },
    { label: "", accessor: "actions" },
  ];

  const sitesTableData = useMemo(() => {
    const tableData = [];

    if (filteredSites?.length > 0) {
      filteredSites.forEach((site) =>
        tableData.push({
          name: (
            <div className="flex items-center space-x-2 max-w-55">
              <p className="font-bold truncate">{site.name}</p>
              {!!site.is_default && (
                <div className="text-xs text-tertiary font-medium bg-gray-140 rounded px-2 py-1">
                  Default
                </div>
              )}
            </div>
          ),
          total_patients: (
            <div
              className="text-xs font-bold text-tertiary px-2 py-1 bg-slate-100 rounded-full
                w-20 flex items-center justify-center space-x-1 border border-gray-foreground"
            >
              <PatientHeartIcon width="16" height="16" stroke="#667085" />
              <p>{site.total_patients}</p>
            </div>
          ),
          direct_phone_numbers: (
            <div className="flex items-center space-x-2">
              <p>
                {getFormattedPhoneNumber(
                  site.direct_phone_numbers[0]?.phone_number,
                ) || "-"}
              </p>
              {site.direct_phone_numbers?.length > 1 && (
                <PhoneNumbersDropdownButton
                  phoneNumbers={site.direct_phone_numbers}
                />
              )}
            </div>
          ),
          clinic_address: (
            <p className="max-w-55 truncate">
              {site.assistant_settings?.clinic_address || "-"}
            </p>
          ),
          actions:
            site.site_id >= 0 ? (
              <ActionsButton
                actions={[
                  {
                    icon: (
                      <EditIcon
                        width="20"
                        height="20"
                        className="flex-none opacity-80"
                        stroke="#667085"
                      />
                    ),
                    label: "Edit",
                    onClick: () => onEditSite(site),
                  },
                  site.total_patients === 0 && {
                    icon: (
                      <DeleteIcon
                        width="20"
                        height="20"
                        className="flex-none"
                        stroke="#667085"
                      />
                    ),
                    label: "Delete",
                    onClick: () => onDeleteSite(site),
                  },
                ]}
              />
            ) : (
              <></>
            ),
        }),
      );
    }
    return tableData;
  }, [filteredSites]);

  const onEditSite = (site: BasicSite) => {
    if (site.site_id < 0) {
      return;
    }
    setSelectedSite(site);
    setOpenSiteModal(true);
  };

  const onAddSite = () => {
    setSelectedSite(newSite);
    setOpenSiteModal(true);
  };

  const onDeleteSite = (site) => {
    setSelectedSite(site);
    setOpenDeleteModal(true);
  };

  const onCloseSiteModal = () => {
    setOpenSiteModal(false);
    setSelectedSite(null);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedSite(null);
  };

  const mapPhoneNumbers = useCallback((phoneNumbers: DirectPhoneNumber[]) => {
    return phoneNumbers
      ? phoneNumbers.map((number) => ({
          name: number.name,
          phone_number: number.phone_number,
          site_direct_phone_number_id: number.site_direct_phone_number_id,
          transfer_to_number: number.transfer_to_number,
          message_to_caller: number.message_to_caller,
          when_to_transfer: number.when_to_transfer,
        }))
      : [];
  }, []);

  const handleConfirmSiteModal = (data) => {
    if (selectedSite.site_id) {
      updateSite({
        site_id: selectedSite.site_id,
        site: {
          name: data.name || selectedSite.name,
          clinic_address:
            data.clinic_address ||
            selectedSite.assistant_settings?.clinic_address ||
            "",
          clinic_hours:
            data.clinic_hours ||
            selectedSite.assistant_settings?.clinic_hours ||
            "",
          faq: data.site_faq || selectedSite.assistant_settings?.faq || "",
          phone_numbers:
            data.phone_numbers ||
            mapPhoneNumbers(selectedSite.direct_phone_numbers),
          is_default: data.is_default,
        },
      });
    } else {
      createSite({
        name: data.name,
        clinic_address: data.clinic_address,
        clinic_hours: data.clinic_hours,
        faq: data.site_faq,
        phone_numbers: data.phone_numbers,
        is_default: data.is_default,
      });
    }
    onCloseSiteModal();
  };

  const handleDeleteSite = () => {
    deleteSite(selectedSite.site_id);
    onCloseDeleteModal();
  };

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <SettingsContainer>
      <div className="space-y-4 h-full md:flex md:flex-col md:overflow-hidden w-full">
        <div className="flex items-center justify-between gap-4">
          <p className="text-xl font-semibold hidden xl:flex">
            {user.customer.name}
          </p>

          <div className="flex items-center space-x-3 w-full xl:max-w-md">
            <div className="relative w-full">
              <input
                value={searchValue}
                onClick={selectInputText}
                onChange={handleSearchInput}
                className="rounded-lg h-10 text-sm outline-none w-full pl-10 placeholder:text-gray-400"
                placeholder="Search sites..."
              />
              <SearchIcon
                className="absolute top-2.5 left-2"
                width="20"
                height="20"
              />
            </div>

            <Button
              variant="blue-light"
              onClick={onAddSite}
              className="font-semibold text-sm sm:text-base space-x-2 w-10 md:w-fit flex-none"
            >
              <PlusIcon
                width="20"
                height="20"
                stroke="#2970FF"
                className="flex-none"
              />
              <p className="hidden md:flex whitespace-nowrap">Add Site</p>
            </Button>
          </div>
        </div>

        {isLoadingSites || !filteredSites ? (
          <SitesTableSkeleton />
        ) : filteredSites.length > 0 ? (
          <>
            <UserTable
              columns={columns}
              data={sitesTableData}
              className="hidden md:block"
            />
            <div className="space-y-3 md:hidden">
              {filteredSites.map((site) => (
                <SiteListItem
                  key={site.site_id}
                  site={site}
                  onDeleteSite={onDeleteSite}
                  onEditSite={onEditSite}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-col items-center justify-center py-10 md:py-16 space-y-7">
            <img
              src={emptyTeamsIllustrationSrc}
              alt="Empty Sites"
              width="459"
              height="165"
            />
            <p className="text-xl font-bold">No Sites Found</p>
          </div>
        )}
      </div>

      <SiteModal
        open={openSiteModal}
        selectedSite={selectedSite}
        onClose={onCloseSiteModal}
        onSave={handleConfirmSiteModal}
      />

      {openDeleteModal && (
        <DeleteModal
          onClose={onCloseDeleteModal}
          onDelete={handleDeleteSite}
          title="Delete Site"
          description="Are you sure you want to delete the site?"
        />
      )}
    </SettingsContainer>
  );
};

export default ClinicSettings;
