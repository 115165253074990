import TabsBorder from "@src/components/Basic/TabsBorder";
import { useUrlState } from "@src/hooks/useUrlState";
import type { Scribe } from "../store/interfaces";
import {
  useGetAudioDetailsQuery,
  useGetTrackedEventsQuery,
} from "../store/scribeApi";
import ScribeAdminAudioDetails from "./ScribeAdminAudioDetails";
import ScribeAdminEventLogs from "./ScribeAdminEventLogs";
import ScribeAdminNote from "./ScribeAdminNote";
import ScribeAdminTranscription from "./ScribeAdminTranscription";

// Add some basic animations
const styles = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes scaleIn {
    from { transform: scale(0.95); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }

  .animate-fadeIn {
    animation: fadeIn 0.2s ease-out;
  }

  .animate-scaleIn {
    animation: scaleIn 0.2s ease-out;
  }
`;

const tabs = [
  "Note",
  "Event Logs",
  "Transcription",
  "Audio Data",
  // "Prompt Template",
];

interface ScribeAdminSettingsProps {
  scribe: Scribe;
}

const ScribeAdminSettings = ({ scribe }: ScribeAdminSettingsProps) => {
  const { value: selectedScribeAdminTab, setValue: setSelectedScribeAdminTab } =
    useUrlState("tab", "Note");

  const { data: events = [], isLoading: isEventsLoading } =
    useGetTrackedEventsQuery(
      {
        audioId: scribe.audioId,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: audioDetails = {} } = useGetAudioDetailsQuery(
    {
      audioId: scribe.audioId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const transcription = audioDetails?.transcription ?? "";
  const wordCount = transcription.split(" ").length || 0;

  return (
    <div id="admin-settings">
      <style>{styles}</style>
      <hr className="my-2" />
      <h1 className="text-lg font-medium">Scribe Admin</h1>

      <TabsBorder
        tabs={tabs}
        activeTab={selectedScribeAdminTab}
        setActiveTab={setSelectedScribeAdminTab}
        className="w-full md:w-fit bg-white border border-gray-200 text-sm"
        tabClassName="py-2 px-8 text-center box-content"
      />
      <div className="my-4 min-h-screen">
        {selectedScribeAdminTab === "Audio Data" && (
          <ScribeAdminAudioDetails audioDetails={audioDetails} />
        )}
        {selectedScribeAdminTab === "Event Logs" && (
          <ScribeAdminEventLogs events={events} isLoading={isEventsLoading} />
        )}
        {selectedScribeAdminTab === "Transcription" && (
          <ScribeAdminTranscription
            audioId={scribe.audioId}
            content={transcription}
            wordCount={wordCount}
            generatedDate={"N/A"}
          />
        )}
        {selectedScribeAdminTab === "Note" &&
          audioDetails?.note_template_id && (
            <ScribeAdminNote scribe={scribe} audioDetails={audioDetails} />
          )}
      </div>
    </div>
  );
};

export default ScribeAdminSettings;
