import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";
import { ReactComponent as FailIcon } from "@assets/icons/exclamation-mark.svg";
import { ReactComponent as RefreshIcon } from "@assets/icons/refresh.svg";
import TooltipMui from "@src/components/Basic/TooltipMui";
import MessageContent from "@src/components/Inbox/RightPanel/MessageContent";
import { getFormattedTime } from "@src/helpers/helpers";
import { memo } from "react";
import ProfilePicture from "../../Basic/ProfilePicture";

const buttonStyle =
  "absolute top-1/2 -translate-y-1/2 w-5 h-5 rounded-full border flex items-center justify-center";

interface MessageCloudProps {
  type: "outgoing" | "incoming";
  combineWithPrevious: boolean;
  profilePicture: string;
  firstName: string;
  lastName: string;
  displayName: string;
  role?: string;
  message: string;
  time: string;
  failed?: boolean;
  isInternal?: boolean;
  onDelete?: () => void;
  onResend?: () => void;
  canRetry?: boolean;
  reason?: string;
}

const MessageCloud = ({
  type,
  combineWithPrevious,
  profilePicture,
  firstName,
  lastName,
  displayName,
  role,
  message,
  time,
  failed = false,
  canRetry = false,
  reason = "",
  isInternal = false,
  onDelete = () => {},
  onResend = () => {},
}: MessageCloudProps) => {
  const outgoing = type === "outgoing";

  return (
    <div
      className={`flex max-w-4/5 xl:max-w-2/3 relative
        ${outgoing ? "flex-row-reverse ml-auto" : "space-x-2"}
        ${combineWithPrevious ? "mt-2" : "mt-3"} `}
    >
      {combineWithPrevious ? (
        <div className="h-0 w-8 flex-none" />
      ) : (
        <ProfilePicture
          src={profilePicture}
          firstName={firstName}
          lastName={lastName}
          flagPadding={false}
        />
      )}

      <div className={outgoing ? "mr-2" : ""}>
        {!combineWithPrevious && (
          <div
            className={`h-8 flex flex-col justify-center ${outgoing ? "items-end" : "items-start"} ${role ? "mb-2" : ""}`}
          >
            <p className="text-sm font-semibold">
              {displayName ? displayName : `${firstName} ${lastName}`}
            </p>
            {role && <p className="text-xs text-tertiary">{role}</p>}
          </div>
        )}
        <div
          className={`text-xs leading-snug py-3 px-3 md:px-4 font-medium w-fit flex relative
            ${isInternal ? "bg-[#FFF7E9]" : outgoing ? "bg-primary-blue-light" : "bg-gray-foreground"}
            ${outgoing ? "rounded-outgoing-message ml-auto" : "rounded-incoming-message"}`}
        >
          <MessageContent
            message={message}
            statusText={
              time && !failed ? (
                getFormattedTime(new Date(`${time.replace(/-/g, "/")} GMT+0`))
              ) : failed ? (
                <TooltipMui
                  title={
                    reason ||
                    "Message failed to send (network issue or connection error). Tap to retry."
                  }
                  arrow
                >
                  <FailIcon
                    width="14px"
                    height="14px"
                    fill="#ef4444"
                    className="mt-1"
                  />
                </TooltipMui>
              ) : (
                getFormattedTime(new Date())
              )
            }
          />

          {failed && (
            <>
              {!reason && (
                <button
                  type="button"
                  onClick={onDelete}
                  className={`${buttonStyle} left-full ml-2 border-red-400`}
                >
                  <CloseIcon stroke="#ef4444" height="12" width="12" />
                </button>
              )}
              {canRetry && (
                <button
                  type="button"
                  onClick={onResend}
                  className={`${buttonStyle} right-full mr-2 border-primary-blue`}
                >
                  <RefreshIcon
                    fill="#2970FF"
                    height="13"
                    width="13"
                    className="rotate-45"
                  />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(MessageCloud);
