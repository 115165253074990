import { z } from "zod";
import {
  baseDoctorSchema,
  zeroOrOneSchema,
} from "../../helpers/commonValidationSchemas";
import { clinicSchema } from "../clinic/schema";

const defaultQuestionSchema = z.string();

export const doctorTeamIdSchema = z.number();

const teamSchema = z.object({
  customer_id: z.number(),
  doctor_team_id: doctorTeamIdSchema,
  members: baseDoctorSchema.array().nullish(),
  name: z.string().nullable(),
  show_inbox: zeroOrOneSchema,
  team_icon: z.string().nullable(),
});

const doctorSchema = baseDoctorSchema.extend({
  show_inbox: zeroOrOneSchema,
  show_patients: zeroOrOneSchema,
  team: teamSchema,
});

const featureFlagsSchema = z.object({
  NOTE_TEMPLATES: z.boolean(),
  SCRIBE_ONLY: z.boolean(),
  FRONT_DESK: z.boolean(),
  FRONT_DESK_INBOX: z.boolean(),
  CCM: z.boolean(),
  CUSTOMIZE_CALLS: z.boolean(),
  COMMAND_CENTER: z.boolean(),
});

export const organizationSchema = z.object({
  deleted_at: z.string().nullable(),
  featureFlags: featureFlagsSchema.partial().nullish(),
  name: z.string(),
  organization_id: z.number(),
});

const settingsSchema = z.object({
  doctor_id: z.number(),
  doctor_settings_id: z.number(),
  email_for_urgent_messages: zeroOrOneSchema,
  note_require_signature: zeroOrOneSchema,
  scribe_input_language: z.string().nullable(),
  scribe_note_language: z.string().nullable(),
  show_differential_diagnoses_explanations: zeroOrOneSchema,
  show_high_priority_messages: zeroOrOneSchema,
  show_language_selector: zeroOrOneSchema,
  show_messages_queue: zeroOrOneSchema,
  time_to_move_complete: z.number(),
  time_to_move_review: z.number(),
});

export const userSchema = z.object({
  created_at: z.string(),
  customer: clinicSchema,
  customer_id: z.number(),
  default_questions: z.array(defaultQuestionSchema).nullable(),
  deleted_at: z.string().nullable(),
  display_name: z.string(),
  doctor_id: z.number(),
  doctors: z.array(doctorSchema),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  note_template_id: z.number().nullable(),
  npi: z.string().nullable(),
  occupation: z.string().nullable(),
  organization: organizationSchema,
  organization_id: z.number(),
  phone_number: z.string().nullable(),
  profile_picture: z.string().nullable(),
  reset_token: z.string().optional(),
  role: z.string(),
  role_id: z.number(),
  settings: settingsSchema,
  shared_users_ids: z.array(z.number()),
  signup_ehr_name: z.string().nullable(),
  status: z.string(),
  terms_signed_at: z.string().nullable(),
  time_to_move_complete: z.number(),
  time_to_move_review: z.number(),
  updated_at: z.string().nullable(),
  preferred_name: z.string().nullish(),
  default_inbox_type: z.string().nullable(),
  default_inbox_id: z.number().nullable(),
});

export const tokenSchema = z.string();

const assistantSchema = baseDoctorSchema.extend({
  reset_token: z.string().nullish(),
});

const userListItemSchema = baseDoctorSchema.extend({
  assistants: z.array(assistantSchema).nullish(),
  reset_token: z.string().nullish(),
  team: teamSchema.nullish(),
  total_patients: z.number().nullish(),
});

export const userListSchema = z.array(userListItemSchema);

export const userInfoSchema = baseDoctorSchema.extend({
  customer: clinicSchema,
  reset_token: z.string().nullish(),
});

export const teamListSchema = z.array(teamSchema);
