import { z } from "zod";
import {
  baseConversationSchema,
  doctorSchema,
  patientSchema,
  zeroOrOneSchema,
} from "../../helpers/commonValidationSchemas";
import { MessageFrom } from "../call/interfaces";
import { callSchema, callStatusSchema } from "../call/validationSchemas";
import { patientScribeSchema } from "../patient/validationSchemas";
import { MessageStatus, MessageType } from "./interfaces";

const messageTypeSchema = z.nativeEnum(MessageType);

const conversationDoctorSchema = z
  .object({
    doctor_id: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    display_name: z.string().nullish(),
    profile_picture: z.string().nullish(),
  })
  .strict();

export const conversationPatientSchema = z
  .object({
    birthdate: z.string().nullable(),
    first_name: z.string(),
    gender: z.string().nullable(),
    is_paused: zeroOrOneSchema.nullish(),
    last_name: z.string(),
    middle_name: z.string().nullish(),
    patient_id: z.number(),
    phone_number: z.string().nullable(),
    preferred_name: z.string().nullable(),
    profile_picture: z.string().nullish(),
    pronouns: z.string().nullable(),
    sex: z.string().nullable(),
    chart_number: z.string().nullish(),
    conversation_id: z.number().nullish(),
    site_id: z.number().nullish(),
  })
  .strict();

const messageDataSchema = z.object({
  message: z.string(),
});

const callDataSchema = z.object({
  call: callSchema,
});

const statusUpdateDataSchema = z.object({
  status_update: z
    .object({
      assistant_call_id: z.number().nullish(),
      created_at: z.string(),
      status_from: callStatusSchema,
      status_to: callStatusSchema,
      status_update_id: z.number(),
      updated_at: z.string(),
      updated_by: z
        .object({
          doctor_id: z.number(),
          first_name: z.string(),
          last_name: z.string(),
          display_name: z.string().nullish(),
          profile_picture: z.string().nullish(),
        })
        .nullish(),
    })
    .nullable(),
});

export const messageContentSchema = z.object({
  message_id: z.number(),
  conversation_id: z.number(),
  from: z.string(),
  message: z.string().nullable(),
  is_question: zeroOrOneSchema.nullable(),
  is_answer: zeroOrOneSchema.nullable(),
  is_automatic: zeroOrOneSchema.nullable(),
  send_at: z.string(),
  read: zeroOrOneSchema,
  sender_id: z.number().nullable(),
  state: z.number().nullable(),
  type: messageTypeSchema,
  parent_id: z.number().nullable(),
  idempotency_key: z.string().nullable(),
});

const baseMessageFields = {
  from: z.nativeEnum(MessageFrom),
  is_automatic: zeroOrOneSchema.nullable(),
  is_internal: zeroOrOneSchema,
  send_at: z.string(),
  sender: z.union([patientSchema.partial(), doctorSchema.partial()]).nullish(),
  idempotency_key: z.string().nullish(),
  status: z.nativeEnum(MessageStatus).nullish(),
  reason: z.string().nullish(),
  can_retry: z.boolean().nullish(),
} as const;

const assistantCallMessage = z
  .object({
    ...baseMessageFields,
    type: z.literal(MessageType.Call),
    message: z.object({
      message_id: z.number(),
      conversation_id: z.number(),
      from: z.nativeEnum(MessageFrom),
      message: z.null(),
      is_question: zeroOrOneSchema,
      is_answer: zeroOrOneSchema,
      is_automatic: zeroOrOneSchema.nullable(),
      send_at: z.string(),
      read: zeroOrOneSchema,
      sender_id: z.number().nullable(),
      state: z.number().nullable(),
      type: z.literal(MessageType.Call),
      parent_id: z.number().nullable(),
      is_internal: zeroOrOneSchema,
      idempotency_key: z.null().nullish(),
    }),
    data: callDataSchema,
  })
  .strict();

const statusUpdateMessage = z
  .object({
    ...baseMessageFields,
    type: z.literal(MessageType.StatusUpdate),
    message: messageContentSchema,
    data: statusUpdateDataSchema,
  })
  .strict();

const noteMessage = z
  .object({
    ...baseMessageFields,
    type: z.literal(MessageType.Note),
    data: z.object({
      note: patientScribeSchema,
    }),
  })
  .strict();

const textMessage = z
  .object({
    ...baseMessageFields,
    type: z.literal(MessageType.Text),
    message: messageContentSchema,
    data: messageDataSchema,
  })
  .strict();

// message: messageContentSchema.nullish(),

export const messageSchema = z.discriminatedUnion("type", [
  assistantCallMessage,
  statusUpdateMessage,
  noteMessage,
  textMessage,
]);

export type Message = z.infer<typeof messageSchema>;

export const conversationSchema = baseConversationSchema
  .extend({
    campaign: z.string(),
    doctor: conversationDoctorSchema,
    assigned_to: conversationDoctorSchema.nullish(),
    assigned_to_team: z
      .object({
        doctor_team_id: z.number(),
        name: z.string(),
        team_icon: z.string().nullable(),
      })
      .nullish(),
    messages: z.array(messageSchema),
    patient: conversationPatientSchema,
    trackable: z.boolean(),
    waiting_response: zeroOrOneSchema,
    is_urgent: zeroOrOneSchema,
    campaign_type: z.string().nullish(),
    show_response: z.boolean().nullish(),
    has_more_patients_with_same_phone_number: z.boolean(),
    patients_with_same_phone_number: z.array(conversationPatientSchema),
  })
  .strict();
