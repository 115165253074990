import Button from "@src/components/Basic/Button";
import Input from "@src/components/Basic/Input";
import Modal from "@src/components/Basic/Modal";
import { useValidatePasswordMutation } from "@src/store/voiceAgent/api";
import { useState } from "react";

interface SubmitPromptModalProps {
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  description: string;
}

const PasswordModal = ({
  onClose,
  onSubmit,
  title,
  description,
}: SubmitPromptModalProps) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [validatePassword] = useValidatePasswordMutation();

  const handleSubmit = async () => {
    if (!password.trim()) {
      setError({ message: "Password can’t be blank" });
      return;
    }

    try {
      const isPasswordValid = await validatePassword(password).unwrap();

      if (isPasswordValid) {
        onSubmit();
      } else {
        setError({ message: "Invalid password" });
      }
    } catch (err) {
      console.error("Failed to validate password:", err);
      setError({ message: "Something went wrong. Please try again." });
    }
  };

  return (
    <Modal className="pt-5 rounded-2xl">
      <div className="w-106">
        <p className="text-base md:text-xl font-semibold mb-5 px-5">{title}</p>

        <div className="px-5">
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            label={description}
            placeholder="Enter your password"
            type="password"
            className="mb-4"
            inputClassName="pr-12"
            error={error}
            autoComplete="new-password"
            withoutAsterisk
          />
        </div>

        <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordModal;
