import Skeleton from "@mui/material/Skeleton";

interface InputSkeletonProps {
  className?: string;
  label?: string;
  heightPx?: number;
  bgColor?: string;
  animation?: "wave" | "pulse";
}

const InputSkeleton = ({
  label,
  className = "",
  heightPx = 40,
  bgColor = "#F8F8F8",
  animation = "wave",
}: InputSkeletonProps) => {
  return (
    <div className={className}>
      {label && <p className="mb-3 font-semibold">{label}</p>}
      <Skeleton
        animation={animation}
        variant="rounded"
        height={heightPx}
        sx={{
          bgcolor: bgColor,
          borderRadius: "8px",
          border: "1px solid #D0D5DD",
        }}
      />
    </div>
  );
};

export default InputSkeleton;
