import { skipToken } from "@reduxjs/toolkit/query";
import { Pages } from "@src/helpers/constants";
import { useAppDispatch, useAppSelector } from "@src/store";
import { useGetConversationQuery } from "@src/store/call/api";
import {
  setConversationsUpdatedAction,
  setMessagesAction,
} from "@src/store/conversation/actions";
import {
  getConversationsUpdated,
  getMessages,
} from "@src/store/conversation/conversationReducer";
import { useFeature } from "@src/store/featureFlagSelectors";
import type { ConversationPatient } from "@src/store/patient/interfaces";
import { FeatureFlags } from "@src/store/types";
import { useGetTeamsQuery, useGetUsersQuery } from "@src/store/user/api";
import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import AddPhoneNumber from "../../../Basic/AddPhoneNumber";
import {
  type MentionItem,
  type MentionSeparator,
  MentionType,
} from "../../../Inbox/Mentions/interfaces";
import InboxChat from "../../../Inbox/RightPanel/InboxChat";
import InboxInputArea from "../../../Inbox/RightPanel/InboxInputArea";
import useConversationSearchParams from "../../../Inbox/hooks/useConversationSearchParams";
import ChatSkeleton from "../../../Skeletons/ChatSkeleton/ChatSkeleton";

type MessageTabProps = {
  patientInfo: ConversationPatient;
  showBottomCompleteButton?: boolean;
};

const MessageTab: FC<MessageTabProps> = ({
  patientInfo,
  showBottomCompleteButton,
}) => {
  const frontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const messages = useAppSelector(getMessages);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { selectedConversationId } = useConversationSearchParams();
  const conversationsUpdated = useAppSelector(getConversationsUpdated);

  const { data: currentConversation } = useGetConversationQuery(
    patientInfo?.phone_number && selectedConversationId
      ? Number(selectedConversationId)
      : skipToken,
  );

  const { data: users = [] } = useGetUsersQuery({
    search: "",
  });

  const { data: teams = [] } = useGetTeamsQuery({
    search: "",
  });

  const [scrolledUp, setScrolledUp] = useState(false);

  const setMessages = useCallback(
    (messages: Record<string, string>) => {
      dispatch(setMessagesAction(messages));
    },
    [dispatch],
  );

  // Memoize mention items to prevent unnecessary recalculations
  const mentionItems = useMemo(() => {
    const userMentions: MentionItem[] = users
      .map((user) => ({
        id: `doctor-${user.doctor_id}`,
        display:
          user.display_name?.trim() ||
          `${user.first_name?.trim() || ""} ${user.last_name?.trim() || "-"}`,
        type: MentionType.USER,
        profile_picture: user.profile_picture,
      }))
      .sort((a, b) => a.display.localeCompare(b.display));

    const teamMentions: MentionItem[] = teams
      .map((team) => ({
        id: `team-${team.doctor_team_id}`,
        display: team.name?.trim() || "-",
        type: MentionType.TEAM,
        team_icon: team.team_icon,
      }))
      .sort((a, b) => a.display.localeCompare(b.display));

    return [
      ...userMentions,
      {
        id: "separator",
        display: "Separator",
        type: MentionType.SEPARATOR,
      } as MentionSeparator,
      ...teamMentions,
    ];
  }, [users, teams]);

  const updateConversations = useCallback(() => {
    dispatch(setConversationsUpdatedAction(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setConversationsUpdatedAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (!conversationsUpdated) {
      updateConversations();
    }
  }, [conversationsUpdated, updateConversations]);

  const InboxPage = useMemo(
    () => (
      <>
        <InboxChat
          scrolledUp={scrolledUp}
          setScrolledUp={setScrolledUp}
          currentConversation={currentConversation}
          selectedConversationId={selectedConversationId}
        />
        <div>
          <InboxInputArea
            setScrolledUp={setScrolledUp}
            messages={messages}
            setMessages={setMessages}
            currentConversation={currentConversation}
            selectedConversationId={selectedConversationId}
            showCompleteButton={showBottomCompleteButton}
            mentionItems={mentionItems}
          />
        </div>
      </>
    ),
    [
      scrolledUp,
      currentConversation,
      messages,
      showBottomCompleteButton,
      mentionItems,
      setMessages,
      selectedConversationId,
    ],
  );

  if (!patientInfo?.phone_number) {
    return <AddPhoneNumber patientId={patientInfo?.patient_id} />;
  }

  return (
    <div className="min-h-0 h-full w-full grid grid-rows-note-layout bg-gray-background2 overflow-hidden">
      {frontDeskInbox &&
      (location.pathname === Pages.FRONT_DESK ||
        location.pathname.includes(Pages.PATIENTS)) ? (
        currentConversation ? (
          InboxPage
        ) : (
          <ChatSkeleton className="px-4" />
        )
      ) : (
        <ChatSkeleton className="px-4" />
      )}
    </div>
  );
};

export default MessageTab;
