import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-right.svg";
// import { ReactComponent as CodeIcon } from "@assets/icons/code.svg";
import FrontDeskMetrics from "@src/components/Settings/CallMetrics/FrontDeskMetrics";
import Reasons from "@src/components/Settings/CallMetrics/Reasons";
import SettingsContainer from "@src/components/Settings/SettingsContainer";
import AnalyticsNumberCard from "@src/components/VoiceAgent/SupportLineView/Analytics/AnalyticsNumberCard";
import DateSelectors from "@src/components/VoiceAgent/SupportLineView/Analytics/DateSelectors/DateSelectors";
import PasswordModal from "@src/components/VoiceAgent/SupportLineView/PasswordModal";
import { Pages } from "@src/helpers/constants";
import {
  getSelectedDateRange,
  makeSlug,
  roundBigNumber,
} from "@src/helpers/helpers";
import {
  useGetAdminCallNumbersQuery,
  useGetAdminCallReasonsQuery,
  useGetAdminCallsPerCustomerQuery,
} from "@src/store/adminMetrics/api";
import { AnalyticsPeriod } from "@src/store/voiceAgent/types";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router";

const weekPeriodUrl = `${Pages.SETTINGS_CALL_METRICS}?period=${makeSlug(AnalyticsPeriod.WEEK)}`;
const monthPeriodUrl = `${Pages.SETTINGS_CALL_METRICS}?period=${makeSlug(AnalyticsPeriod.MONTH)}`;
const yearPeriodUrl = `${Pages.SETTINGS_CALL_METRICS}?period=${makeSlug(AnalyticsPeriod.YEAR)}`;
const customPeriodUrl = `${Pages.SETTINGS_CALL_METRICS}?period=${makeSlug(AnalyticsPeriod.CUSTOM)}`;

const CallMetrics = () => {
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [displayCost, setDisplayCost] = useState(false);
  const [selectedClinicName, setSelectedClinicName] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const selectedPeriod =
    searchParams.get("period") || makeSlug(AnalyticsPeriod.WEEK);
  const startOfCurrWeek = dayjs().startOf("isoWeek").format("YYYY-MM-DD");
  const startOfCurrMonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const startOfCurrYear = dayjs().startOf("year").format("YYYY-MM-DD");
  const today = dayjs().format("YYYY-MM-DD");

  const selectedClinicId = searchParams.get("clinic_id") || "";
  const clinicIdParam = selectedClinicId
    ? `&clinic_id=${selectedClinicId}`
    : "";

  const defaultStartDate =
    selectedPeriod === makeSlug(AnalyticsPeriod.YEAR)
      ? startOfCurrYear
      : selectedPeriod === makeSlug(AnalyticsPeriod.MONTH)
        ? startOfCurrMonth
        : startOfCurrWeek;

  const isValidDate = (date: string | null) =>
    dayjs(date, "YYYY-MM-DD", true).isValid();

  const selectedStartDate = isValidDate(searchParams.get("start-date"))
    ? searchParams.get("start-date")
    : defaultStartDate;
  const selectedEndDate = isValidDate(searchParams.get("end-date"))
    ? searchParams.get("end-date")
    : today;

  const { data: adminCallNumbers, isLoading: adminCallNumbersLoading } =
    useGetAdminCallNumbersQuery({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
  const {
    data: adminCallsPerCustomer,
    isLoading: adminCallsPerCustomerLoading,
  } = useGetAdminCallsPerCustomerQuery({
    startDate: selectedStartDate,
    endDate: selectedEndDate,
  });
  const { data: adminCallReasons, isLoading: adminCallReasonsLoading } =
    useGetAdminCallReasonsQuery({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      customer_id: selectedClinicId,
    });

  const isLoading =
    adminCallNumbersLoading ||
    adminCallsPerCustomerLoading ||
    adminCallReasonsLoading;

  useEffect(() => {
    if (adminCallsPerCustomer?.per_customer && selectedClinicId) {
      const selectedClinicName = adminCallsPerCustomer.per_customer.find(
        (clinic) => clinic.customer_id === Number(selectedClinicId),
      )?.customer_name;

      setSelectedClinicName(selectedClinicName || "-");
    } else {
      setSelectedClinicName("");
    }
  }, [selectedClinicId, adminCallsPerCustomer]);

  const handleSelectClinic = (clinicId: number | null) => {
    const clinicIdParam = clinicId ? `&clinic_id=${clinicId}` : "";
    navigate(
      `${Pages.SETTINGS_CALL_METRICS}?period=${selectedPeriod}${clinicIdParam}&start-date=${selectedStartDate}&end-date=${selectedEndDate}`,
    );
  };

  const onSubmitPassword = () => {
    setOpenPasswordModal(false);
    setDisplayCost(true);
  };

  return (
    <SettingsContainer>
      <div className="h-full grid grid-rows-tab-layout overflow-hidden">
        <div>
          <div className="flex items-center justify-between">
            <NavLink
              to={Pages.SETTINGS_PROFILE}
              className="items-center space-x-2 hidden md:flex md:invisible xl:visible mr-2"
            >
              <ArrowIcon
                width="20"
                height="20"
                fill="#2970FF"
                className="rotate-180"
              />
              <p className="text-primary-blue text-sm font-bold">
                Back to menu
              </p>
            </NavLink>

            <DateSelectors
              weekBaseUrl={`${weekPeriodUrl}${clinicIdParam}`}
              monthBaseUrl={`${monthPeriodUrl}${clinicIdParam}`}
              yearBaseUrl={`${yearPeriodUrl}${clinicIdParam}`}
              customBaseUrl={`${customPeriodUrl}${clinicIdParam}`}
            />
          </div>

          <div className="flex items-center justify-between space-x-2 my-3">
            <p className="text-xl font-semibold truncate">
              {getSelectedDateRange(
                selectedStartDate,
                selectedEndDate,
                selectedPeriod,
              )}
            </p>

            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="h-9 w-9 rounded-md flex items-center justify-center bg-white border border-gray-foreground"*/}
            {/*  onClick={() => setOpenPasswordModal(true)}*/}
            {/*>*/}
            {/*  <CodeIcon width="20" height="20" />*/}
            {/*</button>*/}
          </div>
        </div>

        <div className="overflow-y-auto scrollbar min-h-0 pr-1">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <AnalyticsNumberCard
              title="Inbound Calls"
              className="md:h-30"
              number={roundBigNumber(adminCallNumbers?.incoming_calls)}
              isLoading={isLoading}
            />
            <AnalyticsNumberCard
              title="Call Minutes"
              className="md:h-30"
              number={roundBigNumber(adminCallNumbers?.calls_minutes)}
              isLoading={isLoading}
            />
            <AnalyticsNumberCard
              title="Avg Minutes"
              className="md:h-30"
              number={adminCallNumbers?.average_call_duration}
              isLoading={isLoading}
            />
            <AnalyticsNumberCard
              title="Texts"
              className="md:h-30"
              number={roundBigNumber(adminCallNumbers?.text)}
              isLoading={isLoading}
            />
            <AnalyticsNumberCard
              title="Review"
              className="md:h-30"
              number={roundBigNumber(adminCallNumbers?.total_reviews)}
              isLoading={isLoading}
            />
            <AnalyticsNumberCard
              title="Unassigned"
              className="md:h-30"
              number={roundBigNumber(adminCallNumbers?.unassigned)}
              isLoading={isLoading}
            />
            <AnalyticsNumberCard
              title="Completed"
              className="md:h-30"
              number={roundBigNumber(adminCallNumbers?.total_completed)}
              isLoading={isLoading}
            />
            <AnalyticsNumberCard
              title="Internal"
              className="md:h-30"
              number={roundBigNumber(adminCallNumbers?.internal)}
              isLoading={isLoading}
            />

            {/*<AnalyticsNumberCard*/}
            {/*  title={displayCost ? "Cost" : "Per Min"}*/}
            {/*  className="md:h-30"*/}
            {/*  number={displayCost ? "$3k" : "$0.80"}*/}
            {/*  isLoading={isLoading}*/}
            {/*/>*/}
          </div>

          <FrontDeskMetrics
            className="mt-6 md:max-h-128"
            selectedClinicId={
              selectedClinicId ? Number(selectedClinicId) : null
            }
            onSelectClinic={handleSelectClinic}
            displayCost={displayCost}
            metricsData={adminCallsPerCustomer?.per_customer}
            isLoading={isLoading}
          />

          <Reasons
            className="mt-6"
            selectedClinicName={selectedClinicName}
            callReasons={adminCallReasons?.call_reasons}
            isLoading={isLoading}
          />
        </div>
      </div>

      {openPasswordModal && (
        <PasswordModal
          onSubmit={onSubmitPassword}
          onClose={() => setOpenPasswordModal(false)}
          title="Password"
          description="Enter your password"
        />
      )}
    </SettingsContainer>
  );
};

export default CallMetrics;
