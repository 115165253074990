import Bugsnag from "@bugsnag/js";
import { type BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import api from "@src/helpers/api";
import type { AxiosError, AxiosRequestConfig } from "axios";
import type z from "zod";
import { BASE_URL } from "../helpers/config";
import { handleRequestError } from "../helpers/helpers";

export const validateResponse = <T extends z.ZodType>(schema: T) => {
  return (data: unknown): z.infer<T> => {
    return schema.parse(data);
  };
};

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await api({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
        timeout: 30000,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      console.log("Axios Error:", {
        url,
        method,
        message: err.message,
        code: err.code,
        config: err.config,
        response: err.response?.status,
      });
      Bugsnag.notify(err, (event) => {
        event.severity = "error";
        event.addMetadata("api", {
          endpoint: url,
          error: err instanceof Error ? err.message : "Unknown error",
        });
        event.groupingHash = `NetworkError:${url}`;
      });

      handleRequestError(axiosError);

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const baseApi = createApi({
  reducerPath: "api",
  tagTypes: [
    "User",
    "Team",
    "Conversation",
    "Call",
    "CallNote",
    "Provider",
    "OrganizationPrompt",
    "Site",
    "SystemMessages",
    "PracticeInformation",
    "ProtocolMessages",
    "CallReasons",
    "SelectedAssistant",
    "IsCustomPrompt",
    "DefaultPrompt",
    "CustomPrompt",
    "Scribe",
    "ScheduleSite",
    "ScheduleProvider",
    "ScheduleSlot",
    "ScheduleInsurance",
    "ScheduleType",
    "ScheduleReason",
    "Patient",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: () => ({}),
});
