import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useMoveToCompletedMutation } from "../../../store/call/api";
import {
  getTableScrollPosition,
  setStatusCursor,
  setTableScrollPosition,
} from "../../../store/call/callSlice";
import {
  CallStatusTab,
  type InboxListItem,
} from "../../../store/call/interfaces";
import { setHideMobileNavbar } from "../../../store/ui/actions";
import LoaderDots from "../../Basic/LoaderDots";
import CallListItemsSkeleton from "../../Skeletons/CallListItemsSkeleton";
import { useCallManagement } from "../hooks/useCallManagement";
import CallListItem from "./CallListItem";
import CallsGridTable from "./CallsGridTable";
import EmptyInboxTable from "./EmptyInboxTable";

const CallsTableData = () => {
  const tableScrollPosition = useAppSelector(getTableScrollPosition);

  const {
    data: { data: calls = [], next_cursor } = {},
    isLoading,
    isFetching,
    filters,
    nextConversation,
    selectedConversationId,
    setSelectedConversationId,
  } = useCallManagement();

  const [moveToCompleted] = useMoveToCompletedMutation();

  const hasMore = useMemo(() => next_cursor > 0, [next_cursor]);

  const tableRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();

  const handleCompleteCall = useCallback(
    (call: InboxListItem) => {
      if (
        call.items[0].data.conversation_id === Number(selectedConversationId)
      ) {
        if (nextConversation?.items[0].data.conversation_id) {
          const nextId =
            nextConversation.items[0].data.conversation_id.toString();

          setSelectedConversationId(nextId);
        } else {
          setSelectedConversationId(null);
        }
      }

      moveToCompleted({
        patientId: call.patient.patient_id,
        conversationId: call.items[0].data.conversation_id,
      });
    },
    [
      nextConversation,
      selectedConversationId,
      moveToCompleted,
      setSelectedConversationId,
    ],
  );

  useLayoutEffect(() => {
    if (tableRef.current) {
      tableRef.current.scroll(0, tableScrollPosition);
    }
    return () => {
      if (tableRef.current) {
        dispatch(setTableScrollPosition(tableRef.current.scrollTop));
      }
    };
  }, [tableScrollPosition, dispatch]);

  const handleClickCall = useCallback(
    (call: InboxListItem): void => {
      if (
        selectedConversationId === call.items[0].data.conversation_id.toString()
      ) {
        setSelectedConversationId(null);
        dispatch(setHideMobileNavbar(false));
        return;
      }

      setSelectedConversationId(call.items[0].data.conversation_id.toString());
    },
    [selectedConversationId, setSelectedConversationId, dispatch],
  );

  const onNext = (): void => {
    if (isFetching) {
      return;
    }

    dispatch(setStatusCursor({ status: filters.status, cursor: next_cursor }));
  };

  return (
    <div className="overflow-auto h-full w-full scrollbar pr-1" ref={tableRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={onNext}
        hasMore={hasMore}
        useWindow={false}
        loader={
          <div key={0} className="w-full h-10 flex items-center justify-center">
            <LoaderDots />
          </div>
        }
      >
        <div className="md:min-w-fit w-full">
          {isDesktop ? (
            <CallsGridTable
              handleClickCall={handleClickCall}
              handleCompleteCall={handleCompleteCall}
            />
          ) : (
            <>
              {calls.length === 0 && isLoading ? (
                <CallListItemsSkeleton />
              ) : calls.length > 0 ? (
                calls.map((call: InboxListItem) => (
                  <CallListItem
                    key={call.patient.patient_id}
                    call={call.items[0]}
                    patient={call.patient}
                    numOfSubItems={call.items.length}
                    filters={filters}
                    handleClickCall={() => handleClickCall(call)}
                    handleCompleteCall={() => handleCompleteCall(call)}
                  />
                ))
              ) : (
                <EmptyInboxTable
                  completed={filters.status === CallStatusTab.Completed}
                />
              )}
            </>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default CallsTableData;
