import { getFormattedPhoneNumber } from "@src/helpers/helpers";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone2.svg";

interface VoiceAgentPageHeaderProps {
  className?: string;
  selectedLine: any;
}

const VoiceAgentPageHeader = ({
  className = "",
  selectedLine,
}: VoiceAgentPageHeaderProps) => {
  return (
    <div
      className={`w-full grid items-center grid-cols-conv-layout gap-2 overflow-hidden ${className}`}
    >
      <p className="text-xl font-semibold truncate">
        {selectedLine ? selectedLine.name : "All Support Lines"}
      </p>
      {selectedLine && (
        <div className="flex items-center space-x-1 truncate">
          <PhoneIcon width="20" height="20" className="flex-none" />
          <p className="truncate">
            {getFormattedPhoneNumber(selectedLine.phone_number)}
          </p>
        </div>
      )}
    </div>
  );
};

export default VoiceAgentPageHeader;
