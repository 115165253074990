import type z from "zod";
import type {
  conversationSchema,
  messageContentSchema,
} from "../conversation/validationSchemas";
import type {
  callCountsSchema,
  callNextCursorSchema,
  callSchema,
  inboxItemSchema,
  inboxListItemSchema,
  inboxPatientSchema,
  searchCallResponseSchema,
  searchCallResponseWithDetailsSchema,
} from "./validationSchemas";

export enum CallScribeType {
  CCM = "ccm",
  Other = "other",
}

export enum CallStatus {
  Pending = "pending",
  Completed = "completed",
  Reviewed = "review",
}

export enum CallStatusTab {
  Pending = "review",
  Completed = "completed",
}

export enum CallDirection {
  Inbound = "inbound",
  Outbound = "outbound",
}

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum InboxItemType {
  Call = "call",
  Text = "text",
  TextInternal = "text_internal",
}

export type Call = z.infer<typeof callSchema>;

export type InboxPatient = z.infer<typeof inboxPatientSchema>;

export type InboxItem = z.infer<typeof inboxItemSchema>;

export type InboxListItem = z.infer<typeof inboxListItemSchema>;

export type SearchCallResponse = z.infer<typeof searchCallResponseSchema>;

export type SearchCallResponseWithDetails = z.infer<
  typeof searchCallResponseWithDetailsSchema
>;

export type CallCounts = Required<z.infer<typeof callCountsSchema>>;

export type CallNextCursor = z.infer<typeof callNextCursorSchema>;

export interface CallTags {
  is_new_user: boolean;
  is_urgent: boolean;
  is_external: boolean;
  is_care_team: boolean;
}

export interface CallNote {
  assistant_call_id: number;
  assistant_call_note_id: number;
  created_at: string;
  created_by: number;
  note: string;
  updated_at: string;
  updated_by: number;
}

export interface CallNotes {
  [assistant_call_id: number]: CallNote[];
}

export interface Doctor {
  doctor_id: number;
  first_name: string;
  last_name: string;
  display_name: string;
  profile_picture: string;
  preferred_name?: string;
  occupation?: string;
}

export enum MessageFrom {
  Patient = "patient",
  Doctor = "doctor",
  Note = "note",
}

export type ContentMessage = z.infer<typeof messageContentSchema>;

export interface AssignedUser {
  doctor_id?: number;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  profile_picture?: string;
}

export interface AssignedTeam {
  doctor_team_id?: number;
  name?: string;
  team_icon?: string | null;
}

export type Conversation = z.infer<typeof conversationSchema>;

export interface CallState {
  calls: InboxListItem[] | null;
  callsTotal: number;
  reviewTotal: number;
  callCounts: CallCounts;
  tableScrollPosition: number;
  selectedCall: InboxListItem | null;
  isFilterByMentions: boolean;
  isFilterByAssignedToCurrentUser: boolean;
  filterByTeamId: number | null;
}

export interface CallSearchParams {
  q?: string;
  categories?: readonly string[];
  is_new_user?: boolean;
  is_urgent?: boolean;
  is_external?: boolean;
  is_care_team?: boolean;
  limit?: number;
  sort_direction?: SortDirection;
  assigned_to_me?: 0 | 1;
  unassigned?: 0 | 1;
  team_id?: number | string | null;
  site_id?: number | null;
}
