import Skeleton from "@mui/material/Skeleton";

interface CallReasonFieldsSkeletonProps {
  numOfFields?: number;
  className?: string;
  heightPx?: number;
  bgColor?: string;
  animation?: "wave" | "pulse";
}

const CallReasonFieldsSkeleton = ({
  numOfFields = 3,
  className = "",
  heightPx = 40,
  bgColor = "#F8F8F8",
  animation = "wave",
}: CallReasonFieldsSkeletonProps) => {
  return (
    <>
      {[...Array(numOfFields)].map((_, index) => (
        <div className={className} key={`call-reason-field-${index}`}>
          <div className="h-12 flex items-center justify-between space-x-3 mb-3">
            <Skeleton
              animation={animation}
              variant="rounded"
              height={24}
              width={200}
              sx={{
                bgcolor: bgColor,
                borderRadius: "24px",
              }}
            />
            <Skeleton
              animation={animation}
              variant="rounded"
              height={32}
              width={100}
              sx={{
                bgcolor: bgColor,
                borderRadius: "32px",
                border: "1px solid #EAECF0",
              }}
            />
          </div>

          <Skeleton
            animation={animation}
            variant="rounded"
            height={heightPx}
            sx={{
              bgcolor: bgColor,
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
            }}
          />
        </div>
      ))}
    </>
  );
};

export default CallReasonFieldsSkeleton;
