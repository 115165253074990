import { zeroOrOneSchema } from "@src/helpers/commonValidationSchemas";
import z from "zod";

export const clinicSchema = z.object({
  customer_id: z.number().nullish(),
  name: z.string(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
  phone_number: z.string().nullish(),
  time_to_move_review: z.number().nullish(),
  time_to_move_complete: z.number().nullish(),
  incentive_program_enabled: zeroOrOneSchema.nullish(),
  incentive_program_points: zeroOrOneSchema.nullish(),
  legacy_phone_number: z.string().nullish(),
  clinic_address: z.string().nullish(),
  custom_instructions: z.string().nullish(),
  organization_id: z.number().nullish(),
  display_name: z.string().nullish(),
  note_template_id: z.number().nullish(),
  assistant_phone_number: z.string().nullish(),
  assistant_id: z.string().nullish(),
  has_ehr_data: z.boolean().nullish(),
});
