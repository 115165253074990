import { createSlice } from "@reduxjs/toolkit";
import { LOGOUT } from "../types";

const initialState = {};

export const voiceAgentSlice = createSlice({
  name: "voiceAgent",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => ({
      ...initialState,
    }));
  },
});
