import { type ChangeEvent, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-circle.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { selectInputText } from "../../../../helpers/helpers";
import Button from "../../../Basic/Button";

const TableFilters = () => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="flex items-center justify-between py-5">
      <div className="relative w-full md:max-w-sm">
        <input
          aria-label="Search"
          value={searchValue}
          onClick={selectInputText}
          onChange={handleSearchInput}
          className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
          placeholder="Search..."
        />
        <SearchIcon
          className="absolute top-2.5 left-4"
          width="20"
          height="20"
        />
      </div>

      <Button className="space-x-2">
        <PlusIcon width="20" height="20" className="flex-none" stroke="white" />
        <p>Add Support Line</p>
      </Button>
    </div>
  );
};

export default TableFilters;
