import MonthPicker from "./MonthPicker";
import RangePicker from "./RangePicker";
import WeekPicker from "./WeekPicker";
import YearPicker from "./YearPicker";
import "./DateSelectors.css";

interface DateSelectorsProps {
  weekBaseUrl: string;
  monthBaseUrl: string;
  yearBaseUrl: string;
  customBaseUrl: string;
}

const DateSelectors = ({
  weekBaseUrl,
  monthBaseUrl,
  yearBaseUrl,
  customBaseUrl,
}: DateSelectorsProps) => {
  return (
    <div
      className="w-full h-fit md:w-fit flex rounded-lg overflow-hidden border border-gray-foreground divide-x
        divide-gray-foreground"
    >
      <WeekPicker baseUrl={weekBaseUrl} />
      <MonthPicker baseUrl={monthBaseUrl} />
      <YearPicker baseUrl={yearBaseUrl} />
      <RangePicker baseUrl={customBaseUrl} />
    </div>
  );
};

export default DateSelectors;
