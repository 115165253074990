import { z } from "zod";
import { CallDirection, CallStatus, InboxItemType } from "./interfaces";

export const callStatusSchema = z.nativeEnum(CallStatus);

const callDirectionSchema = z.nativeEnum(CallDirection);

const inboxItemTypeSchema = z.nativeEnum(InboxItemType);

export const callListSchema = z.object({
  assistant_call_id: z.number(),
  date: z.string(),
  direction: z.union([callDirectionSchema.nullable(), z.literal("")]),
  is_new_patient: z.boolean(),
  is_urgent: z.boolean(),
  is_external: z.boolean().nullish(),
  is_care_team: z.boolean().nullish(),
  is_completed: z.boolean().nullish(),
  reasons: z.array(z.string()),
  status: callStatusSchema,
  time: z.number().min(0),
  last_completed_at: z.string().nullish(),
  conversation_id: z.number(),
});

export const callSchema = callListSchema.extend({
  summary: z.string().nullable(),
});

export const callCountsSchema = z
  .object({
    incoming_calls: z.number().min(0),
    me: z.number().min(0),
    teams: z.record(z.coerce.number(), z.number()).nullish(),
    total_completed: z.number().min(0),
    total_reviews: z.number().min(0),
    unassigned: z.number().min(0),
  })
  .strict();

export const callNextCursorSchema = z.number();

export const callNoteSchema = z.object({
  assistant_call_id: z.number(),
  assistant_call_note_id: z.number(),
  created_at: z.string(),
  created_by: z.number(),
  note: z.string(),
  updated_at: z.string(),
  updated_by: z.number(),
});

export const inboxPatientSchema = z.object({
  patient_birthdate: z.string().nullable(),
  patient_full_name: z.string(),
  patient_gender: z.string().nullable(),
  patient_id: z.number(),
  patient_npi: z.coerce.string().nullable(),
  patient_phone_number: z.string().nullable(),
  patient_preferred_name: z.string().nullable(),
  patient_pronouns: z.string().nullable(),
  patient_sex: z.string().nullable(),
});
// .strict();

export const inboxItemSchema = z.object({
  data: callListSchema,
  type: inboxItemTypeSchema,
});
// .strict();

export const inboxListItemSchema = z.object({
  patient: inboxPatientSchema,
  items: z.array(inboxItemSchema).min(1),
});

export const inboxListSchema = z.array(inboxListItemSchema);

export const searchCallResponseSchema = z.object({
  data: inboxListSchema,
  next_cursor: callNextCursorSchema,
  status: z.string(),
});

export const searchCallResponseWithDetailsSchema = z.object({
  data: inboxListSchema,
  total: z.number().min(1),
  status: z.string(),
  last_completed_at: z.string().nullable(),
  assigned_to_id: z.number().nullable(),
  assigned_to_team_id: z.number().nullable(),
  site_id: z.number().nullable(),
});
