import { useUrlState } from "@src/hooks/useUrlState";
import { useAppSelector } from "../../../store";
import { selectUser } from "../../../store/user/userReducer";
import TemplatePreviewWithoutContent from "../../Basic/TemplatePreviewWithoutContent";
import Reports from "../Reports";
import ScribeRecordingFooter from "../ScribeRecordingFooter";
import { selectSelectedNoteTemplate } from "../store/noteTemplateSlice";
import { selectScribeById } from "../store/selectors";

const ScribeRecording = ({
  mediaRecorder,
  onCancel,
  saveRecording,
  togglePause,
}: {
  mediaRecorder: MediaRecorder | null;
  onCancel: () => void;
  saveRecording: () => void;
  togglePause: () => void;
}) => {
  const user = useAppSelector(selectUser);
  const selectedNoteTemplate = useAppSelector(selectSelectedNoteTemplate);
  const { value: selectedScribeId } = useUrlState("scribeId", null);
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  // TODO: implement support to switch between Transcript and Note
  return (
    <>
      <div className="w-full h-full min-h-screen flex flex-col md:justify-between px-4 md:px-0 pt-5">
        <main className="h-full pb-6 pr-1 overflow-y-auto scrollbar">
          <div>
            {/* <FreeText
              originalFreeText={selectedScribe?.freeText}
            /> */}

            {/* demo mock reports */}
            {selectedScribe?.patient?.patient_id === 29360 &&
              user?.doctor_id === 15 && <Reports className="mt-6 max-w-2xl" />}

            {selectedNoteTemplate && (
              <TemplatePreviewWithoutContent
                className="hidden md:block mt-6"
                sections={selectedNoteTemplate?.sections}
              />
            )}
          </div>
        </main>
        <ScribeRecordingFooter
          mediaRecorder={mediaRecorder}
          cancelRecording={onCancel}
          saveRecording={saveRecording}
          togglePause={togglePause}
        />
      </div>
    </>
  );
};

export default ScribeRecording;
