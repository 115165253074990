import { searchCallResponseWithDetailsSchema } from "@src/store/call/validationSchemas";

import z from "zod";

export enum WebsocketEvent {
  Chat = "chat",
  Voice = "voice",
  PatientTransferred = "patient_transferred",
}

export const chatEventMentionsSchema = z.array(
  z.object({
    doctor_team_id: z.number().nullish(),
    doctor_id: z.number().nullish(),
  }),
);

export const chatEventDataSchema = z.object({
  type: z.literal(WebsocketEvent.Chat),
  conversation_id: z.number(),
  conversation: searchCallResponseWithDetailsSchema,
  mentions: chatEventMentionsSchema,
});

export const voiceEventDataSchema = z.object({
  type: z.literal(WebsocketEvent.Voice),
  data: z.any(),
});

export const patientTransferredEventDataSchema = z.object({
  type: z.literal(WebsocketEvent.PatientTransferred),
  data: searchCallResponseWithDetailsSchema,
});
