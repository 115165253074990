import Bugsnag from "@bugsnag/js";
import { deepEqual } from "@src/helpers/helpers";
import LocalStorageService from "@src/services/LocalStorageService";
import { updateFeatureFlag } from "@src/store/admin/adminSlice";
import { setSelectedEnrollCampaignAction } from "@src/store/campaign/actions";
import { getCampaigns } from "@src/store/campaign/thunks";
import {
  setActiveFiltersAction,
  setCampaignOptionsAction,
} from "@src/store/conversation/actions";
import { setInitFiltersAction } from "@src/store/conversation/actions";
import { useFeature } from "@src/store/featureFlagSelectors";
import { initializeFeatureFlags } from "@src/store/featureFlagSlice";
import { getNumOfPatients } from "@src/store/patient/thunks";
import { searchPatients } from "@src/store/patient/thunks";
import { getUserSites } from "@src/store/site/thunks";
import { getSites } from "@src/store/site/thunks";
import { FeatureFlags } from "@src/store/types";
import { getTwilioToken } from "@src/store/voiceRecorder/thunks";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router";
import {
  Pages,
  commonCampaigns,
  filtersClear,
  getFiltersInit,
  rowsPerPage,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { logoutAction, setDoctorOptionsAction } from "../../store/user/actions";
import { selectToken, selectUser } from "../../store/user/userReducer";
import Layout from "../Layout";

const storageService = new LocalStorageService();

const ProtectedRoute = () => {
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const { campaigns } = useAppSelector((state) => state.campaign);
  const { campaignFilterOptions, initFilters } = useAppSelector(
    (state) => state.conversation,
  );
  const dispatch = useAppDispatch();

  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const isCCM = useFeature(FeatureFlags.CCM);

  useEffect(() => {
    if (!user || !token) {
      dispatch(logoutAction());
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    if (token && user?.organization?.featureFlags) {
      dispatch(initializeFeatureFlags(user.organization.featureFlags));
    }
  }, [token, user?.organization?.featureFlags, dispatch]);

  useEffect(() => {
    if (!user || !token) {
      return;
    }

    Bugsnag.setUser(user.doctor_id.toString(), user.email, user.display_name);
    Bugsnag.setContext(`org: ${user.customer.name}`);

    dispatch(getSites());
    dispatch(getUserSites(user.doctor_id));
    if (isFrontDesk || isFrontDeskInbox) {
      dispatch(getTwilioToken());
      dispatch(getNumOfPatients(user));
      dispatch(
        searchPatients(
          storageService.getItem("patientSearchInput") ?? "",
          1,
          rowsPerPage,
          storageService.getItem("selectedDoctorPatients") ?? null,
          true,
          () => {},
          storageService.getItem("selectedPatientCampaigns") ?? [],
          storageService.getItem("selectedPatientStatuses") ?? [],
          storageService.getItem("selectedPatientInsurances") ?? [],
        ),
      );
      dispatch(setDoctorOptionsAction(user.doctors));
    }

    if (isCCM) {
      dispatch(getCampaigns());

      // if CCM enabled, enable front desk inbox
      if (!isFrontDeskInbox) {
        dispatch(
          updateFeatureFlag({
            flag: FeatureFlags.FRONT_DESK_INBOX,
            enabled: true,
          }),
        );
      }
    }
  }, [user, isFrontDesk, isFrontDeskInbox, isCCM, dispatch, token]);

  useEffect(() => {
    if (!user || !token) {
      return;
    }

    if (campaigns.length > 0) {
      dispatch(
        setSelectedEnrollCampaignAction(
          storageService.getItem("selectedEnrollCampaign") ?? campaigns[0],
        ),
      );

      const customerCampaigns = campaigns.map((campaign) => ({
        value: campaign.campaign_id,
        text: campaign.campaign,
      }));

      dispatch(
        setCampaignOptionsAction([...customerCampaigns, ...commonCampaigns]),
      );
    }
  }, [campaigns, dispatch, user, token]);

  useEffect(() => {
    if (!user || !token) {
      return;
    }

    if (campaignFilterOptions.length > 0) {
      dispatch(setInitFiltersAction(getFiltersInit(campaignFilterOptions)));
    }
  }, [campaignFilterOptions, dispatch, user, token]);

  useEffect(() => {
    if (!user || !token) {
      return;
    }

    if (initFilters && !deepEqual(initFilters, filtersClear)) {
      dispatch(
        setActiveFiltersAction(
          storageService.getItem("activeFilters") ?? initFilters,
        ),
      );
    }
  }, [initFilters, dispatch, user, token]);

  if (!user || !token) {
    return <Navigate to={Pages.LOGIN} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default ProtectedRoute;
