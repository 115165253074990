import Popover from "@mui/material/Popover";
import Skeleton from "@mui/material/Skeleton";
import {
  useGetSelectedAssistantQuery,
  useGetVoicesQuery,
  useSelectVoiceMutation,
} from "@src/store/voiceAgent/api";
import type { Voice as VoiceType } from "@src/store/voiceAgent/types";
import type React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/pause-filled.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play.svg";
import ProfilePicture from "../../Basic/ProfilePicture";

const Voice = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { data: voices, isLoading: isLoadingVoices } = useGetVoicesQuery();
  const { data: selectedAssistant, isLoading: isLoadingSelected } =
    useGetSelectedAssistantQuery();
  const [selectVoice] = useSelectVoiceMutation();

  useEffect(() => {
    if (audio) {
      const handleAbort = () => setIsPlaying(false);

      audio.addEventListener("abort", handleAbort);

      return () => {
        audio.pause();
        audio.removeEventListener("abort", handleAbort);
        setAudio(null);
        setIsPlaying(false);
      };
    }
  }, [audio]);

  const handleOpenList = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseList = () => {
    setAnchorEl(null);
  };

  const handleSelectVoice = (voice: VoiceType) => {
    handleCloseList();
    if (
      selectedAssistant?.assistant_voice?.assistant_voice_id !==
      voice.assistant_voice_id
    ) {
      if (audio) {
        audio.pause();
        setAudio(null);
        setIsPlaying(false);
      }
      selectVoice(voice);
    }
  };

  const playPauseAudio = () => {
    if (!selectedAssistant?.assistant_voice?.audio_sample) {
      return;
    }

    // if there's no audio instance, create one and set it in state
    if (!audio) {
      const newAudio = new Audio(
        selectedAssistant.assistant_voice.audio_sample,
      );

      // handle when the audio ends
      newAudio.onended = () => {
        setIsPlaying(false);
      };

      setAudio(newAudio);
      newAudio.play();
      setIsPlaying(true);
    } else {
      // if the audio is already playing, pause it
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        // if the audio is paused, play it again
        audio.play();
        setIsPlaying(true);
      }
    }
  };

  const renderVoices = (voices: VoiceType[]) => {
    return (
      <>
        {voices?.map((voice) => (
          <button
            type="button"
            key={voice.assistant_voice_id}
            className="w-full grid grid-cols-2-right items-center gap-2 p-2 rounded-lg hover:bg-gray-110 overflow-hidden"
            onClick={() => handleSelectVoice(voice)}
          >
            <ProfilePicture
              size={8}
              fontSize="sm"
              firstName={voice.name}
              src={voice.picture}
              flagPadding={false}
            />
            <div className="w-full flex flex-col font-medium overflow-hidden">
              <p className="w-full text-sm truncate text-left">{voice.name}</p>
              <p className="w-full text-xs text-tertiary truncate text-left">
                {voice.description}
              </p>
            </div>
          </button>
        ))}
      </>
    );
  };

  return (
    <div className="grid grid-rows-tab-layout gap-5 overflow-hidden h-full">
      <p className="text-base font-medium h-10 flex items-center">
        Select Voice Assistant
      </p>

      <div className="h-full w-full overflow-y-auto pr-1 scrollbar">
        <div className="w-full h-fit p-5 rounded-lg border border-gray-250 ">
          <div className="min-h-50 pb-5">
            {isLoadingSelected ? (
              <div className="space-y-2">
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={16}
                  sx={{
                    bgcolor: "#F8F8F8",
                    borderRadius: "16px",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={16}
                  width={250}
                  sx={{
                    bgcolor: "#F8F8F8",
                    borderRadius: "16px",
                  }}
                />
              </div>
            ) : (
              <p className="font-normal">
                {selectedAssistant?.assistant_voice?.text_sample}
              </p>
            )}
          </div>

          <div className="border-t border-gray-250 pt-5 grid items-center grid-cols-conv-layout gap-3 overflow-hidden">
            <div className="w-full overflow-hidden">
              {isLoadingSelected ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={50}
                  width={250}
                  sx={{
                    bgcolor: "#F8F8F8",
                    borderRadius: "50px",
                    border: "1px solid #D0D5DD",
                  }}
                />
              ) : (
                <button
                  type="button"
                  className="grid grid-cols-conv-layout items-center rounded-full border border-gray-250 p-1 pr-4
                    space-x-4 overflow-hidden min-w-0 w-fit"
                  onClick={handleOpenList}
                  disabled={isLoadingSelected || isLoadingVoices}
                >
                  <div className="flex items-center space-x-2 overflow-hidden">
                    <ProfilePicture
                      size={10}
                      fontSize="base"
                      firstName={selectedAssistant?.assistant_voice?.name}
                      src={selectedAssistant?.assistant_voice?.picture}
                      flagPadding={false}
                    />
                    <p className="truncate">
                      {selectedAssistant?.assistant_voice?.name}
                    </p>
                  </div>
                  <ArrowIcon
                    width="10"
                    height="7"
                    className={`transition-all duration-200 ${anchorEl ? "-rotate-180" : ""}`}
                  />
                </button>
              )}

              {anchorEl && (
                <Popover
                  open={Boolean(anchorEl)}
                  onClose={handleCloseList}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        borderRadius: "8px",
                        width: "350px",
                        boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                        margin: "8px 0 0 0",
                      },
                    },
                  }}
                >
                  <div className="w-full max-h-[70vh] height-sm:max-h-108 overflow-hidden py-4 px-3 space-y-1">
                    {renderVoices(voices)}
                  </div>
                </Popover>
              )}
            </div>

            {isLoadingSelected ? (
              <Skeleton
                animation="wave"
                variant="circular"
                height={40}
                width={40}
                sx={{
                  bgcolor: "#F8F8F8",
                }}
              />
            ) : (
              <button
                type="button"
                className={`w-10 h-10 rounded-full flex items-center justify-center  flex-none disabled:opacity-50
                  ${isPlaying ? "bg-primary-blue" : "bg-primary"}`}
                disabled={!selectedAssistant?.assistant_voice?.audio_sample}
                onClick={playPauseAudio}
              >
                {isPlaying ? (
                  <PauseIcon width="22" height="22" fill="white" />
                ) : (
                  <PlayIcon width="14" height="14" fill="white" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voice;
