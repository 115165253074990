import { z } from "zod";

export const scheduleSiteSchema = z.object({
  id: z.number(),
  name: z.string(),
  address: z.string(),
  phoneNumber: z.string(),
});

export const scheduleProviderSchema = z.object({
  id: z.number(),
  name: z.string(),
  profilePicture: z.string().nullish(),
  occupation: z.string(),
  treatingPatientsOver18: z.boolean(),
});

export const appointmentTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const insuranceSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const reasonSchema = z.object({
  id: z.number(),
  name: z.string(),
});

// {
//   "4": {                 // site_id
//     "1": {               // provider_id
//       "2025-03-28":      // date
//       [
//         {                // time slots
//           "id": 10,
//           "appointmentTypeId": 10,
//           "startDateTime": "13:00",
//           "endDateTime": "13:15",
//           "durationMinutes": 15
//         }
//       ]
//     }
//   }
// }

export const timeSlotSchema = z.object({
  id: z.number(),
  appointmentTypeId: z.number(),
  startDateTime: z.string(),
  endDateTime: z.string(),
  durationMinutes: z.string(),
});

export const dateSlotsSchema = z.record(z.string(), z.array(timeSlotSchema));

export const providerSlotsSchema = z.record(z.coerce.number(), dateSlotsSchema);

export const siteSlotsSchema = z.record(z.coerce.number(), providerSlotsSchema);
