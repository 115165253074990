import { useAppDispatch } from "@src/store";
import { sendFinishScribe } from "@src/store/voiceRecorder/thunks";
import { useState } from "react";
import type { Scribe } from "../store/interfaces";
import { useGetNoteTemplatesQuery } from "../store/scribeApi";
import { useUpdateNoteTemplateIdMutation } from "../store/scribeApi";
import type { ScribeAudioDetails } from "../store/scribeEventTypes";

interface ScribeAdminNoteProps {
  scribe: Scribe;
  audioDetails: ScribeAudioDetails;
}

const ScribeAdminNote = ({ scribe, audioDetails }: ScribeAdminNoteProps) => {
  const { data: noteTemplates = [] } = useGetNoteTemplatesQuery(
    { showHidden: true },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const [updateNoteTemplateId, { isLoading, isSuccess, isError, error }] =
    useUpdateNoteTemplateIdMutation();

  const dispatch = useAppDispatch();

  const regenerateNote = () => {
    dispatch(
      sendFinishScribe(
        scribe.patient?.patient_id,
        scribe.audioId,
        () => {},
        () => {},
      ),
    );
  };

  // State to track the selected template ID
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    audioDetails.note_template_id
      ? Number(audioDetails.note_template_id)
      : null,
  );

  // Find the matching template if note_template_id exists
  const templateMatch = selectedTemplateId
    ? noteTemplates.find(
        (template) => template.note_template_id === selectedTemplateId,
      )
    : null;

  // Handler for template selection change
  const handleTemplateChange = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newTemplateId = e.target.value ? Number(e.target.value) : null;
    setSelectedTemplateId(newTemplateId);

    if (scribe.audioId) {
      try {
        await updateNoteTemplateId({
          audioId: scribe.audioId,
          noteTemplateId: newTemplateId,
        }).unwrap();
      } catch (err) {
        console.error("Failed to update template ID:", err);
      }
    }
  };

  return (
    <div className="space-y-4">
      {/* Note Template Section with Dropdown */}
      <div className="mt-4 bg-blue-50 p-6 rounded-md">
        <div className="flex flex-col sm:flex-row sm:justify-between items-center mb-4">
          <h3 className="text-md font-medium text-blue-800 mb-2 sm:mb-0">
            Note Template
          </h3>

          <div className="w-full sm:w-auto">
            <div className="flex items-center">
              <select
                value={selectedTemplateId || ""}
                onChange={handleTemplateChange}
                className="block w-full sm:w-64 bg-white border border-blue-300 hover:border-blue-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-500"
                disabled={isLoading}
              >
                <option value="">Select a template</option>
                {noteTemplates.map((template) => (
                  <option
                    key={template.note_template_id}
                    value={template.note_template_id}
                  >
                    {template.name}
                  </option>
                ))}
              </select>

              {isLoading && (
                <div className="ml-3 text-blue-600">
                  <svg
                    className="animate-spin h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                </div>
              )}

              {isSuccess && (
                <div className="ml-3 text-green-600">
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}

              {isError && (
                <div className="ml-3 text-red-600">
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>

            {isError && (
              <p className="mt-1 text-sm text-red-600">
                Failed to update template:{" "}
                {(error as any)?.data?.message || "Unknown error"}
              </p>
            )}
          </div>
        </div>

        {/* Template details display */}
        {templateMatch ? (
          <div>
            <div className="flex flex-col sm:flex-row sm:justify-between mb-3">
              <div className="text-sm text-blue-700 font-medium">
                {templateMatch.name}
              </div>
              <div className="text-xs text-blue-600">
                ID: {templateMatch.note_template_id}
              </div>
            </div>

            {templateMatch.custom_instructions && (
              <div className="mt-3">
                <div className="text-sm font-medium text-gray-700">
                  Custom Instructions:
                </div>
                <div className="text-sm text-gray-600 bg-white p-3 rounded mt-1 border border-blue-100">
                  {templateMatch.custom_instructions}
                </div>
              </div>
            )}

            <div className="mt-3 flex flex-wrap gap-2">
              {templateMatch.is_custom === 1 && (
                <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                  Custom
                </span>
              )}
              {templateMatch.is_hidden === 1 && (
                <span className="px-2 py-1 bg-gray-200 text-gray-700 text-xs rounded-full">
                  Hidden
                </span>
              )}
              {templateMatch.show_icd10_codes_suggestions === 1 && (
                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                  ICD-10 Suggestions
                </span>
              )}
            </div>

            {templateMatch.additional_toggles &&
              templateMatch.additional_toggles.length > 0 && (
                <div className="mt-3">
                  <div className="text-sm font-medium text-gray-700">
                    Additional Features:
                  </div>
                  <div className="flex flex-wrap gap-2 mt-1">
                    {templateMatch.additional_toggles.map((toggle, index) => (
                      <span
                        key={index}
                        className="px-2 py-1 bg-purple-100 text-purple-800 text-xs rounded-full"
                      >
                        {toggle}
                      </span>
                    ))}
                  </div>
                </div>
              )}
          </div>
        ) : (
          <div className="text-sm text-gray-600 italic">
            {selectedTemplateId
              ? `Template ID: ${selectedTemplateId} (Template details not found)`
              : "No template selected. Select a template from the dropdown above."}
          </div>
        )}
      </div>

      <div className="flex justify-between mb-4">
        <button
          type="button"
          aria-label="Regenerate note"
          onClick={regenerateNote}
          className="bg-primary-blue text-white px-4 py-2 rounded-md"
        >
          Regenerate note
        </button>
      </div>
    </div>
  );
};

export default ScribeAdminNote;
