import { ReactComponent as InboundIcon } from "@assets/icons/call-incoming.svg";
import { ReactComponent as OutboundIcon } from "@assets/icons/call-outcoming.svg";
import { ReactComponent as MessageIcon } from "@assets/icons/chat-bubble-filled.svg";
import { ReactComponent as CheckIcon } from "@assets/icons/check-circle-completed.svg";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import {
  CallDirection,
  CallStatusTab,
  type InboxItem,
  InboxItemType,
  type InboxPatient,
} from "../../../store/call/interfaces";
import { useFilterParams } from "../hooks/useFilterParams";
import Badge from "./Badge";

const tableRowClasses = "w-full items-center grid grid-cols-12 gap-4";
const statusColumnClasses = "col-span-1";
const activityLogColumnClasses = "col-span-3 truncate";
const callerColumnClasses = "col-span-3 truncate";
const reasonColumnClasses = "col-span-5 truncate";

interface CallTableRowProps {
  call: InboxItem;
  patient: InboxPatient;
  numOfSubItems: number;
  isSelected: boolean;
  handleClickCall: () => void;
  handleCompleteCall?: () => void;
}

const CallTableRow = ({
  call,
  patient,
  numOfSubItems,
  isSelected,
  handleClickCall,
  handleCompleteCall,
}: CallTableRowProps) => {
  const { filters } = useFilterParams();

  if (!call) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className={`rounded-md py-3 px-4 cursor-pointer text-sm font-medium ${tableRowClasses}
        ${isSelected ? "bg-primary-blue-light" : "bg-white"}`}
      onClick={handleClickCall}
    >
      <div className={statusColumnClasses}>
        <div className="relative w-[30px] h-[30px]">
          {handleCompleteCall && (
            <button
              type="button"
              onClick={(e) => {
                if (filters.status === CallStatusTab.Pending) {
                  e.preventDefault();
                  e.stopPropagation();
                  handleCompleteCall();
                }
              }}
            >
              <CheckIcon
                width="30"
                height="30"
                fill={
                  filters.status === CallStatusTab.Completed
                    ? "#2970FF"
                    : "#D0D5DD"
                }
                className="flex-none"
              />
            </button>
          )}

          {numOfSubItems > 1 && (
            <div
              className="absolute -bottom-1 -right-1.5 rounded-full w-5 h-5 bg-white text-xs font-semibold
                text-primary-blue flex items-center justify-center border border-gray-300"
            >
              {numOfSubItems}
            </div>
          )}
        </div>
      </div>

      <div className={activityLogColumnClasses}>
        <div className="flex items-center space-x-4 truncate">
          <div
            className="h-10 w-10 rounded-full bg-white border border-gray-foreground flex flex-none
              items-center justify-center"
          >
            {call.type === InboxItemType.Call ? (
              call.data.direction === CallDirection.Inbound ? (
                <InboundIcon width="20" height="20" />
              ) : (
                <OutboundIcon width="20" height="20" />
              )
            ) : (
              <MessageIcon width="20" height="20" />
            )}
          </div>
          <p className="truncate">
            {new Date(
              `${call.data.date.replace(/-/g, "/")} GMT+0`,
            ).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </p>
        </div>
      </div>

      <div className={callerColumnClasses}>
        <div className="flex flex-col">
          <p className="font-semibold">{patient.patient_full_name}</p>
          <p className="text-xs">
            {getFormattedPhoneNumber(patient.patient_phone_number)}
          </p>
        </div>
      </div>

      <div className={reasonColumnClasses}>
        <div className="flex items-center space-x-2 truncate">
          <p className="truncate">
            {call.data.reasons.length > 0 ? call.data.reasons.join(", ") : "-"}
          </p>
          {call.data.is_urgent && (
            <Badge variant="urgent" label="Urgent" withBorder />
          )}
          {call.data.is_new_patient && (
            <Badge variant="new" label="New" withBorder />
          )}
          {call.data.is_external && (
            <Badge variant="violet" label="External" withBorder />
          )}
          {call.data.is_care_team && (
            <Badge variant="violet" label="Care Team" withBorder />
          )}
        </div>
      </div>
    </div>
  );
};

export default CallTableRow;
