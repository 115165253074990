import { ReactComponent as AddNoteIcon } from "@assets/icons/add-note.svg";
import { ReactComponent as InboundIcon } from "@assets/icons/call-incoming.svg";
import { ReactComponent as OutboundIcon } from "@assets/icons/call-outcoming.svg";
import Badge from "@src/components/Inbox/InboxTable/Badge";
import { useAppSelector } from "@src/store";
import {
  useAddCallNoteMutation,
  useGetCallNotesQuery,
} from "@src/store/call/api";
import { type Call, CallDirection } from "@src/store/call/interfaces";
import { selectUser } from "@src/store/user/userReducer";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import CallNoteCard from "./CallNoteCard";
import CallNoteEditing from "./CallNoteEditing";

interface CallInfoCardProps {
  call: Call;
}

const CallInfoCard = ({ call }: CallInfoCardProps) => {
  const { data: callNotes = [] } = useGetCallNotesQuery(call.assistant_call_id);
  const user = useAppSelector(selectUser);

  const [newNoteInput, setNewNoteInput] = useState(false);
  const [newNote, setNewNote] = useState("");
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const [addCallNote] = useAddCallNoteMutation();

  const iconSize = isDesktop ? 24 : 16;

  const secondsToMinutes = (seconds: number | null | undefined): string => {
    if (!seconds || seconds < 0) {
      return "0m 0s";
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const handleAddNote = () => {
    if (newNote.trim()) {
      addCallNote({
        assistantCallId: call.assistant_call_id,
        note: newNote.trim(),
        doctorId: user.doctor_id,
      });
    }
    setNewNoteInput(false);
  };

  const handleCancelNoteAdding = () => {
    setNewNoteInput(false);
  };

  const handleNewNoteInput = () => {
    setNewNoteInput(true);
    setNewNote("");
  };

  const BOLD_TEXT_REGEX = /(\*\*.*?\*\*)/g;

  const formatCallSummary = (text: string) => {
    if (!text) {
      return "";
    }

    return text.split(BOLD_TEXT_REGEX).map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        const content = part.slice(2, -2);
        return <b key={index}>{content}</b>;
      }
      return part;
    });
  };

  return (
    <section className="text-xs leading-snug py-3 px-3 md:px-4 font-medium w-full flex flex-col bg-white rounded-2xl">
      <header className="flex items-center space-x-2 w-full">
        <div
          className="h-8 w-8 md:h-12 md:w-12 rounded-full bg-white border border-gray-foreground flex flex-none
            items-center justify-center"
        >
          {call.direction === CallDirection.Inbound ? (
            <InboundIcon width={iconSize} height={iconSize} />
          ) : (
            <OutboundIcon width={iconSize} height={iconSize} />
          )}
        </div>
        <div className="space-y-0.5 w-full">
          <div className="w-full grid grid-cols-conv-layout gap-2 items-center">
            <p className="capitalize text-sm font-semibold truncate">
              {call.direction} call
            </p>
          </div>
          <div className="w-full grid grid-cols-conv-layout gap-2 items-center text-xs md:text-sm font-medium">
            {call.date && (
              <p className="truncate">
                {(() => {
                  try {
                    return new Date(
                      `${call.date.replace(/-/g, "/")} GMT+0`,
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });
                  } catch (e) {
                    console.error("Invalid date format:", call.date, e);
                    return "Invalid date";
                  }
                })()}
              </p>
            )}
            <p className="text-tertiary">{secondsToMinutes(call.time)}</p>
          </div>
        </div>
      </header>
      {(call.reasons?.length > 0 ||
        call.is_urgent ||
        call.is_new_patient ||
        call.is_external ||
        call.is_care_team ||
        call.summary) && (
        <div className="bg-gray-110 rounded-lg py-5 px-4 whitespace-pre-wrap mt-4">
          <div
            className={`w-full grid grid-cols-2-right gap-2 items-center
            ${
              call.reasons?.length > 0 ||
              call.is_urgent ||
              call.is_new_patient ||
              call.is_external ||
              call.is_care_team
                ? "mb-3"
                : ""
            }`}
          >
            {call.reasons?.length > 0 && (
              <p className="truncate text-right text-sm font-semibold">
                {call.reasons.join(", ")}
              </p>
            )}

            <div className="flex space-x-2">
              {call.is_urgent && <Badge variant="urgent" label="Urgent" />}
              {call.is_new_patient && <Badge variant="new" label="New" />}
              {call.is_external && <Badge variant="violet" label="External" />}
              {call.is_care_team && (
                <Badge variant="violet" label="Care Team" />
              )}
            </div>
          </div>

          {formatCallSummary(call.summary)}
        </div>
      )}
      {(callNotes?.length > 0 || newNoteInput) && (
        <div className="mt-2.5">
          {callNotes.map((note, index) => (
            <CallNoteCard
              key={`${note.assistant_call_note_id}_${index}`}
              callNote={note}
              isFirst={index === 0}
              isLast={!newNoteInput && index === callNotes.length - 1}
            />
          ))}
          {newNoteInput && (
            <div className="relative pl-9 py-2.5">
              <div className="absolute left-3 top-0 bottom-0 h-full w-0 border-l border-dashed border-gray-300" />
              {callNotes.length === 0 && (
                <div className="absolute w-6 h-1/2 bg-white left-0 top-0" />
              )}
              <div className="absolute w-6 h-1/2 bg-white left-0 bottom-0" />
              <div className="absolute left-0 top-1/2 -translate-y-1/2 w-6 h-6 bg-white flex items-center justify-center">
                <div className="w-2 h-2 border-2 border-gray-foreground rounded-full box-content" />
              </div>

              <CallNoteEditing
                value={newNote}
                setValue={setNewNote}
                onDelete={handleCancelNoteAdding}
                onSave={handleAddNote}
                saveLabel="Add"
              />
            </div>
          )}
        </div>
      )}

      {!newNoteInput && (
        <button
          type="button"
          onClick={handleNewNoteInput}
          className="flex items-center justify-center space-x-2 w-fit mt-2.5"
        >
          <AddNoteIcon width="16" height="16" />
          <p className="text-primary-blue text-sm font-semibold">Add Notes</p>
        </button>
      )}
    </section>
  );
};

export default CallInfoCard;
