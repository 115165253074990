import { ReactComponent as EditIcon } from "@assets/icons/pen.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/trash-redesign.svg";
import { ReactComponent as PatientHeartIcon } from "@assets/icons/user-heart-rounded.svg";
import ActionsButton from "@src/components/Basic/ActionsButton";
import PhoneNumbersDropdownButton from "@src/components/Settings/Clinic/PhoneNumbersDropdownButton";
import { getFormattedPhoneNumber } from "@src/helpers/helpers";
import type { BasicSite } from "@src/store/voiceAgent/types";

interface SiteListItemProps {
  site: BasicSite;
  onDeleteSite: (site: BasicSite) => void;
  onEditSite: (site: BasicSite) => void;
  onClick?: () => void;
}

const SiteListItem = ({
  site,
  onDeleteSite,
  onEditSite,
  onClick = () => {},
}: SiteListItemProps) => {
  return (
    <div className="p-4 rounded-xl space-y-3 bg-white" onClick={onClick}>
      <div className="flex items-center justify-between space-x-2">
        <div className="overflow-hidden">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold truncate">{site.name}</p>
            {!!site.is_default && (
              <div className="text-xs text-tertiary font-medium bg-gray-140 rounded px-2 py-1">
                Default
              </div>
            )}
          </div>

          <div className="flex items-center space-x-2 h-6">
            <p className="text-xs text-tertiary truncate">
              {getFormattedPhoneNumber(
                site.direct_phone_numbers[0]?.phone_number,
              ) || "-"}
            </p>
            {site.direct_phone_numbers?.length > 1 && (
              <PhoneNumbersDropdownButton
                phoneNumbers={site.direct_phone_numbers}
              />
            )}
          </div>
        </div>

        {site.site_id >= 0 && (
          <ActionsButton
            actions={[
              {
                icon: (
                  <EditIcon
                    width="20"
                    height="20"
                    className="flex-none opacity-80"
                    stroke="#667085"
                  />
                ),
                label: "Edit",
                onClick: () => onEditSite(site),
              },
              site.total_patients === 0 && {
                icon: (
                  <DeleteIcon
                    width="20"
                    height="20"
                    className="flex-none"
                    stroke="#667085"
                  />
                ),
                label: "Delete",
                onClick: () => onDeleteSite(site),
              },
            ]}
          />
        )}
      </div>
      <div className="grid grid-cols-3 divide-x border-gray-foreground">
        <div className="flex flex-col space-y-2 font-medium truncate pr-3">
          <p className="text-xs text-tertiary">Patients</p>
          <div className="bg-gray-foreground rounded-full flex items-center space-x-1 px-2 py-1 w-fit">
            <PatientHeartIcon
              width={16}
              height={16}
              stroke="#667085"
              className="flex-none"
            />
            <p className="text-xs leading-none text-tertiary font-semibold">
              {site.total_patients}
            </p>
          </div>
        </div>
        <div className="col-span-2 flex flex-col space-y-2 font-medium px-3">
          <p className="text-xs text-tertiary">Address</p>
          <p>{site.assistant_settings?.clinic_address}</p>
        </div>
      </div>
    </div>
  );
};

export default SiteListItem;
