import { ReactComponent as MessageIcon } from "@assets/icons/chat-round.svg";
import { ReactComponent as HeartIcon } from "@assets/icons/heart.svg";
import { ReactComponent as ScribeIcon } from "@assets/icons/microphone-scribe.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/x-mark.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import { useUrlState } from "@src/hooks/useUrlState";
import { FeatureFlags } from "@src/store/types";
import type { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useNavigate, useParams } from "react-router";
import DeleteModal from "../components/Basic/DeleteModal";
import ProfilePicture from "../components/Basic/ProfilePicture";
import TabButtonRound from "../components/Basic/TabButtonRound";
import FinishLaterModal from "../components/IndependentScribe/FinishLaterModal";
import useRecorder from "../components/IndependentScribe/Recorder";
import {
  ERROR_NOTE_GENERATION_DELAYED,
  ERROR_NOT_ENOUGH_TRANSCRIPT,
} from "../components/IndependentScribe/consts";
import { useScribeGenerationDelay } from "../components/IndependentScribe/hooks/useScribeGenerationDelay";
import { setPatientNotesLoadingAction } from "../components/IndependentScribe/store/actions";
import type { Scribe } from "../components/IndependentScribe/store/interfaces";
import {
  selectSelectedNoteTemplate,
  setSelectedNoteTemplate,
} from "../components/IndependentScribe/store/noteTemplateSlice";
import {
  type FixLater,
  setInterruptedRecordingAction,
  setScribePaused,
} from "../components/IndependentScribe/store/scribeSlice";
import {
  getRecordingScribe,
  selectInterruptedRecordingAction,
  selectScribeById,
} from "../components/IndependentScribe/store/selectors";
import { describeNoteTemplate } from "../components/IndependentScribe/store/thunks";
import { SetInterruptedRecordingActionType } from "../components/IndependentScribe/store/types";
import { isGeneratedNote } from "../components/IndependentScribe/utils";
import ChatCallScribePanel from "../components/Layout/ChatCallScribePanel";
import EnrollmentEditFields from "../components/PatientProfile/CareCoordination/EnrollmentEditFields";
import EnrollmentEditHeader from "../components/PatientProfile/CareCoordination/EnrollmentEditHeader";
import EnrollmentsTab from "../components/PatientProfile/CareCoordination/EnrollmentsTab";
import { useNewEnrollmentLogic } from "../components/PatientProfile/CareCoordination/useNewEnrollmentLogic";
import HistoryTab from "../components/PatientProfile/History/HistoryTab";
import InformationTab from "../components/PatientProfile/Information/InformationTab";
import NotesTab from "../components/PatientProfile/Notes/NotesTab";
import PatientShortInfo from "../components/PatientProfile/PatientShortInfo";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
  twilioDeviceStatuses,
} from "../helpers/constants";
import { makeSlug } from "../helpers/helpers";
import { useActivityTracker } from "../hooks/useActivityTracker";
import TwilioService from "../services/TwilioService";
import { store, useAppDispatch, useAppSelector } from "../store";
import { openConversationEvent } from "../store/billingMetrics/thunks";
import { useGetConversationQuery } from "../store/call/api";
import { CallScribeType } from "../store/call/interfaces";
import { cancelUpcomingMessage } from "../store/campaign/thunks";
import {
  setConversationsUpdatedAction,
  setMessagesAction,
} from "../store/conversation/actions";
import { useFeature } from "../store/featureFlagSelectors";
import {
  setLastSelectedPatientProfileAction,
  setProfileLoadingAction,
  setSelectedPatientEnrollmentsAction,
} from "../store/patient/actions";
import { getPatientEnrollments, getPatientInfo } from "../store/patient/thunks";
import { setSelectedSettingsTabAction } from "../store/user/actions";
import { selectUser } from "../store/user/userReducer";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
} from "../store/voiceRecorder/actions";
import {
  getTwilioToken,
  sendFinishScribe,
  sendPatientAudioPart,
  setCallScribeType,
  setIsToScribe,
} from "../store/voiceRecorder/thunks";

const buttonClasses =
  "w-full h-full flex flex-col items-center justify-center text-base font-medium text-tertiary";

const PatientProfile = () => {
  const { id } = useParams();

  const user = useAppSelector(selectUser);
  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const isCCM = useFeature(FeatureFlags.CCM);

  const selectedNoteTemplate = useAppSelector(selectSelectedNoteTemplate);
  const {
    selectedPatientInfo,
    selectedPatientEnrollments,
    lastSelectedPatientProfile,
  } = useAppSelector((state) => state.patient);

  const { data: currentConversation } = useGetConversationQuery(
    selectedPatientInfo?.phone_number && selectedPatientInfo?.conversation_id
      ? Number(selectedPatientInfo?.conversation_id)
      : skipToken,
  );

  const { openCallOptionsModal } = useAppSelector(
    (state) => state.voiceRecorder,
  );

  const interruptedRecordingAction = useAppSelector(
    selectInterruptedRecordingAction,
  );
  const { twilioToken, deviceStatus, callEvent, scribeCalls } = useAppSelector(
    (state) => state.voiceRecorder,
  );

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isWidthLg = useMediaQuery({ minWidth: 1024 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });
  const dispatch = useAppDispatch();

  const { INFORMATION, ENROLLMENTS, NOTES, HISTORY } = patientProfileTabs;

  const [isMuted, setIsMuted] = useState(false);
  const [editEnrollment, setEditEnrollment] = useState(false);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [notEnoughTranscript, setNotEnoughTranscript] = useState(false);
  const [showFinishLaterModal, setShowFinishLaterModal] = useState(false);

  const prevSelectedScribeRef = useRef<Scribe | null>(null);
  const twilioServiceRef = useRef(new TwilioService(dispatch));
  const scribeCallsRef = useRef(scribeCalls);

  useEffect(() => {
    scribeCallsRef.current = scribeCalls;
  }, [scribeCalls]);

  useEffect(() => {
    twilioServiceRef.current.destroyTwilioDevice();
    if (twilioToken && user.customer?.phone_number) {
      twilioServiceRef.current.initializeTwilioDevice(twilioToken);
    }
  }, [twilioToken, user.customer?.phone_number]);

  useEffect(() => {
    if (deviceStatus === twilioDeviceStatuses.OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, dispatch]);

  const { value: selectedScribeId, setValue: setSelectedScribeId } =
    useUrlState("scribeId", null);
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );
  const recordingScribe = useAppSelector(getRecordingScribe);

  const { handleNewEnrollment } = useNewEnrollmentLogic();
  const navigate = useNavigate();

  const {
    value: selectedPatientPanelTab,
    setValue: setSelectedPatientPanelTab,
  } = useUrlState("panel", makeSlug(patientPanelTabs.MESSAGE));

  const {
    value: selectedPatientProfileTab,
    setValue: setSelectedPatientProfileTab,
  } = useUrlState("tab", makeSlug(patientProfileTabs.NOTES));

  const {
    showNoteGenerationDelayedWarning,
    hideNoteGenerationDelayedWarning,
    setShowNoteGenerationDelayedWarning,
    setScribeGenerationDelayed,
  } = useScribeGenerationDelay(selectedScribe);

  const doTracking = useMemo(
    () => (currentConversation ? currentConversation.trackable : false),
    [currentConversation],
  );
  const { restartTimer } = useActivityTracker(doTracking);

  useEffect(() => {
    if (
      selectedPatientProfileTab !== makeSlug(patientProfileTabs.NOTES) &&
      selectedScribeId
    ) {
      setSelectedPatientPanelTab(makeSlug(patientPanelTabs.MESSAGE));
      setSelectedPatientProfileTab(makeSlug(patientProfileTabs.NOTES));
    }
  }, [
    selectedScribeId,
    selectedPatientProfileTab,
    setSelectedPatientPanelTab,
    setSelectedPatientProfileTab,
  ]);

  useEffect(() => {
    if (
      !user.customer.has_ehr_data &&
      selectedPatientProfileTab === makeSlug(patientProfileTabs.HISTORY)
    ) {
      setSelectedPatientProfileTab(makeSlug(patientProfileTabs.NOTES));
      setSelectedPatientPanelTab(makeSlug(patientPanelTabs.MESSAGE));
    }
  }, [
    user.customer.has_ehr_data,
    selectedPatientProfileTab,
    setSelectedPatientPanelTab,
    setSelectedPatientProfileTab,
  ]);

  useEffect(() => {
    if (
      lastSelectedPatientProfile?.info &&
      Number(id) === lastSelectedPatientProfile.info.patient_id
    ) {
      dispatch(
        setSelectedPatientEnrollmentsAction(
          lastSelectedPatientProfile.enrollments,
        ),
      );
    } else {
      dispatch(setProfileLoadingAction(true));
      dispatch(getPatientInfo(Number(id)));
    }

    dispatch(
      describeNoteTemplate(user.note_template_id, (data) =>
        dispatch(setSelectedNoteTemplate(data)),
      ),
    );
    dispatch(setConversationsUpdatedAction(false));

    return () => {
      dispatch(setSelectedPatientEnrollmentsAction(null));

      dispatch(setOpenCallOptionsModalAction(false));
      dispatch(setOpenCallModalAction(false));

      dispatch(setMessagesAction({}));

      dispatch(setPatientNotesLoadingAction(true));
    };
  }, [id, dispatch, lastSelectedPatientProfile, user.note_template_id]);

  useEffect(() => {
    if (
      selectedScribe &&
      !isDesktop &&
      isGeneratedNote(selectedScribe) &&
      prevSelectedScribeRef.current?.audioId === selectedScribe.audioId &&
      !isGeneratedNote(prevSelectedScribeRef.current)
    ) {
      dispatch(setOpenCallOptionsModalAction(false));
    }

    prevSelectedScribeRef.current = selectedScribe;
  }, [selectedScribe, isDesktop, dispatch]);

  useEffect(() => {
    (async () => {
      if (doTracking) {
        restartTimer();
        await openConversationEvent(
          currentConversation.patient.patient_id,
          currentConversation.campaign_type,
        );
      }
    })();
  }, [currentConversation, restartTimer, doTracking]);

  useEffect(() => {
    if (callEvent?.status && twilioServiceRef.current) {
      if (callEvent.status === "ringing") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.RINGING));
      }
      if (callEvent.status === "in-progress") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.IN_PROGRESS));
        setTimeout(() => {
          dispatch(
            setCallScribeType(
              callEvent.callsid,
              selectedPatientPanelTab === makeSlug(patientPanelTabs.MESSAGE)
                ? CallScribeType.Other
                : CallScribeType.CCM,
            ),
          );
          dispatch(setIsToScribe(callEvent.callsid, scribeCallsRef.current));
        }, 3000);
      }
      if (
        callEvent.status === "completed" ||
        callEvent.status === "no-answer"
      ) {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
        twilioServiceRef.current.handleDisconnect();
      }
    }
  }, [
    callEvent?.status,
    callEvent?.callsid,
    selectedPatientPanelTab,
    dispatch,
  ]);

  const isRecordingOrPaused = useMemo(() => {
    return selectedScribe?.isRecording || selectedScribe?.isPaused;
  }, [selectedScribe]);

  const setActiveTabPatientProfile = (tab: string) => {
    setSelectedScribeId(null);

    setTimeout(() => {
      setSelectedPatientProfileTab(makeSlug(tab));
      setSelectedPatientPanelTab(makeSlug(selectedPatientPanelTab));
    }, 100);
  };

  const onCloseEditEnrollment = () => {
    setEditEnrollment(false);
    setSelectedEnrollment(null);
  };

  const onDeleteEnrollment = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    setOpenDeleteModal(true);
  };

  const deleteEnrollment = () => {
    setEditEnrollment(false);
    setSelectedEnrollment(null);
    dispatch(
      cancelUpcomingMessage(
        selectedEnrollment.campaign_id,
        selectedEnrollment.campaign_patient_id,
        selectedEnrollment.patient.patient_id,
        () => {
          dispatch(getPatientEnrollments(Number(id)));
        },
      ),
    );
  };

  const onCloseProfile = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (recordingScribe) {
      e.preventDefault();
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.PATIENT_PROFILE_CLOSE,
        }),
      );

      return;
    }

    dispatch(
      setLastSelectedPatientProfileAction({
        info: selectedPatientInfo,
        enrollments: selectedPatientEnrollments,
      }),
    );
    navigate(-1);
  };

  const handleDisconnect = useCallback(() => {
    setIsMuted(false);
    dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
    twilioServiceRef.current.handleDisconnect();
  }, [dispatch]);

  const handleCall = useCallback((phoneNumber: string, patientId: number) => {
    twilioServiceRef.current.handleCall(phoneNumber, patientId);
  }, []);

  const handleMute = useCallback(() => {
    setIsMuted(!isMuted);
    twilioServiceRef.current.toggleMute(!isMuted);
  }, [isMuted]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (recordingScribe) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to cancel the recording?";
      }
    };

    const handlePopState = (_) => {
      if (recordingScribe) {
        setShowFinishLaterModal(true);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      if (!recordingScribe) {
        setNotEnoughTranscript(false);
        setShowFinishLaterModal(false);
        dispatch(setInterruptedRecordingAction(null));
      }

      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [recordingScribe, dispatch]);

  useEffect(() => {
    setShowFinishLaterModal(!!interruptedRecordingAction);
  }, [interruptedRecordingAction]);

  const sendAudioPart = useCallback(
    (data: Blob, chunkPosition: number) => {
      dispatch(
        sendPatientAudioPart(
          selectedPatientInfo?.patient_id,
          data,
          () => {},
          () => {},
          selectedNoteTemplate ? selectedNoteTemplate.note_template_id : null,
          chunkPosition,
        ),
      );
    },
    [dispatch, selectedPatientInfo?.patient_id, selectedNoteTemplate],
  );

  const finishRecording = (withTranscriptAlert = true) => {
    dispatch(
      sendFinishScribe(
        selectedPatientInfo?.patient_id,
        selectedScribeId,
        () => {},
        (error: AxiosError) => {
          if (
            (error.response.data as FixLater).error ===
            ERROR_NOT_ENOUGH_TRANSCRIPT
          ) {
            dispatch(setScribePaused());

            if (withTranscriptAlert) {
              setNotEnoughTranscript(true);
            }
          }

          if (
            (error.response?.data as FixLater)?.error ===
            ERROR_NOTE_GENERATION_DELAYED
          ) {
            setScribeGenerationDelayed(selectedScribe.audioId, true);
          }
        },
      ),
    );
  };

  const {
    audioContext,
    mediaRecorder,
    microphones,
    microphoneId,
    setMicrophoneId,
    startRecording,
    togglePause,
    cancelRecording,
    saveRecording,
    isStalled,
    isReadyToRecord,
    readyToRecordErrors,
    requestMicrophonePermissions,
  } = useRecorder(sendAudioPart, finishRecording);

  const onCancel = useCallback(
    (actionType = "") => {
      if (
        actionType !==
          SetInterruptedRecordingActionType.PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING &&
        actionType !== SetInterruptedRecordingActionType.PATIENT_CALL &&
        actionType !==
          SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING
      ) {
        setSelectedScribeId(null);
        dispatch(setScribePaused());
      }

      setNotEnoughTranscript(false);
      setShowFinishLaterModal(false);
      setShowNoteGenerationDelayedWarning(false);
      dispatch(setInterruptedRecordingAction(null));
    },
    [dispatch, setShowNoteGenerationDelayedWarning, setSelectedScribeId],
  );

  const handleCancelRecording = useCallback(() => {
    onCancel(interruptedRecordingAction?.type);

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NAVIGATION
    ) {
      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_PROFILE_CLOSE
    ) {
      dispatch(
        setLastSelectedPatientProfileAction({
          info: selectedPatientInfo,
          enrollments: selectedPatientEnrollments,
        }),
      );
      navigate(-1);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NEW_ENROLLMENT
    ) {
      handleNewEnrollment();
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.SETTINGS_NAVIGATION
    ) {
      const { selectedSettingsTab } = store.getState().user;

      if (!selectedSettingsTab) {
        dispatch(setSelectedSettingsTabAction("Profile"));
      }

      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING ||
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.PATIENT_CALL ||
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING
    ) {
      togglePause();
      setSelectedScribeId(null);
      dispatch(setScribePaused());
    }

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_CALL
    ) {
      console.log("handle call interrupted recording action");
      handleCall(
        selectedPatientInfo?.phone_number,
        selectedPatientInfo?.patient_id,
      );
    }
  }, [
    selectedPatientInfo,
    interruptedRecordingAction,
    selectedPatientEnrollments,
    navigate,
    dispatch,
    onCancel,
    handleNewEnrollment,
    handleCall,
    togglePause,
    setSelectedScribeId,
  ]);

  useEffect(() => {
    if (!selectedPatientInfo) {
      dispatch(getPatientInfo(Number(id)));
    }
  }, [selectedPatientInfo, dispatch, id]);

  const countTabs = () => {
    const hasFrontDesk = isFrontDesk || isFrontDeskInbox;
    return [isScribeOnly, hasFrontDesk, isCCM].filter(Boolean).length;
  };

  return (
    <div
      className={`h-full w-full overflow-hidden grid grid-cols-conv-layout bg-gray-background
        ${isDesktop ? "space-x-4" : ""}`}
    >
      <div
        className={`relative flex flex-col overflow-x-auto min-h-0
        ${isDesktop && isHeightSm ? "py-4 pl-4 height-md:py-6 height-md:pl-6" : "md:py-2 md:pl-2"}`}
      >
        <div className="px-4 md:px-0 pt-3 md:pt-0 overflow-x-hidden flex flex-col flex-none">
          <div className="grid grid-cols-conv-layout md:grid-cols-2-right md:gap-4 w-full">
            {isDesktop && (
              <button
                type="button"
                onClick={onCloseProfile}
                className="w-6 h-8 md:w-8 md:bg-white md:border rounded-lg flex items-center justify-center flex-none"
              >
                <CloseIcon stroke="#121212" width="18" height="18" />
              </button>
            )}

            <div className="w-full truncate h-14 lg:h-16">
              {selectedPatientInfo && (
                <div className="flex items-center md:items-start truncate">
                  <ProfilePicture
                    firstName={
                      selectedPatientInfo.preferred_name
                        ? selectedPatientInfo.preferred_name
                        : selectedPatientInfo.first_name
                    }
                    lastName={selectedPatientInfo.last_name}
                    src={selectedPatientInfo.profile_picture}
                    size={isWidthLg ? 16 : 12}
                    fontSize={isWidthLg ? "2xl" : "lg"}
                    className="bg-[#FF9800] flex-none text-2xl hidden lg:flex lg:mr-4"
                    bgColor="[#FF9800]"
                    textColor="white"
                    flagPadding={false}
                  />
                  <div className="truncate">
                    <p className="font-semibold mt-1 md:mt-0 truncate text-base md:text-xl lg:text-2xl">
                      {selectedPatientInfo.preferred_name
                        ? selectedPatientInfo.preferred_name
                        : selectedPatientInfo.first_name}{" "}
                      {selectedPatientInfo.last_name}
                    </p>
                    {isDesktop && (
                      <PatientShortInfo
                        patient={selectedPatientInfo}
                        className="mt-1.5 pb-0.5 flex-wrap text-xs md:text-sm lg:text-base"
                      />
                    )}
                  </div>
                </div>
              )}

              {!isDesktop && selectedPatientInfo && (
                <PatientShortInfo
                  patient={selectedPatientInfo}
                  className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                />
              )}
            </div>

            {!isDesktop && (
              <button
                type="button"
                onClick={onCloseProfile}
                className="w-6 h-8 md:w-8 md:bg-white md:border rounded-lg flex items-center justify-center flex-none"
              >
                <CloseIcon stroke="#121212" width="18" height="18" />
              </button>
            )}
          </div>

          {editEnrollment ? (
            <EnrollmentEditHeader
              onClose={onCloseEditEnrollment}
              enrollment={selectedEnrollment}
            />
          ) : (
            <div className="flex min-w-0 space-x-2 whitespace-nowrap overflow-auto scrollbar pb-0.5 my-5">
              <TabButtonRound
                label={NOTES}
                active={selectedPatientProfileTab}
                onClick={() => setActiveTabPatientProfile(NOTES)}
              />
              {isCCM && (
                <TabButtonRound
                  label={ENROLLMENTS}
                  active={selectedPatientProfileTab}
                  onClick={() => setActiveTabPatientProfile(ENROLLMENTS)}
                />
              )}
              <TabButtonRound
                label={INFORMATION}
                active={selectedPatientProfileTab}
                onClick={() => setActiveTabPatientProfile(INFORMATION)}
              />
              {user.customer.has_ehr_data && (
                <TabButtonRound
                  label={HISTORY}
                  active={selectedPatientProfileTab}
                  onClick={() => setActiveTabPatientProfile(HISTORY)}
                />
              )}
            </div>
          )}
        </div>

        <div
          className={`w-full h-full overflow-y-auto scrollbar
          ${
            editEnrollment || selectedScribe?.note || selectedScribe?.isPaused
              ? `md:pt-0
                ${selectedScribe ? "" : "px-4 pt-4"}`
              : "md:pt-2 px-4 md:px-0"
          }`}
        >
          {editEnrollment && selectedEnrollment && selectedPatientInfo ? (
            <div className="w-full h-full pr-1">
              <EnrollmentEditFields
                enrollment={selectedEnrollment}
                patient={selectedPatientInfo}
              />
            </div>
          ) : (
            <div className="w-full h-full min-h-fit pr-1 flex-none">
              {selectedPatientProfileTab === makeSlug(NOTES) && (
                <NotesTab startRecording={startRecording} />
              )}
              {selectedPatientProfileTab === makeSlug(ENROLLMENTS) && (
                <EnrollmentsTab
                  onDeleteEnrollment={onDeleteEnrollment}
                  setSelectedEnrollment={setSelectedEnrollment}
                />
              )}
              {selectedPatientProfileTab === makeSlug(INFORMATION) &&
                selectedPatientInfo && (
                  <InformationTab patientInfo={selectedPatientInfo} />
                )}
              {user.customer.has_ehr_data &&
                selectedPatientProfileTab === makeSlug(HISTORY) && (
                  <HistoryTab patientInfo={selectedPatientInfo} />
                )}
            </div>
          )}
        </div>

        {!isDesktop && !editEnrollment && !selectedScribe && (
          <div className="fixed bottom-5 w-full px-5">
            <div
              className={`bg-white w-full h-16 rounded-full border grid items-center shadow-button
                grid-cols-${countTabs()}`}
            >
              <NavLink
                replace
                to={`${Pages.PATIENTS}/${id}/?tab=${selectedPatientProfileTab}&panel=${makeSlug(patientPanelTabs.MESSAGE)}`}
                onClick={() => {
                  dispatch(setOpenCallOptionsModalAction(true));
                }}
                className={buttonClasses}
              >
                <MessageIcon width="19" height="19" />
                <p>{patientPanelTabs.MESSAGE}</p>
              </NavLink>
              {isCCM && (
                <NavLink
                  replace
                  to={`${Pages.PATIENTS}/${id}/?tab=${selectedPatientProfileTab}&panel=${makeSlug(patientPanelTabs.CALL)}`}
                  onClick={() => {
                    dispatch(setOpenCallOptionsModalAction(true));
                  }}
                  className={buttonClasses}
                >
                  <HeartIcon width="20" height="20" />
                  <p>{patientPanelTabs.CALL}</p>
                </NavLink>
              )}
              {isScribeOnly && (
                <NavLink
                  replace
                  to={`${Pages.PATIENTS}/${id}/?tab=${selectedPatientProfileTab}&panel=${makeSlug(patientPanelTabs.SCRIBE)}`}
                  onClick={() => {
                    dispatch(setOpenCallOptionsModalAction(true));
                  }}
                  className={buttonClasses}
                >
                  <ScribeIcon width="20" height="20" />
                  <p>{patientPanelTabs.SCRIBE}</p>
                </NavLink>
              )}
            </div>
          </div>
        )}

        {openDeleteModal && selectedEnrollment && (
          <DeleteModal
            title="Delete Campaign"
            description="Are you sure you want to stop this campaign? This action cannot be undone."
            onClose={() => setOpenDeleteModal(false)}
            onDelete={deleteEnrollment}
          />
        )}
      </div>
      <div className={"overflow-hidden md:right-0"}>
        {((selectedPatientInfo && isDesktop) ||
          ((openCallOptionsModal || selectedScribe?.isRecording) &&
            !isDesktop)) && (
          <div
            className={`
              ${
                selectedPatientInfo && isDesktop
                  ? `h-full w-[42vw] min-w-[400px]
                    ${isHeightSm ? "py-4 pr-4 height-md:py-6 height-md:pr-6" : "py-2 pr-2"}`
                  : ""
              }
              ${
                (openCallOptionsModal || isRecordingOrPaused) && !isDesktop
                  ? "absolute top-0 left-0 z-20 h-screen-dynamic w-full"
                  : ""
              }`}
          >
            <ChatCallScribePanel
              handleCall={handleCall}
              handleDisconnect={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              patient={selectedPatientInfo}
              selectedTab={selectedPatientPanelTab}
              scribeTab={isScribeOnly}
              notEnoughTranscript={notEnoughTranscript}
              setNotEnoughTranscript={setNotEnoughTranscript}
              cancelRecording={cancelRecording}
              togglePause={togglePause}
              isStalled={isStalled}
              audioContext={audioContext}
              mediaRecorder={mediaRecorder}
              microphones={microphones}
              microphoneId={microphoneId}
              setMicrophoneId={setMicrophoneId}
              startRecording={startRecording}
              saveRecording={saveRecording}
              onCancelScribe={onCancel}
              isReadyToRecord={isReadyToRecord}
              readyToRecordErrors={readyToRecordErrors}
              requestMicrophonePermissions={requestMicrophonePermissions}
              showNoteGenerationDelayedWarning={
                showNoteGenerationDelayedWarning
              }
              hideNoteGenerationDelayedWarning={
                hideNoteGenerationDelayedWarning
              }
              showBottomCompleteButton={!isFrontDeskInbox}
              inboxCall={isFrontDeskInbox}
            />
          </div>
        )}
      </div>

      {showFinishLaterModal && (
        <FinishLaterModal
          onClose={() => {
            setShowFinishLaterModal(false);
            dispatch(setInterruptedRecordingAction(null));
          }}
          onConfirm={handleCancelRecording}
        />
      )}
    </div>
  );
};

export default PatientProfile;
