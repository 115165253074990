import { handleValidation } from "@src/helpers/commonValidationSchemas";
import { handleRequestError } from "@src/helpers/helpers";
import LocalStorageService from "@src/services/LocalStorageService";
import type {
  AdminCallNumbers,
  AdminCallReasons,
  AdminCallsPerCustomer,
  OrganizationToken,
} from "@src/store/adminMetrics/types";
import {
  adminCallNumbersSchema,
  adminCallReasonsSchema,
  adminCallsPerCustomerSchema,
  organizationTokenSchema,
} from "@src/store/adminMetrics/validationSchemas";
import { baseApi } from "@src/store/baseApi";

const storageService = new LocalStorageService();

export const adminMetricsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminCallNumbers: builder.query<
      AdminCallNumbers,
      { startDate: string; endDate: string; customer_id?: string }
    >({
      query: ({ startDate, endDate, customer_id = "" }) => {
        return {
          url: `/admin/metrics/call-numbers?period=custom&customer_id=${customer_id}&start_date=${startDate}&end_date=${endDate}`,
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load numbers");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: AdminCallNumbers) => {
        handleValidation(
          adminCallNumbersSchema,
          response,
          "getAdminCallNumbers",
        );
        return response;
      },
    }),

    getAdminCallsPerCustomer: builder.query<
      AdminCallsPerCustomer,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => {
        return {
          url: `/admin/metrics/calls-per-customer?period=custom&start_date=${startDate}&end_date=${endDate}`,
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(
            error,
            dispatch,
            "Failed to load metrics by clinic",
          );
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: AdminCallsPerCustomer) => {
        handleValidation(
          adminCallsPerCustomerSchema,
          response,
          "getAdminCallsPerCustomer",
        );
        return response;
      },
    }),

    getAdminCallReasons: builder.query<
      AdminCallReasons,
      { startDate: string; endDate: string; customer_id?: string }
    >({
      query: ({ startDate, endDate, customer_id = "" }) => {
        return {
          url: `/admin/metrics/call-reasons?period=custom&customer_id=${customer_id}&start_date=${startDate}&end_date=${endDate}`,
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to load call reasons");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: AdminCallReasons) => {
        handleValidation(
          adminCallReasonsSchema,
          response,
          "getAdminCallReasons",
        );
        return response;
      },
    }),

    impersonateOrganization: builder.mutation<OrganizationToken, number>({
      query: (organizationId) => {
        return {
          url: `/backoffice/${organizationId}/impersonate`,
          method: "POST",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const onError = (error: any) => {
          handleRequestError(error, dispatch, "Failed to login");
        };

        try {
          await queryFulfilled;
        } catch (error) {
          onError(error);
        }
      },
      transformResponse: (response: OrganizationToken) => {
        handleValidation(
          organizationTokenSchema,
          response,
          "impersonateOrganization",
        );

        const prevToken = storageService.getItem("token");
        const token = response?.token;

        storageService.clear();

        storageService.setItem("token", token);
        storageService.setItem("prevToken", prevToken);
        storageService.setItem("redirectToCallMetrics", "true");

        window.location.reload();

        return response;
      },
    }),
  }),
});

export const {
  useGetAdminCallNumbersQuery,
  useGetAdminCallsPerCustomerQuery,
  useGetAdminCallReasonsQuery,
  useImpersonateOrganizationMutation,
} = adminMetricsApi;
