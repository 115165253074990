import { z } from "zod";

export const adminCallNumbersSchema = z.object({
  total_completed: z.number(),
  total_reviews: z.number(),
  unassigned: z.number(),
  text: z.number(),
  internal: z.number(),
  incoming_calls: z.number(),
  calls_minutes: z.number(),
  average_call_duration: z.number(),
});

export const customerMetricsSchema = z.object({
  customer_id: z.number(),
  customer_name: z.string(),
  total_calls: z.number(),
  total_minutes: z.number(),
  avg_minutes: z.number(),
  text: z.number(),
  internal: z.number(),
  unassigned: z.number(),
  support_email: z.string(),
  organization_id: z.number(),
  completed: z.number(),
  review: z.number(),
});

export const adminCallsPerCustomerSchema = z.object({
  per_customer: z.array(customerMetricsSchema),
});

export const callReasonMetricsSchema = z.object({
  category: z.string(),
  total_calls: z.number(),
  total_minutes: z.number(),
  avg_minutes: z.number(),
  max_minutes: z.number(),
  review_count: z.number(),
  completed_count: z.number(),
  call_percentage: z.number(),
  unassigned: z.number(),
});

export const adminCallReasonsSchema = z.object({
  call_reasons: z.array(callReasonMetricsSchema),
});

export const organizationTokenSchema = z.object({
  token: z.string(),
});
