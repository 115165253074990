import { useUrlState } from "@src/hooks/useUrlState";
import { saveEncounterPatient } from "@src/store/conversation/thunks";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import NewScribe from "../../PatientProfile/NewScribe";
import AssignPatientModal from "../AssignPatientModal";
import ScribeAdminSettings from "../ScribeAdmin/ScribeAdminSettings";
import ScribeHeader from "../ScribeHeader";
import ScribeRecording from "../ScribeRecording";
import ScribeViewDetails from "../ScribeViewDetails";
import ScribeViewDetailsSkeleton from "../ScribeViewDetailsSkeleton";
import { selectScribeById } from "../store/selectors";

const ScribeContainer = ({
  saveRecording,
  patient,
  audioContext,
  mediaRecorder,
  microphoneId,
  microphones,
  setMicrophoneId,
  startRecording,
  onBack,
  onCancel,
  handleAssignPatientId,
  togglePause,
  isReadyToRecord,
  readyToRecordErrors,
  requestMicrophonePermissions,
  onResumeRecording,
}: {
  saveRecording: () => void;
  patient: Patient | null;
  audioContext: AudioContext;
  mediaRecorder: MediaRecorder | null;
  microphoneId: string;
  microphones: { text: string; value: string }[];
  setMicrophoneId: (microphoneId: string) => void;
  startRecording: () => void;
  onBack: () => void;
  onCancel: () => void;
  handleAssignPatientId: (patientId: number) => void;
  togglePause: () => void;
  isReadyToRecord: boolean;
  readyToRecordErrors: string[];
  requestMicrophonePermissions: () => void;
  onResumeRecording: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { value: selectedScribeId } = useUrlState("scribeId", null);
  const { value: showAdminSettings } = useUrlState("admin", false);
  const { value: showAssignPatientModal, setValue: setShowAssignPatientModal } =
    useUrlState("assignPatientModal", false);

  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  const onAssignPatient = useCallback(
    (patientId: number) => {
      handleAssignPatientId(patientId);

      if (selectedScribe?.isPaused) {
        dispatch(saveEncounterPatient(selectedScribe?.audioId, patientId));
      }
    },
    [dispatch, handleAssignPatientId, selectedScribe],
  );

  const onCloseAssignPatientModal = useCallback(() => {
    setShowAssignPatientModal(false);
  }, [setShowAssignPatientModal]);

  const renderNoteView = () => (
    <>
      {selectedScribe?.note ? (
        <ScribeViewDetails
          patient={patient}
          onResumeRecording={onResumeRecording}
        />
      ) : (
        <ScribeViewDetailsSkeleton />
      )}
    </>
  );

  if (!selectedScribe?.isVisible) {
    return (
      <NewScribe
        startRecording={startRecording}
        microphoneId={microphoneId}
        microphones={microphones}
        audioContext={audioContext}
        mediaRecorder={mediaRecorder}
        setMicrophoneId={setMicrophoneId}
        onBack={onBack}
        isReadyToRecord={isReadyToRecord}
        readyToRecordErrors={readyToRecordErrors}
        requestMicrophonePermissions={requestMicrophonePermissions}
      />
    );
  }

  return (
    <>
      <ScribeHeader
        handleAssignPatientId={onAssignPatient}
        onBack={onBack}
        patient={patient}
        setShowAssignPatientModal={setShowAssignPatientModal}
      />
      {selectedScribe?.isRecording ||
      selectedScribe?.isPaused ||
      selectedScribe?.isGenerating ? (
        <ScribeRecording
          mediaRecorder={mediaRecorder}
          onCancel={onCancel}
          saveRecording={saveRecording}
          togglePause={togglePause}
        />
      ) : (
        renderNoteView()
      )}
      {showAdminSettings && <ScribeAdminSettings scribe={selectedScribe} />}
      {showAssignPatientModal && (
        <AssignPatientModal
          handleClose={onCloseAssignPatientModal}
          handleAssignPatientId={onAssignPatient}
        />
      )}
    </>
  );
};

export default ScribeContainer;
