import emptyCompletedSrc from "@assets/empty-threads-completed.svg";
import automaticProfileSrc from "@assets/quadrant-ai.svg";
import type { CompletedByUser } from "@src/store/voiceAgent/types";
import { useMemo } from "react";
import ProfilePicture from "../../../Basic/ProfilePicture";
import AnalyticsLeaderboardSkeleton from "../../../Skeletons/AnalyticsLeaderboardSkeleton";
import AnalyticsTable from "./AnalyticsTable";

interface LeaderboardProps {
  completedByUser: CompletedByUser[];
  isLoading?: boolean;
  className?: string;
}

const Leaderboard = ({
  completedByUser,
  isLoading,
  className = "",
}: LeaderboardProps) => {
  const columns = [
    { label: "User", accessor: "updated_by" },
    { label: "Completed", accessor: "total" },
  ];

  const data = useMemo(() => {
    const tableData = [];
    completedByUser?.forEach((user) => {
      tableData.push({
        updated_by: (
          <div className="flex items-center space-x-2 overflow-hidden">
            <ProfilePicture
              firstName={user.updated_by ? user.updated_by.first_name : ""}
              lastName={user.updated_by ? user.updated_by.last_name : ""}
              src={
                user.updated_by
                  ? user.updated_by.profile_picture
                  : automaticProfileSrc
              }
              flagPadding={false}
            />
            <div className="overflow-hidden font-medium">
              <p className="text-sm truncate">
                {user.updated_by
                  ? user.updated_by.display_name ||
                    `${user.updated_by.first_name} ${user.updated_by.last_name}`
                  : "Auto completed"}
              </p>
              {user.updated_by && (
                <p className="text-xxs text-tertiary truncate">
                  {user.updated_by.occupation}
                </p>
              )}
            </div>
          </div>
        ),
        total: user.total,
      });
    });
    return tableData;
  }, [completedByUser]);

  return (
    <div
      className={`p-5 border border-gray-250 rounded-lg h-fit w-full ${className}`}
    >
      <p>Completions</p>
      <p className="text-xs text-tertiary font-normal mt-1 mb-3">
        User and number of calls completed
      </p>

      {isLoading ? (
        <AnalyticsLeaderboardSkeleton rows={3} />
      ) : completedByUser?.length > 0 ? (
        <AnalyticsTable columns={columns} data={data} />
      ) : (
        <div className="flex flex-col items-center justify-center mb-5 md:mb-0 md:min-h-[240px] xl:min-h-[350px]">
          <img
            src={emptyCompletedSrc}
            alt="No Threads"
            width="440"
            height="200"
          />
          <span className="text-base md:text-xl text-center font-semibold">
            No completed threads
          </span>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
