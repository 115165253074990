import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AnalyticsPeriod } from "@src/store/voiceAgent/types";
import { getDefaultDates } from "@src/utils/dateUtil";
import { forwardRef } from "react";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-down.svg";
import CustomPickerButton from "./CustomPickerButton";
import { useDatePicker } from "./useDatePicker";

interface MonthPickerProps {
  baseUrl: string;
}

const MonthPicker = ({ baseUrl }: MonthPickerProps) => {
  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    isActive,
    handleSelectDate,
    normalizeDate,
  } = useDatePicker({
    periodType: AnalyticsPeriod.MONTH,
    baseUrl: baseUrl,
  });

  const defaultDates = getDefaultDates(AnalyticsPeriod.MONTH);

  const CustomMonthButton = forwardRef<HTMLButtonElement, any>(
    ({ onClick }, ref) => (
      <CustomPickerButton
        ref={ref}
        isActive={isActive}
        onClick={onClick}
        label="Month"
        basePeriodUrl={baseUrl}
        defaultStartDate={defaultDates.start}
        defaultEndDate={defaultDates.end}
      />
    ),
  );

  const renderCustomContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={`customMonthPicker shadow-md ${className}`}>
        {children}
      </CalendarContainer>
    );
  };

  const renderCustomHeader = ({
    date,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => {
    return (
      <div className="flex items-center justify-between px-3 pt-2 text-sm font-medium">
        <button
          type="button"
          onClick={decreaseYear}
          disabled={prevYearButtonDisabled}
          className={prevYearButtonDisabled ? "invisible" : ""}
        >
          <ArrowIcon
            width="10"
            height="10"
            stroke="#0F172A"
            className="rotate-90"
          />
        </button>

        <p className="text-base font-semibold">{date.getFullYear()}</p>

        <button
          type="button"
          onClick={increaseYear}
          disabled={nextYearButtonDisabled}
          className={nextYearButtonDisabled ? "invisible" : ""}
        >
          <ArrowIcon
            width="10"
            height="10"
            stroke="#0F172A"
            className="-rotate-90"
          />
        </button>
      </div>
    );
  };

  return (
    <DatePicker
      selected={normalizeDate(startDate)}
      onChange={handleSelectDate}
      customInput={<CustomMonthButton />}
      calendarContainer={renderCustomContainer}
      renderCustomHeader={renderCustomHeader}
      popperClassName="customMonthPickerPopper"
      popperPlacement="bottom"
      startDate={normalizeDate(startDate)}
      endDate={normalizeDate(endDate)}
      minDate={minDate}
      maxDate={maxDate}
      showMonthYearPicker
      showFourColumnMonthYearPicker
      disabledKeyboardNavigation
    />
  );
};

export default MonthPicker;
