import { ReactComponent as BuildingsIcon } from "@assets/icons/buildings.svg";
import { ReactComponent as TransferIcon } from "@assets/icons/round-transfer-vertical.svg";
import { Popover } from "@mui/material";
import {
  useGetSitesQuery,
  useTransferPatientMutation,
} from "@src/store/voiceAgent/api";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import ModalMobile from "src/components/Basic/ModalMobile";
import type { ConversationPatient } from "src/store/patient/interfaces";

interface SiteTransferDropdownProps {
  patient: ConversationPatient;
  className?: string;
  conversationId: number;
}

const SiteTransferDropdown = ({
  patient,
  className = "",
  conversationId,
}: SiteTransferDropdownProps) => {
  const { data: sites = [] } = useGetSitesQuery();
  const [transferPatient] = useTransferPatientMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  if (sites.length < 2) {
    return null;
  }

  const currentSiteId = patient.site_id;

  const sortedSites = sites
    ?.map((site) => ({
      ...site,
      display: site.name?.trim() || "-",
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  const handleOpenOptions = (e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseOptions = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleTransferPatient = (siteId: number) => {
    if (siteId === patient.site_id) {
      return;
    }

    transferPatient({
      patient_id: patient.patient_id,
      site_id: siteId,
      conversation_id: conversationId,
    });
  };

  const children = (
    <div className="flex flex-col gap-2">
      {sortedSites.map((site) => (
        <button
          type="button"
          key={site.site_id}
          className={`text-sm font-medium p-2 rounded-lg hover:bg-gray-background flex items-center justify-between
            space-x-2 overflow-hidden group
            ${currentSiteId === site.site_id ? "bg-gray-background text-primary" : " text-tertiary"}`}
          onClick={() => handleTransferPatient(site.site_id)}
        >
          <p className="truncate">{site.display}</p>
          {currentSiteId === site.site_id ? (
            <BuildingsIcon width="20" height="20" className="flex-none" />
          ) : (
            <TransferIcon
              width="20"
              height="20"
              className="flex-none opacity-0 group-hover:opacity-100 transition-all duration-100"
              stroke="#667085"
            />
          )}
        </button>
      ))}
    </div>
  );

  return (
    <>
      <button
        type="button"
        className={`w-8 h-8 rounded-lg border border-gray-110 flex items-center justify-center
          ${isOpen ? "bg-primary-blue" : ""} ${className}`}
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={handleOpenOptions}
      >
        <TransferIcon
          width="20"
          height="20"
          stroke={isOpen ? "white" : "#2970FF"}
        />
      </button>

      {isDesktop && isOpen && anchorEl && (
        <Popover
          open={isOpen}
          onClose={handleCloseOptions}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: "230px",
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: "8px 0 0 0",
              },
            },
          }}
        >
          <div
            className="w-full overflow-y-auto scrollbar"
            style={{
              maxHeight: "455px",
            }}
          >
            <div className="p-2" onClick={handleCloseOptions}>
              {children}
            </div>
          </div>
        </Popover>
      )}

      {!isDesktop && (
        <ModalMobile
          open={isOpen}
          onClickAway={handleCloseOptions}
          childHeight={600}
          header={
            <div className="flex items-center justify-between">
              <p>
                Filter{" "}
                {/* {numberOfSelectedFilters > 0 && (
                  <span>({numberOfSelectedFilters})</span>
                )} */}
              </p>
            </div>
          }
        >
          <div
            className="w-full h-fit px-1 pb-5 pt-2"
            onClick={handleCloseOptions}
          >
            {children}
          </div>
        </ModalMobile>
      )}
    </>
  );
};

export default SiteTransferDropdown;
