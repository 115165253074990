import { ReactComponent as UnassignedIcon } from "@assets/icons/archive.svg";
import { ReactComponent as InboxIcon } from "@assets/icons/inbox-redesign2.svg";
import { getTeamIcon } from "@src/components/Settings/Users/Teams/IconSelection";
import { formatNumberWithLimit } from "@src/helpers/helpers";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  getFilterByTeamId,
  getIsFilterByAssignedToCurrentUser,
  getIsFilterByUnassigned,
  getUnreadInboxIndicator,
  setFilterByTeamId,
  setIsFilterByAssignedToCurrentUser,
  setIsFilterByUnassigned,
  setUnreadInboxIndicator,
} from "@src/store/call/callSlice";
import { CallStatusTab } from "@src/store/call/interfaces";
import { useGetTeamsQuery } from "@src/store/user/api";
import { type Team, selectUser } from "@src/store/user/userReducer";
import { useCallback } from "react";
import ProfilePicture from "../../Basic/ProfilePicture";
import { useCallManagement } from "../hooks/useCallManagement";
import FilterButton from "./FilterButton";

const optionStyles =
  "grid grid-cols-middle items-center gap-2 truncate text-sm w-full";
const optionButtonStyles =
  "p-3 w-full rounded-lg hover:bg-primary-blue-light truncate relative";
const activeOptionButtonStyles = "text-primary-blue bg-primary-blue-light";
const countStyles =
  "min-w-4.5 h-4.5 px-1 rounded-full flex items-center justify-center bg-primary-blue-light text-primary-blue text-xxs font-semibold flex-none";
const labelStyles = "text-left truncate";
const unreadIndicatorStyles =
  "absolute top-2 right-2 w-2.5 h-2.5 rounded-full bg-urgent border-2 border-white/90";

const MentionsDropdown = () => {
  const user = useAppSelector(selectUser);

  const isFilterByAssignedToCurrentUser = useAppSelector(
    getIsFilterByAssignedToCurrentUser,
  );
  const isFilterByUnassigned = useAppSelector(getIsFilterByUnassigned);
  const filterByTeamId = useAppSelector(getFilterByTeamId);
  const unreadInboxIndicator = useAppSelector(getUnreadInboxIndicator);
  const dispatch = useAppDispatch();
  const { callCounts, filters } = useCallManagement();

  const isAtLeastOneUnread = Object.values(unreadInboxIndicator).some(
    (value) => value,
  );

  const isFiltersApplied =
    isFilterByAssignedToCurrentUser || filterByTeamId || isFilterByUnassigned;

  const { data: teams = [] } = useGetTeamsQuery({
    search: "",
  });

  const canShowCounts = filters.status === CallStatusTab.Pending;

  const getOptionLabel = useCallback(
    (name: string, isActive?: boolean, displayCount?: boolean) => {
      switch (name) {
        case "inbox":
          return (
            <div className={optionStyles}>
              <InboxIcon
                width="20"
                height="20"
                stroke={isActive ? "#2970FF" : "#121212"}
                className="flex-none"
              />
              <p className={labelStyles}>Inbox</p>
            </div>
          );
        case "me":
          return (
            <div className={optionStyles}>
              <ProfilePicture
                size={5}
                fontSize="xxs"
                firstName={user.first_name}
                lastName={user.last_name}
                flagPadding={false}
                src={user.profile_picture}
              />
              <p className={labelStyles}>
                {user.display_name?.trim()
                  ? user.display_name
                  : user.first_name?.trim() || user.last_name?.trim()
                    ? `${user.first_name} ${user.last_name}`
                    : "-"}
              </p>
              {displayCount && (
                <>
                  <div className={countStyles}>
                    {formatNumberWithLimit(callCounts.me)}
                  </div>
                  {!isActive && unreadInboxIndicator.me && (
                    <div className={unreadIndicatorStyles} />
                  )}
                </>
              )}
            </div>
          );
        case "unassigned":
          return (
            <div className={optionStyles}>
              <UnassignedIcon
                width="20"
                height="20"
                stroke={isActive ? "#2970FF" : "#121212"}
                className="flex-none"
              />
              <p className={labelStyles}>Unassigned</p>
              {displayCount && (
                <>
                  <div className={countStyles}>
                    {formatNumberWithLimit(callCounts.unassigned)}
                  </div>
                  {!isActive && unreadInboxIndicator.unassigned && (
                    <div className={unreadIndicatorStyles} />
                  )}
                </>
              )}
            </div>
          );
        default:
          return null;
      }
    },
    [
      user,
      callCounts.me,
      callCounts.unassigned,
      unreadInboxIndicator.me,
      unreadInboxIndicator.unassigned,
    ],
  );

  const getFilterButtonLabel = useCallback(() => {
    if (isFilterByAssignedToCurrentUser) {
      return getOptionLabel("me");
    }
    if (filterByTeamId) {
      const team = teams.find((team) => team.doctor_team_id === filterByTeamId);
      return (
        <div className={optionStyles}>
          {getTeamIcon(team?.team_icon, "20", "#121212")}
          <p className={labelStyles}>{team?.name.trim() || "-"}</p>
        </div>
      );
    }
    if (isFilterByUnassigned) {
      return getOptionLabel("unassigned");
    }
    return getOptionLabel("inbox");
  }, [
    isFilterByAssignedToCurrentUser,
    isFilterByUnassigned,
    filterByTeamId,
    teams,
    getOptionLabel,
  ]);

  const resetFilters = () => {
    dispatch(setIsFilterByAssignedToCurrentUser(false));
    dispatch(setFilterByTeamId(null));
    dispatch(setIsFilterByUnassigned(false));
  };

  const handleFilterByAssignedToCurrentUser = () => {
    dispatch(setIsFilterByAssignedToCurrentUser(true));
    dispatch(setFilterByTeamId(null));
    dispatch(setIsFilterByUnassigned(false));
    dispatch(setUnreadInboxIndicator({ me: false }));
  };

  const handleFilterByUnassigned = () => {
    dispatch(setIsFilterByUnassigned(true));
    dispatch(setIsFilterByAssignedToCurrentUser(false));
    dispatch(setFilterByTeamId(null));
    dispatch(setUnreadInboxIndicator({ unassigned: false }));
  };

  const handleFilterByTeam = (team: Team) => {
    dispatch(setFilterByTeamId(team.doctor_team_id));
    dispatch(setIsFilterByAssignedToCurrentUser(false));
    dispatch(setIsFilterByUnassigned(false));
    dispatch(
      setUnreadInboxIndicator({
        [team.doctor_team_id.toString()]: false,
      }),
    );
  };

  const sortedTeams = teams
    ?.map((team) => ({
      ...team,
      display: team.name?.trim() || "-",
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  return (
    <FilterButton
      buttonClassName={`bg-white text-sm font-semibold p-2 rounded-md border
        ${isAtLeastOneUnread ? "shadow-inbox border-primary-blue/50" : "border-gray-foreground h-fit w-fit"}`}
      dropdownClassName="p-2"
      width="230px"
      mobileHeight={600}
      label={getFilterButtonLabel()}
      closeOnSelect
      maxHeight="455px"
    >
      <div className="w-full flex flex-col">
        <button
          type="button"
          onClick={resetFilters}
          className={`${optionButtonStyles} ${!isFiltersApplied ? activeOptionButtonStyles : ""}`}
        >
          {getOptionLabel("inbox", !isFiltersApplied, canShowCounts)}
        </button>

        <button
          type="button"
          onClick={handleFilterByUnassigned}
          className={`${optionButtonStyles} ${isFilterByUnassigned ? activeOptionButtonStyles : ""}`}
        >
          {getOptionLabel("unassigned", isFilterByUnassigned, canShowCounts)}
        </button>

        <button
          type="button"
          onClick={handleFilterByAssignedToCurrentUser}
          className={`${optionButtonStyles} ${isFilterByAssignedToCurrentUser ? activeOptionButtonStyles : ""}`}
        >
          {getOptionLabel("me", isFilterByAssignedToCurrentUser, canShowCounts)}
        </button>

        {sortedTeams?.length > 0 &&
          sortedTeams?.map((team) => (
            <button
              key={team.doctor_team_id}
              type="button"
              onClick={() => handleFilterByTeam(team)}
              className={`${optionButtonStyles}
              ${filterByTeamId === team.doctor_team_id ? activeOptionButtonStyles : ""}`}
            >
              <div className={optionStyles}>
                {getTeamIcon(
                  team.team_icon,
                  "20",
                  filterByTeamId === team.doctor_team_id
                    ? "#2970FF"
                    : "#121212",
                )}
                <p className={labelStyles}>{team.name?.trim() || "-"}</p>
                {canShowCounts && (
                  <>
                    <div className={countStyles}>
                      {formatNumberWithLimit(
                        callCounts.teams?.[team.doctor_team_id],
                      )}
                    </div>
                    {filterByTeamId !== team.doctor_team_id &&
                      unreadInboxIndicator[team.doctor_team_id.toString()] && (
                        <div className={unreadIndicatorStyles} />
                      )}
                  </>
                )}
              </div>
            </button>
          ))}
      </div>
    </FilterButton>
  );
};

export default MentionsDropdown;
