import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-right.svg";

interface AnalyticsTableProps {
  className?: string;
  columns: { accessor: string; label: string; isSortable?: boolean }[];
  data: Record<string, any>[];
  onClickRow?: (row: Record<string, any>) => void;
  isRowHighlighted?: (row: Record<string, any>) => boolean;
}

const AnalyticsTable = ({
  className = "",
  columns,
  data,
  onClickRow,
  isRowHighlighted,
}: AnalyticsTableProps) => {
  return (
    <div className={`overflow-auto max-h-full w-full scrollbar ${className}`}>
      <table className="min-w-full">
        <thead className="w-full sticky top-0 z-[9] bg-white">
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                scope="col"
                className="p-0 h-11 text-left text-xs font-semibold text-tertiary uppercase"
              >
                <div
                  className={`flex items-center space-x-2 h-full w-full px-3 xl:px-4 whitespace-nowrap
                    ${column.isSortable ? "cursor-pointer" : ""}`}
                >
                  <p>{column.label}</p>
                  {column.isSortable && (
                    <ArrowIcon
                      width="14"
                      height="14"
                      className="-rotate-90"
                      fill="#667085"
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${onClickRow ? "cursor-pointer group" : ""}`}
                onClick={() => onClickRow?.(row)}
              >
                {columns.map(({ accessor }, columnIndex) => {
                  const cellData =
                    row[accessor] || row[accessor] === 0 ? row[accessor] : "——";
                  return (
                    <td
                      key={accessor}
                      className="whitespace-nowrap text-zinc-700 font-semibold text-xs xl:text-sm py-0 px-0"
                    >
                      <div
                        className={`px-3 xl:px-4 h-14 w-full flex items-center border-t group-hover:bg-gray-background
                            ${isRowHighlighted?.(row) ? "bg-gray-background" : ""}
                            ${columnIndex === 0 ? "border-l" : ""}
                            ${columnIndex === columns.length - 1 ? "border-r" : ""}
                            ${rowIndex === data.length - 1 ? "border-b" : ""}
                            ${columnIndex === 0 && rowIndex === 0 ? "rounded-tl-lg" : ""}
                            ${columnIndex === 0 && rowIndex === data.length - 1 ? "rounded-bl-lg" : ""}
                            ${columnIndex === columns.length - 1 && rowIndex === 0 ? "rounded-tr-lg" : ""}
                            ${columnIndex === columns.length - 1 && rowIndex === data.length - 1 ? "rounded-br-lg" : ""}`}
                      >
                        {cellData}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AnalyticsTable;
