import type { ReactNode } from "react";

interface NumberBadgeProps {
  icon?: ReactNode;
  number: number;
}

const NumberBadge = ({ icon, number }: NumberBadgeProps) => {
  return (
    <div
      className="flex items-center justify-center space-x-1 text-xs text-tertiary bg-[#F0F3F9] w-fit rounded-full
        p-1 min-w-21 border border-gray-foreground"
    >
      {icon ? icon : null}
      <p>{number}</p>
    </div>
  );
};

export default NumberBadge;
