import { useUrlState } from "@src/hooks/useUrlState";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import type { ScribeEvent } from "../store/scribeEventTypes";

import ScribeAdminEventModal from "./ScribeAdminEventModal";
interface ScribeAdminEventLogsProps {
  events: ScribeEvent[];
  isLoading: boolean;
}

const ScribeAdminEventLogs = ({
  events,
  isLoading,
}: ScribeAdminEventLogsProps) => {
  const { value: eventTypeFilter, setValue: setEventTypeFilter } = useUrlState(
    "type",
    null,
  );

  const { value: statusFilter, setValue: setStatusFilter } = useUrlState(
    "status",
    null,
  );

  const { value: selectedEventId, setValue: setSelectedEventId } = useUrlState(
    "eventId",
    null,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedEvent = useMemo(() => {
    if (!selectedEventId || !events) {
      return null;
    }

    return events.find((event) => event.id === Number(selectedEventId)) || null;
  }, [selectedEventId, events]);

  const openEventDetails = (event: ScribeEvent) => {
    setSelectedEventId(event.id);
    setIsModalOpen(true);
  };

  const eventTypes = useMemo(() => {
    if (!events) {
      return [];
    }

    return [...new Set(events.map((event) => event.event))];
  }, [events]);

  const statuses = useMemo(() => {
    if (!events) {
      return [];
    }

    return [...new Set(events.map((event) => event.status))];
  }, [events]);

  const filteredEvents = useMemo(() => {
    if (!events) {
      return [];
    }

    return events
      .filter((event) => {
        const matchesEventType =
          !eventTypeFilter || event.event === eventTypeFilter;
        const matchesStatus = !statusFilter || event.status === statusFilter;
        return matchesEventType && matchesStatus;
      })
      .sort(
        (a, b) =>
          new Date(b.eventTimestamp).getTime() -
          new Date(a.eventTimestamp).getTime(),
      ); // Sort by most recent first
  }, [events, eventTypeFilter, statusFilter]);

  return (
    <>
      <div>
        <div className="flex gap-4 mb-4">
          <div>
            <span className="block text-sm font-medium text-gray-700 mb-1">
              Event Type
            </span>
            <select
              value={eventTypeFilter || ""}
              onChange={(e) => setEventTypeFilter(e.target.value || null)}
              className="block w-60 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-blue focus:border-primary-blue"
            >
              <option value="">All Events</option>
              {eventTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div>
            <span className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </span>
            <select
              value={statusFilter || ""}
              onChange={(e) => setStatusFilter(e.target.value || null)}
              className="block w-60 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-blue focus:border-primary-blue"
            >
              <option value="">All Statuses</option>
              {statuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>

        {isLoading ? (
          <div className="py-4 text-center text-gray-500">
            Loading events...
          </div>
        ) : !filteredEvents.length ? (
          <div className="py-4 text-center text-gray-500">No events found</div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-2 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Timestamp
                  </th>
                  <th className="px-4 py-2 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Event
                  </th>
                  <th className="px-4 py-2 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-4 py-2 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-4 py-2 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredEvents.map((event) => (
                  <tr
                    key={event.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => openEventDetails(event)}
                  >
                    <td className="px-4 py-2 text-sm text-gray-500">
                      {new Date(event.eventTimestamp).toLocaleString()}
                    </td>
                    <td className="px-4 py-2">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          event.event === "note_generating"
                            ? "bg-blue-100 text-blue-800"
                            : event.event === "transcribing"
                              ? "bg-purple-100 text-purple-800"
                              : event.event === "uploading"
                                ? "bg-indigo-100 text-indigo-800"
                                : event.event === "concatenating"
                                  ? "bg-pink-100 text-pink-800"
                                  : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {event.event}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          event.status === "end"
                            ? "bg-green-100 text-green-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {event.status}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-sm">{event.description}</td>
                    <td className="px-4 py-2">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the row click handler from firing
                          openEventDetails(selectedEvent);
                        }}
                        className="text-primary-blue hover:text-primary-blue-dark text-sm font-medium"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {isModalOpen &&
        selectedEvent &&
        createPortal(
          <ScribeAdminEventModal
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            selectedEvent={selectedEvent}
          />,
          document.body,
        )}

      {/* Optionally keep the JSON for debugging */}
      <details className="mt-4">
        <summary className="cursor-pointer text-sm text-gray-500">
          View Raw Event Data
        </summary>
        <pre className="mt-2 bg-gray-100 p-2 rounded text-xs overflow-x-auto">
          {JSON.stringify(events, null, 2)}
        </pre>
      </details>
    </>
  );
};

export default ScribeAdminEventLogs;
