import { useEffect, useState } from "react";
import type { ScribeEvent } from "../store/scribeEventTypes";
import FormattedContent from "./FormattedContent";

interface ScribeAdminEventModalProps {
  setIsModalOpen: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
  selectedEvent: ScribeEvent;
}

const ScribeAdminEventModal = ({
  setIsModalOpen,
  selectedEvent,
}: ScribeAdminEventModalProps) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (isFullScreen) {
          setIsFullScreen(false);
        } else {
          setIsModalOpen(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isFullScreen, setIsModalOpen]);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-[999] animate-fadeIn ${isFullScreen ? "p-0" : "p-2 sm:p-4"}`}
      onClick={(e) => {
        if (e.target === e.currentTarget && !isFullScreen) {
          setIsModalOpen(false);
        }
      }}
    >
      <div
        className={`bg-white shadow-xl flex flex-col animate-scaleIn
        ${
          isFullScreen
            ? "fixed inset-0 rounded-none transition-all duration-300"
            : "rounded-lg max-w-5xl w-full max-h-[90vh] md:max-h-[85vh] sm:max-h-[80vh] transition-all duration-300"
        }
      `}
      >
        {/* Modal header */}
        <div className="p-2 sm:p-4 border-b border-gray-200 flex justify-between items-center bg-gray-50">
          <h3 className="text-md sm:text-lg font-medium text-gray-800">
            Event Details
          </h3>
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => setIsFullScreen(!isFullScreen)}
              className="flex items-center px-2 py-1 text-xs sm:text-sm text-gray-700 hover:text-gray-900 border border-gray-300 rounded-md hover:bg-gray-50"
              title={isFullScreen ? "Exit Full Screen" : "Full Screen"}
            >
              {isFullScreen ? "Exit Full Screen" : "Full Screen"}
              <svg
                className="h-4 w-4 ml-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={
                    isFullScreen
                      ? "M9 9L4 4m0 0h5m-5 0v5M15 15l5 5m0-5h-5m5 0v5"
                      : "M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5"
                  }
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="h-5 w-5 sm:h-6 sm:w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Modal body */}
        <div className="p-3 sm:p-4 md:p-6 overflow-y-auto flex-1">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6 bg-gray-50 p-4 rounded-md">
            <div>
              <h4 className="text-xs uppercase font-medium text-gray-500 mb-1">
                Event Type
              </h4>
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  selectedEvent.event === "note_generating"
                    ? "bg-blue-100 text-blue-800"
                    : selectedEvent.event === "transcribing"
                      ? "bg-purple-100 text-purple-800"
                      : selectedEvent.event === "uploading"
                        ? "bg-indigo-100 text-indigo-800"
                        : selectedEvent.event === "concatenating"
                          ? "bg-pink-100 text-pink-800"
                          : "bg-gray-100 text-gray-800"
                }`}
              >
                {selectedEvent.event}
              </span>
            </div>

            <div>
              <h4 className="text-xs uppercase font-medium text-gray-500 mb-1">
                Status
              </h4>
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  selectedEvent.status === "end"
                    ? "bg-green-100 text-green-800"
                    : "bg-yellow-100 text-yellow-800"
                }`}
              >
                {selectedEvent.status}
              </span>
            </div>

            <div>
              <h4 className="text-xs uppercase font-medium text-gray-500 mb-1">
                Timestamp
              </h4>
              <p className="text-sm font-medium">
                {new Date(selectedEvent.eventTimestamp).toLocaleString()}
              </p>
            </div>

            <div>
              <h4 className="text-xs uppercase font-medium text-gray-500 mb-1">
                Description
              </h4>
              <p className="text-sm font-medium">{selectedEvent.description}</p>
            </div>
          </div>

          {selectedEvent.fullPrompt && (
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-sm font-medium text-gray-700">
                  Full Prompt
                </h4>
                <button
                  type="button"
                  onClick={() => copyToClipboard(selectedEvent.fullPrompt)}
                  className="flex items-center text-xs text-primary-blue hover:text-primary-blue-dark"
                  title="Copy to clipboard"
                >
                  <svg
                    className="h-4 w-4 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                    />
                  </svg>
                  Copy
                </button>
              </div>
              <div className="relative border border-gray-300 rounded-md shadow-sm">
                <div className="absolute right-0 top-0 h-8 w-full bg-gray-100 border-b border-gray-300 flex items-center px-3 rounded-t-md">
                  <span className="text-xs text-gray-500">Prompt</span>
                </div>
                <FormattedContent
                  content={selectedEvent.fullPrompt}
                  eventType={selectedEvent.event}
                />
              </div>
            </div>
          )}

          {selectedEvent.fullResponse && (
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-sm font-medium text-gray-700">
                  Full Response
                </h4>
                <button
                  type="button"
                  onClick={() => copyToClipboard(selectedEvent.fullResponse)}
                  className="flex items-center text-xs text-primary-blue hover:text-primary-blue-dark"
                  title="Copy to clipboard"
                >
                  <svg
                    className="h-4 w-4 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                    />
                  </svg>
                  Copy
                </button>
              </div>
              <div className="relative border border-gray-300 rounded-md shadow-sm">
                <div className="absolute right-0 top-0 h-8 w-full bg-gray-100 border-b border-gray-300 flex items-center px-3 rounded-t-md">
                  <span className="text-xs text-gray-500">Response</span>
                </div>
                <FormattedContent
                  content={selectedEvent.fullResponse}
                  eventType={selectedEvent.event}
                />
              </div>
            </div>
          )}
        </div>

        {/* Modal footer */}
        <div className="p-2 sm:p-4 border-t border-gray-200 flex justify-end bg-gray-50">
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="px-3 py-1 sm:px-4 sm:py-2 bg-primary-blue text-white rounded-md hover:bg-primary-blue-dark transition duration-150 ease-in-out text-sm sm:text-base"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScribeAdminEventModal;
