import type { InboxListItem } from "@src/store/call/interfaces";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { callApi } from "../../../store/call/api";

export const usePrefetchConversations = (
  conversations: InboxListItem[],
  selectedId: number | null,
) => {
  const dispatch = useAppDispatch();
  const PREFETCH_COUNT = 3;

  useEffect(() => {
    if (!conversations?.length || selectedId === null) {
      return;
    }

    const currentIndex = conversations.findIndex(
      (conv) => conv.items[0].data.conversation_id === selectedId,
    );

    if (currentIndex === -1) {
      return;
    }

    const startIndex = Math.min(currentIndex + 1, conversations.length - 1);
    const endIndex = Math.min(
      startIndex + PREFETCH_COUNT,
      conversations.length,
    );

    conversations.slice(startIndex, endIndex).forEach((conv) => {
      if (conv.items[0].data.conversation_id) {
        dispatch(
          callApi.util.prefetch(
            "getConversation",
            conv.items[0].data.conversation_id,
            {
              force: false,
            },
          ),
        );
      }
    });

    if (currentIndex > 0) {
      const prevConv = conversations[currentIndex - 1];
      if (prevConv.items[0].data.conversation_id) {
        dispatch(
          callApi.util.prefetch(
            "getConversation",
            prevConv.items[0].data.conversation_id,
            {
              force: false,
            },
          ),
        );
      }
    }
  }, [conversations, selectedId, dispatch]);

  useEffect(() => {
    if (!conversations?.length || !selectedId) {
      return;
    }

    const currentIndex = conversations.findIndex(
      (conv) => conv.items[0].data.conversation_id === selectedId,
    );

    if (currentIndex === -1) {
      return;
    }

    if (currentIndex > 0) {
      const prevId =
        conversations[currentIndex - 1].items[0].data.conversation_id;
      dispatch(
        callApi.util.prefetch("getConversation", prevId, { force: false }),
      );
    }

    if (currentIndex < conversations.length - 1) {
      const nextId =
        conversations[currentIndex + 1].items[0].data.conversation_id;
      dispatch(
        callApi.util.prefetch("getConversation", nextId, { force: false }),
      );
    }
  }, [conversations, selectedId, dispatch]);
};
