import Button from "@src/components/Basic/Button";
import Checkbox from "@src/components/Basic/Checkbox";
import Input from "@src/components/Basic/Input";
import TextareaAutosizeForm from "@src/components/Basic/TextareaAutosizeForm";
import PhoneInputBlock from "@src/components/VoiceAgent/SupportLineView/PhoneInputBlock";
import type { BasicSite } from "@src/store/voiceAgent/types";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-circle.svg";

interface SiteFormProps {
  onSave: (data) => void;
  onCancel: () => void;
  site: BasicSite;
  className?: string;
}

const SiteForm = ({
  onSave,
  onCancel,
  site,
  className = "",
}: SiteFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: site.name || "",
      clinic_address: site.assistant_settings?.clinic_address || "",
      clinic_hours: site.assistant_settings?.clinic_hours || "",
      site_faq: site.assistant_settings?.faq || "",
      phone_numbers: site.direct_phone_numbers || [],
      is_default: !!site.is_default,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "phone_numbers",
    control,
  });

  const onAddNumber = () => {
    append({
      name: "",
      phone_number: "",
      transfer_to_number: "no_transfer",
      when_to_transfer: "",
      message_to_caller: "",
    });
  };

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`w-full h-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="h-full pl-5 pr-2 pb-5 mr-2 flex flex-col space-y-4 overflow-y-auto scrollbar">
        <Input
          register={register}
          name="name"
          label="Site Name"
          labelClassName="text-base mb-3"
          placeholder="Site Name"
          inputClassName="text-base"
          borderColor="gray-250"
          disabledBgColor="stone-60"
          required="Site name is required"
          withoutAsterisk
          error={errors.name}
        />

        <Controller
          name="is_default"
          control={control}
          render={({ field: { value, onChange, onBlur, ref } }) => (
            <Checkbox
              label="Set as default clinic"
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              checked={value}
              variant="primary-filled"
            />
          )}
        />

        <TextareaAutosizeForm
          register={register}
          name="clinic_address"
          label="Clinic Address"
          placeholder="Clinic Address"
          labelClassName="text-base mb-3"
          textareaClassName="text-base"
          minRows={3}
          maxRows={10}
          borderColor="gray-250"
          disabledBgColor="stone-60"
        />

        <TextareaAutosizeForm
          register={register}
          name="clinic_hours"
          label="Clinic Hours"
          placeholder="Clinic Hours"
          labelClassName="text-base mb-3"
          textareaClassName="text-base"
          minRows={3}
          maxRows={10}
          borderColor="gray-250"
          disabledBgColor="stone-60"
        />

        <div className="space-y-3">
          <p>Direct Phone Numbers</p>

          {fields.map((field, index) => (
            <PhoneInputBlock
              key={field.id}
              register={register}
              control={control}
              index={index}
              remove={remove}
            />
          ))}

          <button
            type="button"
            className="text-sm text-primary-blue flex items-center space-x-2"
            onClick={onAddNumber}
          >
            <PlusIcon width="20" height="20" />
            <p>Add number</p>
          </button>
        </div>

        <TextareaAutosizeForm
          register={register}
          name="site_faq"
          label="Site FAQ"
          placeholder="Site FAQ"
          labelClassName="text-base mb-3"
          textareaClassName="text-base"
          minRows={3}
          maxRows={10}
          borderColor="gray-250"
          disabledBgColor="stone-60"
        />
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5">
        <Button
          type="button"
          className="w-full md:w-fit"
          variant="gray"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button className="w-full md:w-fit" type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

export default SiteForm;
