import { memo } from "react";

interface ScrollToBottomButtonProps {
  unreadMessages: number;
  scrolledUp: boolean;
  conversationLengthChanged: boolean;
  onClick: () => void;
}

const ScrollToBottomButton = ({
  unreadMessages,
  scrolledUp,
  conversationLengthChanged,
  onClick,
}: ScrollToBottomButtonProps) => {
  if (!unreadMessages || !scrolledUp || !conversationLengthChanged) {
    return null;
  }

  return (
    <button
      type="button"
      className="fixed -translate-x-1/2 w-fit flex items-center justify-center text-xs text-white px-3 py-1 rounded-full bg-indigo-350"
      onClick={onClick}
    >
      View new messages
    </button>
  );
};

export default memo(ScrollToBottomButton);
