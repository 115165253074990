import {
  LOGOUT,
  OPEN_SUCCESS_MODAL,
  SET_ASSISTANTS,
  SET_DOCTOR_OPTIONS,
  SET_ERROR,
  SET_NOTIFICATION,
  SET_ROLES,
  SET_ROLE_OPTIONS,
  SET_SELECTED_CARE_MANAGEMENT_TAB,
  SET_SELECTED_CLINIC_TAB,
  SET_SELECTED_NOTES_TAB,
  SET_SELECTED_SETTINGS_TAB,
  SET_SIGNUP_INFO,
  SET_TEAM_SEARCH_VALUE,
  SET_TOKEN,
  SET_USER,
  SET_USERS_WITHOUT_TEAM,
  SET_USER_SEARCH_VALUE,
} from "../types";
import type { Notification } from "./userReducer";

export function logoutAction() {
  return {
    type: LOGOUT,
  };
}

export function setUserAction(user) {
  return {
    type: SET_USER,
    payload: {
      user: user,
    },
  };
}

export function setTokenAction(token) {
  return {
    type: SET_TOKEN,
    payload: {
      token: token,
    },
  };
}
export function setRolesAction(roles) {
  return {
    type: SET_ROLES,
    payload: {
      roles: roles,
    },
  };
}

export function setRoleOptionsAction(roleOptions) {
  return {
    type: SET_ROLE_OPTIONS,
    payload: {
      roleOptions: roleOptions,
    },
  };
}

export function setErrorAction(errorMessage) {
  return {
    type: SET_ERROR,
    payload: {
      errorMessage: errorMessage,
    },
  };
}

export function setNotificationAction(notification: Notification) {
  return {
    type: SET_NOTIFICATION,
    payload: {
      notification: notification,
    },
  };
}

export function setOpenSuccessModalAction(successModal) {
  return {
    type: OPEN_SUCCESS_MODAL,
    payload: {
      successModal: successModal,
    },
  };
}

export function setAssistantsAction(assistants) {
  return {
    type: SET_ASSISTANTS,
    payload: {
      assistants: assistants,
    },
  };
}

export function setSignupInfoAction(signupInfo) {
  return {
    type: SET_SIGNUP_INFO,
    payload: {
      signupInfo: signupInfo,
    },
  };
}

export function setDoctorOptionsAction(doctorOptions) {
  return {
    type: SET_DOCTOR_OPTIONS,
    payload: {
      doctorOptions: doctorOptions,
    },
  };
}

export function setSelectedCareManagementTabAction(selectedCareManagementTab) {
  return {
    type: SET_SELECTED_CARE_MANAGEMENT_TAB,
    payload: {
      selectedCareManagementTab: selectedCareManagementTab,
    },
  };
}

export function setSelectedSettingsTabAction(selectedSettingsTab) {
  return {
    type: SET_SELECTED_SETTINGS_TAB,
    payload: {
      selectedSettingsTab: selectedSettingsTab,
    },
  };
}

export function setSelectedClinicTabAction(selectedClinicTab) {
  return {
    type: SET_SELECTED_CLINIC_TAB,
    payload: {
      selectedClinicTab: selectedClinicTab,
    },
  };
}

export function setSelectedNotesTabAction(selectedNotesTab) {
  return {
    type: SET_SELECTED_NOTES_TAB,
    payload: {
      selectedNotesTab: selectedNotesTab,
    },
  };
}

export function setUserSearchValueAction(userSearchValue) {
  return {
    type: SET_USER_SEARCH_VALUE,
    payload: {
      userSearchValue: userSearchValue,
    },
  };
}

export function setTeamSearchValueAction(teamSearchValue) {
  return {
    type: SET_TEAM_SEARCH_VALUE,
    payload: {
      teamSearchValue: teamSearchValue,
    },
  };
}

export function setUsersWithoutTeamAction(usersWithoutTeam) {
  return {
    type: SET_USERS_WITHOUT_TEAM,
    payload: {
      usersWithoutTeam: usersWithoutTeam,
    },
  };
}
