import type ContentFormattingStrategy from "./ContentFormattingStrategy";

class PromptTemplateFormattingStrategy implements ContentFormattingStrategy {
  canHandle(content: string | null, eventType?: string): boolean {
    if (!content) {
      return false;
    }

    // Check for characteristic patterns of prompt templates
    return (
      content.includes("template you should follow") ||
      content.includes("(Section style:") ||
      (content.includes("\\n") && content.includes("<")) ||
      eventType === "prompt_template"
    );
  }

  format(content: string | null): React.ReactNode {
    if (!content) {
      return null;
    }

    // First, replace escaped newlines with actual breaks
    const processedContent = content
      // Replace escaped newlines with actual breaks
      .replace(/\\n/g, "\n");

    // Split by actual newlines
    const lines = processedContent.split("\n");

    return (
      <div className="prompt-template">
        {lines.map((line, i) => {
          // Use a flexible regex to identify section headers in angle brackets
          // This will match any line that contains text enclosed in angle brackets
          const sectionHeaderMatch = line.match(/<([A-Z][A-Za-z0-9\s_]+)>/);

          if (sectionHeaderMatch) {
            return (
              <h3 key={i} className="font-bold mt-4 mb-2">
                {this.highlightHTMLBrackets(line)}
              </h3>
            );
          }

          // Style section titles
          if (/^[A-Z][A-Za-z\s]+:/.test(line)) {
            return (
              <div key={i} className="mb-1">
                {line}
              </div>
            );
          }

          // Handle lists and indentation
          if (line.trim().startsWith("-") || line.trim().startsWith("*")) {
            return (
              <div key={i} className="ml-4 mb-1">
                • {line.trim().substring(1).trim()}
              </div>
            );
          }

          // Handle numbered lists
          if (/^\d+\./.test(line.trim())) {
            return (
              <div key={i} className="ml-4 mb-1">
                {line}
              </div>
            );
          }

          // Regular content - also highlight HTML brackets if present
          return (
            <div key={i} className="mb-1">
              {this.highlightHTMLBrackets(line)}
            </div>
          );
        })}
      </div>
    );
  }

  // Helper method to highlight HTML brackets
  private highlightHTMLBrackets(text: string): React.ReactNode {
    if (!text.includes("<") && !text.includes(">")) {
      return text;
    }

    // Split the text by HTML-like patterns
    const parts = text.split(/(<[^>]*>)/g);

    return (
      <>
        {parts.map((part, index) => {
          if (part.startsWith("<") && part.endsWith(">")) {
            return (
              <span key={index} className="font-mono font-bold">
                {part}
              </span>
            );
          }
          return part;
        })}
      </>
    );
  }
}

export default PromptTemplateFormattingStrategy;
