import appPreview from "@assets/app-preview.png";
import { ReactComponent as MailIcon } from "@assets/icons/mail.svg";
import { ReactComponent as Logo } from "@assets/logo-quadrant-text-dark.svg";
import wave1 from "@assets/wave1.svg";
import wave2 from "@assets/wave2.svg";
import wave3 from "@assets/wave3.svg";
import wave4 from "@assets/wave4.svg";
import wave5 from "@assets/wave5.svg";
import wave6 from "@assets/wave6.svg";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "react-responsive";
import NotificationsWrapper from "../components/Layout/NotificationsWrapper";
import LoginForm from "../components/Login/LoginForm";
import SignupForm from "../components/Login/SignupForm";
import { getDefaultRoute } from "../helpers/constants";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FeatureFlags } from "@src/store/types";
import { useNavigate } from "react-router";
import { useAppSelector } from "../store";
import { useFeature } from "../store/featureFlagSelectors";
import { selectUser } from "../store/user/userReducer";

const Login = ({ signup }) => {
  const user = useAppSelector(selectUser);
  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isFrontDeskInbox = useFeature(FeatureFlags.FRONT_DESK_INBOX);
  const isFrontDesk = useFeature(FeatureFlags.FRONT_DESK);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();

  const defaultLink = getDefaultRoute({
    isScribeOnlyFeatureEnabled: isScribeOnly,
    isFrontDeskInboxEnabled: isFrontDeskInbox,
    isFrontDeskEnabled: isFrontDesk,
  });

  useEffect(() => {
    if (user) {
      navigate(defaultLink);
    }
  }, [user, defaultLink, navigate]);

  return (
    <NotificationsWrapper>
      <div
        className="h-screen-dynamic w-full md:grid grid-cols-2 xl:grid-cols-login-layout overflow-hidden overflow-y-auto
          scrollbar"
      >
        <div
          className={`min-h-screen-dynamic relative overflow-y-auto scrollbar
            ${isDesktop ? "max-h-screen-dynamic" : ""}`}
        >
          <div className="min-h-screen-dynamic relative z-10 h-full p-5 md:p-8 pb-0 md:pb-0 flex flex-col justify-between">
            <Logo
              width="156"
              height="48"
              className="mb-10 lg:ml-2 lg:-mt-1 flex-none"
            />
            {signup ? <SignupForm /> : <LoginForm />}
            <div
              className="flex flex-col md:flex-row md:items-center md:justify-between text-slate-600 text-xs
                lg:text-sm mt-10 pb-5 md:pb-8"
            >
              <p>© Quadrant Health {new Date().getFullYear()}</p>
              <div className="flex items-center space-x-2 mt-6 md:mt-0">
                <MailIcon width="16" height="12" />
                <p>info@quadrant.health</p>
              </div>
            </div>
          </div>

          <div className="fixed bottom-0 left-0 w-full z-0">
            <img src={wave1} alt="" className="absolute bottom-7 left-0" />
            <img src={wave2} alt="" className="absolute bottom-0 left-0" />
          </div>
        </div>

        <div
          className="bg-primary-blue pt-24 pl-14 lg:pl-[92px] xl:pt-[110px] xl:pl-[105px] h-screen-dynamic overflow-hidden
            relative hidden md:flex items-center"
        >
          <div className="relative my-auto grid grid-rows-tab-layout h-full">
            <div className="w-[38vw] max-w-xl text-white mb-16 ">
              <p className="text-4xl font-bold mb-6">
                The Operating System for Healthcare
              </p>
              <p className="font-medium">
                Supercharge your clinical workflows. Communicate with your
                patients, view relevant clinical insights, and generate notes
                using artificial intelligence.
              </p>
            </div>
            <div className="w-[850px] xl:w-[1038px] 3xl:w-[1440px] h-full">
              <LazyLoadImage
                src={appPreview}
                alt="application preview"
                className="min-w-full rounded-2xl"
              />
            </div>
          </div>

          <div className="absolute top-0 left-0 w-full z-0">
            <img src={wave3} alt="" className="absolute top-0 left-0" />
            <img src={wave4} alt="" className="absolute top-0 left-0" />
          </div>
          <div className="absolute bottom-0 left-0 w-full z-0">
            <img src={wave5} alt="" className="absolute bottom-14 left-0" />
            <img src={wave6} alt="" className="absolute bottom-0 left-0" />
          </div>
        </div>
      </div>
    </NotificationsWrapper>
  );
};

export default Login;
