import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AnalyticsPeriod } from "@src/store/voiceAgent/types";
import { getDefaultDates } from "@src/utils/dateUtil";
import dayjs from "dayjs";
import { forwardRef } from "react";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-down.svg";
import CustomPickerButton from "./CustomPickerButton";
import { useDatePicker } from "./useDatePicker";

interface RangePickerProps {
  baseUrl: string;
}

const RangePicker = ({ baseUrl }: RangePickerProps) => {
  const { startDate, endDate, minDate, maxDate, isActive, handleSelectDate } =
    useDatePicker({
      periodType: AnalyticsPeriod.CUSTOM,
      baseUrl: baseUrl,
      isRange: true,
    });

  const defaultDates = getDefaultDates(AnalyticsPeriod.CUSTOM);

  const CustomButton = forwardRef<HTMLButtonElement, any>(
    ({ onClick }, ref) => (
      <CustomPickerButton
        ref={ref}
        isActive={isActive}
        onClick={onClick}
        label="Custom"
        basePeriodUrl={baseUrl}
        defaultStartDate={defaultDates.start}
        defaultEndDate={defaultDates.end}
      />
    ),
  );

  const renderCustomContainer = ({ className, children }) => (
    <CalendarContainer
      className={`customRangePicker shadow-md flex flex-col sm:flex-row ${className}`}
    >
      {children}
    </CalendarContainer>
  );

  const renderCustomHeader = ({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="flex items-center justify-between px-3 pt-2 text-sm font-medium">
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className={
          prevMonthButtonDisabled || customHeaderCount === 1 ? "invisible" : ""
        }
      >
        <ArrowIcon
          width="10"
          height="10"
          stroke="#0F172A"
          className="rotate-90"
        />
      </button>

      <p className="text-base font-semibold">
        {monthDate.toLocaleString("en-US", {
          month: "short",
          year: "numeric",
        })}
      </p>

      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className={
          nextMonthButtonDisabled || customHeaderCount === 0 ? "invisible" : ""
        }
      >
        <ArrowIcon
          width="10"
          height="10"
          stroke="#0F172A"
          className="-rotate-90"
        />
      </button>
    </div>
  );

  const renderDayContents = (_day: Date, date: Date) => {
    const isStartDay = startDate && dayjs(startDate).isSame(date, "day");
    const isLastDay = endDate && dayjs(endDate).isSame(date, "day");
    const isInBetween =
      startDate &&
      endDate &&
      dayjs(date).isBetween(startDate, endDate, "day", "[]");

    return (
      <div
        className={`flex items-center justify-center w-full h-full
          ${isInBetween ? "bg-primary-blue-light text-primary" : ""}
          ${isStartDay ? "rounded-l-full" : ""}
          ${isLastDay ? "rounded-r-full" : ""}
        `}
      >
        <span
          className={`w-full h-full flex items-center justify-center ${
            isStartDay || isLastDay
              ? "bg-primary-blue rounded-full text-white"
              : ""
          }`}
        >
          {date.getDate()}
        </span>
      </div>
    );
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleSelectDate}
      customInput={<CustomButton />}
      calendarContainer={renderCustomContainer}
      renderCustomHeader={renderCustomHeader}
      renderDayContents={renderDayContents}
      popperPlacement="bottom-end"
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      selectsRange
      calendarStartDay={1}
      monthsShown={2}
      disabledKeyboardNavigation
    />
  );
};

export default RangePicker;
