import { ReactComponent as CallIcon } from "@assets/icons/phone-new.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/x-mark.svg";
import { skipToken } from "@reduxjs/toolkit/query";
import { Pages, twilioDeviceStatuses } from "@src/helpers/constants";
import { getFormattedPhoneNumber } from "@src/helpers/helpers";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  useGetConversationQuery,
  useMoveToCompletedMutation,
  useMoveToReviewMutation,
} from "@src/store/call/api";
import { CallScribeType, type InboxListItem } from "@src/store/call/interfaces";
import { selectUser } from "@src/store/user/userReducer";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
} from "@src/store/voiceRecorder/actions";
import {
  getTwilioToken,
  setCallScribeType,
  setIsToScribe,
} from "@src/store/voiceRecorder/thunks";
import { getCallEvent } from "@src/store/voiceRecorder/voiceRecorderReducer";
import { getDeviceStatus } from "@src/store/voiceRecorder/voiceRecorderReducer";
import { getOpenCallModal } from "@src/store/voiceRecorder/voiceRecorderReducer";
import { getScribeCalls } from "@src/store/voiceRecorder/voiceRecorderReducer";
import { getCurrentTwilioToken } from "@src/store/voiceRecorder/voiceRecorderReducer";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router";
import TwilioService from "../../../services/TwilioService";
import PatientShortInfo from "../../PatientProfile/PatientShortInfo";
import MessageTab from "../../PatientProfile/RightPanel/Message/MessageTab";
import ChatSkeleton from "../../Skeletons/ChatSkeleton/ChatSkeleton";
import useConversationSearchParams from "../hooks/useConversationSearchParams";
import CallControlButtons from "./CallControlButtons";
import CallStatusButtons from "./CallStatusButtons";
import SiteTransferDropdown from "./SiteTransferDropdown";

const buttonStyle =
  "w-8 h-8 rounded-lg border border-gray-110 flex items-center justify-center";

interface CallDetailsProps {
  onClose?: () => void;
  className?: string;
  nextConversation: InboxListItem;
}

const CallDetails = ({
  onClose = () => {},
  className = "",
  nextConversation,
}: CallDetailsProps) => {
  const user = useAppSelector(selectUser);
  const { selectedConversationId, setSelectedConversationId } =
    useConversationSearchParams();

  const { data: callConversation } = useGetConversationQuery(
    selectedConversationId ? Number(selectedConversationId) : skipToken,
  );

  // console.log({ callConversation });

  const twilioToken = useAppSelector(getCurrentTwilioToken);
  const deviceStatus = useAppSelector(getDeviceStatus);
  const callEvent = useAppSelector(getCallEvent);
  const openCallModal = useAppSelector(getOpenCallModal);
  const scribeCalls = useAppSelector(getScribeCalls);

  const dispatch = useAppDispatch();
  const [isMuted, setIsMuted] = useState(false);
  const twilioServiceRef = useRef(new TwilioService(dispatch));
  const [callSeconds, setCallSeconds] = useState(0);
  const callInterval = useRef(null);
  const scribeCallsRef = useRef(scribeCalls);

  const [moveToReview] = useMoveToReviewMutation();
  const [moveToCompleted] = useMoveToCompletedMutation();

  const patient = callConversation?.patient;

  const isCurrentConversationLoaded =
    callConversation?.conversation_id === Number(selectedConversationId);

  // console.log({ patient });

  const patientFormattedPhoneNumber = getFormattedPhoneNumber(
    patient?.phone_number,
  );

  useEffect(() => {
    scribeCallsRef.current = scribeCalls;
  }, [scribeCalls]);

  useEffect(() => {
    if (callInterval.current) {
      clearInterval(callInterval.current);
      callInterval.current = null;
    }

    if (deviceStatus !== twilioDeviceStatuses.IN_PROGRESS) {
      setCallSeconds(0);

      return;
    }

    callInterval.current = setInterval(() => {
      setCallSeconds((callSeconds) => callSeconds + 1);
    }, 1000);

    return () => {
      if (callInterval.current) {
        clearInterval(callInterval.current);
        callInterval.current = null;
      }
    };
  }, [deviceStatus]);

  useEffect(() => {
    twilioServiceRef.current.destroyTwilioDevice();
    if (twilioToken && user.customer?.phone_number) {
      twilioServiceRef.current.initializeTwilioDevice(twilioToken);
    }
  }, [twilioToken, user.customer?.phone_number]);

  useEffect(() => {
    if (deviceStatus === twilioDeviceStatuses.OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, dispatch]);

  useEffect(() => {
    if (callEvent?.status && twilioServiceRef.current) {
      if (callEvent.status === "ringing") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.RINGING));
      }
      if (callEvent.status === "in-progress") {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.IN_PROGRESS));
        dispatch(setCallScribeType(callEvent.callsid, CallScribeType.Other));
        dispatch(setIsToScribe(callEvent.callsid, scribeCallsRef.current));
      }
      if (
        callEvent.status === "completed" ||
        callEvent.status === "no-answer"
      ) {
        dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
        twilioServiceRef.current.handleDisconnect();
      }
    }
  }, [callEvent?.status, callEvent?.callsid, dispatch]);

  const handleDisconnect = useCallback(() => {
    setIsMuted(false);
    dispatch(setDeviceStatusAction(twilioDeviceStatuses.OFFLINE));
    twilioServiceRef.current.handleDisconnect();
  }, [dispatch]);

  const handleCall = useCallback(() => {
    if (patientFormattedPhoneNumber) {
      dispatch(setOpenCallModalAction(true));
      twilioServiceRef.current.handleCall(
        patientFormattedPhoneNumber,
        patient?.patient_id,
      );
    }
  }, [patientFormattedPhoneNumber, patient?.patient_id, dispatch]);

  const handleMute = useCallback(() => {
    setIsMuted(!isMuted);
    twilioServiceRef.current.toggleMute(!isMuted);
  }, [isMuted]);

  const handleCompleteCall = (patientId: number) => {
    if (!patientId || !selectedConversationId) {
      return;
    }

    if (patientId === patient?.patient_id) {
      if (nextConversation?.items[0].data.conversation_id) {
        const nextId =
          nextConversation.items[0].data.conversation_id.toString();

        setSelectedConversationId(nextId);
      } else {
        setSelectedConversationId(null);
      }
    }

    moveToCompleted({
      patientId,
      conversationId: Number(selectedConversationId),
    });
  };

  const handleMoveToReview = (patientId: number) => {
    if (!patientId || !selectedConversationId) {
      return;
    }

    if (patientId === patient?.patient_id) {
      if (nextConversation?.items[0].data.conversation_id) {
        const nextId =
          nextConversation.items[0].data.conversation_id.toString();

        setSelectedConversationId(nextId);
      } else {
        setSelectedConversationId(null);
      }
    }

    moveToReview({ conversation: callConversation });
  };

  return (
    <div className={className}>
      <article
        className="w-full h-full rounded-lg border bg-gray-background2 border-gray-foreground
          grid grid-rows-tab-layout relative overflow-hidden"
      >
        <header className="grid grid-cols-2-right bg-white items-center gap-3 overflow-hidden py-3 px-5">
          <button
            className={buttonStyle}
            onClick={onClose}
            type="button"
            aria-label="Close details"
          >
            <CloseIcon width="16" height="16" className="flex-none" />
          </button>

          <div className="space-y-1 w-full overflow-hidden">
            <div className="grid grid-cols-conv-layout gap-2">
              {isCurrentConversationLoaded && (
                <Link
                  to={`${Pages.PATIENTS}/${patient?.patient_id}`}
                  className="mt-0.5 w-full overflow-hidden"
                >
                  <p className="font-semibold text-left text-xl lg:text-xl truncate">
                    {patient?.preferred_name ||
                      `${patient?.first_name || ""} ${patient?.last_name || ""}`}
                  </p>
                </Link>
              )}
              <div className="flex justify-end">
                {isCurrentConversationLoaded && (
                  <CallStatusButtons
                    onComplete={handleCompleteCall}
                    onMoveToReview={handleMoveToReview}
                    patientId={patient?.patient_id}
                    isCompleted={callConversation.completed === 1}
                    conversation={callConversation}
                  />
                )}
                {isCurrentConversationLoaded && patientFormattedPhoneNumber && (
                  <button
                    className={`ml-2 ${buttonStyle}
                      ${openCallModal ? "bg-primary-blue" : ""}`}
                    onClick={handleCall}
                    disabled={openCallModal || !patientFormattedPhoneNumber}
                    type="button"
                    aria-label="Call"
                  >
                    <CallIcon
                      width="20"
                      height="20"
                      className="flex-none"
                      stroke={openCallModal ? "white" : "#2970FF"}
                      fill={openCallModal ? "white" : "#2970FF"}
                    />
                  </button>
                )}
                {patient && (
                  <SiteTransferDropdown
                    patient={patient}
                    className="ml-2"
                    conversationId={callConversation?.conversation_id}
                  />
                )}
              </div>
            </div>
            <div className="grid grid-cols-conv-layout gap-2 h-6">
              {isCurrentConversationLoaded && patient && (
                <>
                  <PatientShortInfo
                    patient={patient}
                    className="max-w-full min-w-0 w-fit truncate text-xs text-tertiary bg-gray-110 rounded py-1 px-3
                  font-semibold"
                    showBirthdate={false}
                  />
                  <p className="text-base text-tertiary font-medium ml-auto">
                    {patientFormattedPhoneNumber}
                  </p>
                </>
              )}
            </div>
          </div>
        </header>

        <main className="overflow-hidden grid grid-rows-note-layout">
          {isCurrentConversationLoaded ? (
            <MessageTab patientInfo={callConversation.patient} />
          ) : (
            <div className="pl-4 pb-4 pr-2 mr-2 overflow-y-auto scrollbar h-full w-full">
              <ChatSkeleton />
            </div>
          )}

          {openCallModal && (
            <CallControlButtons
              onClose={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              callSeconds={callSeconds}
            />
          )}
        </main>
      </article>
    </div>
  );
};

export default CallDetails;
