import { z } from "zod";

export const billingNumbersSchema = z.object({
  enrolled: z.number().min(0),
  ready_to_bill: z.number().min(0),
});

export const billingPatientResultSchema = z.object({
  billingType: z.string(),
  birthdate: z.string().nullable(),
  conditions: z.string(),
  cptCode: z.array(z.union([z.string(), z.number()])),
  first_name: z.string(),
  last_name: z.string(),
  monthlyBillId: z.number(),
  note: z.string().nullable(),
  note_status: z.string(),
  note_title: z.string(),
  patientId: z.number(),
  streaks: z.number().min(0),
  totalTime: z.string(),
});

export const billingPatientsSchema = z.object({
  results: z.array(billingPatientResultSchema),
  totalCount: z.number().min(0),
  note_generated_all: z.boolean(),
});

export const staffSchema = z.object({
  doctor_id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  monthly_bill_staff_adjustment_id: z.number(),
  reason: z.string(),
  seconds: z.number(),
});

export const billingPatientSchema = z.object({
  monthlyBillId: z.number(),
  note: z.string().nullable(),
  noteStatus: z.string(),
  noteTitle: z.string(),
  staff: z.array(staffSchema),
  totalAdjustedSeconds: z.number(),
  totalSeconds: z.number(),
  totalStaffSeconds: z.number(),
});

export const exportBillingDataSchema = z.string();
