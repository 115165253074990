import type ContentFormattingStrategy from "./ContentFormattingStrategy";

class JsonFormattingStrategy implements ContentFormattingStrategy {
  canHandle(content: string | null): boolean {
    if (!content) {
      return false;
    }

    try {
      JSON.parse(content);
      return true;
    } catch {
      return false;
    }
  }

  format(content: string | null): React.ReactNode {
    if (!content) {
      return null;
    }

    try {
      const parsedJson = JSON.parse(content);
      const formattedJson = JSON.stringify(parsedJson, null, 2);

      // Return formatted JSON with syntax highlighting
      return (
        <pre className="whitespace-pre-wrap">
          {formattedJson.split("\n").map((line, i) => (
            <div key={i} className="json-line">
              {line}
            </div>
          ))}
        </pre>
      );
    } catch {
      return null;
    }
  }
}

export default JsonFormattingStrategy;
