import Input from "@src/components/Basic/Input";
import CountryPhoneInput from "@src/components/Basic/PhoneInput/CountryPhoneInput";
import TextareaAutosizeForm from "@src/components/Basic/TextareaAutosizeForm";
import { countryCodes } from "@src/helpers/constants";
import type { Control, UseFormRegister } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus-circle-filled.svg";

interface PhoneInputBlockProps {
  register: UseFormRegister<any>;
  control: Control<any>;
  index: number;
  remove: (index: number) => void;
  mobileLayoutOnMd?: boolean;
}

const transferOptions = [
  { value: "no_transfer", text: "No Transfer" },
  { value: "auto_transfer", text: "Auto Transfer" },
];

const messageToCallerPlaceholder =
  "Custom message for the caller prior to being forwarded \n(e.g., I am forwarding your call to the Pharmacy. Please stay on the line.)";
const whenToTransferPlaceholder =
  "Instructions for when Agent should transfer call \n(e.g., If the patient requests medications)";

const PhoneInputBlock = ({
  register,
  control,
  index,
  remove,
  mobileLayoutOnMd,
}: PhoneInputBlockProps) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isXl = useMediaQuery({ minWidth: 1280 });

  return (
    <div className="w-full bg-stone-60 p-2.5 rounded-lg border border-gray-250 space-y-2 relative">
      <div
        className={`grid grid-cols-1 gap-2 relative ${mobileLayoutOnMd ? "xl:grid-cols-3" : "md:grid-cols-3"}`}
      >
        <Input
          register={register}
          name={`phone_numbers.${index}.name`}
          placeholder="Name"
          inputClassName={`text-sm h-10 pr-38 ${mobileLayoutOnMd ? "md:pr-30 xl:pr-3" : "md:pr-3"}`}
          borderColor="gray-foreground"
        />
        <CountryPhoneInput
          name={`phone_numbers.${index}.phone_number`}
          countries={countryCodes}
          control={control}
          borderColor="#EAECF0"
          className={mobileLayoutOnMd ? "xl:col-span-2" : "md:col-span-2"}
          paddingRight={
            mobileLayoutOnMd ? (isXl ? 123 : 8) : isDesktop ? 123 : 8
          }
        />

        <select
          {...register(`phone_numbers.${index}.transfer_to_number`)}
          className="absolute top-2 right-2 h-6 pl-2.5 pr-6.5 bg-gray-foreground rounded outline-none truncate
            text-xs font-medium"
        >
          {transferOptions.map((option, index) => (
            <option key={index} value={option.value} className="truncate">
              {option.text}
            </option>
          ))}
        </select>
      </div>

      <TextareaAutosizeForm
        register={register}
        name={`phone_numbers.${index}.message_to_caller`}
        label="Message to Caller"
        labelClassName="text-xs text-tertiary font-medium mb-1"
        placeholder={messageToCallerPlaceholder}
        textareaClassName="text-sm whitespace-pre-wrap"
        minRows={2}
        maxRows={8}
        borderColor="gray-foreground"
      />
      <TextareaAutosizeForm
        register={register}
        name={`phone_numbers.${index}.when_to_transfer`}
        label="When to Transfer"
        labelClassName="text-xs text-tertiary font-medium mb-1"
        placeholder={whenToTransferPlaceholder}
        textareaClassName="text-sm whitespace-pre-wrap"
        minRows={2}
        maxRows={8}
        borderColor="gray-foreground"
      />

      <button
        type="button"
        className="absolute -right-2 -top-4 z-10"
        onClick={() => remove(index)}
      >
        <MinusIcon width="20" height="20" />
      </button>
    </div>
  );
};

export default PhoneInputBlock;
