import { baseApi, validateResponse } from "@src/store/baseApi";
import {
  type ScribeAudioDetails,
  ScribeAudioDetailsSchema,
  type ScribeEvents,
  ScribeEventsSchema,
  type ScribeTranscription,
  ScribeTranscriptionSchema,
} from "./scribeEventTypes";
import {
  type ScribeNoteTemplates,
  noteTemplatesSchema,
} from "./validationSchemas";

export const scribeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNoteTemplates: builder.query<
      ScribeNoteTemplates,
      { showHidden?: boolean }
    >({
      query: ({ showHidden = false }) => {
        return {
          url: `/note-template/list?show_hidden=${showHidden ? 1 : 0}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const validatedNoteTemplates = validateResponse(noteTemplatesSchema)(
          response,
        ) as ScribeNoteTemplates;

        return validatedNoteTemplates;
      },
      providesTags: (_, __) => [
        { type: "Scribe" as const, subType: "noteTemplates" },
      ],
    }),

    getTrackedEvents: builder.query<ScribeEvents, { audioId: string }>({
      query: ({ audioId }) => {
        return {
          url: `/admin/scribe/${audioId}/event`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const validatedEvents = validateResponse(ScribeEventsSchema)(
          response,
        ) as ScribeEvents;

        return validatedEvents;
      },
      providesTags: (_, __, { audioId }) => [
        { type: "Scribe" as const, subType: "events", audioId },
      ],
    }),

    getAudioDetails: builder.query<ScribeAudioDetails, { audioId: string }>({
      query: ({ audioId }) => {
        return {
          url: `/admin/scribe/${audioId}/audio-file`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const validatedAudioDetails = validateResponse(
          ScribeAudioDetailsSchema,
        )(response) as ScribeAudioDetails;

        return validatedAudioDetails;
      },
      providesTags: (_, __, { audioId }) => [
        { type: "Scribe" as const, subType: "audioDetails", audioId },
      ],
    }),

    getTranscription: builder.query<ScribeTranscription, { audioId: string }>({
      query: ({ audioId }) => {
        return {
          url: `/admin/scribe/${audioId}/transcription`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const validatedTranscription = validateResponse(
          ScribeTranscriptionSchema,
        )(response) as ScribeTranscription;

        return validatedTranscription;
      },
      providesTags: (_, __, { audioId }) => [
        { type: "Scribe" as const, subType: "transcription", audioId },
      ],
    }),

    updateNoteTemplateId: builder.mutation<
      any,
      { audioId: string; noteTemplateId: number }
    >({
      query: ({ audioId, noteTemplateId }) => {
        return {
          url: `/admin/scribe/${audioId}/noteTemplateId/${noteTemplateId}`,
          method: "POST",
        };
      },
      invalidatesTags: (_, __, { audioId }) => [
        { type: "Scribe" as const, subType: "audioDetails", audioId },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNoteTemplatesQuery,
  useGetTrackedEventsQuery,
  useGetAudioDetailsQuery,
  useGetTranscriptionQuery,
  useUpdateNoteTemplateIdMutation,
} = scribeApi;
