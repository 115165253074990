import { useAppDispatch } from "@src/store";
import { resetStatusCursors } from "@src/store/call/callSlice";
import type { CallTags } from "@src/store/call/interfaces";
import { useGetCallReasonsQuery } from "@src/store/voiceAgent/api";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "../../../assets/icons/funnel.svg";
import Checkbox from "../../Basic/Checkbox";
import { type FilterParams, useFilterParams } from "../hooks/useFilterParams";
import FilterButton from "./FilterButton";

const checkboxLabelClassName = "capitalize text-sm ml-3 font-medium truncate";

interface CallFilterMobileProps {
  className?: string;
}

const CallFilterMobile = ({ className = "" }: CallFilterMobileProps) => {
  const { filters, updateFilters, resetFilters } = useFilterParams();
  const { data: callReasons } = useGetCallReasonsQuery();
  const [reasons, setReasons] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  // const [selectedDirectionOptions, setSelectedDirectionOptions] = useState<
  //   CallDirection[]
  // >(() => {
  //   if (filters.direction === "all") {
  //     return Object.values(CallDirection);
  //   }
  //   return Object.values(CallDirection).includes(filters.direction)
  //     ? [filters.direction]
  //     : Object.values(CallDirection);
  // });

  // Keep direction options in sync with URL
  // useEffect(() => {
  //   if (
  //     filters.direction === "all" &&
  //     selectedDirectionOptions.length !== Object.values(CallDirection).length
  //   ) {
  //     setSelectedDirectionOptions(Object.values(CallDirection));
  //   } else if (
  //     filters.direction !== "all" &&
  //     !selectedDirectionOptions.includes(filters.direction)
  //   ) {
  //     setSelectedDirectionOptions([filters.direction]);
  //   }
  // }, [filters.direction, selectedDirectionOptions]);

  useEffect(() => {
    if (callReasons?.length > 0) {
      setReasons(callReasons.map((reason) => reason.reason));
    } else {
      setReasons([]);
    }
  }, [callReasons]);

  // const selectedDirectionFilters = filters.direction !== "all" ? 1 : 0;
  const selectedReasonFilters = filters.reasons.length;
  const selectedTagFilters =
    Number(filters.isNewUser) +
    Number(filters.isUrgent) +
    Number(filters.isExternal) +
    Number(filters.isCareTeam);

  // const handleUpdateSelectedDirection = (
  //   selectedOptions: readonly CallDirection[],
  // ) => {
  //   const direction =
  //     selectedOptions.length === 0 || selectedOptions.length === 2
  //       ? "all"
  //       : selectedOptions[0];
  //
  //   updateFilters({
  //     direction,
  //     page: 1,
  //   });
  // };

  // const handleSelectDirectionOption = (option: CallDirection) => {
  //   let updatedSelectedOptions = [...selectedDirectionOptions];
  //
  //   if (selectedDirectionOptions.includes(option)) {
  //     updatedSelectedOptions = updatedSelectedOptions.filter(
  //       (item) => item !== option,
  //     );
  //   } else {
  //     updatedSelectedOptions.push(option);
  //   }
  //
  //   handleUpdateSelectedDirection(updatedSelectedOptions);
  //   setSelectedDirectionOptions(updatedSelectedOptions);
  // };

  const handleSelectReason = useCallback(
    (reason: string) => {
      const updatedReasons = filters.reasons.includes(reason)
        ? filters.reasons.filter((item) => item !== reason)
        : [...filters.reasons, reason];

      dispatch(resetStatusCursors());

      updateFilters({
        reasons: updatedReasons,
      });
    },
    [filters.reasons, updateFilters, dispatch],
  );

  const handleSelectTag = useCallback(
    (tag: keyof CallTags) => {
      const updates: Partial<FilterParams> = {
        ...(tag === "is_new_user" && { isNewUser: !filters.isNewUser }),
        ...(tag === "is_urgent" && { isUrgent: !filters.isUrgent }),
        ...(tag === "is_external" && { isExternal: !filters.isExternal }),
        ...(tag === "is_care_team" && { isCareTeam: !filters.isCareTeam }),
      };

      dispatch(resetStatusCursors());

      updateFilters(updates);
    },
    [
      filters.isNewUser,
      filters.isUrgent,
      filters.isExternal,
      filters.isCareTeam,
      updateFilters,
      dispatch,
    ],
  );

  const handleResetFilters = () => {
    // setSelectedDirectionOptions(Object.values(CallDirection));
    resetFilters();
  };

  return (
    <FilterButton
      label={<FilterIcon width="20" height="20" className="flex-none" />}
      numberOfSelectedFilters={
        // selectedDirectionFilters +
        selectedReasonFilters + selectedTagFilters
      }
      className={className}
      buttonClassName="bg-white border border-gray-foreground rounded-lg p-2.5 h-fit w-fit"
      arrow={false}
      onReset={handleResetFilters}
    >
      <div className="space-y-3">
        <p className="text-base font-medium">Tag</p>

        <div className="grid grid-cols-2 gap-3">
          <Checkbox
            name="New User"
            labelClassName={checkboxLabelClassName}
            variant="primary-filled"
            label="New"
            checked={filters.isNewUser}
            onChange={() => handleSelectTag("is_new_user")}
          />

          <Checkbox
            name="Urgent"
            labelClassName={checkboxLabelClassName}
            variant="primary-filled"
            label="Urgent"
            checked={filters.isUrgent}
            onChange={() => handleSelectTag("is_urgent")}
          />

          <Checkbox
            name="External"
            labelClassName={checkboxLabelClassName}
            variant="primary-filled"
            label="External"
            checked={filters.isExternal}
            onChange={() => handleSelectTag("is_external")}
          />

          <Checkbox
            name="Care Team"
            labelClassName={checkboxLabelClassName}
            variant="primary-filled"
            label="Care Team"
            checked={filters.isCareTeam}
            onChange={() => handleSelectTag("is_care_team")}
          />
        </div>

        {/*<hr className="border-gray-foreground" />*/}

        {/*<p className="text-base font-medium">Activity Log</p>*/}

        {/*<div className="grid grid-cols-2 gap-3">*/}
        {/*  {Object.values(CallDirection).map((direction) => (*/}
        {/*    <Checkbox*/}
        {/*      name={direction}*/}
        {/*      key={direction}*/}
        {/*      labelClassName={checkboxLabelClassName}*/}
        {/*      variant="primary-filled"*/}
        {/*      label={direction}*/}
        {/*      checked={selectedDirectionOptions.includes(direction)}*/}
        {/*      onChange={() => handleSelectDirectionOption(direction)}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</div>*/}

        {reasons.length > 0 && (
          <>
            <hr className="border-gray-foreground" />

            <p className="text-base font-medium">Reason</p>

            <div className="grid grid-cols-2 gap-3">
              {reasons.map((reason) => (
                <Checkbox
                  name={reason}
                  key={reason}
                  labelClassName={checkboxLabelClassName}
                  variant="primary-filled"
                  label={reason}
                  checked={filters.reasons.includes(reason)}
                  onChange={() => handleSelectReason(reason)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </FilterButton>
  );
};

export default CallFilterMobile;
