import {
  useGetSystemMessagesQuery,
  useUpdateSystemMessagesMutation,
} from "@src/store/voiceAgent/api";
import type { SystemMessages as SystemMessageType } from "@src/store/voiceAgent/types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextareaAutosizeForm from "../../Basic/TextareaAutosizeForm";
import InputSkeleton from "../../Skeletons/InputSkeleton";
import SectionControls from "./SectionControls";

const systemMessagesDefault: SystemMessageType = {
  first_message: "",
  end_message: "",
  voicemail_message: "",
  idle_message: "",
};

const SystemMessages = () => {
  const [editMode, setEditMode] = useState(false);

  const { data: systemMessages, isLoading } = useGetSystemMessagesQuery();
  const [updateSystemMessages] = useUpdateSystemMessagesMutation();

  const { handleSubmit, register, reset } = useForm<SystemMessageType>({
    defaultValues: systemMessagesDefault,
  });

  const resetSystemMessages = useCallback(() => {
    if (systemMessages) {
      reset(systemMessages);
    } else {
      reset(systemMessagesDefault);
    }
  }, [systemMessages, reset]);

  useEffect(() => {
    resetSystemMessages();
  }, [resetSystemMessages]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    resetSystemMessages();
  };

  const onSubmit = (data: SystemMessageType) => {
    setEditMode(false);
    updateSystemMessages(data);
  };

  return (
    <div
      className={`grid grid-rows-tab-layout gap-5 overflow-hidden h-full ${editMode ? "pb-13 md:pb-0" : ""}`}
    >
      <SectionControls
        title="Configure greetings and responses for different stages of the call"
        editMode={editMode}
        onEdit={handleEdit}
        onCancel={handleCancel}
        disabled={isLoading}
        formId="system-messages-form"
      />

      <form
        id="system-messages-form"
        onSubmit={handleSubmit(onSubmit)}
        className="grid md:grid-cols-2 gap-5 overflow-y-auto pr-1 scrollbar"
      >
        <div className="space-y-5 md:space-y-6">
          {isLoading ? (
            <InputSkeleton heightPx={166} label="First Message" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="first_message"
              label="First Message"
              placeholder="First Message"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={6}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}
          {isLoading ? (
            <InputSkeleton heightPx={166} label="End Message" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="end_message"
              label="End Message"
              placeholder="End Message"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={6}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}
        </div>

        <div className="space-y-6">
          {isLoading ? (
            <InputSkeleton heightPx={166} label="Voicemail Message" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="voicemail_message"
              label="Voicemail Message"
              placeholder="Voicemail Message"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={6}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}

          {isLoading ? (
            <InputSkeleton heightPx={166} label="Idle Message" />
          ) : (
            <TextareaAutosizeForm
              register={register}
              name="idle_message"
              label="Idle Message"
              placeholder="Idle Message"
              disabled={!editMode}
              labelClassName="text-base mb-3"
              textareaClassName="text-base"
              minRows={6}
              maxRows={14}
              borderColor="gray-250"
              disabledBgColor="stone-60"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default SystemMessages;
