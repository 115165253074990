import {
  useGetProtocolMessagesQuery,
  useUpdateProtocolMessagesMutation,
} from "@src/store/voiceAgent/api";
import type { ProtocolMessages } from "@src/store/voiceAgent/types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextareaAutosizeForm from "../../Basic/TextareaAutosizeForm";
import InputSkeleton from "../../Skeletons/InputSkeleton";
import SectionControls from "./SectionControls";

const protocolMessagesDefault: ProtocolMessages = {
  urgent_message: "",
  urgent_flags: "",
};

const Protocols = () => {
  const [editMode, setEditMode] = useState(false);

  const { handleSubmit, register, reset } = useForm<ProtocolMessages>({
    defaultValues: protocolMessagesDefault,
  });

  const { data: protocolMessages, isLoading } = useGetProtocolMessagesQuery();
  const [updateProtocolMessages] = useUpdateProtocolMessagesMutation();

  const resetProtocolMessages = useCallback(() => {
    if (protocolMessages) {
      reset(protocolMessages);
    } else {
      reset(protocolMessagesDefault);
    }
  }, [protocolMessages, reset]);

  useEffect(() => {
    resetProtocolMessages();
  }, [resetProtocolMessages]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    resetProtocolMessages();
  };

  const onSubmit = (data: ProtocolMessages) => {
    setEditMode(false);
    updateProtocolMessages(data);
  };

  return (
    <div
      className={`grid grid-rows-tab-layout gap-5 overflow-hidden h-full ${editMode ? "pb-13 md:pb-0" : ""}`}
    >
      <SectionControls
        title="Set up detection and handling of urgent medical situations"
        editMode={editMode}
        onEdit={handleEdit}
        onCancel={handleCancel}
        disabled={isLoading}
        formId="protocols-form"
      />

      <form
        id="protocols-form"
        onSubmit={handleSubmit(onSubmit)}
        className="grid md:grid-cols-2 gap-5 overflow-y-auto pr-1 scrollbar"
      >
        {isLoading ? (
          <InputSkeleton heightPx={166} label="Urgent Message" />
        ) : (
          <TextareaAutosizeForm
            register={register}
            name="urgent_message"
            label="Urgent Message"
            placeholder="Urgent Message"
            disabled={!editMode}
            labelClassName="text-base mb-3"
            textareaClassName="text-base"
            minRows={6}
            maxRows={14}
            borderColor="gray-250"
            disabledBgColor="stone-60"
          />
        )}

        {isLoading ? (
          <InputSkeleton heightPx={166} label="Urgent Flags" />
        ) : (
          <TextareaAutosizeForm
            register={register}
            name="urgent_flags"
            label="Urgent Flags"
            placeholder="Urgent Flags"
            disabled={!editMode}
            labelClassName="text-base mb-3"
            textareaClassName="text-base"
            minRows={6}
            maxRows={14}
            borderColor="gray-250"
            disabledBgColor="stone-60"
          />
        )}
      </form>
    </div>
  );
};

export default Protocols;
