import axios, { type AxiosError } from "axios";
import { BASE_URL } from "../../helpers/config";
import { handleRequestError } from "../../helpers/helpers";
import type { AppDispatch } from "../index";
import { setNotificationAction } from "../user/actions";

export const saveEncounterNote =
  (audioId: string, note: string, onSuccess = () => {}) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        note: note,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter note",
          );
        } else {
          onSuccess();
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to save encounter note");
      });
  };

export const saveEncounterNoteSimple =
  (audioId, note) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        note: note,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter note",
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to save encounter note");
      });
  };

export const saveEncounterTitle =
  (audioId, title, onSuccess?: () => void, onError?: () => void) =>
  (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        title,
      })
      .then((response) => {
        if (response.data.error) {
          onError?.();
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter title",
          );
          return;
        }

        onSuccess?.();
      })
      .catch((error: Error | AxiosError) => {
        onError?.();
        handleRequestError(error, dispatch, "Failed to save encounter title");
      });
  };

export const saveEncounterFreeText =
  (audioId, freeText) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        free_text: freeText,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter free text",
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(
          error,
          dispatch,
          "Failed to save encounter free text",
        );
      });
  };

export const saveEncounterPatient =
  (audioId, patientId) => (dispatch: AppDispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        patient_id: patientId,
      })
      .then((response) => {
        if (response.data.error) {
          handleRequestError(
            response.data.error,
            dispatch,
            "Failed to save encounter patient",
          );
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully saved patient",
              desc: "Encounter has been successfully updated",
            }),
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        handleRequestError(error, dispatch, "Failed to save encounter patient");
      });
  };
