import type ContentFormattingStrategy from "./Formatters/ContentFormattingStrategy";
import JsonFormattingStrategy from "./Formatters/JsonFormattingStrategy";
import MedicalNoteFormattingStrategy from "./Formatters/MedicalNoteFormattingStrategy";
import PlainTextFormattingStrategy from "./Formatters/PlainTextFormattingStrategy";
import PromptTemplateFormattingStrategy from "./Formatters/PromptTemplateFormattingStrategy";

const ContentFormattingContext = {
  getStrategy(
    content: string | null,
    eventType?: string,
  ): ContentFormattingStrategy {
    const strategies = [
      new JsonFormattingStrategy(),
      new PromptTemplateFormattingStrategy(),
      new MedicalNoteFormattingStrategy(),
      // Add more strategies as needed
      new PlainTextFormattingStrategy(), // Default strategy (must be last)
    ];

    return (
      strategies.find((strategy) => strategy.canHandle(content, eventType)) ||
      strategies[strategies.length - 1]
    ); // Fallback to default
  },
};

export default ContentFormattingContext;
