import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import LocalStorageService from "../services/LocalStorageService";
import {
  type FeatureFlag,
  type FeatureFlagMap,
  type FeatureFlagState,
  FeatureFlags,
  LOGOUT,
} from "./types";

const DEFAULT_FLAG: FeatureFlag = {
  enabled: false,
};

const FEATURE_FLAGS_STORAGE_KEY = "feature_flags";
const localStorageService = new LocalStorageService();

// Pure function to create default state
const createDefaultState = (): FeatureFlagState => ({
  flags: Object.values(FeatureFlags).reduce((acc, flag) => {
    acc[flag] = DEFAULT_FLAG;
    return acc;
  }, {} as FeatureFlagMap),
});

// Pure function to load initial state
const loadInitialState = (): FeatureFlagState => {
  const savedState = localStorageService.getItem<FeatureFlagMap>(
    FEATURE_FLAGS_STORAGE_KEY,
  );
  return savedState ? { flags: savedState } : createDefaultState();
};

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState: loadInitialState(),
  reducers: {
    initializeFeatureFlags: (
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) => {
      const newFlags = Object.values(FeatureFlags).reduce((acc, flag) => {
        acc[flag] = DEFAULT_FLAG;
        return acc;
      }, {} as FeatureFlagMap);

      Object.entries(action.payload).forEach(([flag, value]) => {
        if (flag in FeatureFlags) {
          newFlags[flag as FeatureFlags] = {
            enabled: value,
            config: {},
          };
        }
      });

      state.flags = newFlags;
    },
    setFeatureFlag: (
      state,
      action: PayloadAction<{
        flag: FeatureFlags;
        enabled: boolean;
        config?: Record<string, unknown>;
      }>,
    ) => {
      const { flag, enabled, config } = action.payload;
      state.flags[flag] = {
        enabled,
        config: config || state.flags[flag].config,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => createDefaultState());
  },
});

export const persistFeatureFlagsMiddleware =
  (store: any) => (next: any) => (action: any) => {
    const result = next(action);

    if (
      featureFlagsSlice.actions.initializeFeatureFlags.match(action) ||
      featureFlagsSlice.actions.setFeatureFlag.match(action)
    ) {
      const state = store.getState().featureFlags;
      localStorageService.setItem(FEATURE_FLAGS_STORAGE_KEY, state.flags);
    }

    if (action.type === LOGOUT) {
      localStorageService.removeItem(FEATURE_FLAGS_STORAGE_KEY);
    }

    return result;
  };

export const { initializeFeatureFlags, setFeatureFlag } =
  featureFlagsSlice.actions;
