import OrganizationTopBar from "@src/components/Layout/OrganizationTopBar";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "@src/helpers/constants";
import { makeSlug } from "@src/helpers/helpers";
import { useUrlState } from "@src/hooks/useUrlState";
import LocalStorageService from "@src/services/LocalStorageService";
import { useAppDispatch, useAppSelector } from "@src/store";
import { setSelectedEventAction } from "@src/store/campaign/actions";
import { useFeature } from "@src/store/featureFlagSelectors";
import { FeatureFlags } from "@src/store/types";
import { setOpenCallOptionsModalAction } from "@src/store/voiceRecorder/actions";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { selectScribeById } from "../IndependentScribe/store/selectors";
import Navigation from "./Navigation";
import NotificationsWrapper from "./NotificationsWrapper";

const storageService = new LocalStorageService();
const prevToken = storageService.getItem("prevToken");

const Layout = ({ children }) => {
  const { openCallModal } = useAppSelector((state) => state.voiceRecorder);
  const { selectedPatientInfo } = useAppSelector((state) => state.patient);
  const { isDatePickerOpen, hideMobileNavbar } = useAppSelector(
    (state) => state.ui,
  );
  const { value: selectedScribeId } = useUrlState("scribeId", null);
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );
  const isScribeOnly = useFeature(FeatureFlags.SCRIBE_ONLY);
  const isCCM = useFeature(FeatureFlags.CCM);
  const [isPatientProfile, setIsPatientProfile] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 768 });

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const recordingStartedRef = useRef(selectedScribe?.isRecording);
  const openCallModalRef = useRef(openCallModal);

  useEffect(() => {
    recordingStartedRef.current = selectedScribe?.isRecording;
  }, [selectedScribe?.isRecording]);

  useEffect(() => {
    openCallModalRef.current = openCallModal;
  }, [openCallModal]);

  useEffect(() => {
    if (recordingStartedRef?.current && !isDesktop) {
      dispatch(setOpenCallOptionsModalAction(true));
      if (isScribeOnly && selectedPatientInfo?.patient_id) {
        navigate(
          `${Pages.PATIENTS}/${selectedPatientInfo.patient_id}/?tab=${makeSlug(patientProfileTabs.NOTES)}&panel=${makeSlug(patientPanelTabs.SCRIBE)}`,
        );
      }
    }
    if (openCallModalRef?.current && !isDesktop) {
      dispatch(setOpenCallOptionsModalAction(true));
      if (isCCM && selectedPatientInfo?.patient_id) {
        navigate(
          `${Pages.PATIENTS}/${selectedPatientInfo.patient_id}/?tab=${makeSlug(patientProfileTabs.NOTES)}&panel=${makeSlug(patientPanelTabs.CALL)}`,
        );
      }
    }
  }, [
    isDesktop,
    isScribeOnly,
    isCCM,
    dispatch,
    selectedPatientInfo?.patient_id,
    navigate,
  ]);

  useEffect(() => {
    dispatch(setSelectedEventAction(null));

    if (location.pathname.match(/\/patients\/\d+/)) {
      setIsPatientProfile(true);
    } else {
      setIsPatientProfile(false);
    }
  }, [dispatch, location.pathname]);

  return (
    <NotificationsWrapper>
      <div
        className={`h-screen-dynamic w-screen overflow-hidden md:pt-0
          ${!isDesktop && (hideMobileNavbar || (selectedPatientInfo && isPatientProfile)) ? "pt-0" : "pt-16"}`}
      >
        <Navigation isPatientProfile={isPatientProfile} />
        <div
          className={`h-full w-full overflow-hidden grid
            ${prevToken ? "grid-rows-tab-layout" : ""}
            ${isDesktop ? `pl-[72px] ${!isDatePickerOpen ? "fixed" : ""} h-screen-dynamic` : ""}`}
        >
          {prevToken && <OrganizationTopBar />}
          {children}
        </div>
      </div>
    </NotificationsWrapper>
  );
};

export default Layout;
