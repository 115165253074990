import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import type { Team, User } from "../../../store/user/userReducer";
import Button from "../../Basic/Button";
import Input from "../../Basic/Input";
import TextareaAutosizeForm from "../../Basic/TextareaAutosizeForm";
import AssignButton from "../../Inbox/RightPanel/AssignButton";
import type { ReasonFormValues } from "./CallReasons";

interface CallReasonFormProps {
  usedReasonNames: string[];
  className?: string;
  onClose: () => void;
  onSave: (data: ReasonFormValues) => void;
}

const CallReasonForm = ({
  usedReasonNames,
  className = "",
  onClose,
  onSave,
}: CallReasonFormProps) => {
  const [assignedTo, setAssignedTo] = useState<{
    provider: User | null;
    team: Team | null;
  }>({
    provider: null,
    team: null,
  });
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data: ReasonFormValues) => {
    const assignType = assignedTo?.provider
      ? "provider"
      : assignedTo?.team
        ? "team"
        : null;
    const assignToId =
      assignedTo?.provider?.doctor_id ||
      assignedTo?.team?.doctor_team_id ||
      null;

    onSave({
      ...data,
      assign_type: assignType,
      assign_to_id: assignToId,
    });
  };
  const watchedValues = watch();

  const isSaveDisabled = !watchedValues.reason || !watchedValues.reason_prompt;

  const onAssign = (assignTo: { provider: User | null; team: Team | null }) => {
    setAssignedTo(assignTo);
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <div className="min-h-0 flex flex-col overflow-y-auto scrollbar">
        <form
          id="add-call-reason-form"
          onSubmit={handleSubmit(onSubmit)}
          className="px-5 space-y-4"
        >
          <Input
            register={register}
            validate={(value) => {
              if (usedReasonNames.includes(value.trim().toLowerCase())) {
                return "Reason name already exist. Please use another reason name.";
              }
              return true;
            }}
            name="reason"
            placeholder="Reason Name"
            inputClassName="text-xl font-semibold"
            borderColor="gray-foreground"
            error={errors.reason}
          />
          <TextareaAutosizeForm
            register={register}
            required
            withoutAsterisk
            name="reason_prompt"
            label="Instructions"
            placeholder="Instructions"
            labelClassName="text-base mb-3"
            textareaClassName="text-base"
            minRows={6}
            maxRows={14}
            borderColor="gray-foreground"
          />

          <div className="space-y-3">
            <p>Auto Assign</p>
            <AssignButton
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              className="w-full h-10"
              buttonClassName="w-full justify-between border rounded-md"
              popoverWidth={isDesktop ? "496px" : "100%"}
              label="No Assignment"
              labelClassName="text-[#9ca3afff]"
              displayArrow
              assignedTo={assignedTo}
              onAssign={onAssign}
            />
          </div>
        </form>
      </div>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          form="add-call-reason-form"
          type="submit"
          disabled={isSaveDisabled}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CallReasonForm;
