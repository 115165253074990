import { memo } from "react";
import { type Call, CallDirection } from "../../../../store/call/interfaces";
import ProfilePicture from "../../../Basic/ProfilePicture";
import CallInfoCard from "./CallInfoCard";

interface CallTimelineCardProps {
  call: Call;
  sender: {
    firstName: string;
    lastName?: string;
    displayName?: string;
    profilePicture?: string;
  };
  className?: string;
}

const CallTimelineCard = ({
  call,
  sender,
  className = "",
}: CallTimelineCardProps) => {
  return (
    <div
      className={`flex w-full ${className}
        ${call.direction === CallDirection.Outbound ? "flex-row-reverse ml-auto pl-10" : "pr-10"}`}
    >
      <ProfilePicture
        firstName={sender.firstName}
        lastName={sender.lastName}
        src={sender.profilePicture}
        flagPadding={false}
      />
      <div
        className={`w-full ${call.direction === CallDirection.Outbound ? "mr-2" : "ml-2"}`}
      >
        <p
          className={`h-8 flex items-center text-sm font-semibold
            ${call.direction === CallDirection.Outbound ? "justify-end" : ""}`}
        >
          {sender.displayName
            ? sender.displayName
            : `${sender.firstName} ${sender.lastName}`}
        </p>
        <CallInfoCard call={call} />
      </div>
    </div>
  );
};

export default memo(CallTimelineCard);
