import { memo } from "react";

interface DateIndicatorProps {
  showDateIndicator: boolean;
  dateIndicator: string;
  dateIndicatorRef: React.RefObject<HTMLDivElement>;
}

const DateIndicator = ({
  showDateIndicator,
  dateIndicator,
  dateIndicatorRef,
}: DateIndicatorProps) => (
  <div className="absolute top-0 left-1/2 h-fit w-fit pl-1">
    <div
      ref={dateIndicatorRef}
      className={`fixed z-10 -translate-x-1/2 flex flex-none whitespace-nowrap items-center justify-center
        shadow-md transition-all duration-500 bg-gray-200 rounded-full w-fit text-xxs px-2 py-1 mt-4
        ${showDateIndicator ? "translate-y-0 opacity-1" : "-translate-y-4 opacity-0"}`}
    >
      {dateIndicator}
    </div>
  </div>
);

export default memo(DateIndicator);
