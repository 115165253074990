import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Suspense, useEffect, useState } from "react";
import Loader from "./components/Basic/Loader";
import { setAPIAuthToken } from "./helpers/api";
import { setAuthToken } from "./helpers/config";
import AppRoutes from "./routes";
import { useAppDispatch, useAppSelector } from "./store";
import { updateStoredUser } from "./store/user/thunks";
import { getUser } from "./store/user/thunks";
import { selectToken } from "./store/user/userReducer";

// Global set to track processed tokens
const processedTokens = new Set();

const App = () => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const [isTokenReady, setIsTokenReady] = useState(
    () => !token || processedTokens.has(token),
  );

  // Process the token when it changes
  useEffect(() => {
    // Skip if no token or already processed
    if (!token || processedTokens.has(token) || isTokenReady) {
      return;
    }

    // console.log("[App] Processing token", token);
    setAuthToken(token);
    setAPIAuthToken(token);

    getUser()
      .then((user) => {
        // console.log("[App] Token processed, user fetched");
        dispatch(updateStoredUser(user));
        processedTokens.add(token);
        setIsTokenReady(true);
      })
      .catch((error) => {
        console.error("[App] Token processing failed", error);
        setIsTokenReady(true);
      });
  }, [token, isTokenReady, dispatch]);

  // If token isn't ready, show loader
  if (!isTokenReady) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  // Once token is processed, render the app
  return (
    <>
      <Suspense
        fallback={
          <div className="w-screen h-screen flex items-center justify-center">
            <Loader />
          </div>
        }
      >
        <AppRoutes />
      </Suspense>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
