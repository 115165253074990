import type { z } from "zod";
import type { basicSiteSchema } from "../site/validationSchemas";
import type {
  assistantSchema,
  callReasonSchema,
  completedByUserSchema,
  directPhoneNumbersSchema,
  metricsCallReasonSchema,
  metricsSchema,
  practiceInformationSchema,
  promptSchema,
  protocolMessagesSchema,
  systemMessagesSchema,
  voiceSchema,
} from "./validationSchemas";

export enum SupportLineTab {
  SYSTEM_MESSAGES = "System Messages",
  PRACTICE_INFORMATION = "Practice Information",
  CALL_REASONS = "Call Reasons",
  PROTOCOLS = "Protocols",
  VOICE = "Voice",
  ANALYTICS = "Analytics",
  CUSTOMIZE = "Customize",
}

export enum AnalyticsPeriod {
  WEEK = "Week",
  MONTH = "Month",
  YEAR = "Year",
  CUSTOM = "Custom",
}

export type CallReasonToUpdate = {
  reason: string;
  reason_prompt: string;
  assign_type: "provider" | "team" | null;
  assign_to_id: number | null;
};

export type DirectPhoneNumber = z.infer<typeof directPhoneNumbersSchema>;

export type BasicSite = z.infer<typeof basicSiteSchema>;

export type Voice = z.infer<typeof voiceSchema>;

export type Assistant = z.infer<typeof assistantSchema>;

export type SystemMessages = z.infer<typeof systemMessagesSchema>;

export type PracticeInformation = z.infer<typeof practiceInformationSchema>;

export type ProtocolMessages = z.infer<typeof protocolMessagesSchema>;

export type CallReason = z.infer<typeof callReasonSchema>;

export type MetricsCallReason = z.infer<typeof metricsCallReasonSchema>;

export type CompletedByUser = z.infer<typeof completedByUserSchema>;

export type Metrics = z.infer<typeof metricsSchema>;

export type Prompt = z.infer<typeof promptSchema>;
