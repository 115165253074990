import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "@src/helpers/constants";
import { getFormattedPhoneNumber, makeSlug } from "@src/helpers/helpers";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientsAction,
} from "@src/store/patient/actions";
import { getPatientInfo } from "@src/store/patient/thunks";
import { selectUser } from "@src/store/user/userReducer";
import { useEffect } from "react";
import { NavLink } from "react-router";
import {
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import PatientTableSkeleton from "../Skeletons/PatientTableSkeleton";
import EmptyPatients from "./EmptyPatients";
import TableData from "./TableData";

export const DateCell = ({
  value,
  // }: {
  //   value: string
}) => {
  return value ? (
    <p>
      {new Date(value.replace(/-/g, "/")).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })}
    </p>
  ) : (
    <></>
  );
};

export const PhoneCell = ({
  value,
  patientId,
  // }: {
  //   value: string;
  //   patientId: string;
}) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  return value ? (
    <p>{getFormattedPhoneNumber(value)}</p>
  ) : (
    <NavLink
      to={`${Pages.PATIENTS}/${patientId}/?tab=${makeSlug(patientProfileTabs.INFORMATION)}&panel=${makeSlug(patientPanelTabs.MESSAGE)}`}
      className="flex items-center space-x-2.5 text-sm text-tertiary font-semibold"
      onClick={(e) => {
        e.stopPropagation();
        dispatch(getPatientInfo(patientId));
        if (!user.customer.has_ehr_data) {
          dispatch(setOpenEditInfoModalAction(true));
        }
      }}
    >
      + Add Phone Number
    </NavLink>
  );
};

const PatientsTable = ({
  columns,
  data,
  cancelSelection,
  setCancelSelection,
  onDelete,
  onEdit,
  onPause,
  onAdd,
  // }: {
  //   columns: FixLater[];
  //   data: Patient[];
  //   cancelSelection: boolean;
  //   setCancelSelection: (value: boolean) => void;
  //   onDelete: (patient?: Patient) => void;
  //   onEdit: (patient?: Patient) => void;
  //   onPause: (patientId: number, paused: boolean) => void;
  //   onAdd: () => void;
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    toggleAllRowsSelected,
    rows,
    // } = useTable<Patient>(
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
  );
  const { loader } = useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cancelSelection) {
      toggleAllRowsSelected(false);
      setCancelSelection(false);
      dispatch(setSelectedPatientsAction([]));
    }
  }, [cancelSelection, toggleAllRowsSelected, setCancelSelection, dispatch]);

  return (
    <div className="min-h-0 h-full w-full overflow-hidden">
      {loader ? (
        <PatientTableSkeleton />
      ) : data.length > 0 ? (
        <div className="h-full w-full overflow-hidden">
          <TableData
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            prepareRow={prepareRow}
            onEdit={onEdit}
            onPause={onPause}
            onDelete={onDelete}
          />
        </div>
      ) : (
        <EmptyPatients onClick={onAdd} />
      )}
    </div>
  );
};

export default PatientsTable;
