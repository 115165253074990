import { Popover } from "@mui/material";
import ModalMobile from "@src/components/Basic/ModalMobile";
import SiteDropdownItem from "@src/components/VoiceAgent/SupportLineView/Analytics/SiteDropdownItem";
import { useGetSitesQuery } from "@src/store/voiceAgent/api";
import type React from "react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow-down.svg";

interface SiteDropdownProps {
  selectedSiteId: number | null;
  onSelectSite: (siteId: number) => void;
  className?: string;
}

const SiteDropdown = ({
  selectedSiteId,
  onSelectSite,
  className = "",
}: SiteDropdownProps) => {
  const { data: sites = [] } = useGetSitesQuery();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const handleOpenOptions = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleSelectSite = (siteId: number | null) => {
    if (siteId !== selectedSiteId) {
      onSelectSite(siteId);
    }
    handleCloseOptions();
  };

  const sortedSites = sites
    ?.map((site) => ({
      ...site,
      display: site.name?.trim() || "-",
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  const siteDropdownItems = (
    <div className="flex flex-col gap-2">
      <SiteDropdownItem
        site={{ site_id: null, name: "All Sites" }}
        onSelect={handleSelectSite}
        isSelected={!selectedSiteId}
      />
      {sortedSites.map((site) => (
        <SiteDropdownItem
          key={site.site_id}
          site={site}
          onSelect={handleSelectSite}
          isSelected={site.site_id === selectedSiteId}
        />
      ))}
    </div>
  );

  return (
    <div className={className}>
      <button
        type="button"
        className="h-9 rounded-lg border border-gray-foreground flex items-center space-x-2 px-2 overflow-hidden max-w-full"
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
        onClick={handleOpenOptions}
      >
        <p className="text-xs text-tertiary font-semibold truncate">
          {selectedSiteId
            ? sortedSites?.find((site) => site.site_id === selectedSiteId)
                .display
            : "All Sites"}
        </p>
        <ArrowIcon
          width="10"
          height="7"
          className={`flex-none transition-all duration-200 ${anchorEl ? "-rotate-180" : ""}`}
        />
      </button>

      {isDesktop && anchorEl && (
        <Popover
          open={Boolean(anchorEl)}
          onClose={handleCloseOptions}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: "230px",
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: "8px 0 0 0",
              },
            },
          }}
        >
          <div
            className="w-full overflow-y-auto scrollbar"
            style={{
              maxHeight: "455px",
            }}
          >
            <div className="p-2">{siteDropdownItems}</div>
          </div>
        </Popover>
      )}

      {!isDesktop && (
        <ModalMobile
          open={Boolean(anchorEl)}
          onClickAway={handleCloseOptions}
          childHeight={600}
          header="Sites"
        >
          <div className="w-full h-fit px-1 pb-5 pt-2">{siteDropdownItems}</div>
        </ModalMobile>
      )}
    </div>
  );
};

export default SiteDropdown;
