import {
  type PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import LocalStorageService from "@src/services/LocalStorageService";
import type { User } from "@src/store/user/userReducer";
import type { RootState } from "..";
import { LOGOUT } from "../types";
import {
  type CallNextCursor,
  CallStatusTab,
  type InboxPatient,
} from "./interfaces";

const storageService = new LocalStorageService();
const user: User = storageService.getItem("user");

// Define our state type
interface NormalizedCallState {
  tableScrollPosition: number;
  selectedItemPatient: InboxPatient | null;
  isFilterByMentions: boolean;
  isFilterByAssignedToCurrentUser: boolean;
  isFilterByUnassigned: boolean;
  filterByTeamId: number | null;
  filterBySiteId: number | null;
  unreadInboxIndicator: Record<string, boolean>;
  cursors: {
    [key in CallStatusTab]: number;
  };
}

// Create initial state
const initialState: NormalizedCallState = {
  tableScrollPosition: 0,
  selectedItemPatient: null,
  isFilterByMentions: false,
  isFilterByAssignedToCurrentUser: user?.default_inbox_type === "me" || false,
  isFilterByUnassigned: user?.default_inbox_type === "unassigned" || false,
  filterByTeamId:
    user?.default_inbox_type === "team" && user?.default_inbox_id
      ? user.default_inbox_id
      : null,
  filterBySiteId: null,
  unreadInboxIndicator: {},
  cursors: {
    [CallStatusTab.Completed]: -1,
    [CallStatusTab.Pending]: -1,
  },
};

export const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setTableScrollPosition(state, action: PayloadAction<number>) {
      state.tableScrollPosition = action.payload;
    },

    setSelectedItemPatient(state, action: PayloadAction<InboxPatient | null>) {
      state.selectedItemPatient = action.payload;
    },

    setIsFilterByMentions(state, action: PayloadAction<boolean>) {
      state.isFilterByMentions = action.payload;
    },

    setIsFilterByAssignedToCurrentUser(state, action: PayloadAction<boolean>) {
      state.isFilterByAssignedToCurrentUser = action.payload;
    },

    setIsFilterByUnassigned(state, action: PayloadAction<boolean>) {
      state.isFilterByUnassigned = action.payload;
    },

    setFilterByTeamId(state, action: PayloadAction<number | null>) {
      state.filterByTeamId = action.payload;
    },

    setFilterBySiteId(state, action: PayloadAction<number | null>) {
      state.filterBySiteId = action.payload;
      state.cursors[CallStatusTab.Pending] = -1;
      state.cursors[CallStatusTab.Completed] = -1;
    },

    resetStatusCursors(state) {
      state.cursors[CallStatusTab.Pending] = -1;
      state.cursors[CallStatusTab.Completed] = -1;
    },

    setStatusCursor(
      state,
      action: PayloadAction<{ status: CallStatusTab; cursor: CallNextCursor }>,
    ) {
      const { status, cursor } = action.payload;
      state.cursors[status] = cursor;
    },

    setUnreadInboxIndicator(
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) {
      state.unreadInboxIndicator = {
        ...state.unreadInboxIndicator,
        ...action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => ({
      ...initialState,
    }));
  },
});

export const {
  setTableScrollPosition,
  setSelectedItemPatient,
  setIsFilterByMentions,
  setIsFilterByAssignedToCurrentUser,
  setIsFilterByUnassigned,
  setFilterByTeamId,
  setFilterBySiteId,
  setUnreadInboxIndicator,
  resetStatusCursors,
  setStatusCursor,
} = callSlice.actions;

export const getSelectedItemPatient = createSelector(
  (state: RootState) => state.call,
  (call) => call.selectedItemPatient,
);

export const getIsFilterByMentions = createSelector(
  (state: RootState) => state.call,
  (call) => call.isFilterByMentions,
);

export const getIsFilterByAssignedToCurrentUser = createSelector(
  (state: RootState) => state.call,
  (call) => call.isFilterByAssignedToCurrentUser,
);

export const getIsFilterByUnassigned = createSelector(
  (state: RootState) => state.call,
  (call) => call.isFilterByUnassigned,
);

export const getFilterByTeamId = createSelector(
  (state: RootState) => state.call,
  (call) => call.filterByTeamId,
);

export const getFilterBySiteId = createSelector(
  (state: RootState) => state.call,
  (call) => call.filterBySiteId,
);

export const getTableScrollPosition = createSelector(
  (state: RootState) => state.call,
  (call) => call.tableScrollPosition,
);

export const getUnreadInboxIndicator = createSelector(
  (state: RootState) => state.call,
  (call) => call.unreadInboxIndicator,
);

export const getStatusCursor = createSelector(
  [
    (state: RootState) => state.call.cursors,
    (_: RootState, status: CallStatusTab) => status,
  ],
  (cursors, status) => cursors[status] ?? -1,
);
