import z from "zod";
import { baseApi, validateResponse } from "../baseApi";

const organizationPromptSchema = z.object({
  prompt: z.string(),
});

export const organizationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationPrompt: builder.query<
      z.infer<typeof organizationPromptSchema>,
      void
    >({
      query: () => ({
        url: "/admin/organization/prompt",
      }),
      transformResponse: (
        response: z.infer<typeof organizationPromptSchema>,
      ) => {
        validateResponse(organizationPromptSchema)(response);
        return response;
      },
      providesTags: () => ["OrganizationPrompt"],
    }),
    updateOrganizationPrompt: builder.mutation<
      z.infer<typeof organizationPromptSchema>,
      string
    >({
      query: (prompt: string) => ({
        url: "/admin/organization/prompt",
        method: "PUT",
        data: prompt,
      }),
      invalidatesTags: () => ["OrganizationPrompt"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationPromptQuery,
  useUpdateOrganizationPromptMutation,
} = organizationApi;
