import Analytics from "@src/components/VoiceAgent/SupportLineView/Analytics/Analytics";
import { Pages, supportEmailPattern } from "@src/helpers/constants";
import { makeSlug } from "@src/helpers/helpers";
import { useAppSelector } from "@src/store";
import { selectUser } from "@src/store/user/userReducer";
import { SupportLineTab } from "@src/store/voiceAgent/types";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import TableTabLinks from "../TableTabLinks";
import CallReasons from "./CallReasons";
import Customize from "./Customize";
import PracticeInformation from "./PracticeInformation";
import Protocols from "./Protocols";
import SystemMessages from "./SystemMessages";
import Voice from "./Voice";

interface SupportLineProps {
  className?: string;
}

const baseTabs = [
  SupportLineTab.ANALYTICS,
  SupportLineTab.SYSTEM_MESSAGES,
  SupportLineTab.PRACTICE_INFORMATION,
  SupportLineTab.CALL_REASONS,
  SupportLineTab.PROTOCOLS,
  SupportLineTab.VOICE,
];

const SupportLineView = ({ className = "" }: SupportLineProps) => {
  const user = useAppSelector(selectUser);
  const [supportLineTabs, setSupportLineTabs] = useState(baseTabs);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isSupportEmail = supportEmailPattern.test(user.email);

  const selectedSupportLineTab =
    searchParams.get("tab") || makeSlug(SupportLineTab.ANALYTICS);

  useEffect(() => {
    const availableTabs = [...baseTabs];

    if (isSupportEmail) {
      availableTabs.push(SupportLineTab.CUSTOMIZE);
    }

    setSupportLineTabs(availableTabs);

    const currentTabExists = availableTabs.some(
      (tab) => makeSlug(tab) === selectedSupportLineTab,
    );
    if (!currentTabExists) {
      navigate(
        `${Pages.VOICE_AGENT}?tab=${makeSlug(SupportLineTab.SYSTEM_MESSAGES)}`,
        {
          replace: true,
        },
      );
    }
  }, [isSupportEmail, selectedSupportLineTab, navigate]);

  return (
    <div className="h-full grid grid-rows-tab-layout overflow-hidden">
      <div className={`w-full overflow-hidden ${className}`}>
        <TableTabLinks tabs={supportLineTabs} />
      </div>

      <div
        className={`py-5 bg-white h-full w-full overflow-hidden ${className}`}
      >
        {selectedSupportLineTab === makeSlug(SupportLineTab.ANALYTICS) && (
          <Analytics />
        )}
        {selectedSupportLineTab ===
          makeSlug(SupportLineTab.SYSTEM_MESSAGES) && <SystemMessages />}
        {selectedSupportLineTab ===
          makeSlug(SupportLineTab.PRACTICE_INFORMATION) && (
          <PracticeInformation />
        )}
        {selectedSupportLineTab === makeSlug(SupportLineTab.CALL_REASONS) && (
          <CallReasons />
        )}
        {selectedSupportLineTab === makeSlug(SupportLineTab.PROTOCOLS) && (
          <Protocols />
        )}
        {selectedSupportLineTab === makeSlug(SupportLineTab.VOICE) && <Voice />}
        {isSupportEmail &&
          selectedSupportLineTab === makeSlug(SupportLineTab.CUSTOMIZE) && (
            <Customize />
          )}
      </div>
    </div>
  );
};

export default SupportLineView;
