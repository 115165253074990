import { ReactComponent as ChatCompletedIcon } from "@assets/icons/chat-completed.svg";
import { ReactComponent as ChatOpenIcon } from "@assets/icons/chat-open.svg";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone-new-filled.svg";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import SiteDropdown from "@src/components/Inbox/InboxTable/SiteDropdown";
import { selectInputText } from "@src/helpers/helpers";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  getFilterByTeamId,
  getIsFilterByAssignedToCurrentUser,
  getIsFilterByUnassigned,
  resetStatusCursors,
} from "@src/store/call/callSlice";
import { CallStatusTab } from "@src/store/call/interfaces";
import { throttle } from "lodash";
import { type ChangeEvent, useCallback, useState } from "react";
import TabsBg from "../../Basic/TabsBg";
import { useCallManagement } from "../hooks/useCallManagement";
import CallFilterMobile from "./CallFilterMobile";
import MentionsDropdown from "./MentionsDropdown";
import NumberCard from "./NumberCard";

const CallsTableHeader = () => {
  const dispatch = useAppDispatch();
  const { callCounts, filters, updateFilters } = useCallManagement();

  const [search, setSearch] = useState(filters.search);

  const debouncedUpdateFilters = useCallback(
    throttle((value) => {
      updateFilters({ search: value });
    }, 500),
    [],
  );

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearch(newValue);
    debouncedUpdateFilters(newValue);
  };

  const onChangeCallStatusTab = useCallback(
    (tab: CallStatusTab) => {
      if (filters.status !== tab) {
        dispatch(resetStatusCursors());

        updateFilters({
          status: tab,
        });
      }
    },
    [filters.status, updateFilters, dispatch],
  );

  const isFilterByAssignedToCurrentUser = useAppSelector(
    getIsFilterByAssignedToCurrentUser,
  );
  const isFilterByUnassigned = useAppSelector(getIsFilterByUnassigned);
  const filterByTeamId = useAppSelector(getFilterByTeamId);

  const getTabNumbers = useCallback(() => {
    if (callCounts.total_reviews === undefined) {
      return {};
    }

    const tabNumbers = {};

    if (isFilterByAssignedToCurrentUser) {
      tabNumbers[CallStatusTab.Pending] = callCounts.me;
    } else if (filterByTeamId && callCounts.teams) {
      tabNumbers[CallStatusTab.Pending] = callCounts.teams[filterByTeamId] || 0;
    } else if (isFilterByUnassigned) {
      tabNumbers[CallStatusTab.Pending] = callCounts.unassigned;
    } else {
      tabNumbers[CallStatusTab.Pending] = callCounts.total_reviews;
    }

    return tabNumbers;
  }, [
    callCounts,
    isFilterByAssignedToCurrentUser,
    isFilterByUnassigned,
    filterByTeamId,
  ]);

  return (
    <div className="w-full overflow-hidden">
      <div className="flex items-center justify-between space-x-3 mb-4 md:mb-6">
        <SiteDropdown />
        <MentionsDropdown />
      </div>

      <div className="flex items-center space-x-3 w-full mb-3 md:mb-4">
        <NumberCard
          icon={<PhoneIcon width="24" height="24" />}
          number={callCounts.incoming_calls}
          title="Incoming Calls"
        />
        <NumberCard
          icon={
            filters.status === CallStatusTab.Completed ? (
              <ChatCompletedIcon width="24" height="24" />
            ) : (
              <ChatOpenIcon width="24" height="24" />
            )
          }
          number={
            filters.status === CallStatusTab.Completed
              ? callCounts.total_completed
              : callCounts.total_reviews
          }
          title={
            filters.status === CallStatusTab.Completed
              ? "Completed Threads"
              : "Open Threads"
          }
        />
      </div>

      <div className="flex flex-col md:flex-row items-center overflow-x-auto scrollbar min-w-0">
        <TabsBg
          tabs={Object.values(CallStatusTab)}
          tabNumbers={getTabNumbers()}
          activeTab={filters.status}
          onChange={(tab: CallStatusTab) => onChangeCallStatusTab(tab)}
          className="w-full md:w-fit md:min-w-67 bg-white text-sm mb-4 md:mb-0 md:mr-6"
          tabClassName="py-2 text-center capitalize"
        />

        <div className="w-full flex items-center space-x-2">
          <div className="relative w-full md:w-1/2 md:min-w-60 md:max-w-96 ml-auto">
            <input
              value={search}
              onClick={selectInputText}
              onChange={handleSearchInput}
              className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
              placeholder="Search..."
              aria-label="Search calls"
              role="searchbox"
            />
            <SearchIcon
              aria-hidden="true"
              className="absolute top-2.5 left-4"
              width="20"
              height="20"
            />
          </div>
          <CallFilterMobile className="md:hidden" />
        </div>
      </div>
    </div>
  );
};

export default CallsTableHeader;
