import { ReactComponent as DangerIcon } from "@assets/icons/exclamation-triangle.svg";
import Button from "@src/components/Basic/Button";
import Modal from "@src/components/Basic/Modal";

interface DeactivateModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const DeactivateModal = ({ onConfirm, onCancel }: DeactivateModalProps) => {
  return (
    <Modal className="pt-5 rounded-2xl" onEnterPressed={onConfirm}>
      <div className="w-106">
        <div className="px-5">
          <div className="flex items-center space-x-2 mb-5">
            <div className="w-8 h-8 rounded-full bg-pink-150 flex items-center justify-center">
              <DangerIcon width="20" height="20" />
            </div>
            <p className="text-base md:text-xl font-semibold ">
              Deactivate Account
            </p>
          </div>

          <p className="text-sm md:text-base font-medium">
            Are you sure you want to deactivate this account?
          </p>
        </div>

        <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button variant="gray" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Deactivate</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateModal;
