import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-down.svg";
import Popover from "@mui/material/Popover";
import Skeleton from "@mui/material/Skeleton";
import { useAppDispatch, useAppSelector } from "@src/store";
import {
  getFilterBySiteId,
  setFilterBySiteId,
} from "@src/store/call/callSlice";
import { CallStatusTab } from "@src/store/call/interfaces";
import { selectUser } from "@src/store/user/userReducer";
import { useGetSitesQuery } from "@src/store/voiceAgent/api";
import type { BasicSite } from "@src/store/voiceAgent/types";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ModalMobile from "../../Basic/ModalMobile";
import { useCallManagement } from "../hooks/useCallManagement";

interface SiteDropdownProps {
  className?: string;
  width?: string;
  anchorOrigin?: {
    vertical: "top" | "center" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  transformOrigin?: {
    vertical: "top" | "center" | "bottom";
    horizontal: "left" | "center" | "right";
  };
  popMargin?: string;
}

const SiteDropdown = ({
  className = "",
  width = "240px",
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  popMargin = "8px 0 0 0",
}: SiteDropdownProps) => {
  const user = useAppSelector(selectUser);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();

  const { data: sites, isLoading } = useGetSitesQuery();
  const filterBySiteId = useAppSelector(getFilterBySiteId);

  const { updateFilters } = useCallManagement();

  const selectedSite = useMemo(
    () => sites?.find((site) => site.site_id === filterBySiteId) ?? null,
    [sites, filterBySiteId],
  );

  useEffect(() => {
    if (!filterBySiteId && sites?.length) {
      dispatch(setFilterBySiteId(sites[0].site_id));
    }
  }, [dispatch, filterBySiteId, sites]);

  const onSelectSite = (siteId: number) => {
    if (siteId !== filterBySiteId) {
      dispatch(setFilterBySiteId(siteId));
      updateFilters({ status: CallStatusTab.Pending });
    }
    onCloseDropdown();
  };

  const onCloseDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <button
        type="button"
        className="space-x-2 h-fit w-fit flex items-center"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        disabled={!sites?.length}
      >
        <div className="text-base md:text-xl font-semibold truncate">
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={130}
              height={isDesktop ? 20 : 16}
              sx={{
                bgcolor: "#EAEDF3",
                borderRadius: "20px",
              }}
            />
          ) : (
            selectedSite?.name || user.customer.name
          )}
        </div>
        {selectedSite && (
          <ArrowIcon
            width="10"
            height="7"
            className={`transition-all ${anchorEl ? "-rotate-180" : ""}`}
          />
        )}
      </button>

      {isDesktop && anchorEl && (
        <Popover
          open={Boolean(anchorEl)}
          onClose={onCloseDropdown}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "8px",
                width: width,
                boxShadow: "0px 2px 6px 0px rgba(18, 18, 18, 0.08)",
                margin: popMargin,
              },
            },
          }}
        >
          <div className="w-full p-2">
            {sites?.map((site: BasicSite) => (
              <button
                key={site.site_id}
                type="button"
                onClick={() => {
                  if (site.site_id === filterBySiteId) {
                    onCloseDropdown();
                  } else {
                    onSelectSite(site.site_id);
                  }
                }}
                className={`p-3 w-full text-left flex flex-none items-center rounded-lg hover:bg-primary-blue-light
                  overflow-hidden text-sm
                  ${site.site_id === filterBySiteId ? "text-primary-blue bg-primary-blue-light" : ""}`}
              >
                <p className="truncate">{site.name}</p>
              </button>
            ))}
          </div>
        </Popover>
      )}

      {!isDesktop && (
        <ModalMobile
          open={Boolean(anchorEl)}
          onClickAway={onCloseDropdown}
          childHeight={400}
          header="Clinic Name"
        >
          <div className="w-full h-fit px-1 pb-5 pt-2">
            {sites?.map((site: BasicSite) => (
              <button
                key={site.site_id}
                type="button"
                onClick={() => {
                  if (site.site_id === filterBySiteId) {
                    onCloseDropdown();
                  } else {
                    onSelectSite(site.site_id);
                  }
                }}
                className={`p-3 w-full text-left flex flex-none items-center rounded-lg hover:bg-primary-blue-light
                  overflow-hidden text-sm
                  ${site.site_id === filterBySiteId ? "text-primary-blue bg-primary-blue-light" : ""}`}
              >
                <p className="truncate">{site.name}</p>
              </button>
            ))}
          </div>
        </ModalMobile>
      )}
    </div>
  );
};

export default SiteDropdown;
