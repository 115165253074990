import emptyCompletedSrc from "@assets/empty-threads.svg";
import ReasonListItem from "@src/components/Settings/CallMetrics/ReasonListItem";
import ReasonsTableSkeleton from "@src/components/Skeletons/ReasonsTableSkeleton";
import AnalyticsTable from "@src/components/VoiceAgent/SupportLineView/Analytics/AnalyticsTable";
import CallReasons from "@src/components/VoiceAgent/SupportLineView/Analytics/CallReasons";
import { roundBigNumber, roundNumberToTwoDecimals } from "@src/helpers/helpers";
import type { CallReasonMetrics } from "@src/store/adminMetrics/types";
import type { MetricsCallReason } from "@src/store/voiceAgent/types";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

interface ReasonsProps {
  callReasons: CallReasonMetrics[];
  selectedClinicName?: string;
  className?: string;
  isLoading?: boolean;
}

const Reasons = ({
  callReasons,
  selectedClinicName,
  className = "",
  isLoading,
}: ReasonsProps) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [sortedCallReasons, setSortedCallReasons] = useState<
    CallReasonMetrics[] | null
  >(null);

  useEffect(() => {
    if (callReasons?.length > 0) {
      const sorted = [...callReasons].sort(
        (a, b) => b.total_calls - a.total_calls,
      );
      setSortedCallReasons(sorted);
    } else {
      setSortedCallReasons([]);
    }
  }, [callReasons]);

  const columns = [
    { label: "Reason", accessor: "category" },
    { label: "Calls %", accessor: "call_percentage" },
    { label: "Calls", accessor: "total_calls" },
    { label: "Minutes", accessor: "total_minutes" },
    { label: "Avg min", accessor: "avg_minutes" },
    { label: "Max min", accessor: "max_minutes" },
    { label: "Review", accessor: "review_count" },
    { label: "Unassigned", accessor: "unassigned" },
    { label: "Completed", accessor: "completed_count" },
  ];

  const data = useMemo(() => {
    const tableData = [];
    sortedCallReasons?.forEach((reason) => {
      tableData.push({
        category: (
          <div className="max-w-60 overflow-hidden">
            <p className="text-sm font-medium text-primary truncate">
              {reason.category}
            </p>
          </div>
        ),
        call_percentage: roundBigNumber(reason.call_percentage),
        total_calls: roundBigNumber(reason.total_calls),
        total_minutes: roundBigNumber(reason.total_minutes),
        avg_minutes: roundNumberToTwoDecimals(reason.avg_minutes),
        max_minutes: roundNumberToTwoDecimals(reason.max_minutes),
        review_count: roundBigNumber(reason.review_count),
        unassigned: roundBigNumber(reason.unassigned),
        completed_count: roundBigNumber(reason.completed_count),
      });
    });
    return tableData;
  }, [sortedCallReasons]);

  const chartData: MetricsCallReason[] = useMemo(() => {
    return callReasons?.map((reason) => {
      return {
        category: reason.category,
        total: reason.total_calls,
      };
    });
  }, [callReasons]);

  return (
    <div className={`space-y-6 ${className}`}>
      <div className="bg-white border border-gray-250 rounded-lg p-5 overflow-hidden">
        <p className="text-base fornt-semibold mb-1">
          Reasons for {selectedClinicName ? selectedClinicName : "All Clinics"}
        </p>
        <p className="text-xs font-normal text-tertiary mb-2">
          Calls aggregated by reason of why the call ended or completed
        </p>

        <CallReasons
          callReasons={chartData || []}
          isLoading={isLoading}
          adminLegendVariant
        />
      </div>

      <div className="bg-white border border-gray-250 rounded-lg p-5 overflow-hidden grid grid-rows-tab-layout md:max-h-128">
        {isLoading || !sortedCallReasons ? (
          <ReasonsTableSkeleton rows={5} />
        ) : data?.length > 0 ? (
          isDesktop ? (
            <AnalyticsTable columns={columns} data={data} />
          ) : (
            <div className="space-y-2">
              {sortedCallReasons.map((reason: CallReasonMetrics) => (
                <ReasonListItem key={reason.category} reason={reason} />
              ))}
            </div>
          )
        ) : (
          <div className="flex flex-col items-center justify-center mb-6 min-h-[280px]">
            <img
              src={emptyCompletedSrc}
              alt="No data"
              width="440"
              height="200"
            />
            <span className="text-lg md:text-xl text-center font-semibold">
              No Reasons
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reasons;
