import Bugsnag from "@bugsnag/js";
import axios from "axios";
import LocalStorageService from "../services/LocalStorageService";

const storageService = new LocalStorageService();

// Create axios instance with default config
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor for API calls
api.interceptors.request.use(
  (config) => config,
  (error) => {
    Bugsnag.notify(error);
    return Promise.reject(error);
  },
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle 401 errors by clearing storage and reloading
    if (error.response?.status === 401) {
      storageService.clear();
      window.location.reload();
    }

    // Log other errors to Bugsnag
    if (error.response?.status !== 401) {
      Bugsnag.notify(error);
    }

    return Promise.reject(error);
  },
);

// Helper method to update auth token
export const setAPIAuthToken = (token: string | null) => {
  if (!token) {
    delete api.defaults.headers.common.Authorization;
    return;
  }

  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

// Helper method to get error message
export const getErrorMessage = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.message || error.message;
  }

  return "An unexpected error occurred";
};

export default api;
