import { ReactComponent as ArrowIcon } from "@assets/icons/arrow-left.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit-pen.svg";
import { useUrlState } from "@src/hooks/useUrlState";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppSelector } from "../../../store";
import Input from "../../Basic/Input";
import type { Scribe } from "../../IndependentScribe/store/interfaces";
import { selectScribeById } from "../../IndependentScribe/store/selectors";
import { initialTitle } from "../../IndependentScribe/utils";

const NoteHeader = ({
  note,
  onClose,
  onUpdateTitle = (_) => {},
}: {
  note: Scribe;
  onClose: () => void;
  onUpdateTitle?: (title: string) => void;
}) => {
  const { value: selectedScribeId } = useUrlState("scribeId", null);
  const selectedScribe = useAppSelector((state) =>
    selectScribeById(state, selectedScribeId),
  );

  const [editTitle, setEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(note.title || "");

  const fallbackTitle = initialTitle(new Date(note.startedAt));
  const titleWithFallback = newTitle || fallbackTitle;
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const handleBlur = () => {
    onUpdateTitle(titleWithFallback);
    setNewTitle(titleWithFallback);
    setEditTitle(false);
  };

  const handleBackArrowClick = () => {
    onUpdateTitle(titleWithFallback);
    onClose();
  };

  useEffect(() => {
    if (note.title && note.title !== newTitle && !editTitle) {
      setNewTitle(note.title || fallbackTitle);
    }
  }, [note, newTitle, editTitle, fallbackTitle]);

  return (
    <div
      className="grid grid-cols-conv-layout items-center gap-3 w-full bg-white px-4 py-3 border-b
        md:rounded-t-xl md:border-b-0 md:border-x md:border-t md:py-3"
    >
      <div className="flex items-center w-full truncate">
        <button
          type="button"
          onClick={handleBackArrowClick}
          className="w-6 h-8 md:w-8 flex items-center justify-center md:mr-1"
        >
          <ArrowIcon width="14" height="14" stroke="#121212" />
        </button>
        {editTitle && isDesktop && !selectedScribe?.isGenerating ? (
          <Input
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            autoFocus
            onBlur={handleBlur}
            className="text-base lg:text-xl"
            inputClassName="h-9"
          />
        ) : (
          <div
            className={`group mr-2 md:mr-4 truncate rounded-lg px-3 md:py-1 flex items-center space-x-3
              ${isDesktop ? "cursor-pointer hover:bg-gray-110" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              if (isDesktop && !selectedScribe?.isGenerating) {
                setEditTitle(true);
              }
            }}
          >
            <p className="text-base lg:text-xl font-semibold truncate">
              {isDesktop ? newTitle : note.title}
            </p>
            {isDesktop && (
              <EditIcon
                width="20"
                height="20"
                stroke="#667085"
                className="flex-none opacity-0 group-hover:opacity-100"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NoteHeader;
