import type { FixLater } from "../../components/IndependentScribe/store/scribeSlice";
import { supportEmailPattern } from "../../helpers/constants";
import { baseApi, validateResponse } from "../baseApi";
import type { Team, User } from "./userReducer";
import { teamListSchema, userListSchema } from "./validationSchemas";

type SearchFilterParams = {
  search: string;
};

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], SearchFilterParams>({
      query: (params) => ({
        url: "/user/list",
        params,
      }),
      transformResponse: (response: FixLater) => {
        const validatedResponse = validateResponse(userListSchema)(
          response.data.users,
        ) as User[];

        const usersWithoutSupport = validatedResponse.filter(
          (user) =>
            !(user.last_name || "").toLowerCase().startsWith("support") &&
            !supportEmailPattern.test(user.email),
        );

        return usersWithoutSupport;
      },
      providesTags: (result, _, params: SearchFilterParams) =>
        result
          ? result.map((user) => ({
              type: "User",
              id: user.doctor_id,
              filter: params.search,
            }))
          : [{ type: "User", filter: params.search }],
    }),

    getTeams: builder.query<Team[], SearchFilterParams>({
      query: (params) => ({
        url: "/team/list",
        params,
      }),
      transformResponse: (
        response: FixLater,
        _,
        params: SearchFilterParams,
      ) => {
        let teams = validateResponse(teamListSchema)(response) as Team[];
        if (params.search?.trim()) {
          teams = teams.filter((team) =>
            team.name
              ?.toLowerCase()
              .includes(params.search.trim().toLowerCase()),
          );
        }

        return teams;
      },
      providesTags: (result, _, params: SearchFilterParams) =>
        result
          ? result.map(({ doctor_team_id }) => ({
              type: "Team",
              id: doctor_team_id,
              filter: params.search,
            }))
          : [{ type: "Team", filter: params.search }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetUsersQuery, useGetTeamsQuery } = userApi;
