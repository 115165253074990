import { memo } from "react";
import type { Message } from "../../../store/conversation/validationSchemas";
import type { User } from "../../../store/user/userReducer";
import MessageCloud from "./MessageCloud";
import { shouldCombineWithPrevious } from "./messageUtils";

interface SentMessagesListProps {
  sentMessages: {
    message: string;
    isInternal: boolean;
  }[][];
  lastMessage: Message;
  user: User;
  conversationId: number;
}

const SentMessagesList = ({
  sentMessages,
  lastMessage,
  user,
  conversationId,
}: SentMessagesListProps) => {
  if (!sentMessages[conversationId]?.length) {
    return null;
  }

  return (
    <>
      {sentMessages[conversationId].map(
        (
          sentMessage: { message: string; isInternal: boolean },
          index: number,
        ) => {
          const combineWithPrevious =
            index === 0 ? shouldCombineWithPrevious(lastMessage, user) : true;

          return (
            <div key={index}>
              <MessageCloud
                combineWithPrevious={combineWithPrevious}
                type="outgoing"
                firstName={user.first_name}
                lastName={user.last_name}
                displayName={user.display_name}
                role={user.occupation}
                profilePicture={user.profile_picture}
                message={sentMessage.message}
                time=""
                isInternal={sentMessage.isInternal}
              />
            </div>
          );
        },
      )}
    </>
  );
};

export default memo(SentMessagesList);
