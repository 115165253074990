import { ReactComponent as DotsIcon } from "@assets/icons/ellipsis-horizontal.svg";
import Skeleton from "@mui/material/Skeleton";
import UserTable from "../Basic/UserTable";

const SitesTableSkeleton = ({
  rows = 10,
  bgColor = "#EAEDF3",
  animation = "wave",
}) => {
  const columns = [
    { label: "Site name", accessor: "name" },
    { label: "Number of patients", accessor: "total_patients" },
    { label: "Forwarding numbers", accessor: "direct_phone_numbers" },
    { label: "Address", accessor: "clinic_address" },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        name: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={110}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        total_patients: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={70}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        direct_phone_numbers: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={110}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        clinic_address: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={110}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        actions: (
          <div className="flex items-center justify-center w-8 h-8 rounded-md">
            <DotsIcon width="20" height="20" />
          </div>
        ),
      });
    }
    return tableData;
  };

  return (
    <div className="grid w-full">
      <UserTable
        columns={columns}
        data={getTableData()}
        className="hidden md:block"
      />

      <div className="block md:hidden space-y-3">
        {[...Array(rows)].map((_, index) => (
          <div
            key={`site-${index}`}
            className="p-4 rounded-xl border-b border-gray-foreground space-y-4 bg-white"
          >
            <div className="space-y-0.5">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={160}
                height={14}
                sx={{ bgcolor: bgColor, borderRadius: "14px" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={100}
                height={12}
                sx={{ bgcolor: bgColor, borderRadius: "12px" }}
              />
            </div>
            <div className="grid grid-cols-3 divide-x border-gray-foreground">
              <div className="flex flex-col space-y-3 font-medium pr-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={50}
                  height={24}
                  sx={{ bgcolor: bgColor, borderRadius: "24px" }}
                />
              </div>

              <div className="col-span-2 flex flex-col space-y-3 font-medium px-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={100}
                  height={24}
                  sx={{ bgcolor: bgColor, borderRadius: "24px" }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SitesTableSkeleton;
