import { Pages } from "@src/helpers/constants";
import type { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";

interface SettingsContainerProps {
  children: ReactNode;
}

const SettingsContainer = ({ children }: SettingsContainerProps) => {
  const isXl = useMediaQuery({ minWidth: 1280 });
  const location = useLocation();

  return (
    <div className="h-full w-full md:grid overflow-y-auto bg-gray-background overflow-hidden">
      <div
        className={`overflow-y-auto scrollbar p-4 md:p-6
          ${isXl ? "h-screen-dynamic" : "h-full"}
          ${location.pathname === Pages.SETTINGS_CALL_METRICS ? "xl:pl-0" : "xl:pl-8"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default SettingsContainer;
