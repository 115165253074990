import type z from "zod";
import {
  LOGOUT,
  SET_SITES,
  SET_SITE_SEARCH_VALUE,
  SET_USER_SITES,
} from "../types";
import type { siteSchema } from "./validationSchemas";

export type Site = z.infer<typeof siteSchema>;

export type SiteState = {
  sites: Site[];
  siteSearchValue: string;
  userSites: Record<number, Site[]>;
};

const init: SiteState = {
  sites: [],
  siteSearchValue: "",
  userSites: {},
};

type SiteAction = {
  type: string;
  payload: any;
};

export default function siteReducer(
  state = init,
  action: SiteAction,
): SiteState {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_SITES:
      return { ...state, sites: payload.sites };
    case SET_SITE_SEARCH_VALUE:
      return { ...state, siteSearchValue: payload.siteSearchValue };
    case SET_USER_SITES:
      return {
        ...state,
        userSites: {
          ...state.userSites,
          [payload.id]: payload.sites,
        },
      };
    default:
      return state;
  }
}
