import { ReactComponent as EmptyCallReason } from "@assets/EmptyCallReason.svg";
import Skeleton from "@mui/material/Skeleton";
import type { MetricsCallReason } from "@src/store/voiceAgent/types";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const COLORS = [
  "#F0D19B",
  "#EFA9D6",
  "#A1DCC7",
  "#C4B1E3",
  "#A8CFED",
  "#E0AEA8",
  "#79A0ED",
  "#ACB3CA",
  "#96CFA9",
];

const MIN_LABEL_THRESHOLD = 8;

interface CallReasonsProps {
  callReasons: MetricsCallReason[];
  isLoading?: boolean;
  adminLegendVariant?: boolean;
}

const removedReasonName = "Quick Disconnect";

const CallReasons = ({
  callReasons,
  isLoading,
  adminLegendVariant,
}: CallReasonsProps) => {
  const [sortedCallReasons, setSortedCallReasons] = useState<
    MetricsCallReason[] | null
  >(null);
  const [topCallReason, setTopCallReason] = useState<MetricsCallReason | null>(
    null,
  );
  const [hoveredReason, setHoveredReason] = useState<string | null>(null);
  const [removedReason, setRemovedReason] = useState<MetricsCallReason | null>(
    null,
  );
  const isSm = useMediaQuery({ minWidth: 640 });
  const isMd = useMediaQuery({ minWidth: 768 });
  const isXl = useMediaQuery({ minWidth: 1280 });

  const innerRadius = isXl ? 80 : 60;
  const outerRadius = isXl ? 110 : 80;
  const chartHeight = isXl ? 350 : 250;

  const legendColumnCount = isXl ? 3 : isMd ? 2 : isSm ? 3 : 2;

  const legendItems = useMemo(() => {
    if (!sortedCallReasons) {
      return [];
    }
    const legendRowCount = Math.ceil(callReasons.length / legendColumnCount);
    const items = Array.from({ length: legendColumnCount }, () => []);
    sortedCallReasons.forEach((item, i) => {
      const columnIndex = Math.floor(i / legendRowCount);
      items[columnIndex]?.push({ ...item, originalIndex: i });
    });
    return items;
  }, [sortedCallReasons, legendColumnCount, callReasons.length]);

  useEffect(() => {
    if (callReasons?.length > 0) {
      const sorted = [...callReasons].sort((a, b) => b.total - a.total);

      // exclude reason from chart
      const removedReasonIndex = sorted.findIndex(
        (reason) =>
          reason.category.trim().toLowerCase() ===
          removedReasonName.trim().toLowerCase(),
      );
      if (removedReasonIndex !== -1) {
        setRemovedReason(sorted[removedReasonIndex]);
        sorted.splice(removedReasonIndex, 1);
      }

      setSortedCallReasons(sorted);
      setTopCallReason(sorted[0]);
    } else {
      setTopCallReason({ category: "No Data", total: 0 });
      setSortedCallReasons([]);
    }
  }, [callReasons]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    category,
    total,
    percent,
  }) => {
    const RADIAN = Math.PI / 180;
    const x =
      cx + (outerRadius + (isXl ? 10 : 5)) * Math.cos(-midAngle * RADIAN);
    const y =
      cy + (outerRadius + (isXl ? 25 : 20)) * Math.sin(-midAngle * RADIAN);

    // show label only for larger segments or hovered segment
    if (percent * 100 < MIN_LABEL_THRESHOLD && category !== hoveredReason) {
      return null;
    }

    return (
      <text
        x={x}
        y={y}
        fill="#121212"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        <tspan x={x} dy="-10" fontSize="12" fill="#667085">
          {category}
        </tspan>
        <tspan x={x} dy="15" fontSize="12" fill="#121212">
          {total}
        </tspan>
      </text>
    );
  };

  return (
    <>
      {isLoading || !sortedCallReasons ? (
        <div className="w-full h-[250px] xl:h-[350px] flex items-center justify-center">
          <Skeleton
            animation="wave"
            variant="circular"
            height={isXl ? 230 : 160}
            width={isXl ? 230 : 160}
            sx={{
              bgcolor: "#F8F8F8",
            }}
          />
        </div>
      ) : sortedCallReasons.length > 0 ? (
        <div
          className={`w-full flex flex-col ${adminLegendVariant ? "lg:flex-row" : ""}`}
        >
          <ResponsiveContainer width="100%" height={chartHeight}>
            <PieChart>
              <Pie
                data={sortedCallReasons}
                cx="50%"
                cy="50%"
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                dataKey="total"
                nameKey="category"
                label={renderCustomizedLabel}
                labelLine={false}
                isAnimationActive={false}
              >
                {sortedCallReasons.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onMouseEnter={() => setHoveredReason(entry.category)}
                    onMouseLeave={() => setHoveredReason(null)}
                    className="cursor-pointer outline-none"
                  />
                ))}
              </Pie>

              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="18"
                fill="#333333"
              >
                <tspan
                  x="50%"
                  dy="-20"
                  fontSize={isXl ? "14" : "12"}
                  fill="#667085"
                >
                  Top Call Reasons
                </tspan>
                <tspan
                  x="50%"
                  dy="28"
                  fontSize={isXl ? "24" : "20"}
                  fill="#121212"
                >
                  {topCallReason?.total}
                </tspan>
                <tspan x="50%" dy="20" fontSize="12" fill="#121212">
                  {topCallReason?.category}
                </tspan>
              </text>
            </PieChart>
          </ResponsiveContainer>

          <div
            className={`gap-5
              ${
                adminLegendVariant
                  ? "w-full flex flex-col bg-gray-background p-8 border border-gray-110 rounded-xl max-h-[250px] xl:max-h-[350px] overflow-y-auto scrollbar"
                  : `grid auto-rows-auto grid-cols-${legendColumnCount}`
              }`}
          >
            {legendItems?.map((column, colIndex) => (
              <div key={colIndex} className="flex flex-col space-y-5 h-fit">
                {column.map((reason) => (
                  <div
                    key={reason.category}
                    className={`flex items-start space-x-2 text-xs font-medium overflow-hidden
                      ${adminLegendVariant ? "w-full justify-between" : ""}`}
                  >
                    <div className="flex items-start space-x-2">
                      <span
                        className="w-2 h-2 rounded-full flex-none mt-1 border"
                        style={{
                          backgroundColor:
                            COLORS[reason.originalIndex % COLORS.length],
                          borderColor:
                            COLORS[reason.originalIndex % COLORS.length],
                        }}
                      />
                      <span className="text-tertiary">{reason.category}</span>
                    </div>

                    <span className="text-primary flex-none">
                      {reason.total}
                    </span>
                  </div>
                ))}
                {colIndex === legendColumnCount - 1 && removedReason && (
                  <div
                    key={removedReason.category}
                    className={`flex items-start space-x-2 text-xs font-medium
                      ${adminLegendVariant ? "w-full justify-between" : ""}`}
                  >
                    <div className="flex items-start space-x-2">
                      <span className="w-2 h-2 rounded-full flex-none mt-1 bg-white border border-gray-250" />
                      <span className="text-tertiary">
                        {removedReason.category}
                      </span>
                    </div>
                    <span className="text-primary">{removedReason.total}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full h-[250px] xl:h-[350px] flex items-center justify-center">
          <EmptyCallReason height={isXl ? 230 : 160} width={isXl ? 230 : 160} />
        </div>
      )}
    </>
  );
};

export default CallReasons;
