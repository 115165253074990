import { ReactComponent as SendIcon } from "@assets/icons/plain.svg";
import { type KeyboardEvent, useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { v7 as uuid } from "uuid";
import {
  useAssignPatientToProviderOrTeamMutation,
  useSendMessageMutation,
} from "../../../store/call/api";
import type { Conversation } from "../../../store/call/interfaces";
import type { Team, User } from "../../../store/user/userReducer";
import TabsBorder from "../../Basic/TabsBorder";
import CompleteConversationButton from "../../PatientProfile/RightPanel/Message/CompleteConversationButton";
import InputWithMentions from "../Mentions/InputWithMentions";
import type { MentionItem } from "../Mentions/interfaces";
import AssignButton from "./AssignButton";

const SendMessageTypes = {
  PATIENT: "Patient",
  INTERNAL: "Internal",
};

type InputAreaProps = {
  setScrolledUp: (scrolledUp: boolean) => void;
  messages: Record<string, string>;
  setMessages: (messages: Record<string, string>) => void;
  currentConversation: Conversation;
  selectedConversationId: string;
  className?: string;
  completeClassName?: string;
  showCompleteButton?: boolean;
  mentionItems: MentionItem[];
};

const InboxInputArea = ({
  setScrolledUp,
  messages,
  setMessages,
  currentConversation,
  selectedConversationId,
  className = "",
  completeClassName,
  showCompleteButton,
  mentionItems = [],
}: InputAreaProps) => {
  const [sendMessageType, setSendMessageType] = useState(
    SendMessageTypes.PATIENT,
  );
  const InputRef = useRef<HTMLTextAreaElement>(null);

  const [sendMessage] = useSendMessageMutation();

  const [assignPatientToProviderOrTeam] =
    useAssignPatientToProviderOrTeamMutation();

  const messageKey =
    sendMessageType === SendMessageTypes.PATIENT
      ? `${selectedConversationId}.patient_message`
      : `${selectedConversationId}.internal_message`;

  useEffect(() => {
    if (messages[messageKey] === undefined) {
      setMessages({ ...messages, [messageKey]: "" });
    }
  }, [messageKey, messages[messageKey], setMessages]);

  useEffect(() => {
    if (InputRef.current) {
      InputRef.current.focus();
    }
  }, []);

  const onSendMessage = () => {
    const message = messages[messageKey];
    if (message.trim()) {
      sendMessage({
        message,
        conversationId: Number(selectedConversationId),
        isInternal: sendMessageType !== SendMessageTypes.PATIENT,
        idempotencyKey: uuid(),
      });
      setScrolledUp(false);
    }

    setMessages({
      ...messages,
      [messageKey]: "",
    });
  };

  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      event.key === "Enter"
    ) {
      event.preventDefault();
      onSendMessage();
    }
  };

  const onAssign = (assignTo: { provider: User | null; team: Team | null }) => {
    assignPatientToProviderOrTeam({
      conversation: currentConversation,
      assignTo,
    });
  };

  const handleSetMessageType = (messageType: string) => {
    setSendMessageType(messageType);

    setTimeout(() => {
      if (InputRef.current) {
        InputRef.current.focus();
      }
    }, 100);
  };

  return (
    <div className={className}>
      <div className="px-4 pb-4 w-full max-w-5xl mx-auto">
        <div className="bg-white rounded-lg border border-gray-200 ">
          <div className="h-11 border-b grid grid-cols-conv-layout items-end">
            <TabsBorder
              tabs={Object.values(SendMessageTypes)}
              activeTab={sendMessageType}
              setActiveTab={handleSetMessageType}
              className="text-sm font-medium ml-3"
            />
            {currentConversation && (
              <AssignButton
                className="h-full"
                assignedTo={{
                  provider: currentConversation.assigned_to,
                  team: currentConversation.assigned_to_team,
                }}
                onAssign={onAssign}
              />
            )}
          </div>

          <div
            className={`pb-3 rounded-b-lg border border-gray-foreground
              ${
                sendMessageType === SendMessageTypes.PATIENT
                  ? "bg-white pt-3"
                  : "bg-[#FFF7E9] pt-3.5"
              }`}
          >
            {sendMessageType === SendMessageTypes.PATIENT ? (
              <TextareaAutosize
                value={messages[messageKey]}
                onChange={(e) =>
                  setMessages({
                    ...messages,
                    [messageKey]: e.target.value,
                  })
                }
                onKeyDown={onKeyDown}
                placeholder="Type a message"
                minRows={2}
                maxRows={14}
                ref={InputRef}
                className="w-full resize-none scrollbar border-r-8 border-white pl-3 pr-1 pt-1 text-sm bg-transparent
                  align-top mb-1 leading-[1.3] outline-none"
              />
            ) : (
              <InputWithMentions
                onKeyDown={onKeyDown}
                mentionItems={mentionItems}
                value={messages[messageKey]}
                onChange={(e) =>
                  setMessages({
                    ...messages,
                    [messageKey]: e.target.value,
                  })
                }
                placeholder="Mention people using '@'"
              />
            )}

            <div className="px-3 flex w-full justify-end">
              <button
                type="button"
                aria-label="Send message"
                onClick={onSendMessage}
              >
                <SendIcon width="20" height="20" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {showCompleteButton && (
        <CompleteConversationButton
          conversation={currentConversation}
          className={completeClassName}
        />
      )}
    </div>
  );
};

export default InboxInputArea;
