import { ReactComponent as UnassignedIcon } from "@assets/icons/archive.svg";
import { ReactComponent as InboxIcon } from "@assets/icons/inbox-redesign2.svg";
import ProfilePicture from "@src/components/Basic/ProfilePicture";
import FilterButton from "@src/components/Inbox/InboxTable/FilterButton";
import { getTeamIcon } from "@src/components/Settings/Users/Teams/IconSelection";
import { useAppSelector } from "@src/store";
import { useGetTeamsQuery } from "@src/store/user/api";
import { selectUser } from "@src/store/user/userReducer";
import { useCallback, useEffect, useRef, useState } from "react";

const optionStyles =
  "grid grid-cols-middle items-center gap-2 truncate text-sm w-full";
const optionButtonStyles =
  "p-3 w-full rounded-lg hover:bg-primary-blue-light truncate";
const labelStyles = "text-left truncate";

interface DefaultInboxSelectProps {
  selectedDefaultInbox: string;
  setSelectedDefaultInbox: (defaultInbox: string) => void;
  className?: string;
  disabled?: boolean;
}

const DefaultInboxSelect = ({
  className = "",
  disabled,
  selectedDefaultInbox,
  setSelectedDefaultInbox,
}: DefaultInboxSelectProps) => {
  const [popoverWidth, setPopoverWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector(selectUser);
  const { data: teams = [] } = useGetTeamsQuery({
    search: "",
  });

  const sortedTeams = teams
    ?.map((team) => ({
      ...team,
      display: team.name?.trim() || "-",
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      setPopoverWidth(containerRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const getOptionLabel = useCallback(
    (name: string) => {
      switch (name) {
        case "inbox":
          return (
            <div className={optionStyles}>
              <InboxIcon
                width="20"
                height="20"
                stroke="#121212"
                className="flex-none"
              />
              <p className={labelStyles}>Inbox</p>
            </div>
          );
        case "me":
          return (
            <div className={optionStyles}>
              <ProfilePicture
                size={5}
                fontSize="xxs"
                firstName={user.first_name}
                lastName={user.last_name}
                flagPadding={false}
                src={user.profile_picture}
              />
              <p className={labelStyles}>
                {user.display_name?.trim()
                  ? user.display_name
                  : user.first_name?.trim() || user.last_name?.trim()
                    ? `${user.first_name} ${user.last_name}`
                    : "-"}
              </p>
            </div>
          );
        case "unassigned":
          return (
            <div className={optionStyles}>
              <UnassignedIcon
                width="20"
                height="20"
                stroke="#121212"
                className="flex-none"
              />
              <p className={labelStyles}>Unassigned</p>
            </div>
          );
        default:
          return null;
      }
    },
    [user],
  );

  const getFilterButtonLabel = useCallback(() => {
    if (selectedDefaultInbox.includes("team")) {
      const team = teams.find(
        (team) =>
          team.doctor_team_id === Number(selectedDefaultInbox.split("_")[1]),
      );
      return (
        <div className={optionStyles}>
          {getTeamIcon(team?.team_icon, "20", "#121212")}
          <p className={labelStyles}>{team?.name.trim() || "-"}</p>
        </div>
      );
    }

    return getOptionLabel(selectedDefaultInbox);
  }, [selectedDefaultInbox, teams, getOptionLabel]);

  const handleSelectDefaultInbox = (defaultInbox: string) => {
    setSelectedDefaultInbox(defaultInbox);
  };

  return (
    <div ref={containerRef} className={className}>
      <FilterButton
        buttonClassName="bg-white text-sm font-semibold p-2 rounded-lg border border-gray-300 w-full justify-between
        h-10 disabled:bg-gray-150"
        dropdownClassName="p-2"
        width={`${popoverWidth}px`}
        mobileHeight={600}
        label={getFilterButtonLabel()}
        closeOnSelect
        maxHeight="455px"
        disabled={disabled}
      >
        <div className="w-full flex flex-col">
          <button
            type="button"
            onClick={() => handleSelectDefaultInbox("inbox")}
            className={`${optionButtonStyles}`}
          >
            {getOptionLabel("inbox")}
          </button>

          <button
            type="button"
            onClick={() => handleSelectDefaultInbox("unassigned")}
            className={`${optionButtonStyles}`}
          >
            {getOptionLabel("unassigned")}
          </button>

          <button
            type="button"
            onClick={() => handleSelectDefaultInbox("me")}
            className={`${optionButtonStyles}`}
          >
            {getOptionLabel("me")}
          </button>

          {sortedTeams?.length > 0 &&
            sortedTeams?.map((team) => (
              <button
                key={team.doctor_team_id}
                type="button"
                onClick={() =>
                  handleSelectDefaultInbox(`team_${team.doctor_team_id}`)
                }
                className={`${optionButtonStyles}`}
              >
                <div className={optionStyles}>
                  {getTeamIcon(team.team_icon, "20", "#121212")}
                  <p className={labelStyles}>{team.name?.trim() || "-"}</p>
                </div>
              </button>
            ))}
        </div>
      </FilterButton>
    </div>
  );
};

export default DefaultInboxSelect;
