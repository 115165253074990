import TableFilters from "./TableFilters";

const Metrics = () => {
  return (
    <div className="grid grid-rows-tab-layout overflow-hidden h-full">
      <TableFilters />
    </div>
  );
};

export default Metrics;
