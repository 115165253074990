import { setUnreadInboxIndicator } from "@src/store/call/callSlice";
import { mergeInboxItems } from "@src/store/call/sortingUtils";
import { type RootState, store } from "../../store";
import { baseApi } from "../../store/baseApi";
import { callApi } from "../../store/call/api";
import {
  type CallNextCursor,
  type CallSearchParams,
  CallStatusTab,
  type InboxListItem,
  type SearchCallResponse,
  type SearchCallResponseWithDetails,
} from "../../store/call/interfaces";
import type { ChatEventMentions } from "./types";

/**
 * CacheManager handles updating the Redux store cache based on WebSocket events
 * - Updates the Redux store/cache based on WebSocket messages
 * - Handles cache invalidation strategies
 * - Optimizes updates to prevent unnecessary renders
 * - Implements batch processing for performance
 */
export class CacheManager {
  private batchSize = 10; // Number of queries to process in each batch
  private countInvalidationDelay = 1000; // Delay before invalidating count tags (ms)

  /**
   * Invalidate the cache for a specific conversation
   */
  public invalidateConversationCache(conversationId: number): void {
    store.dispatch(
      baseApi.util.invalidateTags([
        { type: "Conversation", id: conversationId },
      ]),
    );
  }

  /**
   * Find query keys relevant to conversation updates
   */
  private findRelevantQueryKeys(state: RootState): string[] {
    return Object.keys(state.api.queries).filter(
      (key) =>
        key.includes("getCalls-") &&
        state.api.queries[key]?.originalArgs &&
        state.api.queries[key]?.data,
    );
  }

  /**
   * Check if a conversation exists in a specific list
   */
  private isConversationInList(
    relevantQueryKeys: string[],
    state: RootState,
    status: string,
    conversationId: number,
  ): boolean {
    return relevantQueryKeys
      .filter((k) => k.includes(`getCalls-${status}-`))
      .some((key) => {
        const queryState = state.api.queries[key] as {
          data: SearchCallResponse;
        };
        const inbox = queryState.data?.data || [];
        return inbox.some(
          (item: InboxListItem) =>
            item.items[0].data.conversation_id === conversationId,
        );
      });
  }

  /**
   * Update conversation counters based on status changes
   */
  private updateCounters(
    newStatus: string,
    isInCurrentList: boolean,
    isInOtherList: boolean,
    siteId?: number,
  ): void {
    if (siteId === undefined) {
      return;
    }

    if (newStatus === CallStatusTab.Completed && !isInCurrentList) {
      store.dispatch(
        callApi.util.updateQueryData("getCallCounts", siteId, (draft) => {
          draft.total_completed += 1;
          if (isInOtherList) {
            draft.total_reviews -= 1;
          }
        }),
      );
    } else if (newStatus === CallStatusTab.Pending && !isInCurrentList) {
      store.dispatch(
        callApi.util.updateQueryData("getCallCounts", siteId, (draft) => {
          draft.total_reviews += 1;
          if (isInOtherList) {
            draft.total_completed -= 1;
          }
        }),
      );
    }
  }

  /**
   * Process updates in batches for performance
   */
  private processBatchUpdates(
    keys: string[],
    state: RootState,
    conversationId: number,
    newStatus: string,
    updatedResponse: SearchCallResponseWithDetails,
  ): void {
    const keysForStatus = keys.filter((k) =>
      k.includes(`getCalls-${newStatus}-`),
    );

    // console.log(
    //   `Processing ${keys.length} total queries in batches of ${this.batchSize}`,
    // );

    this.processBatch(
      keys,
      keysForStatus,
      state,
      conversationId,
      newStatus,
      updatedResponse,
      0,
    );
  }

  /**
   * Process a batch of updates to avoid UI blocking
   */
  private processBatch(
    allKeys: string[],
    keysForStatus: string[],
    state: RootState,
    conversationId: number,
    newStatus: string,
    updatedResponse: SearchCallResponseWithDetails,
    startIndex: number,
  ): void {
    const endIndex = Math.min(startIndex + this.batchSize, allKeys.length);

    for (let i = startIndex; i < endIndex; i++) {
      const key = allKeys[i];
      const queryState = state.api.queries[key];
      const queryArg = queryState.originalArgs as {
        params: CallSearchParams;
        status: CallStatusTab;
        cursor: CallNextCursor;
      };

      const isStatusKey = keysForStatus.includes(key);

      const matchesFilters =
        isStatusKey &&
        this.doesConversationMatchFilters(updatedResponse, queryArg);

      // console.log(
      //   `Processing ${key} for conversation ${conversationId}, matchesFilters: ${matchesFilters}`,
      // );

      this.updateQueryData(
        queryArg,
        conversationId,
        newStatus,
        updatedResponse,
        matchesFilters,
      );
    }

    if (endIndex < allKeys.length) {
      setTimeout(
        () =>
          this.processBatch(
            allKeys,
            keysForStatus,
            state,
            conversationId,
            newStatus,
            updatedResponse,
            endIndex,
          ),
        0,
      );
    } else {
      // console.log(
      //   `Completed processing ${allKeys.length} queries for conversation ${conversationId}`,
      // );
    }
  }

  /**
   * Add an item to the inbox with correct sorting
   */
  private addItemToInbox(
    draft: SearchCallResponse,
    updatedResponse: SearchCallResponseWithDetails,
    queryArg: { params: CallSearchParams; status: CallStatusTab },
  ): void {
    const isCompletedInbox = queryArg.status === CallStatusTab.Completed;

    // Use the same utility function
    draft.data = mergeInboxItems(
      draft.data,
      updatedResponse.data,
      isCompletedInbox,
      updatedResponse,
    );
  }

  /**
   * Schedule invalidation of call counts after a delay
   */
  private scheduleCountInvalidation(): void {
    setTimeout(() => {
      store.dispatch(baseApi.util.invalidateTags(["Call"]));
    }, this.countInvalidationDelay);
  }

  /**
   * Set batch size (useful for testing or performance tuning)
   */
  public setBatchSize(size: number): void {
    this.batchSize = size;
  }

  /**
   * Set count invalidation delay (useful for testing or performance tuning)
   */
  public setCountInvalidationDelay(delay: number): void {
    this.countInvalidationDelay = delay;
  }

  /**
   * Check if a conversation matches the filter criteria specified in queryArgs
   */
  private doesConversationMatchFilters(
    conversation: SearchCallResponseWithDetails,
    queryArgs: { params: CallSearchParams; status: CallStatusTab },
  ): boolean {
    const { params } = queryArgs;

    if (!conversation?.data?.[0]) {
      return false;
    }

    const item = conversation.data[0];
    const patient = item.patient;
    const callData = item.items?.[0]?.data;

    if (!callData) {
      return false;
    }

    // Check search query
    if (params.q && params.q.trim() !== "") {
      const query = params.q.toLowerCase();
      const patientName = patient.patient_full_name?.toLowerCase() || "";

      if (!patientName.includes(query)) {
        return false;
      }
    }

    // Check categories
    if (params.categories && params.categories.length > 0) {
      const itemReasons = callData.reasons || [];
      const hasMatchingCategory = params.categories.some((category: string) =>
        itemReasons.includes(category),
      );

      if (!hasMatchingCategory) {
        return false;
      }
    }

    // Check is_new_user
    if (params.is_new_user && !callData.is_new_patient) {
      return false;
    }

    // Check is_urgent
    if (params.is_urgent && !callData.is_urgent) {
      return false;
    }

    // Check unassigned filter
    if (params.unassigned === 1) {
      // If conversation has assigned_to_id or assigned_to_team_id, it's not unassigned
      if (
        conversation.assigned_to_id !== null ||
        conversation.assigned_to_team_id !== null
      ) {
        return false;
      }
    }

    // Check assigned_to_me using the top-level assigned_to_id property
    if (params.assigned_to_me === 1) {
      const state = store.getState() as RootState;
      const currentUserId = state.user.user?.doctor_id;

      // If no assigned_to_id or doesn't match current user, filter doesn't match
      if (
        !conversation.assigned_to_id ||
        conversation.assigned_to_id !== currentUserId
      ) {
        return false;
      }
    }

    // Check team_id using the top-level assigned_to_team_id property
    if (params.team_id) {
      // If no assigned_to_team_id or doesn't match requested team, filter doesn't match
      if (
        !conversation.assigned_to_team_id ||
        conversation.assigned_to_team_id !== params.team_id
      ) {
        return false;
      }
    }

    // Check site_id
    if (params.site_id) {
      if (conversation.site_id !== params.site_id) {
        return false;
      }
    }

    return true;
  }

  /**
   * Update RTK Query data for a specific query
   */
  private updateQueryData(
    queryArg: {
      params: CallSearchParams;
      status: CallStatusTab;
      cursor: CallNextCursor;
    },
    conversationId: number,
    _newStatus: string,
    updatedResponse: SearchCallResponseWithDetails,
    shouldInclude: boolean,
  ): void {
    store.dispatch(
      callApi.util.updateQueryData("getCalls", queryArg, (draft) => {
        // Remove existing item if present
        const existingIndex = draft.data.findIndex(
          (item: InboxListItem) =>
            item.items[0].data.conversation_id === conversationId,
        );

        if (existingIndex !== -1) {
          draft.data.splice(existingIndex, 1);
        }

        // Add to list if it should be included
        if (shouldInclude) {
          this.addItemToInbox(draft, updatedResponse, queryArg);

          // console.log(
          //   `Added item to draft.data, total: ${draft.total}`,
          //   draft.data,
          // );
        }
      }),
    );
  }

  /**
   * Fetch updated conversation data
   */
  public async fetchUpdatedConversation(
    conversationId: number,
  ): Promise<SearchCallResponseWithDetails | null> {
    try {
      return await store
        .dispatch(
          callApi.endpoints.searchCall.initiate(
            { params: { conversation_id: conversationId } },
            { forceRefetch: true },
          ),
        )
        .unwrap();
    } catch (error) {
      console.error("Failed to fetch updated conversation data:", error);
      return null;
    }
  }

  /**
   * Process updates to conversation lists and counters
   */
  public processConversationUpdates(
    conversationId: number,
    updatedResponse: SearchCallResponseWithDetails,
  ): void {
    const state = store.getState() as RootState;
    const relevantQueryKeys = this.findRelevantQueryKeys(state);
    const newStatus = updatedResponse.status;
    const siteId = updatedResponse.site_id;

    // console.log(
    //   `Processing updates for conversation ${conversationId}, status: ${newStatus}`,
    // );

    // Check if conversation is in current and other lists
    const isInCurrentList = this.isConversationInList(
      relevantQueryKeys,
      state,
      newStatus,
      conversationId,
    );

    const otherStatus =
      newStatus === CallStatusTab.Completed
        ? CallStatusTab.Pending
        : CallStatusTab.Completed;

    const isInOtherList = this.isConversationInList(
      relevantQueryKeys,
      state,
      otherStatus,
      conversationId,
    );

    // Update counters based on current status
    this.updateCounters(newStatus, isInCurrentList, isInOtherList, siteId);

    // Process updates in batches to avoid UI blocking
    if (relevantQueryKeys.length > 0) {
      this.processBatchUpdates(
        relevantQueryKeys,
        state,
        conversationId,
        newStatus,
        updatedResponse,
      );
    }

    // Schedule invalidation of call counts
    this.scheduleCountInvalidation();
  }

  public processUnreadInboxIndicator(
    updatedResponse: SearchCallResponseWithDetails,
    mentions: ChatEventMentions,
  ): void {
    const state = store.getState() as RootState;

    const isUnassigned =
      updatedResponse.assigned_to_id === null &&
      updatedResponse.assigned_to_team_id === null;
    const isAssignedToCurrentUser =
      updatedResponse.assigned_to_id !== null &&
      state.user.user?.doctor_id !== undefined &&
      updatedResponse.assigned_to_id === state.user.user.doctor_id;
    const assignedToTeamId = updatedResponse.assigned_to_team_id;

    const updatedUnreadInboxIndicator: Record<string, boolean> = {};

    if (isUnassigned && !state.call.isFilterByUnassigned) {
      updatedUnreadInboxIndicator.unassigned = true;
    } else if (
      isAssignedToCurrentUser &&
      !state.call.isFilterByAssignedToCurrentUser
    ) {
      updatedUnreadInboxIndicator.me = true;
    } else if (
      assignedToTeamId &&
      state.call.filterByTeamId !== assignedToTeamId
    ) {
      updatedUnreadInboxIndicator[assignedToTeamId.toString()] = true;
    }

    for (const mention of mentions) {
      if (mention.doctor_team_id) {
        updatedUnreadInboxIndicator[mention.doctor_team_id.toString()] = true;
      } else if (
        mention.doctor_id &&
        state.user.user?.doctor_id &&
        mention.doctor_id === state.user.user.doctor_id
      ) {
        updatedUnreadInboxIndicator.me = true;
      }
    }

    if (Object.keys(updatedUnreadInboxIndicator).length > 0) {
      store.dispatch(setUnreadInboxIndicator(updatedUnreadInboxIndicator));
    }
  }
}

export default CacheManager;
