import { useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router";
import {
  type CallDirection,
  CallStatusTab,
} from "../../../store/call/interfaces";

export interface FilterParams {
  search?: string;
  direction?: CallDirection | "all";
  status?: CallStatusTab;
  reasons?: string[];
  isNewUser?: boolean;
  isUrgent?: boolean;
  isExternal?: boolean;
  isCareTeam?: boolean;
}

const defaultFilters: FilterParams = {
  search: "",
  direction: "all",
  status: CallStatusTab.Pending,
  reasons: [],
  isNewUser: false,
  isUrgent: false,
  isExternal: false,
  isCareTeam: false,
};

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Store the latest filters in a ref to help with stability
  const latestFiltersRef = useRef(null);

  // Memoize these functions to prevent them from being recreated
  const getParam = useCallback((key) => searchParams.get(key), [searchParams]);
  const hasParam = useCallback((key) => searchParams.has(key), [searchParams]);

  // Use a more granular dependency array to prevent unnecessary recalculations
  const filters = useMemo(() => {
    const result = {
      search: hasParam("search") ? getParam("search") : defaultFilters.search,
      direction: hasParam("direction")
        ? (getParam("direction") as CallDirection | "all")
        : defaultFilters.direction,
      status: hasParam("status")
        ? (getParam("status") as CallStatusTab)
        : defaultFilters.status,
      reasons: hasParam("reasons")
        ? getParam("reasons")?.split(",").filter(Boolean)
        : defaultFilters.reasons,
      isNewUser: hasParam("isNewUser")
        ? getParam("isNewUser") === "true"
        : defaultFilters.isNewUser,
      isUrgent: hasParam("isUrgent")
        ? getParam("isUrgent") === "true"
        : defaultFilters.isUrgent,
      isExternal: hasParam("isExternal")
        ? getParam("isExternal") === "true"
        : defaultFilters.isExternal,
      isCareTeam: hasParam("isCareTeam")
        ? getParam("isCareTeam") === "true"
        : defaultFilters.isCareTeam,
    } as FilterParams;

    // Store the latest filters in ref for stable reference in updateFilters
    latestFiltersRef.current = result;
    return result;
  }, [hasParam, getParam]);

  // Use a more stable implementation of updateFilters that doesn't depend on filters
  const updateFilters = useCallback(
    (newFilters: Partial<FilterParams>) => {
      // Use the ref to get the latest filters instead of using the filters from dependency
      const current = latestFiltersRef.current || {};
      const updated = { ...current, ...newFilters };

      const params = new URLSearchParams(searchParams);

      Object.entries(updated).forEach(([key, value]) => {
        const defaultValue = defaultFilters[key as keyof FilterParams];

        if (key === "reasons" && Array.isArray(value) && value.length > 0) {
          params.set(key, value.join(","));
        } else if (
          value !== defaultValue &&
          value !== undefined &&
          value !== ""
        ) {
          params.set(key, String(value));
        } else {
          params.delete(key);
        }
      });

      setSearchParams(params, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  const resetFilters = useCallback(() => {
    const params = new URLSearchParams(searchParams);

    Object.keys(defaultFilters).forEach((key) => {
      params.delete(key);
    });

    setSearchParams(params, { replace: true });
  }, [searchParams, setSearchParams]);

  return {
    filters,
    updateFilters,
    resetFilters,
    defaultFilters,
  };
};
