import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectUser } from "../../../store/user/userReducer";
import {
  useSetClinicDefaultNoteTemplateMutation,
  useSetProviderDefaultNoteTemplateMutation,
} from "../../IndependentScribe/store/noteTemplateApi";
import {
  type NoteTemplate,
  selectDefaultClinicNoteTemplate,
  selectDefaultProviderNoteTemplate,
  selectNoteTemplates,
  setDefaultClinicNoteTemplate,
  setDefaultProviderNoteTemplate,
} from "../../IndependentScribe/store/noteTemplateSlice";
import {
  describeNoteTemplate,
  getNoteTemplates,
} from "../../IndependentScribe/store/thunks";
import Dropdown from "./Dropdown/Dropdown";

const fieldContainerClasses = "space-y-3";
const fieldLabelClasses = "font-semibold";

const NotesGeneral = () => {
  const [setProviderDefaultNoteTemplate] =
    useSetProviderDefaultNoteTemplateMutation();
  const [setClinicDefaultNoteTemplate] =
    useSetClinicDefaultNoteTemplateMutation();

  const user = useAppSelector(selectUser);
  const defaultProviderNoteTemplate = useAppSelector(
    selectDefaultProviderNoteTemplate,
  );
  const defaultClinicNoteTemplate = useAppSelector(
    selectDefaultClinicNoteTemplate,
  );
  const noteTemplates = useAppSelector(selectNoteTemplates);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNoteTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (user?.note_template_id && noteTemplates.length > 0) {
      dispatch(
        describeNoteTemplate(user.note_template_id, (data) =>
          dispatch(setDefaultProviderNoteTemplate(data)),
        ),
      );
    }

    if (user?.customer?.note_template_id && noteTemplates.length > 0) {
      dispatch(
        describeNoteTemplate(user.customer.note_template_id, (data) =>
          dispatch(setDefaultClinicNoteTemplate(data)),
        ),
      );
    }
  }, [noteTemplates, user, dispatch]);

  const handleSelectProviderNoteTemplate = (template: NoteTemplate) => {
    dispatch(setDefaultProviderNoteTemplate(template));
    setProviderDefaultNoteTemplate({
      noteTemplateId: template.note_template_id,
    });
  };

  const handleSelectClinicNoteTemplate = (template: NoteTemplate) => {
    dispatch(setDefaultClinicNoteTemplate(template));
    setClinicDefaultNoteTemplate({ noteTemplateId: template.note_template_id });
  };

  return (
    <form className="space-y-4">
      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Default Clinic Note Template</p>
        <Dropdown
          customItems={noteTemplates.filter((template) => template.is_custom)}
          preMadeItems={noteTemplates.filter(
            (template) => !template.is_custom && !template.is_hidden,
          )}
          selectedItem={defaultClinicNoteTemplate}
          onSelectItem={handleSelectClinicNoteTemplate}
          itemName="template"
          defaultSelect
          closeDropdownOnSelect
          withoutSearch
        />
      </div>

      <div className={fieldContainerClasses}>
        <p className={fieldLabelClasses}>Default Provider Note Template</p>
        <Dropdown
          customItems={noteTemplates.filter((template) => template.is_custom)}
          preMadeItems={noteTemplates.filter(
            (template) => !template.is_custom && !template.is_hidden,
          )}
          selectedItem={defaultProviderNoteTemplate}
          onSelectItem={handleSelectProviderNoteTemplate}
          itemName="template"
          defaultSelect
          closeDropdownOnSelect
          withoutSearch
        />
      </div>
    </form>
  );
};

export default NotesGeneral;
