import type { FixLater } from "../IndependentScribe/store/scribeSlice";

interface UserTableProps {
  columns: FixLater[];
  data: FixLater[];
  onClickRow?: (row: FixLater) => void;
  className?: string;
}

const UserTable = ({
  columns,
  data,
  onClickRow = null,
  className = "",
}: UserTableProps) => {
  return (
    <div className={`overflow-auto max-h-full w-full scrollbar ${className}`}>
      <table className="min-w-full">
        <thead className="w-full sticky top-0 z-[9] bg-gray-background">
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                scope="col"
                className="p-0 h-11 text-left text-sm font-semibold text-tertiary"
              >
                <div
                  className={`flex items-center h-full w-full px-3 xl:px-4 whitespace-nowrap
                    ${column.className ? column.className : ""}`}
                >
                  {column.label}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((row, index) => (
              <tr
                key={index}
                className={onClickRow ? "cursor-pointer" : ""}
                onClick={() => {
                  if (onClickRow) {
                    onClickRow(row.user || row.team || row);
                  }
                }}
              >
                {columns.map(({ accessor }, index) => {
                  const cellData =
                    row[accessor] || row[accessor] === 0 ? row[accessor] : "——";
                  return (
                    <td
                      key={accessor}
                      className="whitespace-nowrap text-zinc-700 font-semibold text-xs xl:text-sm py-1.5 px-0"
                    >
                      <div
                        className={`px-3 xl:px-4 h-14 w-full flex items-center bg-white
                          ${index === 0 ? "rounded-l-lg" : ""}
                          ${index === columns.length - 1 ? "rounded-r-lg" : ""}`}
                      >
                        {cellData}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
