import type { ReactNode } from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/pen.svg";
import Button from "../../Basic/Button";

interface SectionControlsProps {
  title: ReactNode;
  editMode: boolean;
  onEdit: () => void;
  onCancel: () => void;
  formId: string;
  disabled?: boolean;
}

const SectionControls = ({
  title,
  editMode,
  onEdit,
  onCancel,
  disabled,
  formId,
}: SectionControlsProps) => {
  return (
    <div className="flex items-top justify-between space-x-3">
      <div className="text-base font-medium md:pt-2">{title}</div>
      <div className="flex space-x-2">
        {editMode ? (
          <div
            className="absolute z-10 bottom-0 left-0 w-full md:static md:fit flex space-x-2
              bg-gray-background p-4 md:bg-transparent md:p-0"
          >
            <Button
              type="cancel"
              variant="gray"
              onClick={onCancel}
              className="flex-none space-x-2 w-1/2 md:w-18"
              disabled={disabled}
            >
              <p className="text-sm font-semibold">Cancel</p>
            </Button>
            <Button
              type="submit"
              variant="primary"
              form={formId}
              className="flex-none space-x-2 w-1/2 md:w-18"
              disabled={disabled}
            >
              <p className="text-sm font-semibold">Save</p>
            </Button>
          </div>
        ) : (
          <Button
            type="button"
            variant="secondary-light"
            onClick={onEdit}
            className="flex-none space-x-2 w-10 md:w-18"
            disabled={disabled}
          >
            <EditIcon width="16" height="16" className="flex-none" />
            <p className="text-sm font-semibold hidden md:flex">Edit</p>
          </Button>
        )}
      </div>
    </div>
  );
};

export default SectionControls;
