import { useMemo } from "react";
import { useSearchParams } from "react-router";
import { makeSlug } from "../../../helpers/helpers";
import TableTabLinks from "../TableTabLinks";
import Metrics from "./Metrics/Metrics";
import SupportLines from "./SupportLines/SupportLines";

const OverviewTableTabs = {
  SUPPORT_LINES: "Support Lines",
  METRICS: "Metrics",
};

interface OverviewProps {
  className?: string;
}

const Overview = ({ className = "" }: OverviewProps) => {
  const [searchParams] = useSearchParams();

  const selectedOverviewTab = useMemo(() => {
    const tab = searchParams.get("tab");
    return tab || makeSlug(OverviewTableTabs.SUPPORT_LINES);
  }, [searchParams]);

  return (
    <div className="h-full grid grid-rows-tab-layout overflow-hidden">
      <div className={`w-full ${className}`}>
        <TableTabLinks tabs={Object.values(OverviewTableTabs)} />
      </div>

      <div
        className={`pb-4 bg-white h-full w-full overflow-hidden ${className}`}
      >
        {selectedOverviewTab === OverviewTableTabs.SUPPORT_LINES && (
          <SupportLines />
        )}
        {selectedOverviewTab === OverviewTableTabs.METRICS && <Metrics />}
      </div>
    </div>
  );
};

export default Overview;
