import { useAppDispatch } from "@src/store";
import { resetStatusCursors } from "@src/store/call/callSlice";
import type { CallTags } from "@src/store/call/interfaces";
import { useGetCallReasonsQuery } from "@src/store/voiceAgent/api";
import { useEffect, useState } from "react";
import Checkbox from "../../Basic/Checkbox";
import { type FilterParams, useFilterParams } from "../hooks/useFilterParams";
import FilterButton from "./FilterButton";

interface ReasonFilterProps {
  className?: string;
}

const ReasonFilter = ({ className = "" }: ReasonFilterProps) => {
  const { filters, updateFilters } = useFilterParams();
  const { data: callReasons } = useGetCallReasonsQuery();
  const [reasons, setReasons] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (callReasons?.length > 0) {
      setReasons(callReasons.map((reason) => reason.reason));
    } else {
      setReasons([]);
    }
  }, [callReasons]);

  const handleSelectReason = (reason: string) => {
    const updatedReasons = filters.reasons.includes(reason)
      ? filters.reasons.filter((item) => item !== reason)
      : [...filters.reasons, reason];

    dispatch(resetStatusCursors());

    updateFilters({
      reasons: updatedReasons,
    });
  };

  const handleSelectTag = (tag: keyof CallTags) => {
    const updates: Partial<FilterParams> = {
      ...(tag === "is_new_user" && { isNewUser: !filters.isNewUser }),
      ...(tag === "is_urgent" && { isUrgent: !filters.isUrgent }),
      ...(tag === "is_external" && { isExternal: !filters.isExternal }),
      ...(tag === "is_care_team" && { isCareTeam: !filters.isCareTeam }),
    };

    dispatch(resetStatusCursors());

    updateFilters(updates);
  };

  return (
    <FilterButton
      className={className}
      label="Reason"
      numberOfSelectedFilters={
        filters.reasons.length +
        Number(filters.isNewUser) +
        Number(filters.isUrgent) +
        Number(filters.isExternal) +
        Number(filters.isCareTeam)
      }
    >
      <div className="space-y-3" aria-label="Reason filter">
        {reasons.length > 0 && (
          <>
            <p className="text-base font-medium">Reasons</p>
            {reasons.map((reason) => (
              <Checkbox
                name={reason}
                key={reason}
                labelClassName="capitalize text-sm ml-3 font-medium"
                variant="primary-filled"
                label={reason}
                checked={filters.reasons.includes(reason)}
                onChange={() => handleSelectReason(reason)}
              />
            ))}

            <hr className="border-gray-foreground" />
          </>
        )}

        <p className="text-base font-medium">Tags</p>

        <Checkbox
          name="New User"
          labelClassName="capitalize text-sm ml-3 font-medium"
          variant="primary-filled"
          label="New"
          checked={filters.isNewUser}
          onChange={() => handleSelectTag("is_new_user")}
        />

        <Checkbox
          name="Urgent"
          labelClassName="capitalize text-sm ml-3 font-medium"
          variant="primary-filled"
          label="Urgent"
          checked={filters.isUrgent}
          onChange={() => handleSelectTag("is_urgent")}
        />

        <Checkbox
          name="External"
          labelClassName="capitalize text-sm ml-3 font-medium"
          variant="primary-filled"
          label="External"
          checked={filters.isExternal}
          onChange={() => handleSelectTag("is_external")}
        />

        <Checkbox
          name="Care Team"
          labelClassName="capitalize text-sm ml-3 font-medium"
          variant="primary-filled"
          label="Care Team"
          checked={filters.isCareTeam}
          onChange={() => handleSelectTag("is_care_team")}
        />
      </div>
    </FilterButton>
  );
};

export default ReasonFilter;
