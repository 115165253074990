import SiteDropdown from "@src/components/VoiceAgent/SupportLineView/Analytics/SiteDropdown";
import { Pages } from "@src/helpers/constants";
import {
  getSelectedDateRange,
  makeSlug,
  roundBigNumber,
} from "@src/helpers/helpers";
import {
  useGetMetricsQuery,
  useGetSitesQuery,
} from "@src/store/voiceAgent/api";
import { AnalyticsPeriod, SupportLineTab } from "@src/store/voiceAgent/types";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router";
import AnalyticsNumberCard from "./AnalyticsNumberCard";
import CallReasons from "./CallReasons";
import DateSelectors from "./DateSelectors/DateSelectors";
import Leaderboard from "./Leaderboard";

const analyticsTabUrl = `${Pages.VOICE_AGENT}?tab=${makeSlug(SupportLineTab.ANALYTICS)}`;
const weekPeriodUrl = `${analyticsTabUrl}&period=${makeSlug(AnalyticsPeriod.WEEK)}`;
const monthPeriodUrl = `${analyticsTabUrl}&period=${makeSlug(AnalyticsPeriod.MONTH)}`;
const yearPeriodUrl = `${analyticsTabUrl}&period=${makeSlug(AnalyticsPeriod.YEAR)}`;
const customPeriodUrl = `${analyticsTabUrl}&period=${makeSlug(AnalyticsPeriod.CUSTOM)}`;

const Analytics = () => {
  const { data: sites = [] } = useGetSitesQuery();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const selectedSiteId = searchParams.get("site_id") || "";
  const siteIdParam = selectedSiteId ? `&site_id=${selectedSiteId}` : "";

  const selectedPeriod =
    searchParams.get("period") || makeSlug(AnalyticsPeriod.WEEK);
  const startOfCurrWeek = dayjs().startOf("isoWeek").format("YYYY-MM-DD");
  const startOfCurrMonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const startOfCurrYear = dayjs().startOf("year").format("YYYY-MM-DD");
  const today = dayjs().format("YYYY-MM-DD");

  const defaultStartDate =
    selectedPeriod === makeSlug(AnalyticsPeriod.YEAR)
      ? startOfCurrYear
      : selectedPeriod === makeSlug(AnalyticsPeriod.MONTH)
        ? startOfCurrMonth
        : startOfCurrWeek;

  const isValidDate = (date: string | null) =>
    dayjs(date, "YYYY-MM-DD", true).isValid();

  const selectedStartDate = isValidDate(searchParams.get("start-date"))
    ? searchParams.get("start-date")
    : defaultStartDate;
  const selectedEndDate = isValidDate(searchParams.get("end-date"))
    ? searchParams.get("end-date")
    : today;

  const { data: metrics, isLoading } = useGetMetricsQuery({
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    siteId: selectedSiteId,
  });

  const handleSelectSite = (siteId: number | null) => {
    const siteIdParam = siteId ? `&site_id=${siteId}` : "";
    navigate(
      `${Pages.VOICE_AGENT}?tab=${makeSlug(SupportLineTab.ANALYTICS)}&period=${selectedPeriod}${siteIdParam}&start-date=${selectedStartDate}&end-date=${selectedEndDate}`,
    );
  };

  return (
    <div className="grid grid-rows-tab-layout gap-3 overflow-hidden h-full">
      <div className="w-full flex flex-col space-y-3">
        <div className="w-full flex flex-col md:flex-row items-top justify-between gap-3">
          <p className="text-base font-medium md:pt-2">
            Track total calls, reasons for calls, and completed threads
          </p>
          <DateSelectors
            weekBaseUrl={`${weekPeriodUrl}${siteIdParam}`}
            monthBaseUrl={`${monthPeriodUrl}${siteIdParam}`}
            yearBaseUrl={`${yearPeriodUrl}${siteIdParam}`}
            customBaseUrl={`${customPeriodUrl}${siteIdParam}`}
          />
        </div>
        <div
          className={`h-9 w-full grid items-center gap-3 ${sites?.length > 1 ? "grid-cols-2" : "grid-cols-1"}`}
        >
          <p className="text-xl font-semibold truncate">
            {getSelectedDateRange(
              selectedStartDate,
              selectedEndDate,
              selectedPeriod,
            )}
          </p>
          {sites?.length > 1 && (
            <SiteDropdown
              selectedSiteId={selectedSiteId ? Number(selectedSiteId) : null}
              onSelectSite={handleSelectSite}
              className="max-w-full md:max-w-48 ml-auto"
            />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-6 overflow-y-auto pr-1 scrollbar">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          <AnalyticsNumberCard
            title="Inbound Calls"
            className="md:h-34"
            number={roundBigNumber(metrics?.incoming_calls)}
            isLoading={isLoading}
          />
          <AnalyticsNumberCard
            title="Call Minutes"
            className="md:h-34"
            number={roundBigNumber(Number(metrics?.calls_minutes))}
            isLoading={isLoading}
          />
          <AnalyticsNumberCard
            title="Completed Threads"
            className="md:h-34 col-span-2 md:col-span-1"
            number={roundBigNumber(metrics?.total_completed)}
            isLoading={isLoading}
          />
        </div>
        <div className="flex flex-col md:grid grid-cols-9 gap-6">
          <div className="p-5 border border-gray-250 rounded-lg h-fit w-full col-span-4">
            <p>Call Reasons</p>
            <p className="text-xs text-tertiary font-normal mt-1 mb-3">
              Calls aggregated by reason of why the call ended or completed
            </p>

            <CallReasons
              callReasons={metrics?.call_reasons || []}
              isLoading={isLoading}
            />
          </div>

          <Leaderboard
            className="col-span-5 md:min-h-full"
            isLoading={isLoading}
            completedByUser={metrics?.completed_by_user || []}
          />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
