import Skeleton from "@mui/material/Skeleton";
import AnalyticsTable from "@src/components/VoiceAgent/SupportLineView/Analytics/AnalyticsTable";
import { useMemo } from "react";

interface FrontDeskMetricsTableSkeletonProps {
  rows?: number;
  bgColor?: string;
  animation?: "pulse" | "wave";
}

const FrontDeskMetricsTableSkeleton = ({
  rows = 15,
  bgColor = "#F8F8F8",
  animation = "wave",
}: FrontDeskMetricsTableSkeletonProps) => {
  const columns = [
    { label: "Clinic", accessor: "clinic" },
    { label: "Calls", accessor: "total_calls" },
    { label: "Minutes", accessor: "total_minutes" },
    { label: "Avg min", accessor: "avg_minutes" },
    { label: "Texts", accessor: "text" },
    { label: "Internal", accessor: "internal" },
    { label: "Review", accessor: "review" },
    { label: "Unassigned", accessor: "unassigned" },
    { label: "Completed", accessor: "completed" },
  ];

  const data = useMemo(() => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        clinic: (
          <div className="space-y-1">
            <Skeleton
              animation={animation}
              variant="rounded"
              width={150}
              height={14}
              sx={{ bgcolor: bgColor, borderRadius: "16px" }}
            />
            <Skeleton
              animation={animation}
              variant="rounded"
              width={100}
              height={10}
              sx={{ bgcolor: bgColor, borderRadius: "16px" }}
            />
          </div>
        ),
        total_calls: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        total_minutes: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        avg_minutes: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        text: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        internal: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        review: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        unassigned: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        completed: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={50}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
      });
    }
    return tableData;
  }, [rows, bgColor, animation]);

  return (
    <div className="grid w-full">
      <AnalyticsTable
        columns={columns}
        data={data}
        className="hidden md:block"
      />

      <div className="block md:hidden space-y-2">
        {[...Array(rows)].map((_, index) => (
          <div
            key={`item-${index}`}
            className="border border-gray-foreground rounded-lg p-3 flex flex-col"
          >
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={150}
                  height={14}
                  sx={{ bgcolor: bgColor, borderRadius: "14px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={100}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
              </div>

              <Skeleton
                animation={animation}
                variant="rounded"
                width={32}
                height={32}
                sx={{ bgcolor: bgColor, borderRadius: "8px" }}
              />
            </div>

            <div className="grid grid-cols-4 gap-2 mt-2 overflow-hidden transition-all h-9">
              {[...Array(4)].map((_, index) => (
                <div className="space-y-0.5" key={`number-${index}`}>
                  <Skeleton
                    animation={animation}
                    variant="rounded"
                    width={50}
                    height={12}
                    sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                  />
                  <Skeleton
                    animation={animation}
                    variant="rounded"
                    width={40}
                    height={14}
                    sx={{ bgcolor: bgColor, borderRadius: "14px" }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrontDeskMetricsTableSkeleton;
